import {LoanInfo} from "../../ShowLoan";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import React from "react";
import {Loading, MyInput, MyLabel} from "../../../../../utils/Components";
import {Button, Confirm, Form, Modal, Table, TextArea} from "semantic-ui-react";
import moment from "moment";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";

export interface LoanReschedule {
    schedule_date: string
    reschedule_principal: number
    reschedule_interest: number
    interest_added: number
    principal_added: number
    time_created: string
    reschedule_status: 'active' | 'removed'
}

export default function LoanReschedules(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loading, setLoading] = React.useState({message: "", show: false})

    const [reschedule, setReschedule] = React.useState<LoanReschedule | undefined>(undefined)

    const reschedule_loan = () => {
        const system_error = () => {
            ToastsStore.error(`Error occurred while modifying loan, please retry`)
        }
        setConfirm({
            ...confirm, open: true, content: "Are you sure you want to reschedule this loan",
            onCancel: () => setConfirm({...confirm, open: false}),
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                setLoading({message: "Interesting loan, please wait", show: true})

                Req.reschedule_loan({loan_id: params.loanInfo.loan_id, date: params.loanInfo.others.reschedule.date})
                    .then((response) => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                ToastsStore.success(`Successfully rescheduled loan`)
                                params.setLoanInfo(Utils.format_loan_data(response))
                            } else if (response.data.code === 503) {
                                ToastsStore.info(`This service is not yet available at the moment`)
                            } else {
                                ToastsStore.error(response.data.msg ?? "Error while rescheduling loan")
                            }
                        } else {
                            system_error()
                        }
                    })
                    .catch((error: any) => {
                        console.error(error)
                        setLoading({message: "", show: false})
                        system_error()
                    })
            }
        })
    }

    const delete_reschedule = () => {
        if (showDelete.delete_reason.trim().length < 5) {
            ToastsStore.error("Enter valid reason as to why you are deleting this loan reschedule")
        } else {
            setLoading({show: true, message: 'Deleting reschedule, please wait'})

            Req.remove_loan_reschedule({
                loan_id: params.loanInfo.loan_id, schedule_date: showDelete.schedule_date, reason: showDelete.delete_reason.trim()
            })
                .then((response) => {
                    setLoading({...loading, show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Successfully deleted loan reschedule")
                            setShowDelete({schedule_date: '', delete_reason: '', show: false})
                            params.setLoanInfo(Utils.format_loan_data(response))
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Loan not found or was already cleared")
                        }
                    } else {
                        ToastsStore.error("Could not delete loan reschedule, please retry")
                    }
                })
                .catch(() => {
                    setLoading({...loading, show: false})
                    ToastsStore.error("Could not delete loan reschedule, please retry")
                })
        }
    }

    const [showDelete, setShowDelete] = React.useState({schedule_date: '', delete_reason: '', show: false})

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <Modal open={showDelete.show} basic size='mini' centered={false}>
                <div className="modal_div modal_form payment_modal">
                    <div>
                        <MyLabel title="Reason for removing reschedule"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason as to why you are removing reschedule' rows={4}
                                value={showDelete.delete_reason}
                                onChange={((event, data) => {
                                    setShowDelete({...showDelete, delete_reason: data.value as string})
                                })}/>
                        </Form>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button
                                        negative onClick={() => setShowDelete({show: false, schedule_date: '', delete_reason: ''})}
                                        content="Close Window" size='mini' fluid icon='close' labelPosition={"left"}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button
                                        positive onClick={delete_reschedule} content="Remove Reschedule" size='mini' fluid
                                        icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 col-md-8 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '85px'}}/>
                                    <Table.HeaderCell className="time_row">Payment Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Total Added</Table.HeaderCell>
                                    <Table.HeaderCell className="time_row">Date Rescheduled</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.others.reschedule.data
                                        .map((info, index) =>
                                            <Table.Row key={info.schedule_date} className={info.reschedule_status === 'removed' ? 'reversed' : ''}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">
                                                    {Utils.row_number(index)}
                                                </Table.Cell>

                                                <Table.Cell style={{width: '80px'}} textAlign="center">
                                                    <Button
                                                        primary={true} disabled={info.reschedule_status === 'removed'}
                                                        size='mini' icon='info circle' compact onClick={() => setReschedule(info)}/>

                                                    <Button
                                                        negative={true} size='mini' icon='trash' compact
                                                        disabled={
                                                            !user.roles.core_roles?.includes("remove_reschedule") ||
                                                            info.reschedule_status === 'removed'
                                                        }
                                                        onClick={() => setShowDelete({delete_reason: '',
                                                            schedule_date: info.schedule_date, show: true})}/>
                                                </Table.Cell>

                                                <Table.Cell className="time_row">{Utils.localise_date(info.schedule_date)}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>
                                                    {Utils.comma_number(info.principal_added + info.interest_added)}
                                                </Table.Cell>
                                                <Table.Cell className="time_row">{Utils.localise_date(info.time_created)}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 p-1 col-md-4 h-100">
                    <div className="form">
                        <div className="form_header">{reschedule ? "Reschedule details" : "Click on a reschedule to view details"}</div>

                        <div className='form_content'>
                            <div className="row m-0">
                                <div className="col-6 col-md-6 pl-0 pr-1">
                                    <MyInput placeholder="" title="Interest Before" name="reschedule_interest"
                                             value={Utils.comma_number(reschedule ?
                                                 reschedule.reschedule_interest : params.loanInfo.payments.summary.interest.balance)}/>
                                </div>
                                <div className="col-6 col-md-6 pl-1 pr-0">
                                    <MyInput placeholder="" title="Principal Before" name="reschedule_principal"
                                             value={Utils.comma_number(reschedule ? reschedule.reschedule_principal :
                                                 params.loanInfo.payments.summary.principal.balance)}/>
                                </div>
                            </div>

                            <div className="row m-0">
                                <div className="col-6 col-md-6 pl-0 pr-1">
                                    <MyInput placeholder="" title="Interest Added" name="interest_added"
                                             value={reschedule ? Utils.comma_number(reschedule.interest_added) : ""}/>
                                </div>
                                <div className="col-6 col-md-6 pl-1 pr-0">
                                    <MyInput placeholder="" title="Principal Added" name="principal_added"
                                             value={reschedule ? Utils.comma_number(reschedule.principal_added) : ""}/>
                                </div>
                            </div>

                            <MyInput placeholder="" title="Total Interest & Principal Added" name="total_added"
                                     value={reschedule ? Utils.comma_number(reschedule.principal_added + reschedule.interest_added) : ""}/>

                            <div className="row m-0">
                                <div className="col-6 col-md-6 pl-0 pr-1">
                                    <MyInput placeholder="" title="Schedule Payment Date" name="schedule_date"
                                             value={reschedule ? moment(reschedule.schedule_date).format("ll") : ""}/>
                                </div>
                                <div className="col-6 col-md-6 pl-1 pr-0">
                                    <MyInput placeholder="" title="Time Rescheduled" name="time_created"
                                             value={reschedule ? moment(reschedule.time_created).format("lll") : ""}/>
                                </div>
                            </div>
                        </div>
                        <div className="form_footer">
                            {
                                (params.loanInfo.others.reschedule.date === '' || params.loanInfo.loan_status === 'cleared') ?
                                    <Button negative labelPosition={"left"} icon="close" size='mini'
                                            content="Cannot be rescheduled try extension" fluid/> :
                                    <Button positive content="Reschedule Loan" icon="save" fluid labelPosition="left" size="tiny"
                                            onClick={reschedule_loan}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
