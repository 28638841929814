import {ToastsStore} from "react-toasts";
import Utils, {InputFile} from "../../../../utils/Utils";
import {Req} from "../../../../utils/Req";
import {Button, Image} from "semantic-ui-react";
import placeholder from "../../../../../assets/images/image.png";
import {Loading, MyDateInput, MyInput, MySelect, select_type} from "../../../../utils/Components";
import React from "react";
import {AccountTransfer} from "../CashTransfers";
import {Account} from "../../../../utils/Models";
import {BranchUser} from "../../../../nav/SideBar";

export default function TransferInfo(params: {
    transfer: AccountTransfer, initial: AccountTransfer, accounts: Account[], branchUser: BranchUser, transfers: AccountTransfer[],
    setTransfers: (transfers: AccountTransfer[]) => void, setTransfer: (transfer: AccountTransfer) => void
}) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [transfer, setTransfer] = React.useState(params.transfer)
    const handle_change = (name: string, value: string | number) => {
        setTransfer({...transfer, [name]: value})
    }

    const save_transfer = () => {
        const amount = Utils.strip_commas(transfer.amount_transferred)

        if (transfer.office_id === 0) {
            ToastsStore.error("Select office making the bank deposit")
        } else if (transfer.user_id === 0) {
            ToastsStore.error("Select user who made the deposit")
        } else if (transfer.account_from === 0) {
            ToastsStore.error("Select account money was deposited to")
        } else if (transfer.account_to === 0) {
            ToastsStore.error("Select account money was deposited to")
        } else if (!Utils.is_valid_number(amount) || parseFloat(amount) < 0) {
            ToastsStore.error("Enter valid amount deposited")
        } else if (transfer.date_transferred === "") {
            ToastsStore.error("Select date transfer was made")
        } else if (transfer.time_transferred === "") {
            ToastsStore.error("Select time transfer was made")
        } else {
            setLoading({message: "Saving cash transfer, please wait", show: true})
            Req.save_cash_transfer({
                account_from: transfer.account_from, account_to: transfer.account_to, amount_transferred: parseFloat(amount),
                date_transferred: `${transfer.date_transferred} ${transfer.time_transferred}`, office_id: transfer.office_id,
                transfer_id: transfer.transfer_id, transfer_receipt: transfer.transfer_receipt, user_id: transfer.user_id
            })
                .then((response) => {
                    setLoading({message: "", show: false})

                    if (response.data.hasOwnProperty("code") && response.data['code'] === 1) {
                        let newTransfer = {
                            ...transfer, transfer_id: response.data.transfer_id, date_modified: response.data.date_modified,
                            transfer_receipt: response.data.transfer_receipt === "" ? transfer.transfer_receipt :
                                new InputFile(`${Req.BASE_URL}${response.data.transfer_receipt}`)
                        }

                        if (transfer.transfer_id === 0) {
                            params.setTransfers([newTransfer, ...params.transfers])
                        } else {
                            params.setTransfers(params.transfers.map((aTransfer) =>
                                aTransfer.transfer_id === newTransfer.transfer_id ? newTransfer : aTransfer
                            ))
                        }
                        params.setTransfer(newTransfer)
                        ToastsStore.success("Successfully saved cash transfer")
                    } else {
                        ToastsStore.error("Error while creating cash transfer, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while creating cash transfer, please retry")
                })
        }
    }

    React.useEffect(() => {
        setTransfer(params.transfer)
    }, [params.transfer])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <div className="form">
                <div className="form_header">
                    {
                        transfer.transfer_id === 0 ? "Create new cash transfer"
                            : `Update transfer at ${Utils.localise_date(transfer.date_transferred)}`
                    }
                </div>

                <div className="form_content">
                    <div className="mb-2">
                        <div style={{height: '150px'}}>
                            <Image centered src={transfer.transfer_receipt === null ? placeholder : transfer.transfer_receipt.file_src}/>
                        </div>
                        <div className="mt-2">
                            <input type="file" className="input_file" id="deposit_receipt" accept="image/*"
                                   onChange={(event) => {
                                       const files = event.target.files;
                                       if (files !== null && files.length > 0) {
                                           const file: File | null = files.item(0);
                                           if (file !== null) {
                                               Utils.format_file(file as File)
                                                   .then(result => {
                                                       setTransfer({...transfer, 'transfer_receipt': result})
                                                   })
                                           }
                                       }
                                   }}/>
                            <label htmlFor="deposit_receipt" className="ui tiny primary button fluid">
                                <i className="ui upload icon"/> Select money transfer receipt
                            </label>
                        </div>
                    </div>

                    <MySelect
                        change={(value) => setTransfer({...transfer, office_id: (value as number), user_id: 0})}
                        title="Branch making the cash transfer" name="office_id" value={transfer.office_id}
                        options={[
                            {text: "Select branch", value: 0},
                            ...params.branchUser.branches.map((branch) => ({text: branch.office_name, value: branch.office_id}))
                        ]}/>

                    <MySelect
                        change={(value) => setTransfer({...transfer, user_id: value as number})}
                        title="User making the cash transfer" name="user_id" value={transfer.user_id}
                        placeholder="Select depositor"
                        options={[
                            {text: "Select user", value: 0},
                            ...params.branchUser.users
                                .filter((user) => user.office_id === transfer.office_id && user.user_status === "active")
                                .map((user) => ({text: user.user_name, value: user.user_id}))
                        ]}/>

                    <MyInput
                        placeholder="Enter amount transfered" title="Amount Transfered" name="amount_transferred"
                        change={handle_change} value={Utils.comma_input(transfer.amount_transferred)}/>

                    <div className={'row mx-0'}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MySelect
                                title="Source account" name="account_from" value={transfer.account_from}
                                change={(value: select_type) => handle_change('account_from', (value as number))}
                                options={
                                    [
                                        {text: "Select an account ", value: 0},
                                        ...params.accounts.map((account) => ({text: account.account_name, value: account.account_id}))
                                    ]
                                }/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MySelect
                                title="Destination account" name="account_to" value={transfer.account_to}
                                change={(value: select_type) => handle_change('account_to', (value as number))}
                                options={
                                    [
                                        {text: "Select an account ", value: 0},
                                        ...params.accounts.map((account) => ({text: account.account_name, value: account.account_id}))
                                    ]
                                }/>
                        </div>
                    </div>

                    <div className={'row mx-0'} style={{marginBottom: '15px'}}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MyDateInput
                                title="Date transfered" value={transfer.date_transferred} name="date_transferred"
                                placeholder="Select date loan acquired" change={handle_change} maxDate={Utils.today()}/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MyDateInput
                                title="Time transfered" value={transfer.time_transferred} name="time_transferred"
                                placeholder="Select time loan acquired" change={handle_change} type={"time"}/>
                        </div>
                    </div>

                    {
                        transfer.transfer_id > 0 &&
                        <>
                            <MyInput
                                placeholder="Date and time created" title="Date and time created"
                                name="date_modified" value={Utils.localise_date_time(transfer.date_modified)}/>
                        </>
                    }
                </div>

                <div className="form_footer">
                    <div className='row m-0'>
                        <div className='col-6 pl-0 pr-1'>
                            <Button negative onClick={() => params.setTransfer(params.initial)}
                                    content='Clear Data' fluid size='tiny' icon='trash' labelPosition='left'/>
                        </div>
                        <div className='col-6 pl-1 pr-0'>
                            <Button
                                onClick={save_transfer} size='tiny' icon='save' labelPosition='left' fluid positive
                                content={transfer.transfer_id === 0 ? 'Save Transfer' : 'Update Transfer'}
                                disabled={transfer.transfer_id > 0}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
