import React from "react"
import Utils from "../../../utils/Utils"
import {Table} from "semantic-ui-react"

interface Report {
    account: number
    allocated: number
    allocated_by: string
    allocation_date: string
    cash: number
    disbursed: number
    extra: number
    loan_charges: number
    interest: number
    others: number
    penalties: number
    principal: number
    user: string
    user_id: number
}

export default function TillAllocationReport(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Report[]>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <div className="table_container container_no_footer">
            <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                        <Table.HeaderCell className="time_row">Date Allocated</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '180px'}}>Cashier Name</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Allocated</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Disbursed</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Account Fees</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Loan Fees</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Principal Paid</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Interest Paid</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Other Fees Paid</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Penalties Paid</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Extra Paid</Table.HeaderCell>
                        <Table.HeaderCell className="amount_row">Cash At Hand</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        statements
                            .map((allocation, index) =>
                                <Table.Row key={index}>
                                    {
                                        (index === statements.length - 1) ?
                                            <Table.Cell style={{width: '370px'}} colSpan={3}>Total Amounts</Table.Cell> :
                                            <>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell className="time_row">{Utils.localise_date(allocation.allocation_date)}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{allocation.user}</Table.Cell>
                                            </>
                                    }
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.allocated)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.disbursed)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.account)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.loan_charges)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.principal)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.interest)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.others)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.penalties)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.extra)}</Table.Cell>
                                    <Table.Cell className="amount_row">{Utils.comma_number(allocation.cash)}</Table.Cell>
                                </Table.Row>
                            )
                    }
                </Table.Body>
            </Table>
        </div>
    )
}
