import {Loading, MyLabel} from "../../utils/Components";
import React from "react";
import {Button, Checkbox, Modal, Radio, Table} from "semantic-ui-react";
import Utils from "../../utils/Utils";
import {Req} from "../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Account} from "../../utils/Models";

export interface AccountFee {
    fee_id: number
    fee_name: number
    fee_amount: number
    date_paid: string
    amount_paid: number
    account_id: number
}

interface Payment {
    account_id: number
    fee_id: number
    fee_amount: number
}

export default function AccountFees(params: {
    client_id: number, show: boolean, client_name: string,
    payments: AccountFee[], accounts: Account[], close: (payments: AccountFee[]) => void,
}) {
    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [payments, setPayments] = React.useState(Array<Payment>())
    const [accountID, setAccountID] = React.useState(0)

    const save_fees = () => {
        if (calculate_fees() === 0) {
            ToastsStore.info("No payment available to be made")
        } else if (accountID === 0) {
            ToastsStore.error("Select an account receiving the payment")
        } else {
            setLoading({show: true, title: "Saving payment, please wait"})
            Req.save_account_fee({
                client_id: params.client_id,
                payments: JSON.stringify(payments),
                account_id: accountID
            })
                .then((response) => {
                    setLoading({show: false, title: ""})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            params.close(response.data.account_fees)
                            ToastsStore.success("Account fees saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Some fees were already paid, reload client and make payments again")
                        } else if (response.data.code === 3) {
                            ToastsStore.error(response.data.status === 'empty' ?
                                "Your account hs not been opened up to make transactions" :
                                "Your account has been closed for today's transactions"
                            )
                        }
                    } else {
                        ToastsStore.error("Error while making payments, please retry")
                    }
                })
                .catch(() => {
                    setLoading({show: false, title: ""})
                    ToastsStore.error("Error while making payments, please retry")
                })
        }
    }

    const calculate_fees = () => {
        let fees = 0;
        payments.forEach((payment) => fees += payment.fee_amount)
        return fees
    }

    React.useEffect(() => {
        setPayments(
            params.payments
                .filter((payment) => payment.date_paid === "")
                .map((payment) => ({fee_amount: payment.fee_amount, fee_id: payment.fee_id, account_id: payment.account_id}))
        )
        setAccountID(0)
    }, [params.show, params.client_id])

    return (
        <>
            <Loading show={loading.show} text={loading.title} hide={() => setLoading({...loading, 'show': false})}/>

            <Modal size='mini' open={params.show} centered={false} closeOnDimmerClick={true} onClose={() => params.close(params.payments)}>
                <div className="modal_div">
                    <div className="form_header">Account Fees For {params.client_name}</div>

                    <div className="form_content mb-4">
                        <div className="table_container mb-4" style={{height: 'auto'}}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '40px'}}/>
                                        <Table.HeaderCell style={{width: '120px'}}>Fee Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '95px'}}>Fee Amount</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '95px'}}>Amount Paid</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        params.payments.map((payment) =>
                                            <Table.Row key={payment.fee_id}>
                                                <Table.Cell style={{width: '40px'}}>
                                                    {
                                                        payment.date_paid === '' &&
                                                        <Checkbox
                                                            checked={
                                                                payments
                                                                    .map((payment) => (payment.fee_id))
                                                                    .includes(payment.fee_id)
                                                            }
                                                            onChange={(event, data) => {
                                                                if (data.checked) {
                                                                    setPayments([...payments, {
                                                                        fee_id: payment.fee_id,
                                                                        fee_amount: payment.fee_amount,
                                                                        account_id: payment.account_id
                                                                    }])
                                                                } else {
                                                                    setPayments(payments.filter(
                                                                        (_payment) => payment.fee_id !== _payment.fee_id))
                                                                }
                                                            }}/>
                                                    }
                                                </Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{payment.fee_name}</Table.Cell>
                                                <Table.Cell style={{width: '95px'}}>{Utils.comma_number(payment.fee_amount)}</Table.Cell>
                                                <Table.Cell style={{width: '95px'}}>{Utils.comma_number(payment.amount_paid)}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                        <MyLabel title={"Account where fees have been deposited"}/>
                        <div className={"row m-0"}>
                            {
                                params.accounts.map((aAccount) =>
                                    <div key={aAccount.account_id} className='col-12 px-0 py-1'>
                                        <Radio
                                            label={aAccount.account_name}
                                            checked={accountID === aAccount.account_id}
                                            onChange={(event, data) => {
                                                if (data.checked) {
                                                    setAccountID(aAccount.account_id)
                                                }
                                            }}/>
                                    </div>
                                )
                            }
                        </div>


                        <div className="disburse_summary">
                            <div className="title">Amount to be Collected</div>
                            <div className="amount">{Utils.comma_number(calculate_fees())}</div>
                        </div>
                    </div>

                    {
                        payments.length > 0 &&
                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={() => params.close(params.payments)} content="Close Window"
                                            size='mini' fluid icon='close' labelPosition={"left"}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={save_fees} content="Save Payment" size='mini' fluid
                                            icon="save" labelPosition={"left"} disabled={payments.length === 0}/>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </Modal>
        </>
    )
}
