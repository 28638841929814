import React from "react";
import {SelectData} from "../../../utils/Components";
import {Accordion, Button, Checkbox, Form, Menu, Modal} from "semantic-ui-react";
import {Req} from "../../../utils/Req";
import {User} from "../users/Users";
import {UserRights} from "../../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";

export class Rights {
    static has_right = (user_rights: UserRights, right: string) => {
        return user_rights.hasOwnProperty(right)
    }

    static create_clients = "create_clients"
    static create_users = "create_users"
    static manage_user_rights = "manage_user_rights"

    static admin_panel = "admin_panel"

    static create_loan = "create_loan"
    static view_loans = "view_loans"
    static approve_loan = "approve_loan"
    static verify_loan = "verify_loan"
    static disburse_loan = "disburse_loan"
    static loan_extensions = "loan_extensions"
    static loan_inspections = "loan_inspections"

    static make_payments = "make_payments"
    static view_payments = "view_payments"
    static bank_deposits = "bank_deposits"

    static creditor_management = "creditor_management"
    static salary_management = "salary_management"
    static accounting_books = "accounting_books"
    static financial_reports = "financial_reports"
}

export interface AccessRight {
    right_id: string
    right_name: string
    branches: Array<number>
}

export const initial_right: Array<AccessRight> = [
    /*user management*/
    {right_id: Rights.admin_panel, right_name: "View the system admin panel", branches: []},

    /*user management*/
    {right_id: Rights.create_clients, right_name: "Create and Manage Client Data", branches: []},
    {right_id: Rights.create_users, right_name: "Create and Manage User Data", branches: []},
    {right_id: Rights.manage_user_rights, right_name: "Manage User Rights", branches: []},

    /*loan management*/
    {right_id: Rights.view_loans, right_name: "View Loans", branches: []},
    {right_id: Rights.create_loan, right_name: "Create Loan", branches: []},
    {right_id: Rights.approve_loan, right_name: "Approve Loan", branches: []},
    {right_id: Rights.verify_loan, right_name: "Verify Loan", branches: []},
    {right_id: Rights.disburse_loan, right_name: "Disburse Loan", branches: []},
    {right_id: Rights.loan_extensions, right_name: "Make Loan Extensions", branches: []},
    {right_id: Rights.loan_inspections, right_name: "Inspect Potential Loan Applicants", branches: []},

    /*loan repayments*/
    {right_id: Rights.make_payments, right_name: "Make Loan Payments", branches: []},
    {right_id: Rights.view_payments, right_name: "View Loan Payments", branches: []},

    /*accounting data*/
    {right_id: Rights.bank_deposits, right_name: "Save bank deposit transactions", branches: []},
    {right_id: Rights.creditor_management, right_name: "Creditors loan management", branches: []},
    {right_id: Rights.salary_management, right_name: "Pay and manage user salaries", branches: []},

    /*reporting*/
    {right_id: Rights.accounting_books, right_name: "Create books of accounts", branches: []},
    {right_id: Rights.financial_reports, right_name: "Make financial reports", branches: []},
]

export default function AccessRights(params: { show: boolean, close: (rights?: UserRights) => void, user: User, offices: Array<SelectData> }) {
    const [saving, setSaving] = React.useState(false)
    const [accessRights, setAccessRights] = React.useState(Array<AccessRight>())
    const [displayBranch, setDisplayBranch] = React.useState(false)

    const display_rights = () => {
        return (
            accessRights.map((accessRight, right_index) =>
                <Menu.Item key={right_index}>
                    <Accordion.Title key={accessRight.right_id} content={accessRight.right_name} active={true} index={right_index}/>

                    <Accordion.Content
                        active={true}
                        content={
                            params.offices
                                .filter((office) => office.value as number !== 0)
                                .filter((office) => displayBranch ? office.value as number === params.user.office_id : true)
                                .map((office, office_index) =>
                                    <Form.Checkbox
                                        className="mb-1" checked={accessRight.branches.includes(office.value as number)}
                                        key={accessRight.right_id + "_" + office_index} label={office.text} value={office.value as number}
                                        onChange={((event, data) => {
                                            let branches: Array<number> = []
                                            if (data.checked) {
                                                branches = [...accessRight.branches, office.value as number]
                                            } else {
                                                accessRight.branches.forEach((accessible_office) => {
                                                    if (accessible_office !== office.value as number) {
                                                        branches = [...branches, accessible_office]
                                                    }
                                                })
                                            }
                                            let userRights: Array<AccessRight> = [];
                                            let new_user_right = {...accessRight, 'branches': branches}
                                            accessRights.forEach((user_right) => {
                                                if (user_right.right_id === new_user_right.right_id) {
                                                    userRights = [...userRights, new_user_right]
                                                } else {
                                                    userRights = [...userRights, user_right]
                                                }
                                            })
                                            setAccessRights(userRights)
                                        })}/>
                                )
                        }/>
                </Menu.Item>
            )
        )
    }

    const save_access_rights = () => {
        const right_offices: Array<number> = params.offices
            .filter((office) => office.value as number !== 0)
            .filter((office) => displayBranch ? office.value as number === params.user.office_id : true)
            .map((office) => (office.value as number))

        const user_rights: UserRights = {}
        accessRights.forEach((right) => {
            const right_branches = right.branches.filter((branch) => right_offices.includes(branch))
            if (right_branches.length > 0) {
                user_rights[right.right_id] = right_branches
            }
        })

        setSaving(true)
        Req.update_user_rights({access_rights: JSON.stringify(user_rights), user_id: params.user.user_id})
            .then((response) => {
                setSaving(false)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    ToastsStore.success("User rights successfully updated")
                    params.close(user_rights)
                } else {
                    ToastsStore.error("Could not update user rights, please retry")
                }
            })
            .catch(() => {
                setSaving(false)
                ToastsStore.error("Could not update user rights, please retry")
            })
    }

    React.useEffect(() => {
        setAccessRights(params.user.access_rights)
    }, [params.user.access_rights])

    return (
        <>
            <Modal open={params.show} basic size='mini' centered={false} onClose={() => params.close()}>
                <div className="modal_div access_rights">
                    <div className="form">
                        <div className="form_header">{params.user.last_name + " " + params.user.first_name}</div>
                        <div className="form_content">
                            <Checkbox
                                checked={displayBranch} label="User branches only" className='mb-0'
                                onChange={(event, data) => {
                                    setDisplayBranch(data.checked as boolean);
                                }}/>

                            {params.user.user_id !== 0 && <Accordion as={Menu} vertical fluid>{display_rights()}</Accordion>}

                        </div>
                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 p-0 pr-1">
                                    <Button
                                        negative onClick={() => params.close()} content="Cancel Update" size='mini' fluid
                                        icon="close" labelPosition={"left"}/>
                                </div>
                                <div className="col-6 p-0 pl-1">
                                    <Button
                                        positive onClick={save_access_rights} content="Update User Rights" size='mini' fluid loading={saving}
                                        icon="save" labelPosition={"left"} disabled={saving || params.user.user_id === 0}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
