import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import React from "react";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import Utils from "../../../utils/Utils";

export interface BranchSummary {
    branch_id: number
    branch_name: string
    fee_balance: number
    income_balance: number
    income_collection: number
    interest_balance: number
    interest_balances: number
    loans: number
    loans_active: number
    loans_active_balance: number
    loans_complete: number
    loans_defaulters: number
    loans_defaulters_amount: number
    loans_due: number
    loans_due_balance: number
    loans_due_defaulters: number
    loans_due_defaulters_amount: number
    penalty_balance: number
    principal_balance: number
    principal_collection: number
    principal_disbursed: number
    total_clients: number
}

export default function Branch() {
    const user = useAppSelector(getStaff)

    const [summary, setSummary] = React.useState({
        fee_balance: 0, income_balance: 0, interest_balances: 0, interest_balance: 0, loans: 0, loans_active: 0,
        loans_active_balance: 0, loans_complete: 0, loans_defaulters: 0, loans_defaulters_amount: 0, loans_due: 0, loans_due_balance: 0,
        loans_due_defaulters: 0, loans_due_defaulters_amount: 0, penalty_balance: 0, principal_balance: 0, total_clients: 0,
    })

    const search_data = () => {
        Req.get_branch_summary({branch_id: user.staff.office_id, max_date: Utils.today(), min_date: '2018-01-01'})
            .then((response) => {
                if (response.data.hasOwnProperty("summary")) {
                    setSummary(response.data.summary)
                } else {
                    ToastsStore.error("Error while loading summaries")
                }
            })
            .catch(() => {
                ToastsStore.error("Error while loading summaries")
            })
    }

    const format_figure = (number: number) => {
        return Utils.comma_number(Utils.roundToTwo(Utils.roundToTwo(number, '0')))
    }

    React.useEffect(() => {
        search_data()
    }, [])

    React.useEffect(() => {
        if (user.staff.user_id > 0) {
            search_data()
        }
    }, [user.staff.user_id])

    return (
        <div className="row m-0">
            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">groups</i></div>
                    <div>
                        <div className="title">Total Clients</div>
                        <div className="money">{Utils.comma_number(summary.total_clients, '', ' Clients')}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">groups</i></div>
                    <div>
                        <div className="title">Total Loans</div>
                        <div className="money">{Utils.comma_number(summary.loans, '', ' Loans')}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">Principal Balances</div>
                        <div className="money">{format_figure(summary.principal_balance)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">Incomes Balance</div>
                        <div className="money">{format_figure(summary.income_balance)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">Projected Interest Balance</div>
                        <div className="money">{format_figure(summary.interest_balances)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">Actual Interest Balance</div>
                        <div className="money">{format_figure(summary.interest_balance)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">Penalty Balance</div>
                        <div className="money">{format_figure(summary.penalty_balance)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">Loan Fees Balance</div>
                        <div className="money">{format_figure(summary.fee_balance)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">Complete Loans</div>
                        <div className="money">{Utils.comma_number(summary.loans_complete, '', ' Loans')}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">{Utils.comma_number(summary.loans_active, '', ' Active Loans')}</div>
                        <div className="money">{format_figure(summary.loans_active_balance)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">{Utils.comma_number(summary.loans_defaulters, '', ' Defaulter')}</div>
                        <div className="money">{format_figure(summary.loans_defaulters_amount)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">{Utils.comma_number(summary.loans_due, '', ' Due Loans')}</div>
                        <div className="money">{format_figure(summary.loans_due_balance)}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-1">
                <div className="dashboard_item">
                    <div><i className="material-icons-outlined">money</i></div>
                    <div>
                        <div className="title">{Utils.comma_number(summary.loans_due_defaulters, '', ' Due Defaulter')}</div>
                        <div className="money">{format_figure(summary.loans_due_defaulters_amount)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
