import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import {LoanInfo} from "../../ShowLoan";
import {Loading, MyInput, MyLabel} from "../../../../../utils/Components";
import {Button, Confirm, Form, Modal, Table, TextArea} from "semantic-ui-react";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";
import moment from "moment";

export interface Penalty {
    penalty_id: number
    penalty_reason: string
    penalty_amount: string | number
    date_penalised: string
    time_created: string
    penalty_balance: string | number
    penalised_by: string
    penalty_status: 'active' | 'removed'
}

export default function LoanPenalties(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loader, setLoader] = React.useState({message: "", show: false})

    const initial_penalty: Penalty = {
        penalty_id: 0, penalised_by: `${user.staff.last_name} ${user.staff.first_name}`, penalty_balance: "",
        penalty_amount: "", penalty_reason: "", date_penalised: Utils.today(), penalty_status: 'active', time_created: ''
    }

    const [penalty, setPenalty] = React.useState(initial_penalty)
    const handle_penalty = (name: string, value: string) => {
        setPenalty({...penalty, [name]: value})
    }
    const add_penalty = () => {
        const penalty_amount = Utils.strip_commas(penalty.penalty_amount)
        if (!Utils.is_valid_number(penalty_amount) || parseFloat(penalty_amount) < 0) {
            ToastsStore.error("Enter valid penalty added")
        } else {
            setConfirm({
                ...confirm,
                'open': true,
                'content': `Are you sure you want to proceed with adding a penalty of ${Utils.comma_number(parseFloat(penalty_amount))} to this loan?`,
                'onConfirm': () => {
                    setConfirm({...confirm, 'open': false})
                    setLoader({message: "Adding loan penalty, please wait", show: true})

                    Req.add_penalty({
                        penalty_amount: penalty_amount, penalty_reason: penalty.penalty_reason, loan_id: params.loanInfo.loan_id
                    })
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    ToastsStore.success("Successfully added penalty to loan")
                                    setPenalty(initial_penalty)
                                    params.setLoanInfo(Utils.format_loan_data(response))
                                } else if (response.data.code === 2) {
                                    ToastsStore.error("Loan not found")
                                } else if (response.data.code === 3) {
                                    ToastsStore.error("Loan was already cleared, you cannot add a penalty")
                                }
                            } else {
                                ToastsStore.error("Could not add penalty to loan, please retry")
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                            ToastsStore.error("Could not add penalty to loan, please retry")
                        })
                },
                'onCancel': () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    const [showDelete, setShowDelete] = React.useState({penalty_id: 0, delete_reason: '', show: false})

    const delete_penalty = () => {
        if (showDelete.delete_reason.trim().length < 5) {
            ToastsStore.error("Enter valid reason as to why you are deleting this penalty")
        } else {
            setLoader({show: true, message: 'Deleting extension, please wait'})

            Req.remove_penalty({
                loan_id: params.loanInfo.loan_id, penalty_id: showDelete.penalty_id,
                reason_removed: showDelete.delete_reason.trim()
            })
                .then((response) => {
                    setLoader({...loader, show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Successfully deleted loan penalty")
                            setShowDelete({penalty_id: 0, delete_reason: '', show: false})
                            params.setLoanInfo(Utils.format_loan_data(response))
                        } else {
                            ToastsStore.error(response.data.msg ?? "Error removing penalty")
                        }
                    } else {
                        ToastsStore.error("Could not delete loan extension, please retry")
                    }
                })
                .catch(() => {
                    setLoader({...loader, show: false})
                    ToastsStore.error("Could not delete loan extension, please retry")
                })
        }
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 col-md-8 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '80px'}}></Table.HeaderCell>
                                    <Table.HeaderCell className="time_row">Time Created</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Created By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Balance</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.others.penalties.map((aPenalty, index) =>
                                        <Table.Row key={aPenalty.penalty_id}
                                                   className={aPenalty.penalty_status === 'removed' ? 'reversed' : ''}>
                                            <Table.Cell style={{width: '40px'}} textAlign="center">
                                                {Utils.row_number(index)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '80px'}} textAlign={"center"}>
                                                <Button
                                                    primary={true} disabled={penalty.penalty_status === 'removed'}
                                                    size='mini' icon='info circle' compact onClick={() => setPenalty(aPenalty)}/>

                                                <Button
                                                    negative={true} size='mini' icon='trash' compact
                                                    disabled={
                                                        !user.roles.core_roles?.includes("remove_penalty") ||
                                                        penalty.penalty_status === 'removed'
                                                    }

                                                    onClick={() => setShowDelete({
                                                        delete_reason: '', penalty_id: aPenalty.penalty_id, show: true
                                                    })}/>
                                            </Table.Cell>
                                            <Table.Cell className="time_row">{moment(aPenalty.time_created).format("lll")}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{aPenalty.penalised_by}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(aPenalty.penalty_amount)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(aPenalty.penalty_balance)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 p-1 col-md-4 h-100">
                    <div className="form">
                        <div className='form_content'>
                            {
                                penalty.penalty_id > 0 &&
                                <MyInput placeholder="Added By" title="Added By" name="penalised_by" value={penalty.penalised_by}/>
                            }

                            <MyInput placeholder="Enter penalty amount added" title="Enter penalty amount added" name="penalty_amount"
                                     change={handle_penalty} value={Utils.comma_input(penalty.penalty_amount)}
                                     disabled={penalty.penalty_id > 0}/>

                            {
                                penalty.penalty_id > 0 &&
                                <>
                                    <MyInput placeholder="Penalty balance" title="Penalty balance" name="penalty_balance"
                                             value={Utils.comma_number(penalty.penalty_balance as number)}/>

                                    <div className={'row mx-0'}>
                                        <div className='col-6 pl-0 pr-1'>
                                            <MyInput placeholder="Penalty Date" title="Penalty Date" name="date_penalised"
                                                     value={moment(penalty.date_penalised).format("ll")}/>
                                        </div>
                                        <div className='col-6 pl-1 pr-0'>
                                            <MyInput placeholder="Time Created" title="Time Created" name="time_created"
                                                     value={moment(penalty.time_created).format("lll")}/>
                                        </div>
                                    </div>
                                </>
                            }

                            <div style={{marginTop: '15px'}}>
                                <MyLabel title="Reason for adding penalty"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter reason as to why you have added a penalty to loan'
                                        rows={3} value={penalty.penalty_reason} disabled={penalty.penalty_id > 0}
                                        onChange={((event, data) => {
                                            handle_penalty('penalty_reason', data.value as string)
                                        })}/>
                                </Form>
                            </div>

                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={() => setPenalty(initial_penalty)} labelPosition={"left"}
                                            icon='close' content="Clear Data" size='mini' fluid
                                            disabled={params.loanInfo.payments.summary.principal.balance === 0}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={add_penalty} content="Save Penalty" size='mini' fluid
                                            icon="save" labelPosition={"left"} disabled={penalty.penalty_id > 0}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={showDelete.show} basic size='mini' centered={false}>
                <div className="modal_div modal_form payment_modal">
                    <div>
                        <MyLabel title="Reason for deleting penalty"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason as to why you are removing penalty' rows={4}
                                value={showDelete.delete_reason}
                                onChange={((event, data) => {
                                    setShowDelete({...showDelete, delete_reason: data.value as string})
                                })}/>
                        </Form>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button
                                        negative onClick={() => setShowDelete({show: false, penalty_id: 0, delete_reason: ''})}
                                        content="Close Window" size='mini' fluid icon='close' labelPosition={"left"}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button
                                        positive onClick={delete_penalty} content="Delete Extension" size='mini' fluid
                                        icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
