import React from "react";
import {Button, Table} from "semantic-ui-react";
import Utils from "../../../../../utils/Utils"
import {MyDateInput, MyInput, MySelect, select_type} from "../../../../../utils/Components";
import moment from "moment";
import {CalculatedSchedule} from "./LoanCalculator"
import {Schedule} from "../schedule/PaymentScheduleIdeal"
import {ToastsStore} from "react-toasts";

export function CalculateInterest(props: {
    result: (data?: CalculatedSchedule, schedules?: Array<Schedule>, interestRate?: number) => void
}) {
    const [data, setData] = React.useState<CalculatedSchedule>({
        period: "", principal: "", expected: "", interval: 7, payment: "", start: Utils.today(), end: "", rate_type: 'simple'
    })
    const [interestRate, setInterestRate] = React.useState(0)
    const [schedules, setSchedules] = React.useState<Array<Schedule>>([])

    const handle_change = (name: string, value: string | number) => {
        setData({...data, [name]: value})
    }

    const calculate_interest = () => {
        const get_next_payment = (current: string) => {
            const date = moment(current + " 23:59:59", "YYYY-MM-DD hh:mm:ss")
            if (data.interval === 30) {
                date.add(1, "month")
            } else {
                date.add(data.interval, "days")
            }
            return date.format("YYYY-MM-DD")
        }

        if (Utils.is_valid_number(data.period) && Utils.is_valid_number(data.principal) && Utils.is_valid_number(data.expected)) {
            const total_payable = parseFloat(data.expected)
            let total_installments
            if (data.interval === 30) {
                total_installments = parseInt(data.period)
            } else if (data.interval === 14) {
                total_installments = parseInt(data.period) * 2
            } else {
                total_installments = parseInt(data.period) * 4
            }

            let start_date = data.start
            const installment = total_payable / total_installments
            const installment_principal = (parseFloat(data.principal) * installment) / total_payable
            const installment_interest = ((total_payable - parseFloat(data.principal)) * installment) / total_payable
            const new_schedules: Array<Schedule> = []

            let principal_payable = installment_principal
            let principal_offset = 0
            let interest_payable = installment_interest
            let interest_offset = 0
            setInterestRate((100 * (parseFloat(data.expected) - parseFloat(data.principal))) /
                (parseFloat(data.principal) * parseInt(data.period)))

            if (principal_payable % 100 > 0) {
                principal_offset = principal_payable % 100
                principal_payable = Math.ceil(principal_payable - principal_offset)
                principal_offset = principal_offset * total_installments
            }

            if (interest_payable % 100 > 0) {
                interest_offset = installment_interest % 100
                interest_payable = Math.floor(interest_payable - interest_offset)
                interest_offset = interest_offset * total_installments
            }

            let balance = total_payable

            for (let index = 0; index < total_installments; index++) {
                if (index === total_installments - 1) {
                    principal_payable = Math.ceil(principal_offset + principal_payable)
                    interest_payable = Math.floor(interest_payable + interest_offset)
                    setData({...data, 'end': start_date})
                }
                balance = balance - (interest_payable + principal_payable)

                new_schedules.push({
                    balance: balance,
                    date: start_date,
                    interest: interest_payable,
                    principal: principal_payable,
                    total: interest_payable + principal_payable,
                    month: index + 1
                })
                start_date = get_next_payment(start_date)
            }
            setSchedules(new_schedules)
        }
    }

    const use_data = () => {
        if (!Utils.is_valid_number(data.principal)) {
            ToastsStore.error("Provide a valid principal")
        } else if (!Utils.is_valid_number(data.expected)) {
            ToastsStore.error("Provide a valid amount expected")
        } else if (!Utils.is_valid_number(data.period)) {
            ToastsStore.error("Provide a valid loan period")
        } else if (schedules.length === 0) {
            ToastsStore.error("No schedules have been calculated")
        } else {
            props.result(data, schedules, interestRate)
        }
    }

    React.useEffect(() => {
        calculate_interest()
    }, [data.period, data.expected, data.start, data.interval, data.principal])

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 col-md-4 p-1">
                        <MyInput
                            placeholder="Insert principal amount" title="Principal Receivable" name="principal" value={data.principal}
                            change={handle_change}/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyInput
                            placeholder="Insert amount expected" title="Total Expected"
                            name="expected" value={data.expected} change={handle_change}/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyInput
                            placeholder="Loan Period (months)" title="Loan Period" name="period" value={data.period} change={handle_change}/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MySelect
                            change={(value: select_type) => handle_change('interval', parseInt(value as string))}
                            title="Loan payment Interval" name="interval" value={data.interval}
                            options={
                                [
                                    {text: "One week (7 days)", value: 7},
                                    {text: "Two weeks (14 days)", value: 14},
                                    {text: "One Month (30 days)", value: 30}
                                ]
                            }/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyDateInput
                            title="Payment start date" value={data.start} name="start"
                            placeholder="Payment start date" change={handle_change}/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyDateInput
                            title="Payment due date" value={data.end} name="end" disabled={true}
                            placeholder="Payment due date" change={handle_change}/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyInput
                            placeholder="Loan Rate" title="Loan Rate" disabled={true}
                            name="rate" value={`${interestRate.toFixed(2)}%`}/>
                    </div>

                    <div className="col-12 p-0">
                        <div className="table_container interest_calculator_container">
                            <Table celled striped compact size='small' inverted color='grey' selectable>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                                        <Table.HeaderCell>Payment Date</Table.HeaderCell>
                                        <Table.HeaderCell>Principal Payment</Table.HeaderCell>
                                        <Table.HeaderCell>Interest Payment</Table.HeaderCell>
                                        <Table.HeaderCell>Total Payment</Table.HeaderCell>
                                        <Table.HeaderCell>Balance After</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        schedules.map((schedule, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell width={1}>
                                                    {Utils.row_number(index)}
                                                </Table.Cell>
                                                <Table.Cell>{Utils.localise_date(schedule.date)}</Table.Cell>
                                                <Table.Cell>{Utils.comma_number(schedule.principal)}</Table.Cell>
                                                <Table.Cell>{Utils.comma_number(schedule.interest)}</Table.Cell>
                                                <Table.Cell>{Utils.comma_number(schedule.total)}</Table.Cell>
                                                <Table.Cell>{Utils.comma_number(schedule.balance)}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 offset-md-8 p-1 calculated_buttons">
                        <Button.Group floated='right' size='mini'>
                            <Button negative onClick={() => props.result()}>Discard Data</Button>
                            <Button.Or/>
                            <Button positive onClick={use_data}>Use Data</Button>
                        </Button.Group>
                    </div>
                </div>
            </div>
        </>
    )
}
