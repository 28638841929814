import React from "react";
import {Req} from "../../../utils/Req";
import Utils from "../../../utils/Utils";
import {Loading, MyInput, MySelect, select_type} from "../../../utils/Components";
import {Button, Modal, Table} from "semantic-ui-react";
import {User} from "./Users";
import {ToastsStore} from "react-toasts";

export interface Office {
    office_id: number
    office_name: string
    office_location: string
    office_manager: number
    office_contact: string
}

export const initial_office: Office = {office_contact: "", office_id: 0, office_location: "", office_manager: 0, office_name: ""}

export default function Offices(params: {
    show: boolean, save: (office: Office) => void, users: Array<User>, offices: Array<Office>, close: () => void
}) {
    const [loading, setLoading] = React.useState({show: false, title: "Loading offices, please wait..."})

    const [office, setOffice] = React.useState<Office>(initial_office)
    const handle_change = (name: string, value: string | number) => {
        setOffice({...office, [name]: value})
    }

    const save_office = () => {
        if (Utils.invalid_full_name(office.office_name)) {
            ToastsStore.error("Please enter valid name of the office")
        } else if (Utils.invalid_full_name(office.office_name)) {
            ToastsStore.error("Please enter valid name of the office")
        } else if (Utils.invalid_full_name(office.office_name)) {
            ToastsStore.error("Please enter valid office contact")
        } else {
            Req.save_office(office)
                .then(response => {
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        const new_office = {...office, office_id: response.data.office_id}
                        setOffice(new_office)
                        params.save(new_office)
                        ToastsStore.success("Office data saved successfully")
                    } else {
                        ToastsStore.error("Error while saving office, please retry")
                    }
                })
                .catch(error => {
                    ToastsStore.error(error.message)
                })
        }
    }

    return (
        <>
            <Loading show={loading.show} text={loading.title} hide={() => setLoading({...loading, 'show': false})}/>

            <Modal open={params.show} basic size='small' centered={false} onClose={params.close}>
                <div className='modal_div'>
                    <div className="row m-0 h-100">
                        <div className="col-12 col-md-6 p-1 h-100">
                            <div className="table_container full_height">
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign='center'>No.</Table.HeaderCell>
                                            <Table.HeaderCell>Office Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            params.offices.map((office, index) =>
                                                <Table.Row key={office.office_id} onClick={() => setOffice(office)}>
                                                    <Table.Cell style={{width: '50px'}} textAlign='center'>
                                                        {Utils.row_number(index)}
                                                    </Table.Cell>
                                                    <Table.Cell>{office.office_name}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 p-1 h-100">
                            <div className="form">
                                <div className="form_header">{office.office_id === 0 ? "Create new office" : office.office_name}</div>
                                <div className="form_content">
                                    <MyInput placeholder="Enter office name" title="Office Name" name="office_name"
                                             change={handle_change} value={office.office_name}/>

                                    <MyInput placeholder="Enter office location" title="Office Location" name="office_location"
                                             change={handle_change} value={office.office_location}/>

                                    <MyInput placeholder="Enter office contact" title="Office Contact" name="office_contact"
                                             change={handle_change} value={office.office_contact}/>

                                    <MySelect
                                        change={(value: select_type) => handle_change('office_manager', value as number)}
                                        title="Office Manager" name="office_manager" value={office.office_manager} style={{'marginBottom': '0px'}}
                                        options={[
                                            {text: "Select an office manager", value: 0},
                                            ...params.users
                                                .filter((user) => user.user_status === 'active')
                                                .map((user) => ({text: `${user.first_name} ${user.last_name}`, value: user.user_id}))
                                        ]}/>
                                </div>
                                <div className="form_footer">
                                    <div className='row m-0'>
                                        <div className='col-6 p-0 pr-1'>
                                            <Button negative onClick={() => setOffice(initial_office)} content='Clear Data'
                                                    fluid size='tiny' icon='trash' labelPosition='left'/>
                                        </div>
                                        <div className='col-6 p-0 pl-1'>
                                            <Button positive onClick={save_office} content='Save Office'
                                                    fluid size='tiny' icon='save' labelPosition='left'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
