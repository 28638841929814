import React from "react"
import {Button, Confirm, Form, Table, TextArea} from "semantic-ui-react";
import {MyDateInput, MyInput, MyLabel, MySelect} from "../../../utils/Components"
import {Req} from "../../../utils/Req";
import PhoneInput from "react-phone-input-2"
import {ToastsStore} from "react-toasts";
import Utils, {ConfirmAction, initial_confirm} from "../../../utils/Utils";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";

export interface Inspection {
    inspection_id: number
    inspected_by: number
    user_name: string
    date_inspected: string

    client_name: string
    client_contact: string
    work_address: string
    residential_address: string
    marital_status: string
    market_value: string
    job_period: string
    weekly_earning: string | number
    weekly_savings: string | number
    payment_capacity: number
    guarantor_commitment: number
    self_employed: number
    rider_remittance: string | number
    surety: string
    properties: string
}

export default function InspectionReports() {
    const user = useAppSelector(getStaff)
    const [saving, setSaving] = React.useState(false)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)

    const [search, setSearch] = React.useState({min: Utils.min_month_date, max: Utils.max_month_date, user_name: ""})
    const handle_change = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }

    const initial_inspection: Inspection = {
        client_contact: "", client_name: "", date_inspected: Utils.today(), guarantor_commitment: 0, inspection_id: 0,
        job_period: "", marital_status: "Other", market_value: "", payment_capacity: 0, properties: "",
        rider_remittance: "", self_employed: 0, surety: "", weekly_earning: "", weekly_savings: "", work_address: "",
        inspected_by: user.staff.user_id, residential_address: "", user_name: `${user.staff.last_name} ${user.staff.first_name}`
    }

    const [inspections, setInspections] = React.useState<Array<Inspection>>([])
    const [inspection, setInspection] = React.useState(initial_inspection)
    const handle_inspection = (name: string, value: string | number) => {
        setInspection({...inspection, [name]: value})
    }

    const [searching, setSearching] = React.useState(false)
    const search_inspections = () => {
        setSearching(true)
        Req.get_inspection_reports({client_name: search.user_name, max_date: search.max, min_date: search.min})
            .then((response) => {
                setSearching(false)
                if (response.data.hasOwnProperty("inspections")) {
                    const inspections_: Array<Inspection> = response.data["inspections"]
                    if (inspections_.length === 0) {
                        ToastsStore.info("No inspection reports available")
                    }
                    setInspections(inspections_)
                } else {
                    ToastsStore.error("Could not load inspection reports, please retry")
                }
            })
            .catch(() => {
                setSearching(false)
                ToastsStore.error("Could not load inspection reports, please retry")
            })
    }

    const save_inspection = () => {
        if (Utils.invalid_full_name(inspection.client_name)) {
            ToastsStore.error("Enter valid client name")
        } else if (Utils.invalid_contact(inspection.client_contact)) {
            ToastsStore.error("Enter valid client contact")
        } else if (!Utils.is_valid_number(inspection.market_value)) {
            ToastsStore.error("Enter a valid market value")
        } else if (!Utils.is_valid_number(inspection.job_period)) {
            ToastsStore.error("Enter period at work place")
        } else if (!Utils.is_valid_number(inspection.weekly_earning as string)) {
            ToastsStore.error("Enter valid client earning")
        } else if (!Utils.is_valid_number(inspection.weekly_savings as string)) {
            ToastsStore.error("Enter valid weekly savings")
        } else if (!Utils.is_valid_number(inspection.rider_remittance as string)) {
            ToastsStore.error("Enter valid amount remitted to property owner")
        } else {
            setConfirm({
                cancelButton: "Cancel", confirmButton: "Proceed", open: true,
                content: "Do you want to proceed saving this inspection",
                onCancel: () => setConfirm({...confirm, 'open': false}),
                onConfirm: () => {
                    setSaving(true)
                    setConfirm({...confirm, 'open': false})
                    Req.save_inspection_report(inspection)
                        .then((response) => {
                            setSaving(false)
                            if (response.data.hasOwnProperty("code")) {
                                const new_inspection = {...inspection, inspection_id: response.data.inspection_id}
                                if (inspection.inspection_id === 0) {
                                    setInspection(new_inspection)
                                    setInspections([new_inspection, ...inspections])
                                } else {
                                    setInspections(
                                        inspections.map((inspection_) =>
                                            inspection_.inspection_id === inspection.inspection_id ? new_inspection : inspection_)
                                    )
                                }
                                ToastsStore.error("Inspection report successfully saved")
                            } else {
                                ToastsStore.error("Could not save inspection report, please retry")
                            }
                        })
                        .catch(() => {
                            setSaving(false)
                            ToastsStore.error("Could not save inspection report, please retry")
                        })

                }
            })
        }
    }

    React.useEffect(() => {
        search_inspections()
    }, [])

    return (
        <>
            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} size='mini' content={confirm.content}/>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MyDateInput
                                    title="" value={search.min} name="min" placeholder="Minimum Date"
                                    change={handle_change} maxDate={search.max}/>
                            </div>
                            <div>
                                <MyDateInput
                                    title="" value={search.max} name="max" placeholder="Maximum Date"
                                    change={handle_change} minDate={search.min}/>
                            </div>
                            <div>
                                <MyInput placeholder="Enter client name" title="" name="user_name"
                                         value={search.user_name} change={handle_change}/>
                            </div>
                        </div>
                        <div className="button_bar">
                            <Button primary floated='right' size='mini' loading={searching} disabled={searching}
                                    onClick={search_inspections}>Search</Button>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell>Processed By</Table.HeaderCell>
                                    <Table.HeaderCell>Date Inspected</Table.HeaderCell>
                                    <Table.HeaderCell>Weekly Earning</Table.HeaderCell>
                                    <Table.HeaderCell>Weekly Saving</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    inspections.map((inspection, index) =>
                                        <Table.Row
                                            key={inspection.inspection_id}
                                            onClick={() => {
                                                let inspection_ = {...inspection}
                                                if (Utils.invalid_contact(inspection_.client_contact.toString())) {
                                                    inspection_ = {...inspection_, 'client_contact': ''}
                                                } else {
                                                    inspection_ = {
                                                        ...inspection_,
                                                        'client_contact': '+' + inspection_.client_contact
                                                    }
                                                }
                                                setInspection(inspection_)
                                            }}>
                                            <Table.Cell width={1} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell>{inspection.client_name}</Table.Cell>
                                            <Table.Cell>{inspection.user_name}</Table.Cell>
                                            <Table.Cell>{Utils.localise_date(inspection.date_inspected)}</Table.Cell>
                                            <Table.Cell>{Utils.comma_number(inspection.weekly_earning as number)}</Table.Cell>
                                            <Table.Cell>{Utils.comma_number(inspection.weekly_savings as number)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="form">

                        <div className="form_content no_header">
                            <MyInput placeholder="Enter client name" title="Client Name" name="client_name"
                                     value={inspection.client_name} change={handle_inspection}/>

                            <div>
                                <MyLabel title="Client Contact"/>
                                <PhoneInput
                                    countryCodeEditable={false} containerStyle={{marginBottom: '15px'}}
                                    country={'ug'} value={inspection.client_contact} onlyCountries={['ug']}
                                    onChange={((value) => handle_inspection('client_contact', value))}/>
                            </div>

                            {/*client work address*/}
                            <div style={{marginBottom: '15px'}}>
                                <MyLabel title="Client Work Address"/>
                                <Form>
                                    <TextArea
                                        placeholder='Describe work address of client' rows={3}
                                        value={inspection.work_address}
                                        onChange={((event, data) => {
                                            handle_inspection('work_address', data.value as string)
                                        })}/>
                                </Form>
                            </div>

                            {/*client residential address*/}
                            <div style={{marginBottom: '15px'}}>
                                <MyLabel title="Client Residential Address"/>
                                <Form>
                                    <TextArea
                                        placeholder='Describe the residential address of client' rows={3}
                                        value={inspection.residential_address}
                                        onChange={((event, data) => {
                                            handle_inspection('residential_address', data.value as string)
                                        })}/>
                                </Form>
                            </div>

                            {/*client marital status*/}
                            <MySelect
                                change={(value) => handle_inspection('marital_status', value as string)}
                                title="Select client marital status" name="marital_status"
                                value={inspection.marital_status}
                                options={[
                                    {text: "Not Disclosed", value: "Other"},
                                    {text: "Married", value: "Married"},
                                    {text: "Divorced", value: "Divorced"},
                                    {text: "Widow", value: "Widow"},
                                    {text: "Widower", value: "Widower"},
                                    {text: "Single", value: "Single"}
                                ]}/>

                            {/*Job, stage market value*/}
                            <MyInput placeholder="Enter monetary value" title="Work place market value"
                                     value={inspection.market_value} change={handle_inspection} name="market_value"/>

                            {/*Job period*/}
                            <MyInput
                                placeholder="Time in months" title="Time at current work"
                                value={inspection.job_period} change={handle_inspection} name="job_period"/>

                            {/*weekly earning*/}
                            <MyInput
                                placeholder="Weekly earnings" title="Approximate weekly earning"
                                value={inspection.weekly_earning as string} change={handle_inspection} name="weekly_earning"/>

                            {/*weekly saving*/}
                            <MyInput
                                placeholder="Weekly savings" title="Approximate weekly savings"
                                value={inspection.weekly_savings as string} change={handle_inspection} name="weekly_savings"/>

                            {/*client payment capacity*/}
                            <MySelect
                                change={(value) => handle_inspection('payment_capacity', value as number)}
                                title="Capacity of customer payment"
                                name="payment_capacity" value={inspection.payment_capacity}
                                options={[
                                    {text: "Very Low chances of payment", value: 0},
                                    {text: "Relatively higher payment chances", value: 1},
                                ]}/>

                            {/*Guarantor payment commitment*/}
                            <MySelect
                                change={(value) => handle_inspection('guarantor_commitment', value as number)}
                                title="Guarantor commitment to payment"
                                name="guarantor_commitment" value={inspection.guarantor_commitment}
                                options={[
                                    {text: "Low guarantor commitment to pay", value: 0},
                                    {text: "Relatively high commitment to pay", value: 1},
                                ]}/>

                            {/*client marital status*/}
                            <MySelect
                                change={(value) => handle_inspection('self_employed', value as number)}
                                title="Owner of workplace or work property"
                                name="self_employed" value={inspection.self_employed}
                                options={[
                                    {text: "Does not own property (e.g. Motorbike)", value: 0},
                                    {text: "Owns the workplace or property", value: 1},
                                ]}/>

                            {/*rider remittance*/}
                            <MyInput
                                placeholder="Remittance to owner" title="Money remitted to owner of property"
                                value={inspection.rider_remittance as string} change={handle_inspection} name="rider_remittance"/>

                            {/*property surety*/}
                            <div style={{marginBottom: '15px'}}>
                                <MyLabel title="Surety of workplace or property"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter details to prove ownership of property e.g (Motorbike Card)'
                                        rows={2} value={inspection.surety}
                                        onChange={((event, data) => {
                                            handle_inspection('surety', data.value as string)
                                        })}/>
                                </Form>
                            </div>

                            {/*any other possessions*/}
                            <div style={{marginBottom: '15px'}}>
                                <MyLabel title="Any other client possessions"/>
                                <Form>
                                    <TextArea
                                        placeholder='Describe any other valuable possessions, separated by comma(,)'
                                        rows={4} value={inspection.properties}
                                        onChange={((event, data) => {
                                            handle_inspection('properties', data.value as string)
                                        })}/>
                                </Form>
                            </div>

                            {/*Inspected By*/}
                            <MyInput
                                placeholder="Inspector Name" title="Inspection made by" disabled={true}
                                value={inspection.user_name} name="user_name"/>

                            {/*Date of Inspection*/}
                            <MyInput
                                placeholder="Inspection Date" title="Date of Inspection" name="date_inspected" disabled={true}
                                value={Utils.localise_date(inspection.date_inspected)} style={{marginBottom: '0px'}}/>
                        </div>

                        <div className="form_footer">
                            <div className='row m-0'>
                                <div className='col-3 p-0 pr-1'>
                                    <Button negative content='Reject' fluid size='tiny' disabled={saving}
                                            onClick={() => {
                                            }}/>
                                </div>
                                <div className='col-3 p-0 pr-1 pl-1'>
                                    <Button primary content='Approve' fluid size='tiny' disabled={saving}
                                            onClick={() => {
                                            }}/>
                                </div>
                                <div className='col-3 p-0 pr-1 pl-1'>
                                    <Button negative onClick={() => setInspection(initial_inspection)} content='Clear'
                                            fluid size='tiny' disabled={saving}/>
                                </div>
                                <div className='col-3 p-0 pl-1'>
                                    <Button positive onClick={save_inspection} content='Save' disabled={saving} fluid size='tiny'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
