import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import {Loading, MyDateInput, MyInput, MyLabel, MySelect} from "../../../../../utils/Components";
import {Button, Confirm, Form, Modal, Table, TextArea} from "semantic-ui-react";
import {LoanInfo} from "../../ShowLoan";
import {Req} from "../../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";

export interface Extension {
    extension_id: number
    extended_by: string
    extension_period: number | string
    extension_reason: string
    extension_start_date: string
    payment_due_date: string
    extension_interest: number
    extension_principal: number
    date_extended: string
    extension_rate: string | number
    interest_added: number
    principal_added: number
    interest_mode: 'principal_only' | 'interest_principal'
    extension_status: 'active' | 'removed'
}


export function LoanExtension(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)

    const [loading, setLoading] = React.useState({message: "Extending loan, please wait", show: false})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)

    const initial_extension: Extension = {
        interest_added: 0, extended_by: "", extension_id: 0, extension_period: '1', extension_reason: "",
        date_extended: "", payment_due_date: "", interest_mode: "interest_principal",
        extension_start_date: params.loanInfo.due_date, extension_rate: params.loanInfo.loan_rate,
        extension_principal: params.loanInfo.payments.summary.principal.balance, extension_status: 'active',
        extension_interest: params.loanInfo.payments.summary.interest.balance, principal_added: 0
    }

    const [extension, setExtension] = React.useState(initial_extension)

    const handle_change = (name: string, value: string) => {
        setExtension({...extension, [name]: value})
    }

    const extend_loan = () => {
        if (!Utils.is_valid_number(extension.extension_rate as string) || parseFloat(extension.extension_rate as string) < 0
            || parseFloat(extension.extension_rate as string) > 100) {
            ToastsStore.error("Enter a valid extension rate")
        } else if (extension.extension_start_date === "") {
            ToastsStore.error("Enter valid start date of extension")
        } else if (!Utils.is_valid_number(extension.extension_period as string) || parseInt(extension.extension_period as string) < 1) {
            ToastsStore.error("Enter a valid extension period")
        } else if (extension.extension_reason.trim().length < 5) {
            ToastsStore.error("Enter valid reason for loan extension")
        } else {
            setConfirm({
                ...confirm,
                open: true, content: 'Are you sure you want to proceed with extending this loan?',
                onConfirm: () => {
                    setConfirm({...confirm, 'open': false})
                    setLoading({...loading, show: true})
                    Req.extend_loan({
                        loan_id: params.loanInfo.loan_id,
                        extension_period: parseInt(extension.extension_period as string),
                        extension_reason: extension.extension_reason,
                        payment_start_date: extension.extension_start_date,
                        extension_principal: extension.extension_principal,
                        extension_interest: extension.extension_interest,
                        extension_rate: parseFloat(extension.extension_rate as string),
                        interest_mode: extension.interest_mode
                    })
                        .then((response) => {
                            setLoading({...loading, show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    ToastsStore.success("Successfully extended loan")
                                    setExtension(initial_extension)
                                    params.setLoanInfo(Utils.format_loan_data(response))
                                } else if (response.data.code === 2) {
                                    ToastsStore.error("Loan not found")
                                } else if (response.data.code === 3) {
                                    ToastsStore.error("Loan due date not yet reached")
                                } else if (response.data.code === 4) {
                                    ToastsStore.error("Loan was already cleared, you cannot extend it")
                                }
                            } else {
                                ToastsStore.error("Could not extend loan, please retry")
                            }
                        })
                        .catch(() => {
                            setLoading({...loading, show: false})
                            ToastsStore.error("Could not extend loan, please retry")
                        })
                },
                onCancel: () => setConfirm({...confirm, open: false})
            })
        }
    }

    const [showDelete, setShowDelete] = React.useState({extension_id: 0, delete_reason: '', show: false})

    const delete_extension = () => {
        if (showDelete.delete_reason.trim().length < 5) {
            ToastsStore.error("Enter valid reason as to why you are deleting this extension")
        } else {
            setLoading({show: true, message: 'Deleting extension, please wait'})

            Req.remove_loan_extension({
                loan_id: params.loanInfo.loan_id, extension_id: showDelete.extension_id, removal_reason: showDelete.delete_reason.trim()
            })
                .then((response) => {
                    setLoading({...loading, show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Successfully deleted loan extension")
                            setShowDelete({extension_id: 0, delete_reason: '', show: false})
                            params.setLoanInfo(Utils.format_loan_data(response))
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Loan not found or was already cleared")
                        }
                    } else {
                        ToastsStore.error("Could not delete loan extension, please retry")
                    }
                })
                .catch(() => {
                    setLoading({...loading, show: false})
                    ToastsStore.error("Could not delete loan extension, please retry")
                })
        }
    }

    React.useEffect(() => {
        setExtension(initial_extension)
    }, [params.loanInfo])

    React.useEffect(() => {
        setExtension(initial_extension)
    }, [])

    return (
        <>
            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} centered={false}
                     size='mini' content={confirm.content}/>

            <Loading show={loading.show} text={loading.message}/>

            <Modal open={showDelete.show} basic size='mini' centered={false}>
                <div className="modal_div modal_form payment_modal">
                    <div>
                        <MyLabel title="Reason for deleting extension"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason as to why you are removing extension' rows={4}
                                value={showDelete.delete_reason}
                                onChange={((event, data) => {
                                    setShowDelete({...showDelete, delete_reason: data.value as string})
                                })}/>
                        </Form>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button
                                        negative onClick={() => setShowDelete({show: false, extension_id: 0, delete_reason: ''})}
                                        content="Close Window" size='mini' fluid icon='close' labelPosition={"left"}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button
                                        positive onClick={delete_extension} content="Delete Extension" size='mini' fluid
                                        icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 col-md-8 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign={'center'}>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '80px'}}/>
                                    <Table.HeaderCell style={{width: '120px'}}>Extension Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Extended By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Principal Before</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Interest Before</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Total Added</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.others.extension.data.map((extension, index) =>
                                        <Table.Row key={extension.extension_id} className={extension.extension_status === 'removed' ? 'reversed' : ''}>
                                            <Table.Cell style={{width: '40px'}} textAlign={'center'}>{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '80px'}} textAlign={'center'}>
                                                <Button
                                                    primary={true} disabled={extension.extension_status === 'removed'}
                                                    size='mini' icon='info circle' compact onClick={() => setExtension(extension)}/>

                                                <Button
                                                    negative={true} size='mini' icon='trash' compact
                                                    disabled={
                                                        !user.roles.core_roles?.includes("remove_extension") || extension.extension_status === 'removed'
                                                    }
                                                    onClick={() => setShowDelete({
                                                        delete_reason: '', extension_id: extension.extension_id, show: true
                                                    })}/>
                                            </Table.Cell>

                                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(extension.date_extended)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{extension.extended_by}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(extension.extension_principal as number)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(extension.extension_interest as number)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.comma_number(extension.interest_added + extension.principal_added)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 p-1 col-md-4 h-100">
                    <div className="form">
                        <div className='form_content'>
                            <div className="row mx-0">
                                <div className="col-6 pl-0 pr-1">
                                    <MyInput
                                        placeholder="Principal Balance" title="Principal Balance"
                                        name="extension_principal" value={extension.extension_principal.toString()}/>
                                </div>

                                <div className="col-6 pl-1 pr-0">
                                    <MyInput placeholder="Interest Balance" title="Interest Balance" name="extension_interest"
                                             value={extension.extension_interest.toString()}/>
                                </div>

                                <div className="col-6 pl-0 pr-1">
                                    <MyInput
                                        placeholder="Rate" title="Extension Rate"
                                        name="extension_rate" change={handle_change} value={extension.extension_rate as string}/>
                                </div>

                                <div className="col-6 pl-1 pr-0">
                                    <MyInput
                                        placeholder="Enter period in months" title="Months Extended"
                                        name="extension_period" change={handle_change} value={extension.extension_period as string}/>
                                </div>

                                <div className="col-6 pl-0 pr-1" style={{marginBottom: '10px'}}>
                                    <MyDateInput
                                        title="Extension Start Date" value={extension.extension_start_date}
                                        placeholder="Extension start date" minDate={params.loanInfo.due_date}
                                        change={handle_change} name="extension_start_date"/>
                                </div>

                                <div className="col-6 pl-1 pr-0">
                                    <MySelect
                                        change={(value) => handle_change('interest_mode', value as string)}
                                        title="Extension method" name='interest_mode' value={extension.interest_mode}
                                        options={[
                                            {text: 'Principal balance', value: 'principal_only'},
                                            {text: 'Principal + interest balance', value: 'interest_principal'}
                                        ]}/>
                                </div>
                            </div>

                            {
                                extension.extension_id > 0 &&
                                <>
                                    <div className="row mx-0">
                                        <div className="col-6 pl-0 pr-1">
                                            <MyInput
                                                placeholder="Interest Added" title="Interest Added" name=""
                                                value={Utils.comma_number(extension.interest_added)}/>
                                        </div>
                                        <div className="col-6 pl-1 pr-0">
                                            <MyInput
                                                placeholder="Principal Added" title="Principal Added" name=""
                                                value={Utils.comma_number(extension.principal_added)}/>
                                        </div>

                                        <div className="col-6 pl-0 pr-1">
                                            <MyInput
                                                placeholder="Total Added" title="Total Added" name=""
                                                value={Utils.comma_number(extension.interest_added + extension.principal_added)}/>
                                        </div>

                                        <div className="col-6 pl-1 pr-0">
                                            <MyInput
                                                placeholder="Total Expected Payment" title="Total Payment" name=""
                                                value={
                                                    Utils.comma_number(
                                                        extension.extension_principal +
                                                        extension.extension_interest + extension.interest_added
                                                    )
                                                }/>
                                        </div>
                                    </div>
                                </>
                            }

                            <div>
                                <MyLabel title="Reason for extending loan"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter reason as to why you have extended the loan' rows={3}
                                        value={extension.extension_reason}
                                        onChange={((event, data) => {
                                            handle_change('extension_reason', data.value as string)
                                        })}/>
                                </Form>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                {
                                    (params.loanInfo.others.extension.proceed === 0 || params.loanInfo.loan_status === 'cleared') ?
                                        <Button negative labelPosition={"left"} icon="close" size='mini'
                                                content="Cannot be rescheduled try rescheduling" fluid/> :
                                        <>
                                            <div className="col-6 pl-0 pr-1">
                                                <Button
                                                    negative onClick={() => setExtension(initial_extension)} fluid icon="trash"
                                                    size="tiny" content="Clear Data" labelPosition="left"/>
                                            </div>
                                            <div className="col-6 pl-1 pr-0">
                                                <Button
                                                    positive onClick={extend_loan} content="Extend loan" icon="save" fluid
                                                    labelPosition="left" size="tiny" disabled={extension.extension_id > 0}/>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
