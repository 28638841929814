import React from "react";
import {intervals, rate_types} from "../../admin/loans/LoanTypes";
import {ShowLoan} from "../../loans/container/ShowLoan";
import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import {Account} from "../../../utils/Models";

interface Loan {
    account_opening_fee: number
    client_name: string
    date_created: string
    date_disbursed: string
    due_date: string
    insurance_fee: number
    loan_id: number
    loan_period: number
    loan_principal: number
    loan_processing_fee: number
    loan_rate: number
    loan_status: string
    main_contact: number | string
    office_name: string
    payment_interval: intervals
    rate_type: rate_types
    type_name: string
    total_balance: number
}

export default function LoanReport(params: { transactions: any, accounts: Account[], }) {
    const [showLoan, setShowLoan] = React.useState({show: false, loan_id: 0})
    const [loans, setLoans] = React.useState<Loan[]>([])

    const get_info = (loan: Loan) => {
        const interval = loan.payment_interval === 30 ? "monthly" : loan.payment_interval === 14
            ? "biweekly" : loan.payment_interval === 7 ? "weekly" : "daily"
        return `${loan.loan_period} Months - ${Utils.comma_number(loan.loan_rate, '')}% ${interval}`
    }

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setLoans(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <div className="table_container container_no_footer">
            {
                showLoan.show ?
                    <ShowLoan
                        close={() => setShowLoan({loan_id: 0, show: false})}
                        accounts={params.accounts} loan_id={showLoan.loan_id}/>
                    :
                    <Table celled striped compact size='small' inverted color='grey' selectable className="">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '70px'}}>Loan No</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Branch Name</Table.HeaderCell>

                                <Table.HeaderCell style={{width: '190px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '110px'}}>Client Contact</Table.HeaderCell>

                                <Table.HeaderCell className='time_row'>Date Created</Table.HeaderCell>
                                <Table.HeaderCell className='amount_row'>Loan Amount</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Loan Info</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Loan Type</Table.HeaderCell>
                                <Table.HeaderCell className='amount_row'>Insurance Fees</Table.HeaderCell>
                                <Table.HeaderCell className='amount_row'>Processing Fees</Table.HeaderCell>

                                <Table.HeaderCell style={{width: '80px'}}>Status</Table.HeaderCell>
                                <Table.HeaderCell className='time_row'>Disbursed Date</Table.HeaderCell>
                                <Table.HeaderCell className='time_row'>Due Date</Table.HeaderCell>

                                <Table.HeaderCell className='amount_row'>Total Balance</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                loans.map((loan, index) =>
                                    <Table.Row key={loan.loan_id}
                                               onClick={() => {
                                                   if (loan.loan_id > 0) {
                                                       setShowLoan({loan_id: loan.loan_id, show: true});
                                                   }
                                               }}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">
                                            {(index < loans.length + 1) && (index + 1)}
                                        </Table.Cell>

                                        <Table.Cell style={{width: '70px'}} textAlign="center">
                                            {(index < loans.length + 1) && loan.loan_id}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{loan.office_name}</Table.Cell>

                                        <Table.Cell style={{width: '190px'}}>{loan.client_name}</Table.Cell>
                                        <Table.Cell style={{width: '110px'}}>{loan.main_contact}</Table.Cell>

                                        <Table.Cell className='time_row'>{Utils.localise_date(loan.date_created)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(loan.loan_principal)}</Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{get_info(loan)}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{loan.type_name}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(loan.insurance_fee)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(loan.loan_processing_fee)}</Table.Cell>
                                        <Table.Cell style={{width: '80px'}}>{loan.loan_status.toUpperCase()}</Table.Cell>
                                        <Table.Cell className='time_row'>{Utils.localise_date(loan.date_disbursed)}</Table.Cell>
                                        <Table.Cell className='time_row'>{Utils.localise_date(loan.due_date)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(loan.total_balance)}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
            }
        </div>
    )
}
