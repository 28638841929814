import {LoanInfo} from "../../ShowLoan";
import {Table} from "semantic-ui-react";
import React from "react";
import Utils from "../../../../../utils/Utils";

export default function LoanHistory(params: { loanInfo: LoanInfo }) {
    return (
        <div className="table_container full_height">
            <Table celled striped compact size='small' inverted color='grey' selectable style={{width: '800px'}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Status Date</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '100px'}}>Status Type</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '300px'}}>Created By</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        params.loanInfo.others.loan_statuses
                            .map((status, index) =>
                                <Table.Row key={status.status_id}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '150px'}}>{Utils.localise_date_time(status.status_date)}</Table.Cell>
                                    <Table.Cell style={{width: '100px'}}>
                                        {(status.status_name === 'active' ? 'disbursed' : status.status_name).toUpperCase()}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '300px'}}>{status.user_name}</Table.Cell>
                                </Table.Row>
                            )
                    }
                </Table.Body>
            </Table>
        </div>
    )
}
