import {Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import React from "react";
import {Account} from "../../../../utils/Models";

export interface RcAccountFee {
    account_id: number
    amount_paid: number
    client: string
    date_paid: string
    fee_name: string
    payment_id: number
    payment_type: string
}

export default function RcAccountFees(params: { fees: RcAccountFee[], accounts: Account[] }) {
    return (
        <>
            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Client Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Fee Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Payment Method</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Amount Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Payment Time</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Date Paid</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.fees.map((fee, index) =>
                                <Table.Row key={fee.payment_id}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{fee.client}</Table.Cell>
                                    <Table.Cell style={{width: '150px'}}>{fee.fee_name}</Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>
                                        {Utils.get_journal_account(fee.account_id, params.accounts)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>{Utils.comma_number(fee.amount_paid)}</Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>{fee.payment_type}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{Utils.localise_date_time(fee.date_paid)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
