import React from 'react'
import {Modal, Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";

interface Report {
    branch_name: string
    interest_expected: number
    interest_received: number
    loan_account_fees: number
    loan_type_fees: number
    other_payments: number
    penalty_paid: number
    principal_disbursed: number
    principal_expected: number
    principal_received: number
    loans: Loan[]
}

interface Loan {
    client_name: string
    email_address: string
    interest_expected: number
    interest_paid: number
    loan_id: number
    main_contact: number | string
    principal_expected: number
    principal_paid: number
}

export default function DailyReport(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Array<Report>>([])
    const [loans, setLoans] = React.useState(Array<Loan>())

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <div className="table_container container_no_footer">
            <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                <Table.Header>
                    <Table.Row key={0}>
                        <Table.HeaderCell style={{width: '50px'}} textAlign="center" rowSpan={2}>No.</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '180px'}} rowSpan={2}>Branch Name</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row' rowSpan={2}>Disbursed</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row' rowSpan={2}>Account Fees</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row' rowSpan={2}>Loan Fees</Table.HeaderCell>

                        <Table.HeaderCell style={{width: '240px'}} colSpan={2} rowSpan={1} textAlign="center">
                            Principal Summary
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{width: '240px'}} colSpan={2} rowSpan={1} textAlign="center">
                            Interest Summary
                        </Table.HeaderCell>

                        <Table.HeaderCell className='amount_row' rowSpan={2}>Penalty Paid</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row' rowSpan={2}>Other Payments</Table.HeaderCell>
                    </Table.Row>

                    <Table.Row key={1}>
                        <Table.HeaderCell className='amount_row'>Expected</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row'>Received</Table.HeaderCell>

                        <Table.HeaderCell className='amount_row'>Expected</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row'>Received</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        statements
                            .map((transaction, index) =>
                                <Table.Row key={index} onClick={() => setLoans(transaction.loans)}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{transaction.branch_name}</Table.Cell>
                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.principal_disbursed)}</Table.Cell>
                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.loan_account_fees)}</Table.Cell>
                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.loan_type_fees)}</Table.Cell>

                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.principal_expected)}</Table.Cell>
                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.principal_received)}</Table.Cell>

                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.interest_expected)}</Table.Cell>
                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.interest_received)}</Table.Cell>

                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.penalty_paid)}</Table.Cell>
                                    <Table.Cell className='amount_row'>{Utils.comma_number(transaction.other_payments)}</Table.Cell>
                                </Table.Row>
                            )
                    }
                </Table.Body>
            </Table>

            <Modal open={loans.length > 0} basic size='large' centered={false} onClose={() => setLoans([])}>
                <div className='modal_div modal_form'>
                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable className="">
                            <Table.Header>
                                <Table.Row key={1}>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center" rowSpan={2}>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}} rowSpan={2}>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Main Contact</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '360px'}} colSpan={3} rowSpan={1} textAlign="center">
                                        Principal Information
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '360px'}} colSpan={3} rowSpan={1} textAlign="center">
                                        Interest Information
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row' rowSpan={2}>Total Balance</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row key={2}>
                                    <Table.HeaderCell className='amount_row'>Expected</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Received</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Balance</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Expected</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Received</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Balance</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    loans.map((loan, index) =>
                                        <Table.Row key={loan.loan_id}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{loan.client_name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{loan.main_contact}</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.principal_expected)}</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.principal_paid)}</Table.Cell>
                                            <Table.Cell className='amount_row'>
                                                {Utils.comma_number(loan.principal_expected - loan.principal_paid)}
                                            </Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.interest_expected)}</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.interest_paid)}</Table.Cell>
                                            <Table.Cell className='amount_row'>
                                                {Utils.comma_number(loan.interest_expected - loan.interest_paid)}
                                            </Table.Cell>
                                            <Table.Cell className='amount_row'>
                                                {Utils.comma_number(
                                                    (loan.principal_expected + loan.interest_expected) -
                                                    (loan.principal_paid + loan.interest_paid))}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
