import React from "react";
import {Button, Table} from "semantic-ui-react";
import Utils from "../../../../../utils/Utils";
import {Loading, MyInput, PDFReader} from "../../../../../utils/Components";
import {LoanInfo, LoanProps} from "../../ShowLoan";
import {Req} from "../../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";
import JSPrinter from "../../../../../utils/JSPrinter";
import ReversePayment from "./ReversePayment";
import moment from "moment";

export interface LoanPayment {
    office_name: string
    accountant_name: string
    interest_paid: number
    loan_id: number
    payer_contact: string
    payer_name: string
    payment_id: number
    penalty_paid: number
    interest_extra: number
    principal_paid: number
    time_paid: string
    time_verified: null | string
    total_paid: number
    verified_by: null | string
    reversible: 1 | 0
    accountant_id: number
    payment_status: 'accepted' | 'rejected'
}

export default function LoanPayments(params: { loanProps: LoanProps, loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)

    const [loading, setLoading] = React.useState({message: "", show: false})
    const [showReader, setShowReader] = React.useState({display: false, source: ""})
    const [reverseID, setReverseID] = React.useState(0)

    const process_receipt = (payment_id: number) => {
        setLoading({message: "Generating receipt, please wait", show: true})
        Req.get_payment_receipt({payment_id: payment_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        if (response.data.hasOwnProperty("file")) {
                            setShowReader({display: true, source: `${Req.BASE_URL}${response.data.file}`})
                        } else if (response.data.hasOwnProperty("receipt")) {
                            JSPrinter.print_receipt({response: response.data})
                        }
                    } else {
                        ToastsStore.error("Could not generate receipt, please retry")
                    }
                } else {
                    ToastsStore.error("Could not generate receipt, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Could not generate receipt, please retry")
            })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <PDFReader
                source={showReader.source} open={showReader.display}
                close={() => setShowReader({...showReader, display: false})}/>

            <ReversePayment
                payment_id={reverseID} loan_id={params.loanInfo.loan_id}
                close={() => setReverseID(0)} setLoanInfo={params.setLoanInfo}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1">
                    <div className="client_payment_summary">
                        <div className="row m-0">
                            <div className='col-12 col-md-6 p-0'>
                                <div className='row m-0'>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Total Principal Paid" title="Total Principal Paid" name="principal_paid"
                                            value={Utils.comma_number(params.loanInfo.payments.summary.principal.paid)}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Total Interest Paid" title="Total Interest Paid" name="interest_paid"
                                            value={Utils.comma_number(params.loanInfo.payments.summary.interest.paid)}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Principal Balance" title="Principal Balance" name="principal_balance"
                                            value={Utils.comma_number(params.loanInfo.payments.summary.principal.balance)}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Interest Balance" title="Interest Balance" name="interest_balance"
                                            value={Utils.comma_number(params.loanInfo.payments.summary.interest.balance)}/>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 p-0'>
                                <div className='row m-0'>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Penalty Balance" title="Penalty Balance" name="penalty_balance"
                                            value={Utils.comma_number(params.loanInfo.payments.summary.penalties?.balance ?? 0)}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Loan Charges" title="Loan Charges" name="other_fees"
                                            value={Utils.comma_number(params.loanInfo.payments.summary.charges?.balance ?? 0)}/>
                                    </div>

                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Payment Date" title="Payment Date" name="next_payment_date"
                                            value={
                                                params.loanInfo.payments.summary.principal.balance === 0 ? 'LOAN COMPLETE'
                                                    : Utils.localise_date(params.loanInfo.payments.next.date)
                                            }/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Next Payment" title="Next Payment" name="next_payment_amount"
                                            value={Utils.comma_number(params.loanInfo.payments.next.amount)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 p-1 loan_payments_parent">
                    <div className="table_container loan_payments_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}}/>
                                    <Table.HeaderCell style={{width: '80px'}}/>
                                    <Table.HeaderCell style={{width: '140px'}}>Date Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Accountant Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Penalty Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Interest Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Extra Interest</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Principal Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Total Paid</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.payments.data.map((payment, index) =>
                                        <Table.Row key={payment.payment_id} className={payment.payment_status === 'rejected' ? 'reversed' : ''}>
                                            <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '80px'}}>
                                                <Button
                                                    primary size='mini' icon='file pdf' compact
                                                    disabled={payment.payment_status === 'rejected'}
                                                    onClick={() => process_receipt(payment.payment_id)}/>
                                                <Button
                                                    primary size='mini' icon='redo' compact
                                                    disabled={
                                                        !user.roles.core_roles?.includes("reverse_payments") ||
                                                        payment.payment_status === 'rejected'
                                                    }
                                                    onClick={() => setReverseID(payment.payment_id)}/>
                                            </Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{moment(payment.time_paid).format("lll")}</Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{payment.accountant_name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.penalty_paid)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.interest_paid)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.interest_extra)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.principal_paid)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.total_paid)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
