import React from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {getCounter, getStaff, lockUser, updateCounter} from "../store/slices/userSlice";

export default function Header() {
    const user = useAppSelector(getStaff)
    const counter = useAppSelector(getCounter)
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            if (user.user_token !== "") {
                if (counter <= 180) {
                    dispatch(updateCounter(counter + 5))
                } else {
                    dispatch(lockUser())
                }
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, [counter])

    return (
        <>

        </>
    )
}
