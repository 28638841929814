import Header from "./nav/Header";
import SideBar from "./nav/SideBar";
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {getCounter, getStaff, lockUser, updateCounter} from "./store/slices/userSlice";
import React from "react";
import Login from "./modules/admin/users/auth/Login";
import axios from "axios";
import {Req} from "./utils/Req";
import Utils from "./utils/Utils";
import {getSummary, updateSummary} from "./store/slices/systemSlice";
import {FirebaseApp, initializeApp} from "firebase/app";
import Firebase from "./nav/Firebase";
import Version from "../../package.json";
import SystemState from "./SystemState";
import {ToastsStore} from "react-toasts";

export default function System() {
    const dispatch = useAppDispatch()
    const user = useAppSelector(getStaff)
    const counter = useAppSelector(getCounter)
    const summary = useAppSelector(getSummary)

    const [status, setStatus] = React.useState<{ locked: string, upgrade: boolean }>({locked: '', upgrade: false})

    const firebaseApp: FirebaseApp = initializeApp({
        apiKey: "AIzaSyA63Nl28w2HphP7Z0IEHzMWClp6zIb06lo",
        authDomain: "misc-applications.firebaseapp.com",
        databaseURL: "https://misc-applications.firebaseio.com",
        projectId: "misc-applications",
        storageBucket: "misc-applications.appspot.com",
        messagingSenderId: "797043390702",
        appId: "1:797043390702:web:b06c06f1addcef10bcdbff",
        measurementId: "G-HHVSH870S6"
    })

    const set_axios_data = () => {
        axios.defaults.baseURL = Req.BASE_URL;

        axios.defaults.headers.common.Authorization = `Bearer ${user.user_token}`
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Client'] = `${Req.CLIENT}`
        axios.defaults.headers.common['Version'] = Version.version;
        axios.defaults.timeout = 1000 * 60

        axios.interceptors.response.use(
            function (response) {
                console.log(response)
                console.log(response.data)

                if (response.data.hasOwnProperty("code")) {
                    if (response.data['code'] === 426) {
                        setStatus({locked: '', upgrade: true})
                    } else if (response.data['code'] === 401) {
                        dispatch(lockUser())
                    } else if (response.data.code === 423) {
                        setStatus({locked: response.data.time, upgrade: false})
                    } else if (response.data.code === 503) {
                        ToastsStore.info(`This service is not yet available at the moment`)
                    }
                }

                return response
            },
            function (error) {
                console.log(error)
                return Promise.reject(error);
            });
    }
    set_axios_data()

    const load_today_summary = () => {
        Req.daily_summary({})
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    dispatch(updateSummary({
                        date: response.data.date,
                        expected: response.data.data.pending,
                        due_loans: response.data.data.due_loans,
                        received: response.data.data.payments
                    }))
                }
            })
    }

    React.useEffect(() => {
        if (Utils.today() !== summary.date) {
            load_today_summary()
        }
    }, [])

    window.onclick = () => {
        dispatch(updateCounter(0))
    }

    React.useEffect(() => {
        axios.defaults.headers['common']['Authorization'] = `Bearer ${user.user_token}`
    }, [user.user_token])

    return (
        <>
            <Firebase firebaseApp={firebaseApp}/>
            <Header/>
            <SideBar/>
            {(counter >= 180 || user.user_token === "") && <Login/>}
            {(status.locked || status.upgrade) && <SystemState locked={status.locked} upgrade={status.upgrade}/>}
        </>
    )
}
