import {LoanDetail} from "../LoansDetails";
import React from "react";
import {rate_types} from "../../../../admin/loans/LoanTypes";
import {ToastsStore} from "react-toasts";
import Utils from "../../../../../utils/Utils";
import {Req} from "../../../../../utils/Req";
import {Button} from "semantic-ui-react";
import {Loading, MyDateInput, MyInput, MySelect, select_type} from "../../../../../utils/Components";
import {Creditor, initial_creditor} from "../../Creditors";
import {Account} from "../../../../../utils/Models";

export default function CreditorLoanInfo(params: {
    loanDetail: LoanDetail, close: () => void, creditors: Array<Creditor>,
    hasRoles: boolean, accounts: Account[], update: (loan: LoanDetail) => void
}) {
    const [loanDetail, setLoanDetail] = React.useState(params.loanDetail)
    const [loading, setLoading] = React.useState({message: "", show: false})

    const handle_change = (name: string, value: string | number | rate_types) => {
        setLoanDetail({...loanDetail, schedule: [], loan: {...loanDetail.loan, [name]: value, due_date: ''}})
    }

    const save_creditor_loan = () => {
        if (loanDetail.creditor.creditor_id === 0) {
            ToastsStore.error("Select a creditor")
        } else if (!Utils.is_valid_number(Utils.strip_commas(loanDetail.loan.loan_principal)) ||
            parseFloat(Utils.strip_commas(loanDetail.loan.loan_principal)) < 0) {
            ToastsStore.error("Enter a valid loan amount")
        } else if (!Utils.is_valid_number(loanDetail.loan.credit_rate) || parseFloat(loanDetail.loan.credit_rate) < 0) {
            ToastsStore.error("Enter a valid loan rate")
        } else if (!Utils.is_valid_number(loanDetail.loan.loan_period) || parseFloat(loanDetail.loan.loan_period) < 0) {
            ToastsStore.error("Enter a valid loan period greater than 0")
        } else if ((new Date(loanDetail.loan.start_date)) < (new Date(loanDetail.loan.date_acquired))) {
            ToastsStore.error("Payment start date cannot be less than date loan was acquired")
        } else {
            setLoading({message: "Saving loan, please wait", show: true})
            Req.save_creditor_loan({
                creditor_id: loanDetail.creditor.creditor_id,
                date_acquired: `${loanDetail.loan.date_acquired} ${loanDetail.loan.time_acquired}`,
                due_date: loanDetail.loan.due_date,
                loan_id: loanDetail.loan.loan_id,
                loan_period: parseInt(loanDetail.loan.loan_period),
                loan_principal: parseFloat(Utils.strip_commas(loanDetail.loan.loan_principal)),
                loan_rate: parseFloat(loanDetail.loan.credit_rate),
                start_date: loanDetail.loan.start_date,
                account_id: loanDetail.loan.account_id
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        ToastsStore.success("Successfully saved loan")
                        params.update(response.data.loan)
                    } else {
                        ToastsStore.error("Could not save loan, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Could not save loan, please retry")
                })
        }
    }

    const revalidate_loan = () => {
        setLoading({message: "Loading loan information, please wait", show: true})
        Req.revalidate_creditor_loan({loan_id: params.loanDetail.loan.loan_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    ToastsStore.success("Successfully loaded loan details")
                    params.update(response.data.loan)
                } else {
                    ToastsStore.error("Could not load loan details, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Could not load loan details, please retry")
            })
    }

    const load_top_ups = () => {
        let top = 0
        params.loanDetail.top_ups.forEach((topUp) => {
            top += parseFloat(topUp.top_up_amount.toString())
        })

        return (
            <div className={'row mx-0'}>
                <div className={'col-6 pl-0 pr-1'}>
                    <MyInput placeholder="Total top ups" title="Total top ups" name="" value={Utils.comma_number(top)}/>
                </div>
                <div className={'col-6 pl-1 pr-0'}>
                    <MyInput
                        placeholder="Total principal" title="Total principal" name="total_principal"
                        value={Utils.comma_number(top + parseFloat(params.loanDetail.loan.loan_principal.toString()))}/>
                </div>
            </div>
        )
    }

    React.useEffect(() => {
        setLoanDetail(params.loanDetail)
    }, [params.loanDetail])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <div className="form">
                <div className="form_content">
                    <MySelect
                        change={(value: select_type) => {
                            if (value as number === 0) {
                                setLoanDetail({...loanDetail, creditor: initial_creditor})
                            } else {
                                for (let index = 0; index < params.creditors.length; index++) {
                                    if (params.creditors[index].creditor_id === value) {
                                        setLoanDetail({...loanDetail, creditor: params.creditors[index]})
                                        break
                                    }
                                }
                            }
                        }}
                        title="Select creditor providing loan" name="creditor_id" value={loanDetail.creditor.creditor_id}
                        options={[
                            {text: "Select a creditor", value: 0},
                            ...params.creditors.map((creditor) => ({text: creditor.creditor_name, value: creditor.creditor_id}))
                        ]}/>

                    <div className={'row mx-0'}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MyInput
                                placeholder="Enter loan amount borrowed" title="Loan principal" name="loan_principal"
                                change={handle_change} value={Utils.comma_input(loanDetail.loan.loan_principal)}/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MySelect
                                title="Account receiving the loan" name="account_id" value={loanDetail.loan.account_id}
                                change={(value: select_type) => handle_change('account_id', (value as number))}
                                options={
                                    [
                                        {text: "Select an account ", value: 0},
                                        ...params.accounts.map((account) => ({text: account.account_name, value: account.account_id}))
                                    ]
                                }/>
                        </div>
                    </div>

                    <div className={'row mx-0'}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MyInput placeholder="Enter loan rate" title="Interest Rate" name="credit_rate"
                                     change={handle_change} value={loanDetail.loan.credit_rate}/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MyInput placeholder="Enter loan period" title="Loan Period" name="loan_period"
                                     change={handle_change} value={loanDetail.loan.loan_period}/>
                        </div>
                    </div>

                    <div className={'row mx-0'} style={{marginBottom: '15px'}}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MyDateInput
                                title="Date acquired" value={loanDetail.loan.date_acquired} name="date_acquired"
                                placeholder="Select date loan acquired" change={handle_change} maxDate={Utils.today()}/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MyDateInput
                                title="Time acquired" value={loanDetail.loan.time_acquired} name="time_acquired"
                                placeholder="Select time loan acquired" change={handle_change} type={"time"}/>
                        </div>
                    </div>

                    <div style={{marginBottom: '15px'}}>
                        <MyDateInput
                            title="Payment start date" value={loanDetail.loan.start_date} name="start_date"
                            change={handle_change} minDate={loanDetail.loan.date_acquired}
                            placeholder="Select payment start date"/>
                    </div>

                    {
                        loanDetail.loan.loan_id > 0 &&
                        <>
                            <div style={{marginBottom: '15px'}}>
                                <MyDateInput
                                    title="Due date of loan" value={loanDetail.loan.due_date} name="due_date"
                                    placeholder="Expected payment due date"/>
                            </div>

                            {params.loanDetail.top_ups.length > 0 && load_top_ups()}

                            <MyInput placeholder="Total principal balance" title="Total principal balance" name="" className={"mb-0"}
                                     value={Utils.comma_number(params.loanDetail.loan.principal_balance.toString())}/>
                        </>
                    }

                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-2 px-0">
                            <Button negative onClick={revalidate_loan} size='tiny' icon="repeat"
                                    fluid disabled={loanDetail.loan.loan_id === 0}/>
                        </div>

                        <div className="col-5 px-1">
                            <Button negative onClick={params.close} size='tiny' icon="close"
                                    fluid content="Close" labelPosition="left"/>
                        </div>
                        <div className="col-5 px-0">
                            <Button
                                positive onClick={save_creditor_loan} fluid size='tiny' icon="save" labelPosition="left"
                                content={loanDetail.loan.loan_id === 0 ? 'Save Loan' : 'Update Loan'}
                                disabled={
                                    loanDetail.loan.loan_status === 'deleted' || !params.hasRoles ||
                                    params.loanDetail.loan.principal_balance <= 0
                                }/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
