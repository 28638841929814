import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store"

export type info_titles = "access_rights_update" | "report_rights_update"

export interface LoanSummary {
    client_name: string
    due_date: string
    interest_paid: number
    loan_id: number
    main_contact: number
    payment_date: string
    pending_interest: number
    pending_principal: number
    principal_paid: number
    loan_principal: number
}

export interface DailySummary {
    date: string
    due_loans: LoanSummary[]
    expected: LoanSummary[]
    received: LoanSummary[]
}

export interface AppNotification {
    notification_id: number
    loan_id: number
    notification_type: string
    notification_title: string
    notification_message: string
    time_created: string
    time_read: string
}

export interface SystemNotification {
    notification_type: info_titles
    notification_title: string
    notification_message: string
    notification_data: string
}

interface Company {
    name: string
    motto: string
    location: string
    contact: string[]
    logo: string
}

type DailyState = {
    summary: DailySummary
    notifications: AppNotification[]
    information: SystemNotification[]
    current_date: string
    company?: Company
}


const initialState: DailyState = {
    summary: {date: "", due_loans: [], expected: [], received: []},
    notifications: [],
    information: [],
    current_date: ''
}

const systemSlice = createSlice({
    name: 'system', initialState: initialState,
    reducers: {
        updateSummary: (state: Draft<typeof initialState>, action: PayloadAction<DailySummary>) => {
            state.summary = action.payload
        },
        addNotification: (state: Draft<typeof initialState>, action: PayloadAction<AppNotification>) => {
            state.notifications = [...state.notifications, action.payload]
        },
        addInformation: (state: Draft<typeof initialState>, action: PayloadAction<SystemNotification>) => {
            state.information = [...state.information, action.payload]
        },
        updateDate: (state: Draft<typeof initialState>, action: PayloadAction<string>) => {
            state.current_date = action.payload
        },
        updateCompany: (state: Draft<typeof initialState>, action: PayloadAction<Company>) => {
            state.company = action.payload
        },
    },
});

// Selectors
export const getSummary = (state: RootState) => state.system.summary
export const getNotifications = (state: RootState) => state.system.notifications
export const getCurrentDate = (state: RootState) => state.system.current_date
export const getCompanyInfo = (state: RootState) => state.system.company

// Reducers and actions
export const {updateSummary, addNotification, addInformation, updateDate, updateCompany} = systemSlice.actions

export default systemSlice.reducer
