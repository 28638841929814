import {Table} from "semantic-ui-react";
import {MyInput} from "../Components";
import Utils from "../Utils";
import React from "react";
import {Allocation} from "../Models";

export default function AllocationTable(params: { allocations: Allocation[], setAllocations: (allocations: Allocation[]) => void }) {
    return (
        <>
            {
                params.allocations.length > 0 &&
                <div className="table_container mb-3" style={{height: 'auto'}}>
                    <Table celled striped compact size='small' inverted color='grey' selectable>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '130px'}}>Account Type</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Balance</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Amount</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                params.allocations
                                    .map((allocation) =>
                                        <Table.Row key={allocation.account_id}>
                                            <Table.Cell style={{width: '130px'}}>{allocation.account_name}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{allocation.balance.toLocaleString()}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                <MyInput
                                                    placeholder="Amount" name={"amount_" + allocation.account_id} className="mb-0"
                                                    value={Utils.comma_input(allocation.amount)}
                                                    change={(name, value) => {
                                                        params.setAllocations(
                                                            params.allocations.map((aA) => aA.account_id === allocation.account_id ?
                                                                {...allocation, amount: value} : aA))
                                                    }}/>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                            }
                        </Table.Body>
                    </Table>
                </div>
            }
        </>
    )
}
