import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import {PersistGate} from "redux-persist/integration/react";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts'
import {Route, RouterProvider} from "react-router";
import System from "./system/System";
import NoMatch from './system/NoMatch';
import {persistedStore, store} from "./system/store/store";
import 'semantic-ui-css/semantic.min.css'
import 'react-phone-input-2/lib/style.css';
import './assets/css/index.scss'
import {createBrowserRouter, createRoutesFromElements} from 'react-router-dom';

const render = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<System/>}>
                <Route index element={<System/>}/>
                <Route path="*" element={<NoMatch/>}/>
            </Route>
        )
    );

    ReactDOM
        .createRoot(document.getElementById('root') as HTMLElement)
        .render(
            <React.StrictMode>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistedStore}>
                        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER}/>
                        <RouterProvider router={router}/>
                    </PersistGate>
                </Provider>
            </React.StrictMode>
        )
}

render()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
