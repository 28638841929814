import {Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import React from "react";
import {LoansDetails} from "../../creditors/loans/LoansDetails";
import {ToastsStore} from "react-toasts";
import {useAppSelector} from "../../../../store/hooks";
import {getStaff} from "../../../../store/slices/userSlice";
import {Creditor} from "../../creditors/Creditors";
import {SelectData} from "../../../../utils/Components";
import {Account} from "../../../../utils/Models";

export interface CreditorReconcile {
    users: { username: string, user_id: number }[]
    loans: Loan[],
    creditors: Creditor[]
}

interface Loan {
    creditor_name: string
    creditor_id: number
    date_acquired: string
    loan_principal: number
    loan_id: number
    loan_rate: number
    balance: number
}

export default function ReconcileCreditors(params: { data: CreditorReconcile, accounts: Account[] }) {
    const user = useAppSelector(getStaff)

    const [users, setUsers] = React.useState<Array<SelectData>>([])
    const [loans, setLoans] = React.useState<Loan[]>([])
    const [creditors, setCreditors] = React.useState<Array<Creditor>>([])
    const [showLoan, setShowLoan] = React.useState({loan_id: 0, show: false})

    React.useEffect(() => {
        setLoans(params.data.loans)
        setCreditors(params.data.creditors)
        setUsers(params.data.users.map((user) => ({text: user.username, value: user.user_id})))
    }, [params.data])

    return (
        <>
            <LoansDetails
                show={showLoan.show} creditors={creditors} loan_id={showLoan.loan_id}
                users={users} user_id={user.staff.user_id} accounts={params.accounts}
                close={() => setShowLoan({...showLoan, show: false})}
                update={(loan) => {
                    setLoans(loans.map((aLoan) => aLoan.loan_id === loan.loan_id ? {
                        balance: loan.principal_balance,
                        creditor_id: aLoan.creditor_id,
                        creditor_name: aLoan.creditor_name,
                        date_acquired: loan.date_acquired,
                        loan_id: loan.loan_id,
                        loan_principal: parseFloat(loan.loan_principal as string),
                        loan_rate: parseFloat(loan.credit_rate)
                    } : aLoan))
                    setShowLoan({...showLoan, loan_id: loan.loan_id})
                }}
                delete={((loan_id) => {
                    setLoans(loans.filter((loan) => loan.loan_id !== loan_id))
                    ToastsStore.success("Loan has been deleted successfully")
                    setShowLoan({...showLoan, show: false})
                })}/>

            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Date Acquired</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '250px'}}>Creditor Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}} textAlign={'center'}>Loan No</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}} textAlign={'center'}>Loan Rate</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Loan Principal</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Principal Balance</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            loans.map((loan, index) =>
                                <Table.Row key={loan.loan_id} onClick={() => setShowLoan({loan_id: loan.loan_id, show: true})}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{Utils.localise_date_time(loan.date_acquired)}</Table.Cell>
                                    <Table.Cell style={{width: '250px'}}>{loan.creditor_name}</Table.Cell>
                                    <Table.Cell style={{width: '100px'}} textAlign={'center'}>{loan.loan_id}</Table.Cell>
                                    <Table.Cell style={{width: '100px'}} textAlign={'center'}>{loan.loan_rate}%</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{Utils.comma_number(loan.loan_principal)}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{Utils.comma_number(loan.balance)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
