import {MyDateInput, MyInput, MySelect, select_type, SelectData} from "../../../../../utils/Components";
import Utils from "../../../../../utils/Utils";
import {Button} from "semantic-ui-react";
import React from "react";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import {LoanDetail} from "../LoansDetails";
import {Account} from "../../../../../utils/Models";

export interface PayCreditor {
    payment_id: number
    loan_id: number
    principal_paid: number | string
    interest_paid: number | string
    date_paid: string
    time_paid: string
    account_id: number
    reference_no: string
    user_id: number
    prepared_by: number
}

export default function MakeCreditorPayment(params: {
    payment: PayCreditor, loan: LoanDetail, users: Array<SelectData>, initial: PayCreditor,
    update: (loanDetail: LoanDetail) => void, accounts: Account[], close: () => void, hasRoles: boolean,
}) {
    const [payment, setPayment] = React.useState(params.payment)
    const handle_change = (name: string, value: string | number) => {
        setPayment({...payment, [name]: value})
    }
    const [saving, setSaving] = React.useState(false)

    const make_payment = () => {
        const interest_paid = Utils.strip_commas(payment.interest_paid)
        const principal_paid = Utils.strip_commas(payment.principal_paid)

        if (payment.user_id === 0) {
            ToastsStore.error("Select user who made the payment")
        } else if (!Utils.is_valid_number(principal_paid) || parseFloat(principal_paid) < 0) {
            ToastsStore.error("Enter a valid total principal paid")
        } else if (!Utils.is_valid_number(interest_paid) || parseFloat(interest_paid) < 0) {
            ToastsStore.error("Enter a valid total interest paid")
        } else if (payment.account_id === 0) {
            ToastsStore.error("Select a mode of payment")
        } else {
            setSaving(true)
            Req.pay_creditor_loan({
                loan_id: params.loan.loan.loan_id,
                account_id: payment.account_id,
                reference_no: payment.reference_no,
                time_paid: payment.date_paid,
                principal_paid: parseFloat(principal_paid),
                interest_paid: parseFloat(interest_paid),
                user_id: payment.user_id,
                payment_id: payment.payment_id
            })
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            setPayment(params.initial)
                            params.update(response.data.loan)
                            ToastsStore.success("Successfully saved payment")
                        } else if (response.data.code === 2) {
                            ToastsStore.error(`Loan balance is only ${Utils.comma_number(response.data.balance)}`)
                        }
                    } else {
                        ToastsStore.error("Error while making payment, please retry")
                    }
                    setSaving(false)
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Error while making payment, please retry")
                })
        }
    }

    React.useEffect(() => {
        setPayment(params.payment)
    }, [params.payment])

    return (
        <>
            <div className="form">
                <div className="form_content no_header">
                    <MySelect
                        change={(value: select_type) => handle_change('user_id', value as number)}
                        title="Prepared By" name="prepared_by" disabled={true}
                        value={payment.prepared_by} options={params.users}/>

                    <MySelect
                        change={(value: select_type) => handle_change('user_id', value as number)}
                        title="Payment made by" name="user_id" disabled={payment.payment_id > 0}
                        value={payment.user_id} options={params.users}/>

                    <div className={'row mx-0 mb-2'}>
                        <div className='col-6 pl-0 pr-1'>
                            <MyInput
                                placeholder="Enter principal paid" title="Principal Paid" name="principal_paid"
                                change={handle_change} value={Utils.comma_input(payment.principal_paid).toString()}/>
                        </div>
                        <div className='col-6 pl-1 pr-0'>
                            <MyInput
                                placeholder="Enter interest paid" title="Interest Paid" name="interest_paid"
                                change={handle_change} value={Utils.comma_input(payment.interest_paid).toString()}/>
                        </div>
                    </div>

                    <div className={'row mx-0 mb-2'}>
                        <div className='col-6 pl-0 pr-1'>
                            <MyDateInput
                                title="Payment Date" value={payment.date_paid} name="date_paid"
                                placeholder="Select payment date" change={handle_change}
                                maxDate={Utils.today()} minDate={params.loan.loan.date_acquired}/>
                        </div>
                        <div className='col-6 pl-1 pr-0'>
                            <MyDateInput
                                title="Payment Time" value={payment.time_paid} name="time_paid"
                                placeholder="Select payment time" change={handle_change} type={'time'}/>
                        </div>
                    </div>

                    <MySelect
                        title="Payment Method" name="account_id" value={payment.account_id}
                        change={(value: select_type) => handle_change('account_id', (value as number))}
                        options={
                            [
                                {text: "Select payment method", value: 0},
                                ...params.accounts.map((account) => ({
                                    text: account.account_name, value: account.account_id
                                }))
                            ]
                        }/>

                    <MyInput
                        placeholder="Enter Reference No." title="Reference No." name="reference_no"
                        change={handle_change} style={{marginBottom: '15px'}} value={payment.reference_no}/>
                </div>

                <div className="form_footer">
                    <div className="row mx-0">
                        <div className="col-2 pl-0 pr-1">
                            <Button negative disabled={saving} fluid size='tiny' icon="close" onClick={params.close}/>
                        </div>
                        <div className="col-5 px-1">
                            <Button
                                negative disabled={saving} fluid size='tiny' icon="trash" labelPosition="left"
                                content={"Clear Data"} onClick={() => setPayment(params.initial)}/>
                        </div>
                        <div className="col-5 pl-1 pr-0">
                            <Button
                                positive onClick={make_payment} loading={saving} fluid size='tiny' labelPosition="left"
                                content={payment.payment_id === 0 ? 'Save Payment' : 'Update Payment'} icon="save"
                                disabled={saving || !params.hasRoles || params.loan.loan.principal_balance <= 0}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
