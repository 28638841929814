import {Checkbox} from "semantic-ui-react";
import {multiselect_type} from "./MultiSelect";

export default function MultiRadio(
    params: {
        items: Array<{ text: string, value: string | number }>,
        selected: (number | string)[], change: (value: multiselect_type) => void,
    }
) {
    return (
        <div className={"row m-0"}>
            {
                params.items.map((item) =>
                    <div key={item.value} className={"col-12 multi_checks"}>
                        <Checkbox
                            label={item.text} checked={params.selected.includes(item.value)}
                            onChange={(event, data) => {
                                if (data.checked) {
                                    params.change([...params.selected, item.value])
                                } else {
                                    params.change(params.selected.filter((value) => value !== item.value))
                                }
                            }}/>
                    </div>
                )
            }
        </div>
    )
}
