import {Loading, MyDateInput, MyInput, MyLabel, MySelect, select_type} from "../../../../utils/Components";
import React from "react";
import {Drawing} from "../DirectorDrawings";
import {Account} from "../../../../utils/Models";
import {BranchUser} from "../../../../nav/SideBar";
import Utils from "../../../../utils/Utils";
import {Button, Form, TextArea} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../utils/Req";

export default function DrawingsInfo(params: {
    drawing: Drawing, initial: Drawing, accounts: Account[], branchUser: BranchUser, drawings: Drawing[],
    setDrawings: (drawings: Drawing[]) => void, setDrawing: (drawing: Drawing) => void,
}) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [drawing, setDrawing] = React.useState(params.drawing)
    const handle_change = (name: string, value: string | number) => {
        setDrawing({...drawing, [name]: value})
    }

    const save_drawing = () => {
        const amount = Utils.strip_commas(drawing.amount_drawn)
        if (drawing.drawn_by === 0) {
            ToastsStore.error("Select user making the drawings")
        } else if (drawing.account_id === 0) {
            ToastsStore.error(`Account being ${drawing.drawing_type === 'cash_in' ? 'deposited to' : 'withdrawn from'}`)
        } else if (!Utils.is_valid_number(amount) || parseFloat(amount) < 0) {
            ToastsStore.error("Enter valid amount drawn")
        } else if (drawing.descriptions.trim().length < 5) {
            ToastsStore.error("Enter description of drawing at least 5 characters")
        } else if (drawing.date_drawn === "") {
            ToastsStore.error("Select date drawing was made")
        } else if (drawing.time_drawn === "") {
            ToastsStore.error("Select time drawing was made")
        } else {
            setLoading({message: "Saving cash drawings, please wait", show: true})
            Req.save_director_drawings({
                account_id: drawing.account_id, amount_drawn: parseFloat(amount), drawings_id: drawing.drawings_id,
                date_drawn: `${drawing.date_drawn} ${drawing.time_drawn}`, drawn_by: drawing.drawn_by,
                descriptions: drawing.descriptions.trim(), drawing_type: drawing.drawing_type,
            })
                .then((response) => {
                    setLoading({message: "", show: false})

                    if (response.data.hasOwnProperty("code") && response.data['code'] === 1) {
                        const newDrawing = {
                            ...drawing, drawings_id: response.data.drawings_id,
                            date_created: response.data.date_created, amount_drawn: parseFloat(amount),
                        }

                        if (drawing.drawings_id === 0) {
                            params.setDrawings([newDrawing, ...params.drawings])
                        } else {
                            params.setDrawings(params.drawings.map((aDrawing) =>
                                aDrawing.drawings_id === newDrawing.drawings_id ? newDrawing : aDrawing
                            ))
                        }
                        params.setDrawing(newDrawing)
                        ToastsStore.success("Successfully saved director drawings")
                    } else {
                        ToastsStore.error("Error while creating cash transfer, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while creating cash transfer, please retry")
                })
        }
    }

    React.useEffect(() => {
        setDrawing(params.drawing)
    }, [params.drawing])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <div className="form">
                <div className="form_header">
                    {drawing.drawings_id === 0 ? "Create new director drawing" : `Update drawing at ${Utils.localise_date(drawing.date_drawn)}`}
                </div>

                <div className="form_content">
                    <MySelect
                        change={(value) => setDrawing({...drawing, drawn_by: value as number})}
                        title="User making the drawings" name="user_id" value={drawing.drawn_by} placeholder="Select user"
                        options={[
                            {text: "Select user", value: 0},
                            ...params.branchUser.users
                                .filter((user) => user.user_id === drawing.drawn_by || user.user_status === "active")
                                .map((user) => ({text: user.user_name, value: user.user_id}))
                        ]}/>

                    <div className={'row mx-0'}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MySelect
                                change={(value) => setDrawing({...drawing, drawing_type: value as 'cash_in' | 'cash_out'})}
                                title="Type of drawing" name="drawing_type" value={drawing.drawing_type} placeholder="Select drawings type"
                                options={[{text: "Cash Deposit", value: 'cash_in'}, {text: "Cash Withdrawal", value: 'cash_out'},]}/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MyInput
                                placeholder="Enter amount drawn" title="Amount Drawn" name="amount_drawn"
                                change={handle_change} value={Utils.comma_input(drawing.amount_drawn)}/>
                        </div>
                    </div>

                    <MySelect
                        title={`Account being ${drawing.drawing_type === 'cash_in' ? 'deposited to' : 'withdrawn from'}`}
                        name="account_id" value={drawing.account_id}
                        change={(value: select_type) => handle_change('account_id', (value as number))}
                        options={
                            [
                                {text: "Select an account ", value: 0},
                                ...params.accounts.map((account) => ({text: account.account_name, value: account.account_id}))
                            ]
                        }/>

                    <MyLabel title={"Reasons for drawing"}/>
                    <Form style={{marginBottom: '15px'}}>
                        <TextArea
                            placeholder='Enter details of drawings' rows={3} value={drawing.descriptions}
                            onChange={((event, data) => handle_change('descriptions', data.value as string))}/>
                    </Form>

                    <div className={'row mx-0'} style={{marginBottom: '15px'}}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MyDateInput
                                title="Date drawn" value={drawing.date_drawn} name="date_drawn"
                                placeholder="Select date drawing was made" change={handle_change} maxDate={Utils.today()}/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MyDateInput
                                title="Time drawn" value={drawing.time_drawn} name="time_drawn"
                                placeholder="Select time drawing was made" change={handle_change} type={"time"}/>
                        </div>
                    </div>

                    {
                        drawing.drawings_id > 0 &&
                        <>
                            <MyInput
                                placeholder="Date and time created" title="Date and time created"
                                name="date_modified" value={Utils.localise_date_time(drawing.date_created)}/>

                            <MyInput
                                placeholder="" title="Drawing saved by" className={"mb-0"} name="date_created"
                                value={params.branchUser.users.filter((user) => user.user_id === drawing.drawn_by)[0]?.user_name}/>
                        </>
                    }
                </div>

                <div className="form_footer">
                    <div className='row m-0'>
                        <div className='col-6 pl-0 pr-1'>
                            <Button
                                negative onClick={() => setDrawing(params.initial)}
                                content='Clear Data' fluid size='tiny' icon='trash' labelPosition='left'/>
                        </div>
                        <div className='col-6 pl-1 pr-0'>
                            <Button
                                onClick={save_drawing} size='tiny' icon='save' labelPosition='left' fluid positive
                                content={drawing.drawings_id === 0 ? 'Save Drawing' : 'Update Drawing'}
                                disabled={drawing.drawings_id > 0}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
