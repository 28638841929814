import {Button, Confirm, Modal} from "semantic-ui-react";
import React from "react";
import {ConfirmAction, initial_confirm} from "../../../../utils/Utils"
import {Loading, SelectData} from "../../../../utils/Components"
import {Req} from "../../../../utils/Req";
import {CreditorPayment, LoanPayments} from "./LoanPayments";
import LoanReschedule from "./utils/LoanReschedule";
import {Creditor, initial_creditor} from "../Creditors";
import {ToastsStore} from "react-toasts";
import {CreditorLoan} from "../SystemCreditors";
import {Account} from "../../../../utils/Models";
import CreditorLoanSchedule from "./details/CreditorLoanSchedule";
import CreditorLoanInfo from "./details/CreditorLoanInfo";
import LoanTopUp from "./utils/LoanTopUp";
import {Rights} from "../../../admin/settings/AccessRights";
import {useAppSelector} from "../../../../store/hooks";
import {getStaff} from "../../../../store/slices/userSlice";

export interface TopUp {
    top_up_id: number
    loan_id: number
    user_id: number
    account_id: number
    top_up_amount: number | string
    top_up_reason: string
    top_up_date: string
    top_up_time: string
    date_created: string
    top_up_status: 'active' | 'deleted'
    start_date: string
    top_up_period: number | string
}

interface Schedule {
    payment_date: string
    balance_after: number
    principal_payable: number
    principal_paid: number
    interest_paid: number
}

export interface LoanDetail {
    payments: Array<CreditorPayment>
    schedule: Array<Schedule>
    creditor: Creditor
    loan: CreditorLoan
    top_ups: TopUp[]
}

const initial_creditor_loan: LoanDetail = {
    creditor: initial_creditor,
    loan: {
        date_acquired: "", time_acquired: "", credit_rate: "", due_date: "", loan_id: 0, loan_period: "", principal_balance: 0,
        principal_overdue: 0, start_date: "", creditor_name: "", interest_paid: 0, loan_principal: 0, next_date: "",
        next_total: 0, principal_paid: 0, can_extend: 0, loan_status: "active", account_id: 0
    },
    payments: [],
    schedule: [],
    top_ups: []
}

export function LoansDetails(
    params: {
        close: () => void, update?: (loan: CreditorLoan) => void, delete?: (loan_id: number) => void, user_id: number,
        show: boolean, creditors: Array<Creditor>, loan_id: number, users: Array<SelectData>, accounts: Account[]
    }
) {
    const hasRoles = Rights.has_right(useAppSelector(getStaff).roles.user_roles, Rights.creditor_management)

    const [loading, setLoading] = React.useState({message: "", show: false})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loanDetail, setLoanDetail] = React.useState(initial_creditor_loan)

    const [showTopUp, setShowTopUp] = React.useState(false)
    const [showPayment, setShowPayment] = React.useState({show: false})
    const [showReschedule, setShowReschedule] = React.useState(false)

    const delete_loan = () => {
        setConfirm({
            ...confirm, open: true, content: 'Are you sure you want to delete this loan?',
            onConfirm: () => {
                setConfirm({...confirm, 'open': false})
                setLoading({message: "", show: false})
                setLoading({message: "Deleting loan, please wait", show: true})

                Req.delete_creditor_loan({loan_id: loanDetail.loan.loan_id})
                    .then(response => {
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                params.delete && params.delete(loanDetail.loan.loan_id)
                            } else if (response.data.code === 2) {
                                ToastsStore.info("Loan already has payments, it cannot be deleted")
                            }
                        } else {
                            ToastsStore.error("Error while deleting loan, please retry")
                        }
                        setLoading({message: "", show: false})
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        ToastsStore.error("Error while deleting loan, please retry")
                    })
            },
            onCancel: () => setConfirm({...confirm, 'open': false})
        })
    }


    React.useEffect(() => {
        if (params.show) {
            setLoanDetail(initial_creditor_loan)

            if (params.loan_id > 0) {
                setLoading({message: "Getting loan data, please wait", show: true})
                Req.get_creditor_loan({loan_id: params.loan_id})
                    .then((response) => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            setLoanDetail(response.data.loan)
                        } else {
                            ToastsStore.error("Loan not found")
                            params.close()
                        }
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        params.close()
                    })
            }
        }
    }, [params.show])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <LoanPayments
                show={showPayment.show} loan={loanDetail} users={params.users} user_id={params.user_id}
                accounts={params.accounts} close={() => setShowPayment({show: false})} hasRoles={hasRoles}
                update={(detail) => {
                    setLoanDetail(detail)
                    params.update && params.update(detail.loan)
                }}/>

            <LoanReschedule
                show={showReschedule} creditorLoan={loanDetail} close={() => setShowReschedule(false)}
                update={(detail) => {
                    setLoanDetail(detail)
                    params.update && params.update(detail.loan)
                    ToastsStore.success("Successfully extended loan")
                }}/>

            <LoanTopUp
                loanDetail={loanDetail} show={showTopUp} users={params.users} hasRoles={hasRoles}
                close={() => setShowTopUp(false)} accounts={params.accounts}
                update={(detail) => {
                    params.update && params.update(detail.loan)
                    setLoanDetail(detail)
                }}/>

            <Confirm
                open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} size='mini'
                cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <Modal open={params.show} basic size='large' closeOnDocumentClick={true} centered={false} onClose={params.close}>
                <div className="modal_div creditor_loan">
                    <div className="row m-0 h-100">
                        <div className="col-8 pl-0 pr-1 h-100">
                            <div className="creditor_schedules">
                                <div className="table_container">
                                    <CreditorLoanSchedule loanDetail={loanDetail}/>
                                </div>

                                <div className={"creditor_buttons"}>
                                    <div>
                                        <Button
                                            onClick={() => setShowReschedule(true)} primary icon='sync alternate' size='tiny'
                                            disabled={
                                                loanDetail.loan.can_extend === 0 || !hasRoles ||
                                                loanDetail.loan.principal_balance <= 0
                                            }
                                            content={"Reschedule"} labelPosition="left"/>

                                        <Button
                                            negative icon='level up' size='tiny' onClick={() => setShowTopUp(true)}
                                            disabled={loanDetail.loan.loan_id === 0}
                                            content={"Top Up"} labelPosition="left"/>

                                        <Button
                                            primary icon="money bill alternate outline" disabled={loanDetail.loan.loan_id === 0}
                                            onClick={() => setShowPayment({show: true})} size='tiny'
                                            content={"Payments"} labelPosition="left"/>
                                    </div>
                                    <div>
                                        <Button
                                            negative icon='trash' size='tiny' onClick={delete_loan} content={"Delete Loan"}
                                            labelPosition="left"
                                            disabled={loanDetail.loan.principal_balance <= 0 || !hasRoles}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 pl-1 pr-0 h-100">
                            <CreditorLoanInfo
                                loanDetail={loanDetail} close={params.close} hasRoles={hasRoles}
                                creditors={params.creditors} accounts={params.accounts}
                                update={(loan: LoanDetail) => {
                                    setLoanDetail(loan)
                                    params.update && params.update(loan.loan);
                                }}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
