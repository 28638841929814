import React from "react";
import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";

interface Report {
    branch_name: string
    current_balance: number
    current_loans: number
    due_balance: number
    due_loans: number
    loan_type: string
    portfolio: number
    restructured_balance: number
    restructured_loans: number
}

export default function PortfolioRiskReport(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Array<Report>>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp.portfolio)
        }
    }, [params.transactions])

    return (
        <div className="table_container container_no_footer">
            <Table celled striped compact size='small' inverted color='grey' selectable>
                <Table.Header>
                    <Table.Row key={1}>
                        <Table.HeaderCell style={{width: '150px'}} rowSpan={2}>Branch Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '200px'}} rowSpan={2}>Loan Type</Table.HeaderCell>

                        <Table.HeaderCell style={{width: '200px'}} colSpan={2} textAlign="center">Current Portfolio</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '200px'}} colSpan={2} textAlign="center">Due Portfolio</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '200px'}} colSpan={2} textAlign="center">Restructured Portfolio</Table.HeaderCell>

                        <Table.HeaderCell style={{width: '70px'}} rowSpan={2} textAlign="center">Portfolio</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row key={2}>
                        <Table.HeaderCell style={{width: '130px'}}>Balance</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '70px'}} textAlign="center">Loans</Table.HeaderCell>

                        <Table.HeaderCell style={{width: '130px'}}>Balance</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '70px'}} textAlign="center">Loans</Table.HeaderCell>

                        <Table.HeaderCell style={{width: '130px'}}>Balance</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '70px'}} textAlign="center">Loans</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        statements
                            .map((transaction, index) =>
                                <Table.Row key={index}>
                                    <Table.Cell style={{width: '150px'}} rowSpan={2}>{transaction.branch_name}</Table.Cell>
                                    <Table.Cell style={{width: '200px'}} rowSpan={2}>{transaction.loan_type}</Table.Cell>

                                    <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.current_balance)}</Table.Cell>
                                    <Table.Cell style={{width: '70px'}} textAlign="center">
                                        {Utils.comma_number(transaction.current_loans, '')}
                                    </Table.Cell>

                                    <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.due_balance)}</Table.Cell>
                                    <Table.Cell style={{width: '70px'}} textAlign="center">
                                        {Utils.comma_number(transaction.due_loans, '')}
                                    </Table.Cell>

                                    <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.restructured_balance)}</Table.Cell>
                                    <Table.Cell style={{width: '70px'}} textAlign="center">
                                        {Utils.comma_number(transaction.restructured_loans, '')}
                                    </Table.Cell>

                                    <Table.Cell style={{width: '70px'}} textAlign="center">
                                        {Utils.comma_number(transaction.portfolio, '', '%')}
                                    </Table.Cell>
                                </Table.Row>
                            )
                    }
                </Table.Body>
            </Table>
        </div>
    )
}
