import React from "react";
import {Modal, Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Loading} from "../../../utils/Components";
import {CriteriaStatements} from "../criteria/IncomeStatementsCriteria";

interface Report {
    name: string
    amount?: number
    total?: number
    id?: number
    type?: string
}

interface Statement {
    account: number | string
    acname: string
    amount: number
    time_paid: string
}

export default function IncomesStatementReport(params: { transactions: any, criteria: CriteriaStatements }) {
    const [reports, setReports] = React.useState<Report[]>([])
    const [statements, setStatements] = React.useState<Statement[]>([])
    const [loader, setLoader] = React.useState({message: "", show: false})

    const generate_report = (criteria: string) => {
        setLoader({message: "Loading report details, please wait", show: true})
        Req.process_report({criteria: criteria}, {action: 'incomes_statement_info', type: 'table'})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("report")) {
                    const _statements: Statement[] = response.data.report
                    if (_statements.length === 0) {
                        ToastsStore.info("There are no statements to display")
                    } else {
                        setStatements(_statements)
                    }
                } else {
                    ToastsStore.error("Could not generate statements, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not generate statements, please retry")
            })
    }

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setReports(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({message: "", show: false})}/>

            <Modal open={statements.length > 0} basic size='large' centered={false} onClose={() => setStatements([])} dimmer='blurring'>
                <div className='modal_div modal_form'>
                    <div className="table_container full_height">
                        <Table celled striped inverted compact structured size='small' color='grey'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign={'center'}>No</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}}>A/C ID</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '300px'}}>Account Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Date</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    statements.map((report, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '50px'}} textAlign={'center'}>{index + 1}</Table.Cell>
                                            <Table.Cell style={{width: '70px'}}>{report.account}</Table.Cell>
                                            <Table.Cell style={{width: '300px'}}>{report.acname}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(report.amount)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.localise_date(report.time_paid)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Modal>

            <div className="table_container container_no_footer">
                <div className="table_container full_height">
                    <Table celled striped inverted compact structured size='small' color='grey'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '450px'}}>Item Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Item Amount</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Sub Total</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                reports.map((report, index) =>
                                    <Table.Row
                                        key={index}
                                        onClick={() => {
                                            if (report.type !== undefined) {
                                                generate_report(JSON.stringify({...params.criteria, id: report.id, type: report.type}))
                                            }
                                        }}>
                                        <Table.Cell style={{width: '450px'}}>{report.name}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>
                                            {report.amount !== undefined ? Utils.comma_number(report.amount) : ''}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>
                                            {report.total !== undefined ? Utils.comma_number(report.total) : ''}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
