import React from "react";
import Utils, {InputFile} from "../../../utils/Utils";
import {Loading, loading_params, LoadingParams, MyDateInput, MySelect, SelectData, TablePagination} from "../../../utils/Components";
import {Page, Req} from "../../../utils/Req";
import {Button, Table} from "semantic-ui-react";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import {multiselect_type} from "../../../utils/components/MultiSelect";
import AddExpense, {Expense} from "./AddExpense";
import {Account, Allocation} from "../../../utils/Models";


export default function ExpenseManagement() {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)

    const initial_expense: Expense = {
        accountant_id: user.staff.user_id, accountant_name: `${user.staff.first_name} ${user.staff.last_name}`,
        expense_amount: "", expense_date: Utils.today(), expense_description: "", expense_id: 0, account_id: 0,
        last_modified: "", office_id: 0, office_name: "", expense_receipt: null
    }
    const [showExpense, setShowExpense] = React.useState({expense: initial_expense})

    const [expenses, setExpenses] = React.useState<Array<Expense>>([])
    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})
    const [search, setSearch] = React.useState({
        min_date: Utils.weekly_date().sunday, max_date: Utils.today(), accountant_id: 0
    })
    const handle_search = (name: string, value: string | number | multiselect_type) => {
        setSearch({...search, [name]: value})
    }

    const [accounts, setAccounts] = React.useState<Account[]>([])

    const load_expenses = (params: { page: number, pages: number }) => {
        if (search.accountant_id === 0) {
            ToastsStore.info("Select accountant to search")
        } else {

            setLoading({show: true, text: "Loading expenses, please wait"})
            Req.get_expenses({
                page: params.page, pages: params.pages, max_date: search.max_date,
                min_date: search.min_date, accountant_id: search.accountant_id
            })
                .then((response) => {
                    setLoading(loading_params)
                    if (response.data.hasOwnProperty("expenses")) {
                        let new_expenses: Array<any> = response.data['expenses'];
                        new_expenses.forEach((new_expense, index) => {
                            new_expense.expense_receipt = new_expense.expense_receipt === "" ? null :
                                new InputFile(`${Req.BASE_URL}${new_expense.expense_receipt}`)
                            new_expense[index] = new_expense
                        })
                        setExpenses(new_expenses)
                        setPages({...pages, pages: response.data.pages, page: params.page})
                    } else {
                        ToastsStore.error("Could not load expenses")
                    }
                })
                .catch(() => {
                    setLoading(loading_params)
                    ToastsStore.error("Could not load expenses")
                })
        }
    }

    const [allocations, setAllocations] = React.useState(Array<Allocation>())
    const [users, setUsers] = React.useState(Array<{ id: number, name: string }>())

    const initialise_expenses = () => {
        setLoading({...loading_params, show: true, text: 'Initialising expense data, please wait'})
        Req.initialise_expenses({max_date: search.max_date, min_date: search.min_date})
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setAllocations((response.data.allocations as Allocation[]).filter((allocation) => allocation.balance > 0))
                    setAccounts(response.data.expenses.map((aAccount: any) => ({account_id: aAccount.value, account_name: aAccount.text})))
                    setUsers(response.data.users)
                    setLoading(loading_params)
                } else {
                    setLoading({
                        error: "Could not get initial expense data, check your internet connection and retry",
                        reload: initialise_expenses, show: true, text: ""
                    })
                }
            })
            .catch(() => {
                setLoading({
                    error: "Could not get initial expense data, check your internet connection and retry",
                    reload: initialise_expenses, show: true, text: ""
                })
            })
    }

    React.useEffect(() => {
        initialise_expenses()
    }, [search.min_date, search.max_date])

    return (
        <>
            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MySelect
                                    value={search.accountant_id}
                                    change={(value) => handle_search("accountant_id", value as number)}
                                    options={[
                                        {text: "Select accountant", value: 0},
                                        ...users
                                            .filter((a) => user.roles.core_roles?.includes("till_allocation") || user.staff.user_id === a.id)
                                            .map((a) => ({text: a.name, value: a.id}))
                                    ]}/>
                            </div>
                            <div>
                                <MyDateInput
                                    title="" value={search.min_date} name="min_date" maxDate={search.max_date}
                                    placeholder="Minimum payment date" change={handle_search}/>
                            </div>
                            <div>
                                <MyDateInput
                                    title="" value={search.max_date} name="max_date" maxDate={Utils.today()} minDate={search.min_date}
                                    placeholder="Maximum payment date" change={handle_search}/>
                            </div>
                        </div>

                        <div className="button_bar">
                            <Button size='mini' content="Search" primary labelPosition="left" icon="search"
                                    onClick={() => load_expenses({page: 1, pages: 0})}/>
                        </div>
                    </div>

                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Branch Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Expense Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Expense Reason</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Expense Date</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    expenses.map((expense, index) =>
                                        <Table.Row
                                            key={expense.expense_id}
                                            onClick={() => setShowExpense({expense: expense})}>
                                            <Table.Cell style={{width: '40px'}} textAlign="center">
                                                {Utils.row_number(index, pages)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{expense.office_name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.comma_number(expense.expense_amount as number)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>
                                                {Utils.get_journal_account(expense.account_id, accounts)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(expense.expense_date)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="table_footer">
                        <TablePagination
                            total={pages.pages} change={(page: number) => load_expenses({page: page, pages: pages.pages})}/>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <AddExpense
                        expense={showExpense.expense} allocations={allocations} setAllocations={setAllocations} accounts={accounts}
                        clear={() => setShowExpense({expense: initial_expense})}
                        saveExpense={(aExpense) => {
                            if (showExpense.expense.expense_id === 0) {
                                setExpenses(expenses => [aExpense, ...expenses])
                            } else {
                                setExpenses(expenses.map((_expense) => aExpense.expense_id === _expense.expense_id ? aExpense : _expense))
                            }
                        }}/>
                </div>
            </div>
        </>
    )
}
