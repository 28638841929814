import {Loading, loading_params, LoadingParams, MyLabel, MySelect} from "../../utils/Components";
import Utils, {ConfirmAction, initial_confirm} from "../../utils/Utils";
import {Button, Confirm, Form, Modal, Table, TextArea} from "semantic-ui-react";
import React from "react";
import {Req} from "../../utils/Req";
import {ToastsStore} from "react-toasts";
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";
import {BranchUser} from "../../nav/SideBar";
import {TillSummary, TillTransaction} from "./TillBalances";
import {TillAccount} from "./TillAccounts";
import {Account} from "../../utils/Models";

interface Summary {
    name: string

    [key: number]: number
}

export default function TillSummaries(params: {
    show: boolean, close: () => void, branchUsers: BranchUser, accounts: Account[],
    transactions: TillTransaction[], tillSummary: TillSummary,
    tillAccount: TillAccount, setTillAccount: (tillAccount: TillAccount) => void
}) {
    const user = useAppSelector(getStaff)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)

    const [close, setClose] = React.useState<{ till_status: 'closed' | 'faulty' | '', comments: string }>(
        {till_status: '', comments: ''})

    const [headers, setHeaders] = React.useState<number[]>([])
    const [summaries, setSummaries] = React.useState(Array<Summary>())

    const close_transactions = () => {
        if (close.till_status === '') {
            ToastsStore.error("Select a status for closing the tills")
        } else if (close.till_status === 'faulty' && close.comments.trim().length < 10) {
            ToastsStore.error("Enter a valid reason why tills are faulty")
        } else if (close.comments.trim().length >= 500) {
            ToastsStore.error("Comment must not be more than 500 characters")
        } else {
            const username = params.branchUsers.users
                .filter((user) => user.user_id === params.tillAccount.user_id)[0]?.user_name ?? ""

            setConfirm({
                ...confirm, open: true,
                content:
                    `Are you sure you want to proceed with closing the transactions of "${username}"? 
                Note that the "${username}" shall not be able to make any further transactions for the rest of the day.`,
                onConfirm: () => {
                    setConfirm({...confirm, open: false})
                    setLoading({show: true, text: "Closing transactions, please wait"})

                    Req.close_user_transactions({
                        till_data: JSON.stringify(params.tillSummary),
                        till_date: params.tillAccount.till_date,
                        user_id: params.tillAccount.user_id,
                        till_status: close.till_status as 'closed' | 'faulty',
                        till_comments: close.comments.trim()
                    })
                        .then((response) => {
                            setLoading({show: false, text: ""})
                            if (response.data.hasOwnProperty("code")) {
                                const code = response.data.code
                                if (code === 1) {
                                    params.setTillAccount({...params.tillAccount, till_status: close.till_status as 'closed' | 'faulty'})
                                    ToastsStore.success("User transactions have been successfully closed")
                                } else if (code === 2) {
                                    ToastsStore.error("Could not find till allocated to user")
                                } else if (code === 3) {
                                    ToastsStore.error("User transactions for this date were already closed")
                                }
                            } else {
                                ToastsStore.error("Could not close user transactions, please retry")
                            }
                        })
                        .catch(() => {
                            setLoading({show: false, text: ""})
                            ToastsStore.error("Could not close user transactions, please retry")
                        })
                },
                onCancel: () => setConfirm({...confirm, open: false})
            })
        }
    }

    React.useEffect(() => {
        let aSummaries: Summary[] = []
        if (headers.length > 0) {
            [params.tillSummary.loan.allocated, params.tillSummary.loan.disbursed, params.tillSummary.loan.collected]
                .forEach((aTillSummary, index) => {
                    let aSummary: Summary = {name: index === 0 ? "Loan Allocation" :
                            (index === 1 ? "Loans Disbursed" : "Loan Collection")}

                    headers.forEach((header) => {
                        aSummary[header] = aTillSummary[header] ?? 0
                    })
                    aSummaries = [...aSummaries, aSummary]
                })
        }
        setSummaries(aSummaries)
    }, [params.tillSummary, headers])

    React.useEffect(() => {
        setClose({comments: "", till_status: ''})
    }, [params.show])

    React.useEffect(() => {
        setHeaders(
            params.accounts
                .sort((a, b) => a.account_name.localeCompare(b.account_name))
                .map((account) => (account.account_id))
        )
    }, [params.accounts])

    return (
        <>
            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <Confirm
                open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} centered={false}
                size='mini' content={confirm.content}/>

            <Modal open={params.show} onClose={params.close} basic size='large' centered={false}>
                <div className='row m-0' style={{height: '450px', background: 'white'}}>
                    <div className='col-8 pl-0 p-1 h-100'>
                        <div className="table_container full_height">
                            <Table celled striped compact size='small' inverted structured color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '150px'}}>Transaction Type</Table.HeaderCell>
                                        {
                                            params.accounts
                                                .sort((a, b) =>
                                                    a.account_name.localeCompare(b.account_name))
                                                .map((account) =>
                                                    <Table.HeaderCell style={{width: '130px'}} key={account.account_id} textAlign={"center"}>
                                                        {account.account_name}
                                                    </Table.HeaderCell>
                                                )
                                        }
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        summaries.map((aSummary, index) =>
                                            <Table.Row>
                                                <Table.Cell style={{width: '150px'}}>{aSummary.name}</Table.Cell>
                                                {
                                                    headers.map((aHeader) =>
                                                        <Table.Cell style={{width: '130px'}} key={index + "_" + aHeader} textAlign={"center"}>
                                                            {aSummary[aHeader] > 0 ? Utils.comma_number(aSummary[aHeader]) : "-"}
                                                        </Table.Cell>
                                                    )
                                                }
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className='col-4 pl-1 pr-0 h-100'>
                        <div className="form">
                            <div className="form_content">
                                <MySelect
                                    change={(value) => setClose({...close, till_status: value as 'closed' | 'faulty'})}
                                    title="Status of closing the tills" value={close.till_status}
                                    options={[
                                        {text: 'Select a general status of tills', value: ''},
                                        {text: 'Transactions are all correct', value: 'closed'},
                                        {text: 'Some faulty transactions', value: 'faulty'},
                                    ]}/>

                                <MyLabel title="Reason for extending loan"/>
                                <Form>
                                    <TextArea
                                        placeholder='General comments' rows={3} value={close.comments}
                                        onChange={((event, data) => {
                                            setClose({...close, comments: data.value as string})
                                        })}/>
                                </Form>
                            </div>

                            <div className="form_footer">
                                <div className='row m-0'>
                                    <div className='col-2 pl-0 pr-1'>
                                        <Button negative fluid size='tiny' icon='close' onClick={() => params.close()}/>
                                    </div>

                                    <div className='col-10 pl-1 pr-0'>
                                        <Button
                                            positive onClick={close_transactions} content='Close Account'
                                            disabled={
                                                params.tillAccount.till_status !== 'open' ||
                                                !user.roles.core_roles?.includes("till_closing")
                                            }
                                            fluid size='tiny' icon='moon outline' labelPosition='left'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
