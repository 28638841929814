import React from "react";
import Utils from "../../../utils/Utils";
import {Loading, loading_params, MySelect, select_type, SelectData} from "../../../utils/Components";
import {Button, Table} from "semantic-ui-react";
import {Req} from "../../../utils/Req";
import SalaryScales from "./SalaryScales";
import SalaryPayments from "./SalaryPayments";
import {ToastsStore} from "react-toasts";

export interface SalarySchedule {
    allowance_paid: number
    allowance_payable: number
    gross_salary: number
    payment_month: string
    payment_year: number
    salary_paid: number
    schedule_id: number
    user_id: number
    username: string
}

export interface SalarySummary {
    allowance_paid: number
    allowance_payable: number
    gross_salary: number
    salary_paid: number
    user_id: number
}

export interface SalaryBranch {
    office_id: number
    office_name: string
}

export interface SalaryUser {
    office_id: number
    user_id: number
    user_status: "active" | "inactive"
    username: string
}

interface Search {
    year: number
    month: string
    user_id: number
    office_id: number
    user_status: "all" | "active" | "inactive"
}

export default function SalaryManagement() {
    const [loading, setLoading] = React.useState(loading_params)

    const [showScales, setShowScales] = React.useState(false)
    const [showPayments, setShowPayments] = React.useState(false)

    const [offices, setOffices] = React.useState<Array<SalaryBranch>>([])
    const [users, setUsers] = React.useState<Array<SalaryUser>>([])
    const [years, setYears] = React.useState<Array<SelectData>>([])
    const [months, setMonths] = React.useState<Array<SelectData>>([])


    const [salarySchedules, setSchedules] = React.useState<Array<SalarySchedule>>([])
    const [salarySummaries, setSummaries] = React.useState<Array<SalarySummary>>([])
    const [search, setSearch] = React.useState<Search>({year: 0, month: "", user_id: 0, office_id: 0, user_status: "active"})
    const handle_change = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const set_data = () => {
        setLoading({show: true, text: "Initialising salary data, please wait"})
        Req.initialise_salary_data()
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setYears(response.data.years)
                    setMonths(response.data.months)
                    setOffices(response.data.offices)
                    setUsers(response.data.users)
                    setSearch({month: response.data.month, office_id: 0, user_id: 0, year: response.data.year, user_status: "active"})

                    setLoading(loading_params)
                } else {
                    setLoading({
                        error: "Could not initialise information, please check your internet connection and then retry",
                        reload: set_data, show: true, text: ""
                    })
                }
            })
            .catch(() => {
                setLoading({
                    error: "Could not initialise information, please check your internet connection and then retry",
                    reload: set_data, show: true, text: ""
                })
            })
    }

    const fetch_salary_data = () => {
        setLoading({show: true, text: "Loading salary information, please wait"})
        Req.get_salary_schedules({
            office_id: search.office_id, payment_month: search.month, payment_year: search.year, user_id: search.user_id
        })
            .then((response) => {
                setLoading(loading_params)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setSummaries(response.data.summary)
                    setSchedules(response.data.schedules)
                } else {
                    ToastsStore.error("Could not load salary information")
                }
            })
            .catch(() => {
                setLoading(loading_params)
                ToastsStore.error("Could not load salary information")
            })
    }

    React.useEffect(() => {
        set_data()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.text} hide={() => setLoading({show: false, text: ""})}/>

            <SalaryScales show={showScales} close={() => setShowScales(false)}/>

            <SalaryPayments show={showPayments} close={() => setShowPayments(false)}
                            users={users} salarySummaries={salarySummaries} setSummaries={setSummaries}/>

            <>
                <div className="content_bar">
                    <div className="search_bar">
                        <div>
                            <MySelect
                                change={(value: select_type) => handle_change('user_status', (value as string))}
                                title="" name="user_status" value={search.user_status}
                                options={[
                                    {text: 'All users', value: "all"},
                                    {text: 'Active users only', value: "active"},
                                    {text: 'In-Active users only', value: "inactive"},
                                ]}/>
                        </div>

                        <div>
                            <MySelect
                                change={(value: select_type) => handle_change('office_id', (value as number))}
                                title="" name="office_id" value={search.office_id}
                                options={[
                                    {text: 'Select all branches', value: 0},
                                    ...offices.map((office) => ({text: office.office_name, value: office.office_id}))
                                ]}/>
                        </div>

                        <div>
                            <MySelect
                                change={(value: select_type) => handle_change('user_id', (value as number))}
                                title="" name="user_id" value={search.user_id}
                                options={[
                                    {text: 'Select all users', value: 0},
                                    ...users
                                        .filter((user) => search.user_status === "all" || search.user_status === user.user_status)
                                        .filter((user) => search.office_id === 0 || search.office_id === user.office_id)
                                        .map((user) => ({text: user.username, value: user.user_id}))
                                ]}/>
                        </div>

                        <div style={{width: '80px'}}>
                            <MySelect
                                change={(value: select_type) => handle_change('year', (value as number))}
                                title="" name="year" value={search.year} options={years}/>
                        </div>

                        <div style={{width: '120px'}}>
                            <MySelect
                                change={(value: select_type) => handle_change('month', (value as number))}
                                title="" name="month" value={search.month} options={months}/>
                        </div>
                    </div>

                    <div className="button_bar">
                        <Button size='mini' content="Search" primary labelPosition="left"
                                onClick={fetch_salary_data} icon="search"/>
                        <Button size='mini' content="Scales" primary labelPosition="left" icon="balance scale"
                                onClick={() => setShowScales(true)}/>
                        <Button floated='right' size='mini' content="Payments" primary labelPosition="left" icon="payment"
                                disabled={salarySummaries.length === 0} onClick={() => setShowPayments(true)}/>
                    </div>
                </div>

                <div className="table_container container_no_footer">
                    <Table celled striped compact size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '40px'}}>No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>User Name</Table.HeaderCell>
                                <Table.HeaderCell className="amount_row">Total Allowances</Table.HeaderCell>
                                <Table.HeaderCell className="amount_row">Allowances Paid</Table.HeaderCell>
                                <Table.HeaderCell className="amount_row">Allowance Arrears</Table.HeaderCell>
                                <Table.HeaderCell className="amount_row">Gross Salary</Table.HeaderCell>
                                <Table.HeaderCell className="amount_row">Salary Paid</Table.HeaderCell>
                                <Table.HeaderCell className="amount_row">Salary Arrears</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                salarySchedules.map((payment, index) =>
                                    <Table.Row key={payment.schedule_id}>
                                        <Table.Cell style={{width: '40px'}}>{Utils.row_number(index)}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{payment.username}</Table.Cell>
                                        <Table.Cell className="amount_row">{Utils.comma_number(payment.allowance_payable)}</Table.Cell>
                                        <Table.Cell className="amount_row">{Utils.comma_number(payment.allowance_paid)}</Table.Cell>
                                        <Table.Cell className="amount_row">
                                            {Utils.comma_number(payment.allowance_payable - payment.allowance_paid)}
                                        </Table.Cell>
                                        <Table.Cell className="amount_row">{Utils.comma_number(payment.gross_salary)}</Table.Cell>
                                        <Table.Cell className="amount_row">{Utils.comma_number(payment.salary_paid)}</Table.Cell>
                                        <Table.Cell className="amount_row">
                                            {Utils.comma_number(payment.gross_salary - payment.salary_paid)}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </>
        </>
    )
}
