import React from "react";
import Utils from "../../../../../utils/Utils";
import {MyDateInput, MyInput, MySelect, select_type} from "../../../../../utils/Components";
import {LoanInfo, LoanProps, TypeCharge} from "../../ShowLoan";
import {LoanType} from "../../../../admin/loans/LoanTypes";
import ChargesAdded from "./ChargesAdded";
import ChargesAvailable from "./ChargesAvailable";

export default function LoanStructure(params: {
    loanProps: LoanProps, loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void, editDisabled: boolean,
    charges: TypeCharge[], setCharges: (charges: TypeCharge[]) => void
}) {
    const handle_change = (name: string, value: string | number | select_type | LoanType | null) => {
        let loanInfo: LoanInfo = {...params.loanInfo, [name]: value}
        if (['loan_principal', 'loan_type', 'rate_type', 'payment_interval', 'loan_rate', 'loan_period', 'start_date'].includes(name)) {
            loanInfo = {...loanInfo, schedules: {expected: [], actual: []}, projected_interest: '', due_date: '',}
        }
        params.setLoanInfo(loanInfo)
    }

    const comma_input = (value: string | number) => {
        return params.editDisabled ? Utils.comma_number(parseFloat(value.toString())) : Utils.comma_input(value)
    }

    return (
        <>
            <div className="row mx-0">
                {/*Loan Type*/}
                <div className="col-12 col-md-3 loan_form">
                    <div>
                        <MySelect
                            onChange={handle_change} value={params.loanInfo.inspector_id} name="inspector_id"
                            title="Loan inspecting officer" disabled={params.editDisabled}
                            options={[
                                {text: 'Select a loan inspector', value: 0},
                                ...params.loanProps.staffs
                                    .filter((staff) => params.loanInfo.loan_id === 0 ? staff.user_id > 0 : true)
                                    .map((staff) => ({text: staff.user_name, value: staff.user_id}))
                            ]}/>

                        <MySelect
                            change={(value: select_type) => {
                                const loan_type = Utils.get_loan_type(params.loanProps.loanTypes, parseInt(value as string));
                                let loanInfo: LoanInfo
                                if (loan_type === null) {
                                    loanInfo = {...params.loanInfo, loan_rate: '', start_date: Utils.today(), loan_type: loan_type}
                                } else {
                                    const days = (loan_type.start_days as number) < 30 ? loan_type.start_days : undefined
                                    const months = (loan_type.start_days as number) < 30 ? undefined : loan_type.start_days / 30
                                    const start_date = Utils.format_payment_date(Utils.today(), months, days)

                                    loanInfo = {
                                        ...params.loanInfo, payment_interval: loan_type.payment_interval,
                                        loan_rate: loan_type.loan_rate, start_date: start_date, loan_type: loan_type
                                    }
                                }
                                params.setLoanInfo(loanInfo)
                            }}
                            title="Loan Type" name="loan_type" disabled={params.editDisabled}
                            value={params.loanInfo.loan_type !== null ? params.loanInfo.loan_type.type_id : 0}
                            options={[
                                {text: "Select a loan type", value: 0},
                                ...params.loanProps.loanTypes
                                    .filter((loanType) => loanType.loan_status === "active")
                                    .map((loanType) => ({text: loanType.type_name, value: loanType.type_id}))
                            ]}/>

                        <MyInput
                            placeholder="Enter amount of money to be borrowed" title="Loan principal" name="loan_principal"
                            change={handle_change} disabled={params.editDisabled} className={'mb-0'}
                            value={comma_input(params.loanInfo.loan_principal).toString()}/>
                    </div>
                </div>

                {/*Loan principal, rate and interest*/}
                <div className="col-12 col-md-3 loan_form">
                    <div>
                        <div style={{marginBottom: '15px'}}>
                            <MyDateInput
                                title="Date of first installment payment" value={params.loanInfo.start_date} name="start_date"
                                placeholder="Select payment start date" disabled={params.editDisabled}
                                change={handle_change}/>
                        </div>

                        <MySelect
                            change={(value: select_type) => handle_change('rate_type', (value as string))}
                            title="Interest Rate Type" name="rate_type" value={params.loanInfo.rate_type} disabled={params.editDisabled}
                            options={
                                [
                                    {text: "Select interest type", value: ""},
                                    {text: "Flat Interest", value: "simple"},
                                    {text: "Flat Interest (Only Interest Paid)", value: "simple_interest"},
                                    {text: "Reducing Balance", value: "compound"}
                                ]
                            }/>

                        <MyInput
                            placeholder="Enter loan rate" title="Interest Rate (In percentage)" name="loan_rate" className={'mb-0'}
                            disabled={params.editDisabled} change={handle_change} value={params.loanInfo.loan_rate}/>
                    </div>
                </div>

                <div className="col-12 col-md-3 loan_form">
                    <div>
                        <MyInput
                            placeholder="Enter loan period" title="Loan Period (In months)" name="loan_period"
                            disabled={params.editDisabled} change={handle_change} value={params.loanInfo.loan_period}/>

                        <MySelect
                            change={(value: select_type) => handle_change('payment_interval', parseInt(value as string))}
                            disabled={params.editDisabled} title="Loan payment Interval" name="payment_interval" className={'mb-0'}
                            value={params.loanInfo.payment_interval}
                            options={
                                [
                                    {text: "Daily Payment", value: 1}, {text: "One week (7 days)", value: 7},
                                    {text: "Two weeks (14 days)", value: 14}, {text: "One Month", value: 30}
                                ]
                            }/>
                    </div>
                </div>

                <div className="col-12 col-md-3 loan_form">
                    <div>
                        <MyInput
                            placeholder="Interest Projection" title="Projected Interest" disabled={true} name="projected_interest"
                            change={handle_change} value={comma_input(params.loanInfo.projected_interest).toString()}/>

                        <div style={{marginBottom: '0px'}}>
                            <MyDateInput
                                title="Loan expiry date" value={params.loanInfo.due_date} name="due_date"
                                placeholder="Calculated payment due date" disabled={true} change={handle_change}/>
                        </div>
                    </div>
                </div>

                {/*Loan charges data*/}
                <div className="col-6 loan_form">
                    <ChargesAvailable
                        loanInfo={params.loanInfo} loanProps={params.loanProps} charges={params.charges}
                        setCharges={params.setCharges} setLoanInfo={params.setLoanInfo}/>
                </div>
                <div className="col-6 loan_form">
                    <ChargesAdded loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                </div>
            </div>
        </>
    )
}
