import React from "react";
import {Button, Modal, Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import {Loading, loading_params, MyInput, MySelect, select_type} from "../../../utils/Components";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";

interface SalaryScale {
    user_id: number
    salary: number | string
    salary_arrears: number
    allowances: number | string
    username: string
    allowance_arrears: number
    bank_name: string
    account_number: string
    account_name: string
    scale_id: number
    date_expired: string
}

const salary_scale: SalaryScale = {
    account_name: "", account_number: "", allowance_arrears: 0, allowances: "", bank_name: "",
    date_expired: "", salary: "", salary_arrears: 0, scale_id: 0, user_id: 0, username: ""
}

interface User {
    user_id: number
    username: string
    office_id: number
    user_status: 'active' | 'inactive'
}

export default function SalaryScales(params: { show: boolean, close: () => void }) {
    const [loading, setLoading] = React.useState(loading_params)

    const [onlyActive, setOnlyActive] = React.useState(true)
    const [users, setUsers] = React.useState(Array<User>())

    const [salaryScale, setSalaryScale] = React.useState(salary_scale)
    const handle_change = (name: string, value: string | number) => {
        setSalaryScale({...salaryScale, [name]: value})
    }

    const [salaryScales, setSalaryScales] = React.useState(Array<SalaryScale>())

    const save_salary_scale = () => {
        if (salaryScale.user_id === 0) {
            ToastsStore.error("Please select an employee")
        } else if (!Utils.is_valid_number(salaryScale.salary as string) || parseFloat(salaryScale.salary as string) <= 0) {
            ToastsStore.error("Enter valid gross salary for employee")
        } else if (!Utils.is_valid_number(salaryScale.allowances as string) || parseFloat(salaryScale.allowances as string) < 0) {
            ToastsStore.error("Enter valid allowances receivable for employee")
        } else if (salaryScale.bank_name.trim().length < 3) {
            ToastsStore.error("Enter a valid employee bank name")
        } else if (salaryScale.account_name.trim().length < 3) {
            ToastsStore.error("Enter a valid employee bank account name")
        } else if (salaryScale.account_number.trim().length < 8) {
            ToastsStore.error("Enter a valid employee bank account number")
        } else {
            setLoading({show: true, text: 'Saving salary scale, please wait'})
            Req.save_salary_scale({
                account_name: salaryScale.account_name, account_number: salaryScale.account_number,
                allowances: parseFloat(salaryScale.allowances as string), bank_name: salaryScale.bank_name.trim(),
                salary: parseFloat(salaryScale.salary as string), scale_id: salaryScale.scale_id, user_id: salaryScale.user_id
            })
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        switch (response.data.code as number) {
                            case 1:
                                let x_user = (users.filter((user) => user.user_id === salaryScale.user_id))
                                let _scale = {
                                    ...salaryScale,
                                    scale_id: response.data.scale_id,
                                    username: x_user.length === 1 ? x_user[0].username : ''
                                }
                                if (salaryScale.scale_id === 0) {
                                    setSalaryScales([_scale, ...salaryScales])
                                } else {
                                    setSalaryScales(salaryScales.map((scale) =>
                                        scale.scale_id === _scale.scale_id ? _scale : scale))
                                }
                                setSalaryScale(_scale)
                                ToastsStore.success("Successfully saved salary scale")
                                break

                            case 2:
                                ToastsStore.info("Salary scale already expired, you can no longer modify it")
                                break
                        }
                    }
                    setLoading(loading_params)
                })
                .catch(() => {
                    setLoading(loading_params)
                })
        }
    }

    const load_salary_scales = () => {
        setLoading({show: true, text: 'Loading salary scales, please wait'})
        Req.get_salary_scales()
            .then((response) => {
                setLoading(loading_params)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setUsers(response.data.users)
                    setSalaryScales(response.data.scales)
                } else {
                    params.close()
                }
            })
            .catch(() => {
                setLoading(loading_params)
                params.close()
            })
    }

    React.useEffect(() => {
        if (params.show) {
            load_salary_scales()
        }
    }, [params.show])

    return (
        <>
            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <Modal open={params.show} basic size='large' centered={false} onClose={params.close}>
                <div className="modal_div salary_scales">
                    <div className='row m-0 h-100'>
                        <div className='col-12 col-md-8 p-1 h-100'>
                            <div className="table_container full_height">
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}}>No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '160px'}}>User Name</Table.HeaderCell>
                                            <Table.HeaderCell className="amount_row">Salary</Table.HeaderCell>
                                            <Table.HeaderCell className="amount_row">Salary Arrears</Table.HeaderCell>
                                            <Table.HeaderCell className="amount_row">Allowances</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '150px'}}>Allowance Arrears</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Date Expired</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            salaryScales.map((scale, index) =>
                                                <Table.Row key={scale.scale_id} onClick={() => setSalaryScale(scale)}>
                                                    <Table.Cell style={{width: '50px'}}>{Utils.row_number(index)}</Table.Cell>
                                                    <Table.Cell style={{width: '160px'}}>{scale.username}</Table.Cell>
                                                    <Table.Cell className="amount_row">
                                                        {Utils.comma_number(scale.salary as number)}
                                                    </Table.Cell>
                                                    <Table.Cell className="amount_row">
                                                        {Utils.comma_number(scale.salary_arrears)}
                                                    </Table.Cell>
                                                    <Table.Cell className="amount_row">
                                                        {Utils.comma_number(scale.allowances as number)}
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '150px'}}>
                                                        {Utils.comma_number(scale.allowance_arrears)}
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '120px'}}>
                                                        {scale.date_expired === '' ? '' : Utils.localise_date(scale.date_expired)}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className='col-12 col-md-4 p-1 h-100'>
                            <div className='form'>
                                <div className="form_content no_header">
                                    <MySelect
                                        change={(value: select_type) => handle_change('user_id', (value as number))}
                                        title="Select a user" name="user_id" value={salaryScale.user_id} disabled={salaryScale.scale_id > 0}
                                        options={
                                            [
                                                {text: 'Select a user', value: 0},
                                                ...users
                                                    .filter((user) => onlyActive || salaryScale.scale_id === 0
                                                        ? user.user_status === 'active' : true)
                                                    .map((user) => ({text: user.username, value: user.user_id}))
                                            ]
                                        }/>

                                    <MyInput placeholder="Enter gross salary" title="Gross Salary" name="salary"
                                             change={handle_change} value={salaryScale.salary as string}/>

                                    <MyInput placeholder="Enter total allowances" title="Total allowances" name="allowances"
                                             change={handle_change} value={salaryScale.allowances as string}/>

                                    <MyInput placeholder="Enter bank name" title="Bank Name" name="bank_name"
                                             change={handle_change} value={salaryScale.bank_name}/>

                                    <MyInput placeholder="Enter account name" title="Account Name" name="account_name"
                                             change={handle_change} value={salaryScale.account_name}/>

                                    <MyInput placeholder="Enter account number" title="Account Number" name="account_number"
                                             change={handle_change} value={salaryScale.account_number}/>

                                    {
                                        salaryScale.date_expired !== '' &&
                                        <MyInput placeholder="" title="Date Expired" name="date_expired"
                                                 value={Utils.localise_date(salaryScale.date_expired)}/>
                                    }

                                </div>

                                <div className="form_footer">
                                    <div className='row m-0'>
                                        <div className='col-6 pl-0 pr-1'>
                                            <Button negative onClick={() => setSalaryScale(salary_scale)}
                                                    content='Clear Data' fluid size='tiny' icon='trash' labelPosition='right'/>
                                        </div>
                                        <div className='col-6 pl-1 pr-0'>
                                            <Button positive onClick={save_salary_scale} content='Save Scale' size='tiny' icon='save'
                                                    disabled={salaryScale.date_expired !== ""} fluid labelPosition='left'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
