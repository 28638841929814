import React from 'react';
import {Button, Image, Modal} from "semantic-ui-react";
import Utils, {InputFile} from "../../utils/Utils";
import {MyDateInput, MyInput, MyLabel, MySelect, select_type} from "../../utils/Components";
import PhoneInput from "react-phone-input-2"
import {CountryList} from "../../utils/Countries"
import placeholder from '../../../assets/images/image.png'
import {Req} from "../../utils/Req";
import {ToastsStore} from "react-toasts";
import AccountFees, {AccountFee} from "./AccountFees";
import {Account} from "../../utils/Models";
interface Client {
    client_id: number
    first_name: string
    last_name: string
    other_name: string
    client_gender: 'Male' | 'Female' | 'Other'
    birth_date: string
    nationality: string
    id_type: string
    id_number: string
    main_contact: string
    other_contact: string
    client_avatar: InputFile | null
    district: string
    county: string
    parish: string
    village: string
    email_address: string
    account_fees: AccountFee[]
}

export interface SystemClient {
    client_id: number
    client_name: string
    main_contact: number | string
}

export const initial_client: Client = {
    county: "", district: "", parish: "", village: "",
    birth_date: "", first_name: "", client_gender: "Other", id_number: "", id_type: "None", last_name: "", main_contact: "+256",
    nationality: "ug", other_contact: "+256", other_name: "", client_avatar: null, client_id: 0, email_address: "", account_fees: []
}

export default function CreateClient(props: {
    show: boolean, accounts: Account[], client_id?: number,
    close: (params?: { client: Client, systemClient: SystemClient }) => void,
}) {
    const [loading, setLoading] = React.useState(false)
    const [showFees, setShowFees] = React.useState<{ client_id: number, client_name: string, payments: AccountFee[], show: boolean }>(
        {client_id: 0, client_name: '', show: false, payments: []})

    const [client, setClient] = React.useState<Client>(initial_client)
    const handle_change = (name: string, value: string | number) => {
        setClient({...client, [name]: value})
    }

    const [countries, setCountries] = React.useState<Array<{ key: string, value: string, flag: string, text: string }>>([])

    const get_system_client = (client: Client): SystemClient => {
        return {
            client_id: client.client_id,
            client_name: `${client.last_name} ${client.first_name} ${client.other_name}`,
            main_contact: client.main_contact
        }
    }

    const save_client = () => {
        if (Utils.invalid_name(client.first_name.trim())) {
            ToastsStore.error("Enter valid first name<br/>(Only alphabetical letters allowed)")
        } else if (Utils.invalid_name(client.last_name.trim())) {
            ToastsStore.error("Enter valid last name<br/>(Only alphabetical letters allowed)")
        } else if (client.other_name.length > 0 && Utils.invalid_name(client.other_name.trim())) {
            ToastsStore.error("Enter valid other name<br/>(Only alphabetical letters allowed)")
        } else if (Utils.invalid_contact(client.main_contact)) {
            ToastsStore.error("Enter a valid main contact")
        } else if (client.other_contact.trim().length > 4 && Utils.invalid_contact(client.other_contact.trim())) {
            console.log(client.other_contact.trim(), client.other_contact.trim().length, Utils.invalid_contact(client.other_contact.trim()))
            ToastsStore.error("Enter a valid secondary contact")
        } else if (client.email_address.trim().length > 0 && Utils.invalid_email(client.email_address.trim())) {
            ToastsStore.error("Provide a valid email address or leave empty")
        } else if (client.id_type !== "None" && client.id_number.length < 5) {
            ToastsStore.error("Provide an ID Number")
        } else if (client.district.trim().length < 3) {
            ToastsStore.error("Please provide a residential district")
        } else if (client.village.trim().length < 3) {
            ToastsStore.error("Please provide a residential village")
        } else {
            setLoading(true)

            const formData = new FormData()
            formData.append("client_id", client.client_id.toString())
            formData.append("first_name", client.first_name.trim())
            formData.append("last_name", client.last_name.trim())
            formData.append("other_name", client.other_name.trim())
            formData.append("client_gender", client.client_gender)
            formData.append("birth_date", client.birth_date)
            formData.append("nationality", client.nationality)
            formData.append("id_type", client.id_type)
            formData.append("id_number", client.id_number)
            formData.append("main_contact", client.main_contact)
            formData.append("other_contact", Utils.invalid_contact(client.other_contact) ? '' : client.other_contact)
            formData.append("district", client.district)
            formData.append("county", client.county)
            formData.append("parish", client.parish)
            formData.append("village", client.village)
            if (client.client_avatar !== null && client.client_avatar.file !== undefined) {
                formData.append("client_avatar", client.client_avatar.file)
                formData.append("MAX_FILE_SIZE", "524288")
            }

            Req.save_client(formData)
                .then(response => {
                    setLoading(false)
                    if (response.data.hasOwnProperty("code")) {
                        const code = response.data['code']
                        if (code === 1) {
                            const aClient = {...client, client_id: response.data.client_id, account_fees: response.data.account_fees}
                            setClient(aClient)
                            if (aClient.account_fees.length > 0) {
                                setShowFees({
                                    client_id: aClient.client_id, payments: aClient.account_fees, show: true,
                                    client_name: get_system_client(aClient).client_name,
                                })
                            } else {
                                props.close({systemClient: get_system_client(aClient), client: aClient})
                            }
                            ToastsStore.success("Successfully saved client information")
                        } else if (code === 2) {
                            ToastsStore.error("Main contact is already in use")
                        } else if (code === 3) {
                            ToastsStore.error("Other contact is already in use")
                        } else if (code === 4) {
                            ToastsStore.error("Error while saving avatar, please retry")
                        } else {
                            ToastsStore.error("Error while creating user, please retry")
                        }
                    } else {
                        ToastsStore.error("Error while creating user, please retry")
                    }
                })
                .catch(() => {
                    setLoading(false)
                    ToastsStore.error("Error while creating user, please retry")
                })
        }
    }

    const get_client = () => {
        setLoading(true)
        Req.get_client({client_id: (props.client_id as number)})
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("client_id")) {
                    const data = response.data
                    setClient({
                        ...data,
                        client_avatar: data.client_avatar === '' ? null : (new InputFile(`${Req.BASE_URL}${data.client_avatar}`)),
                        main_contact: data.main_contact === "" ? "+256" : `+${data.main_contact}`,
                        other_contact: data.other_contact === "" ? "+256" : `+${data.other_contact}`,
                    })
                } else {
                    ToastsStore.error("Could not load client")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Could not load client")
            })
    }

    React.useEffect(() => {
        if (props.client_id !== undefined && props.client_id > 0) {
            get_client()
        } else {
            setClient(initial_client)
        }
    }, [props.client_id])

    React.useEffect(() => {
        setCountries(CountryList)
    }, [])

    return (
        <>
            <AccountFees
                client_id={showFees.client_id} payments={showFees.payments} show={showFees.show}
                client_name={showFees.client_name} accounts={props.accounts}
                close={(fees) => {
                    setShowFees({...showFees, show: false})
                    props.close({systemClient: get_system_client(client), client: {...client, account_fees: fees}})
                }}/>

            <Modal size='large' open={props.show} onClose={() => props.close()} closeOnDimmerClick={false}>
                <Modal.Content>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-3 p-1">
                                {/*User Avatar*/}
                                <div className="row mx-0 mt-3">
                                    <div className="col-12 p-1">
                                        <div>
                                            <div>
                                                <Image src={client.client_avatar === null ? placeholder : client.client_avatar.file_src}/>
                                            </div>

                                            <div className="mt-2">
                                                <input type="file" className="input_file" id="user_avatar" accept="image/*"
                                                       onChange={(event) => {
                                                           const files = event.target.files;
                                                           if (files !== null && files.length > 0) {
                                                               const file: File | null = files.item(0);
                                                               if (file !== null) {
                                                                   Utils.format_file(file as File)
                                                                       .then(result => setClient({...client, client_avatar: result}))
                                                               }
                                                           }
                                                       }}/>
                                                <label htmlFor="user_avatar" className="ui tiny primary button fluid">
                                                    <i className="ui upload icon"/>
                                                    Select Passport File
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-9 p-1 client_modal">
                                <div className="row m-0">
                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput
                                            placeholder="Enter given name" title="Given Name" name="first_name"
                                            change={handle_change} value={client.first_name} disabled={client.client_id > 0}/>
                                    </div>
                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput placeholder="Enter Surname" title="Surname" name="last_name"
                                                 change={handle_change} value={client.last_name} disabled={client.client_id > 0}/>
                                    </div>
                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput placeholder="Enter other name" title="Other Name" name="other_name"
                                                 change={handle_change} value={client.other_name} disabled={client.client_id > 0}/>
                                    </div>

                                    <div className="col-12 col-md-4 p-1">
                                        <MySelect
                                            change={(value: select_type) => handle_change('nationality', (value as string))}
                                            title="User Nationality" name="nationality" value={client.nationality} options={countries}/>
                                    </div>
                                    <div className="col-12 col-md-4 p-1">
                                        <MySelect
                                            change={(value: select_type) => handle_change('client_gender', (value as string))}
                                            title="User Gender" name="gender" value={client.client_gender} options={Utils.genders}/>
                                    </div>
                                    <div className="col-12 col-md-4 p-1">
                                        <MyDateInput
                                            title="Date of Birth" value={client.birth_date} name="birth_date"
                                            placeholder="Select date of birth" maxDate={Utils.today()} change={handle_change}/>
                                    </div>

                                    <div className="col-12 col-md-4 p-1">
                                        <MyLabel title="Main Contact"/>
                                        <PhoneInput
                                            countryCodeEditable={false} containerStyle={{marginBottom: '15px'}} country={'ug'}
                                            value={client.main_contact} onlyCountries={['ug']} disabled={client.client_id > 0}
                                            onChange={(value) => handle_change('main_contact', value)}/>
                                    </div>
                                    <div className="col-12 col-md-4 p-1">
                                        <MyLabel title="Other Contact (Optional)"/>
                                        <PhoneInput
                                            countryCodeEditable={false} containerStyle={{marginBottom: '15px'}}
                                            country={'ug'} value={client.other_contact} onlyCountries={['ug']}
                                            onChange={((value) => handle_change('other_contact', value))}/>
                                    </div>

                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput placeholder="Enter email address" title="Email Address (Optional)" name="email_address"
                                                 change={handle_change} value={client.email_address}/>
                                    </div>

                                    <div className="col-12 col-md-4 p-1">
                                        <MySelect
                                            change={(value: select_type) => handle_change('id_type', (value as string))}
                                            title="Select ID type" name="id_type" value={client.id_type} options={Utils.ids}/>
                                    </div>
                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput placeholder="Enter ID Number" title="ID Number" name="id_number"
                                                 change={handle_change} value={client.id_number}/>
                                    </div>

                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput placeholder="Enter residential district" title="Residential District" name="district"
                                                 change={handle_change} value={client.district}/>
                                    </div>
                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput placeholder="Enter residential county" title="Residential County" name="county"
                                                 change={handle_change} value={client.county}/>
                                    </div>

                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput placeholder="Enter residential parish" title="Residential Parish" name="parish"
                                                 change={handle_change} value={client.parish} className={'mb-md-0'}/>
                                    </div>
                                    <div className="col-12 col-md-4 p-1">
                                        <MyInput placeholder="Enter residential village" title="Residential Village" name="village"
                                                 change={handle_change} className={'mb-md-0'} value={client.village}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        negative icon='cancel' labelPosition='right' size='tiny'
                        content='Close Window' disabled={loading} onClick={() => props.close()}/>
                    <Button
                        positive icon='save' labelPosition='right' size='tiny'
                        content='Save Client' disabled={loading} loading={loading} onClick={save_client}/>
                </Modal.Actions>
            </Modal>
        </>
    )
}
