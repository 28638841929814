import React from "react";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Button, Form, Modal, Table} from "semantic-ui-react";
import {Loading, MySelect, select_type} from "../../../utils/Components";
import {BranchUser} from "../../../nav/SideBar";

export type report_type = "loan_report" | "loan_balances" | "loan_portfolio" | "payment_report" | "incomes_report" | "income_statement" |
    "aging_report" | "till_allocation" | "expenses_report" | "trial_balance" | "daily_report" | "balance_sheet" | "" |
    "bank_statement" | "sales_report" | "restructured_loans" | "portfolio_risk" | "journals" | 'daily_summary'

interface Report {
    report_type: report_type
    report_name: string
}

export const accessRoles: Array<Report> = [
    {report_type: "journals", report_name: "Journal Entries"},
    {report_type: "balance_sheet", report_name: "Balance Sheet"},
    {report_type: "bank_statement", report_name: "Bank Statement"},
    {report_type: "daily_report", report_name: "Daily Reports"},
    {report_type: "loan_report", report_name: "Loan Processing"},
    {report_type: "loan_balances", report_name: "Loan Balances"},
    {report_type: "loan_portfolio", report_name: "Loan Portfolio"},
    {report_type: "payment_report", report_name: "Loan Payments"},
    {report_type: "incomes_report", report_name: "Incomes Report"},
    {report_type: "income_statement", report_name: "Incomes Statement"},
    {report_type: "aging_report", report_name: "Loan Aging"},
    {report_type: "till_allocation", report_name: "Till Allocation"},
    {report_type: "expenses_report", report_name: "Expenses Report"},
    {report_type: "trial_balance", report_name: "Trial Balance"},
    {report_type: "sales_report", report_name: "Sales Report"},
    {report_type: "restructured_loans", report_name: "Restructured Loans"},
    {report_type: "portfolio_risk", report_name: "Portfolio at Risk"},
    {report_type: "daily_summary", report_name: "Daily Summary"},
]

interface User {
    user_id: number
    office_id: number
    username: string
    report_rights: Array<string>
    user_status: 'active' | 'inactive'
}

export default function ReportsPermissions(params: { branchUser: BranchUser }) {
    const [users, setUsers] = React.useState(Array<User>())
    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [user, setUser] = React.useState<User | undefined>(undefined)

    const [search, setSearch] = React.useState({office_id: 0})

    const save_report_rights = () => {
        setLoading({show: true, title: "Saving roles, please wait"})
        Req.update_report_rights({report_rights: JSON.stringify(user!.report_rights), user_id: user!.user_id})
            .then((response) => {
                setLoading({show: false, title: ""})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    ToastsStore.success("User rights successfully updated")
                    setUsers(users.map((aUser) => aUser.user_id === user!.user_id ? user! : aUser))
                    setUser(undefined)
                } else {
                    ToastsStore.error("Could not update report rights, please retry")
                }
            })
            .catch(() => {
                setLoading({show: false, title: ""})
                ToastsStore.error("Could not update report rights, please retry")
            })
    }

    const get_users = () => {
        setLoading({show: true, title: "Loading users, please wait"})
        Req.get_report_rights_users({office_id: search.office_id})
            .then((response) => {
                setLoading({show: false, title: ""})
                if (response.data.hasOwnProperty("users")) {
                    setUsers(response.data.users)
                } else {
                    ToastsStore.error("Could not load users, please retry")
                }
            })
            .catch(() => {
                setLoading({show: false, title: ""})
                ToastsStore.error("Could not load users, please retry")
            })
    }

    const get_roles = (aRoles: string[]) => {
        const roleNames =
            aRoles
                .map((aRole) =>
                    (accessRoles.filter((bRole) => aRole === bRole.report_type)[0]?.report_name ?? ""))
                .filter((aRole) => aRole !== "")
                .sort((a, b) => a?.localeCompare(b))

        let list = ""
        roleNames.forEach((aRole, index) => {
            list += aRole
            if (aRoles.length > 1) {
                list += (aRoles.length - 2 === index) ? " and " : (index !== aRoles.length - 1 ? ", " : "")
            }
        })
        return list
    }

    return (
        <>
            <Loading show={loading.show} text={loading.title} hide={() => setLoading({...loading, 'show': false})}/>

            <Modal open={user !== undefined} basic size='mini' centered={false} onClose={() => setUser(undefined)}>
                <div className="modal_div modal_form">
                    <div className="form_header">{`${user?.username}`}</div>

                    <div className="form_content rights_form">
                        {
                            accessRoles
                                // .sort((a, b) => a.report_name.localeCompare(b.report_type))
                                .map((role) =>
                                    <Form.Checkbox
                                        className="mb-2" checked={user?.report_rights.includes(role.report_type)}
                                        key={role.report_type} label={role.report_name} value={role.report_type}
                                        onChange={((event, data) => {
                                            if (data.checked as Boolean) {
                                                setUser({...user!, report_rights: [...user!.report_rights, role.report_type]})
                                            } else {
                                                setUser({
                                                    ...user!,
                                                    report_rights: user!.report_rights.filter((right) => right !== role.report_type)
                                                })
                                            }
                                        })}/>
                                )
                        }
                    </div>

                    <div className="form_footer">
                        <div className="row m-0">
                            <div className="col-6 p-0 pr-1">
                                <Button
                                    onClick={() => setUser(undefined)} content="Cancel Update"
                                    negative size='mini' fluid icon="close" labelPosition={"left"}/>
                            </div>
                            <div className="col-6 p-0 pl-1">
                                <Button
                                    onClick={save_report_rights} content="Update Report Rights"
                                    positive size='mini' fluid icon="save" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '180px'}}>
                        <MySelect
                            change={(value: select_type) => setSearch({...search, office_id: parseInt(value as string)})}
                            title="" name="branch" value={search.office_id}
                            options={[
                                {text: "Display all branches", value: 0},
                                ...params.branchUser.branches
                                    .map((branch) => ({text: branch.office_name, value: branch.office_id}))
                            ]}/>
                    </div>
                </div>

                <div className="search_button">
                    <Button primary size='mini' content='Search' labelPosition='left' onClick={get_users} icon='search'/>
                </div>
            </div>

            <div className="table_container container_no_footer">
                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '40px'}}/>
                            <Table.HeaderCell style={{width: '150px'}}>UserName</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '130px'}}>Branch</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}}>Status</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '600px'}}>User Roles</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            users
                                .sort((b, a) => a.report_rights.length - b.report_rights.length)
                                .map((aUser, index) =>
                                    <Table.Row onClick={() => setUser(aUser)} key={aUser.user_id}>
                                        <Table.Cell style={{width: '40px'}} textAlign={'center'}>{index + 1}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{aUser.username}</Table.Cell>
                                        <Table.Cell style={{width: '130px'}}>
                                            {
                                                params.branchUser.branches
                                                    .filter((branch) => branch.office_id === aUser.office_id
                                                    )[0]?.office_name
                                            }
                                        </Table.Cell>
                                        <Table.Cell style={{width: '80px'}}>{aUser.user_status === 'active' ? 'Active' : 'Not Active'}</Table.Cell>
                                        <Table.Cell style={{width: '600px'}}>
                                            {get_roles(aUser.report_rights)}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
