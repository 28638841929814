import React from "react";
import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import ReportJournals from "../ReportJournals";
import {CriteriaBalanceSheet} from "../criteria/BalanceSheetCriteria";

interface Report {
    title?: string
    account_name?: string
    type?: string
    amount?: number
    total?: number
    account_id?: number
}

export default function BalanceSheet(params: { transactions: any, criteria: CriteriaBalanceSheet }) {
    const [statements, setStatements] = React.useState<Array<Report>>([])
    const [showJournal, setShowJournal] = React.useState({account_id: 0})

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <>
            <ReportJournals
                account_id={showJournal.account_id} min_date={params.criteria.min_date}
                max_date={params.criteria.max_date} close={() => setShowJournal({account_id: 0})}/>

            <div className="table_container container_no_footer">
                <Table celled striped compact size='small' inverted color='grey' selectable className='has_total'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '450px'}}/>
                            <Table.HeaderCell style={{width: '150px'}}>Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            statements.map((report, index) =>
                                <Table.Row
                                    key={index}
                                    onClick={() => report.account_id && setShowJournal({account_id: report.account_id})}>
                                    {
                                        report.title !== undefined &&
                                        <Table.Cell style={{width: '750px', fontWeight: 'bolder'}} rowSpan={3}>{report.title}</Table.Cell>
                                    }
                                    {
                                        (report.account_name !== undefined && report.amount !== undefined) &&
                                        <>
                                            <Table.Cell style={{width: '450px'}}>{report.account_name}</Table.Cell>
                                            <Table.Cell style={{width: '300px'}} rowSpan={2}>{Utils.comma_number(report.amount)}</Table.Cell>
                                        </>
                                    }
                                    {
                                        (report.total !== undefined && report.amount !== undefined) &&
                                        <>
                                            <Table.Cell style={{width: '500px', fontWeight: 'bolder'}} rowSpan={2}>{report.total}</Table.Cell>
                                            <Table.Cell style={{width: '150px', fontWeight: 'bolder'}}>{Utils.comma_number(report.amount)}</Table.Cell>
                                        </>
                                    }
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
