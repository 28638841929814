import React from "react";
import Utils from "../../../utils/Utils";
import {Table} from "semantic-ui-react";
import {TablePagination} from "../../../utils/Components";
import {CriteriaJournal} from "../criteria/JournalCriteria";
import {ToastsStore} from "react-toasts";
import {ShowLoan} from "../../loans/container/ShowLoan";
import {LoansDetails} from "../../accounts/creditors/loans/LoansDetails";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import CreateClient from "../../clients/CreateClient";
import {Account} from "../../../utils/Models";

type journal_type = 'Till Allocation' | 'Account Fees' | 'Disbursement' | 'Loan Charges' | 'Loan Payment' |
    'Expenses' | 'Creditor Loan' | 'Creditor Payment'

interface Statement {
    _id: number
    debit: number
    credit: number
    date: string
    account: string
    client: string
    type: journal_type
}

export default function JournalsReport(params: {
    transactions: any, search: (page: number) => void, accounts: Account[],
    setJournalsCriteria: (criteria: CriteriaJournal) => void, criteria: CriteriaJournal
}) {
    const user = useAppSelector(getStaff)
    const [statements, setStatements] = React.useState<Statement[]>([])

    const [type, setType] = React.useState<"" | "loan" | "creditor" | "client">("")
    const [creditor, setCreditor] = React.useState(0)
    const [client, setClient] = React.useState(0)
    const [loan, setLoan] = React.useState(0)

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp.journals)
            params.setJournalsCriteria({
                ...params.criteria,
                pages: params.transactions.rp.summary.pages,
                page: params.transactions.rp.summary.page,
                limit: params.transactions.rp.summary.limit
            })
        }
    }, [params.transactions])

    return (
        <>
            {
                type === 'loan' &&
                <div style={{height: 'calc(100% - 50px)'}}>
                    <ShowLoan
                        loan_id={loan} accounts={params.accounts}
                        close={() => {
                            setType("")
                            setLoan(0)
                        }}/>
                </div>
            }

            <LoansDetails
                show={type === "creditor"} creditors={[]} loan_id={creditor} users={[]}
                user_id={user.staff.user_id} accounts={params.accounts}
                close={() => {
                    setType("")
                    setCreditor(0)
                }}/>


            <CreateClient
                show={type === 'client'} client_id={client} accounts={params.accounts}
                close={() => {
                    setType("")
                    setClient(0)
                }}/>

            <div className={`table_container ${type === '' ? '' : 'd-none'}`}>
                <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Journal Date</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Journal Type</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Journal Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '280px'}}>Account Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Debit</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Credit</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            statements.map((transaction, index) =>
                                <Table.Row
                                    key={index}
                                    onClick={() => {
                                        if (["Loan", "Disbursement", "Loan Charge", "Loan Payment"].includes(transaction.type)) {
                                            setLoan(transaction._id)
                                            setType("loan")
                                        } else if (["Account Fee"].includes(transaction.type)) {
                                            setClient(transaction._id)
                                            setType("client")
                                        } else if (["Creditor Loan"].includes(transaction.type)) {
                                            setCreditor(transaction._id)
                                            setType("creditor")
                                        } else {
                                            /*"" | "Expense" |  "Savings Account" | "Other"*/
                                            ToastsStore.info("No details can be shown")
                                        }
                                    }}>

                                    <Table.Cell style={{width: '50px'}} textAlign="center">
                                        {Utils.row_number(index, {
                                            pages: params.transactions.rp.summary.pages,
                                            page: params.transactions.rp.summary.page,
                                            limit: params.transactions.rp.summary.limit
                                        })}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{Utils.localise_date(transaction.date)}</Table.Cell>
                                    <Table.Cell style={{width: '100px'}}>{transaction.type}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{transaction.account}</Table.Cell>
                                    <Table.Cell style={{width: '280px'}}>{transaction.client}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {transaction.debit !== 0 ? Utils.comma_number(transaction.debit) : ''}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {transaction.credit !== 0 ? Utils.comma_number(transaction.credit) : ''}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>

            <div className={`table_footer ${type === '' ? '' : 'd-none'}`}>
                <TablePagination total={params.criteria.pages} change={(page: number) => params.search(page)}/>
            </div>
        </>
    )
}
