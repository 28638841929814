import {Tab} from "semantic-ui-react";
import React from "react";
import CorePermissions from "./CorePermissions";
import ReportsPermissions from "./ReportsPermissions";
import {BranchUser} from "../../../nav/SideBar";

export interface Insurance {
    charge_amount: number
    charge_type: 'Flat' | 'Percentage'
}

export interface Charge {
    minimum: number
    amount: number
    charge_type: 'Flat' | 'Percentage'
}

export default function Settings(params: { branchUser: BranchUser }) {
    return (
        <>
            <div className="row m-0 h-100">
                <div className="col-12 p-1 h-100">
                    <Tab menu={{pointing: true,}} className='settings_container'
                         panes={[
                             {
                                 menuItem: 'Core Permissions',
                                 render() {
                                     return (
                                         <Tab.Pane attached={false} className="tab_pane" key='core_permissions'>
                                             <CorePermissions/>
                                         </Tab.Pane>
                                     )
                                 }
                             },
                             {
                                 menuItem: 'Reports Permissions',
                                 render() {
                                     return (
                                         <Tab.Pane attached={false} className="tab_pane" key='reports_permissions'>
                                             <ReportsPermissions branchUser={params.branchUser}/>
                                         </Tab.Pane>
                                     )
                                 }
                             },
                         ]}/>
                </div>
            </div>
        </>
    )
}
