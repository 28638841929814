import {Loading, MyDateInput} from "../../../../utils/Components";
import React from "react";
import Utils from "../../../../utils/Utils";
import {multiselect_type} from "../../../../utils/components/MultiSelect";
import {Drawing} from "../DirectorDrawings";
import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Button, Table} from "semantic-ui-react";
import {BranchUser} from "../../../../nav/SideBar";
import {Account} from "../../../../utils/Models";

export default function DrawingsList(params: {
    drawings: Drawing[], setDrawings: (drawings: Drawing[]) => void,
    branchUser: BranchUser, accounts: Account[], setDrawing: (drawing: Drawing) => void
}) {
    const [loading, setLoading] = React.useState({message: "", show: false})

    const [search, setSearch] = React.useState({min_date: Utils.weekly_date().sunday, max_date: Utils.today()})
    const handle_search = (name: string, value: string | number | multiselect_type) => {
        setSearch({...search, [name]: value})
    }

    const get_drawings = () => {
        setLoading({message: "Loading drawings, please wait", show: true})
        Req.get_director_drawings({max_date: search.max_date, min_date: search.min_date})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("drawings")) {
                    params.setDrawings(response.data.drawings)
                } else {
                    ToastsStore.error("Could not load director drawings")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not load director drawings")
                setLoading({message: "", show: false})
            })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" maxDate={search.max_date}
                            placeholder="Select min date" change={handle_search}/>
                    </div>
                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" minDate={search.min_date}
                            placeholder="Select max date" change={handle_search}/>
                    </div>
                </div>

                <div className="button_bar">
                    <Button size='mini' content="Search" primary labelPosition="left" icon="search" onClick={get_drawings}/>
                </div>
            </div>

            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Type</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Account</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Drawn By</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '170px'}}>Date Drawn</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            params.drawings.map((drawing, index) =>
                                <Table.Row key={drawing.drawings_id} onClick={() => params.setDrawing(drawing)}>
                                    <Table.Cell style={{width: '40px'}} textAlign="center">{index + 1}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {drawing.drawing_type === 'cash_in' ? 'Cash Deposit' : 'Cash Withdrawal'}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {params.accounts.filter((account) => account.account_id === drawing.account_id)[0]?.account_name}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{Utils.comma_number(drawing.amount_drawn as number)}</Table.Cell>
                                    <Table.Cell style={{width: '150px'}}>
                                        {params.branchUser.users.filter((user) => user.user_id === drawing.drawn_by)[0]?.user_name}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '170px'}}>
                                        {Utils.localise_date_time(`${drawing.date_drawn} ${drawing.time_drawn}`)}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
