import Utils from "../../../utils/Utils";
import {MyDateInput, MyLabel, SelectData} from "../../../utils/Components";
import {MultiSelect} from "../../../utils/components/MultiSelect";
import {Button, Modal} from "semantic-ui-react";
import React from "react";
import {MultiUser} from "../../admin/users/Users";

export interface CriteriaExpenses {
    min_date: string
    max_date: string
    branches: []
    expenses: []
}

export default function ExpensesCriteria(
    params: {
        branches: SelectData[], expenses: MultiUser[], show: boolean, close: () => void, criteria: CriteriaExpenses,
        handle_change: (criteria: CriteriaExpenses) => void, generate: () => void
    }
) {
    const handle_change = (name: string, value: string | number | Array<number | string>) => {
        params.handle_change({...params.criteria, [name]: value})
    }

    return (
        <Modal open={params.show} basic size='mini' centered={false} onClose={params.close}>
            <div className='modal_div modal_form'>
                <div className="form_content">
                    <div className="row m-0">
                        <div className="col-12 p-1">
                            <MyDateInput
                                title="Minimum expense Date" value={params.criteria.min_date} name="min_date"
                                maxDate={params.criteria.max_date} placeholder="Select expense date" change={handle_change}/>
                        </div>
                        <div className="col-12 p-1">
                            <MyDateInput
                                title="Maximum expense date" value={params.criteria.max_date} name="max_date" maxDate={Utils.today()}
                                minDate={params.criteria.min_date} placeholder="Select expense date" change={handle_change}/>
                        </div>
                        <div className="col-12 p-1">
                            <MyLabel title="Select branches"/>
                            <MultiSelect
                                multiple={true} placeholder="Select branches" items={params.branches} selected={params.criteria.branches}
                                change={(value) => handle_change('branches', value)}/>
                        </div>
                        <div className="col-12 p-1">
                            <MyLabel title="Select expense type"/>
                            <MultiSelect
                                multiple={true} placeholder="Select expense type" items={params.expenses} selected={params.criteria.expenses}
                                change={(value) => handle_change('expenses', value)}/>
                        </div>
                    </div>
                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative onClick={params.close} content="Close Window" size='mini'
                                    fluid icon='close' labelPosition="left"/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive onClick={params.generate} content="Load Report" size='mini'
                                    fluid icon="table" labelPosition="left"/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
