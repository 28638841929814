import {Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import React from "react";

import {Account} from "../../../../utils/Models";

export interface RcDisbursement {
    client: string
    date_disbursed: string
    loan_id: number
    loan_principal: number
    office_name: string
    sources: { account: number, amount: number }[]
}

export default function RcDisbursements(params: { accounts: Account[], disbursements: RcDisbursement[] }) {
    const get_disbursement = (disbursement: RcDisbursement, index: number) => {
        const sources: { account: number, amount: number }[] = disbursement.sources.length === 0 ?
            [{account: 2, amount: disbursement.loan_principal}] : disbursement.sources
        const rowCount = sources.length

        return (
            <>
                {
                    sources.map((source, sIndex) =>
                        sIndex === 0 ?
                            <Table.Row key={`${disbursement.loan_id}_${sIndex}`}>
                                <Table.Cell style={{width: '50px'}} textAlign="center" rowCount={rowCount}>
                                    {Utils.row_number(index)}
                                </Table.Cell>
                                <Table.Cell style={{width: '80px'}} textAlign="center" rowCount={rowCount}>
                                    {disbursement.loan_id}
                                </Table.Cell>
                                <Table.Cell style={{width: '150px'}} rowCount={rowCount}>{disbursement.office_name}</Table.Cell>
                                <Table.Cell style={{width: '180px'}} rowCount={rowCount}>{disbursement.client}</Table.Cell>

                                <Table.Cell style={{width: '140px'}}>
                                    {Utils.get_journal_account(source.account, params.accounts)}
                                </Table.Cell>
                                <Table.Cell style={{width: '140px'}}>{Utils.comma_number(source.amount)}</Table.Cell>

                                <Table.Cell style={{width: '160px'}} rowCount={rowCount}>
                                    {Utils.comma_number(disbursement.loan_principal)}
                                </Table.Cell>
                                <Table.Cell style={{width: '180px'}} rowCount={rowCount}>
                                    {Utils.localise_date_time(disbursement.date_disbursed)}
                                </Table.Cell>
                            </Table.Row>
                            :
                            <Table.Row key={`${disbursement.loan_id}_${sIndex}`}>
                                <Table.Cell
                                    style={{width: '140px'}}>{Utils.get_journal_account(source.account, params.accounts)}</Table.Cell>
                                <Table.Cell style={{width: '140px'}}>{Utils.comma_number(source.amount)}</Table.Cell>
                            </Table.Row>
                    )
                }
            </>
        )
    }

    return (
        <>
            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}} textAlign="center">Loan No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Office Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Client Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Source Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Source Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Loan Principal</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Date Disbursed</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.disbursements.map((disbursement, index) =>
                                get_disbursement(disbursement, index)
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
