import {Button, Modal, Table} from "semantic-ui-react";
import React from "react";
import {Loading, loading_params, LoadingParams, MyDateInput, MyInput, MySelect} from "../../utils/Components";
import {BranchUser} from "../../nav/SideBar";
import Utils from "../../utils/Utils";
import moment from "moment";
import {ToastsStore} from "react-toasts";
import {Req} from "../../utils/Req";
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";

export interface TillAccount {
    office_id: number
    user_id: number
    till_date: string
    opened_by: number
    till_data: any
    closed_by: number
    till_status: 'open' | 'closed' | 'faulty'
    time_opened: string
    time_closed: string
}

export const initial_till_account: TillAccount = {
    closed_by: 0, office_id: 0, opened_by: 0, till_data: undefined, till_date: "",
    till_status: 'open', time_closed: "", time_opened: "", user_id: 0
}

export default function TillAccounts(params: {
    till_date: string, show: boolean, tillAccounts: TillAccount[], branchUser: BranchUser,
    close: () => void, update: (account: TillAccount) => void
}) {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)
    const [account, setAccount] = React.useState(initial_till_account)

    const save_till_account = () => {
        if (account.office_id === 0) {
            ToastsStore.error("Select office you are opening tills for")
        } else if (account.user_id === 0) {
            ToastsStore.error("Select user you are opening up tills for")
        } else if (account.user_id === user.staff.user_id && user.staff.user_email !== 'pandewilliam100@gmail.com') {
            ToastsStore.error("You cannot open up tills for your own account")
        } else if (account.till_date === "") {
            ToastsStore.error("Select a date of till opening")
        } else {
            setLoading({show: true, text: "Opening up till account, please wait"})

            Req.open_allocation_account({
                till_date: account.till_date, user_id: account.user_id, office_id: account.office_id
            })
                .then((response) => {
                    setLoading({show: false, text: ""})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            const aAccount = {...account, opened_by: response.data.opened_by, time_opened: response.data.time_opened}
                            setAccount(aAccount)
                            params.update(aAccount)
                            ToastsStore.success("Till account has been opened up for today's transactions")
                        } else if (response.data['code'] === 2) {
                            ToastsStore.error("Till was already closed, you cannot make another allocation")
                        } else if (response.data['code'] === 3) {
                            ToastsStore.error(response.data.msg)
                        }
                    } else {
                        ToastsStore.error("Could not open up till account")
                    }
                })
                .catch(() => {
                    setLoading({show: false, text: ""})
                    ToastsStore.error("Could not open up till account")
                })
        }
    }

    React.useEffect(() => {
        setAccount({...account, till_date: params.till_date})
    }, [params.till_date])

    return (
        <>
            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <Modal open={params.show} onClose={params.close} basic size='small' centered={false}>
                <div className='row m-0' style={{height: '500px', background: 'white'}}>
                    <div className='col-7 pl-0 p-1 h-100'>
                        <div className="table_container full_height">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '120px'}}>Office</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '160px'}}>Till User</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}}>Status</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        params.tillAccounts.map((tillAccount) =>
                                            <Table.Row key={tillAccount.user_id}>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {
                                                        params.branchUser.branches.filter((aBranch) =>
                                                            aBranch.office_id === tillAccount.office_id)[0].office_name
                                                    }
                                                </Table.Cell>
                                                <Table.Cell style={{width: '160px'}}>
                                                    {
                                                        params.branchUser.users.filter((aUser) =>
                                                            aUser.user_id === tillAccount.user_id)[0].user_name
                                                    }
                                                </Table.Cell>
                                                <Table.Cell style={{width: '80px'}}>
                                                    {
                                                        tillAccount.till_status === 'open' ? 'Open' : (
                                                            tillAccount.till_status === 'closed' ? 'Closed' : 'Closed (Faulty)'
                                                        )
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                    </div>

                    <div className='col-5 pl-1 pr-0 h-100'>
                        <div className="form">
                            <div className="form_content">
                                <MySelect
                                    change={(value) => setAccount({...account, office_id: value as number, user_id: 0})}
                                    title="Branch of Allocation" value={account.office_id}
                                    disabled={account.time_opened !== ""}
                                    options={[
                                        {text: 'Select a branch', value: 0},
                                        ...params.branchUser.branches.map((branch) =>
                                            ({text: branch.office_name, value: branch.office_id}))
                                    ]}/>

                                <MySelect
                                    value={account.user_id} disabled={account.time_opened !== ""}
                                    title="User till account being opened for"
                                    change={(value) => setAccount({...account, user_id: value as number})}
                                    options={[
                                        {text: 'Select a user', value: 0},
                                        ...params.branchUser.users
                                            .filter((user) =>
                                                (user.user_id === account.user_id && account.time_opened !== "") ||
                                                (user.office_id === account.office_id && user.user_status === 'active'))
                                            .map((user) => ({text: user.user_name, value: user.user_id}))
                                    ]}/>

                                <div style={{marginBottom: '15px'}}>
                                    <MyDateInput
                                        title="Date of allocation" value={account.till_date} name="till_date"
                                        placeholder="Set date of allocation" maxDate={Utils.today()}/>
                                </div>

                                <MySelect
                                    title="Allocation Status" value={account.till_status}
                                    options={[
                                        {text: 'Till Open', value: 'open'},
                                        {text: 'Closed successfully', value: 'closed'},
                                        {text: 'Closed with errors', value: 'faulty'},
                                    ]}/>
                                {
                                    account.time_opened !== "" &&
                                    <>
                                        <MyInput
                                            placeholder="Time Opened" title="Time Opened" name="time_opened"
                                            value={moment(account.time_opened).format("lll")}/>

                                        <MyInput
                                            placeholder="Opened By" title="Opened By" name="opened_by"
                                            value={
                                                params.branchUser.users.filter((user) =>
                                                    user.user_id === account.opened_by)[0].user_name
                                            }/>
                                    </>
                                }

                                {
                                    account.time_closed !== "" &&
                                    <>
                                        <MyInput
                                            placeholder="Time Closed" title="Time Closed" name="time_closed"
                                            value={moment(account.time_closed).format("lll")}/>

                                        <MyInput
                                            placeholder="Closed By" title="Closed By" name="closed_by"
                                            value={
                                                params.branchUser.users.filter((user) =>
                                                    user.user_id === account.closed_by)[0].user_name
                                            }/>
                                    </>
                                }
                            </div>

                            <div className="form_footer">
                                <div className='row m-0'>
                                    <div className='col-2 px-0'>
                                        <Button negative fluid size='tiny' icon='close' onClick={() => params.close()}/>
                                    </div>

                                    <div className='col-5 px-1'>
                                        <Button
                                            negative content='Clear' fluid size='tiny' icon='close' labelPosition='left'
                                            onClick={() => setAccount({...initial_till_account, till_date: params.till_date})}/>
                                    </div>
                                    <div className='col-5 px-0'>
                                        <Button
                                            positive onClick={save_till_account} content='Save'
                                            disabled={account.time_opened !== ""}
                                            fluid size='tiny' icon='save' labelPosition='left'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
