import {Button, Confirm, Table} from "semantic-ui-react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import React from "react";
import {CreditorPayment} from "../LoanPayments";
import {LoanDetail} from "../LoansDetails";
import {PayCreditor} from "./MakeCreditorPayment";
import {Loading} from "../../../../../utils/Components";
import {Req} from "../../../../../utils/Req";
import {ToastsStore} from "react-toasts";

export default function CreditorPaymentHistory(
    params: {
        update: (loanDetail: LoanDetail) => void, loanDetail: LoanDetail,
        setPayment: (payment: PayCreditor) => void, hasRoles: boolean
    }
) {
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loading, setLoading] = React.useState({message: "", show: false})

    const delete_payment = (payment: CreditorPayment) => {
        setConfirm({
            ...confirm, open: true, content: 'Are you sure you want to delete this loan payment?',
            onConfirm: () => {
                setConfirm({...confirm, 'open': false})
                setLoading({message: "Deleting loan payment, please wait", show: true})

                Req.delete_creditor_payment({loan_id: params.loanDetail.loan.loan_id, payment_id: payment.payment_id})
                    .then(response => {
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            params.update(response.data.loan)
                            ToastsStore.success("Payment has been deleted successfully")
                        } else {
                            ToastsStore.error("Error while deleting loan payment, please retry")
                        }
                        setLoading({message: "", show: false})
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        ToastsStore.error("Error while deleting loan payment, please retry")
                    })
            },
            onCancel: () => setConfirm({...confirm, 'open': false})
        })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content}/>

            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '100px'}} textAlign="center"/>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Date Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Principal Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Interest Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Total Paid</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            params.loanDetail.payments.map((payment, index) =>
                                <Table.Row key={payment.payment_id}>
                                    <Table.Cell style={{width: '100px'}} textAlign="center">
                                        <Button
                                            positive size='tiny' icon='info circle'
                                            onClick={() =>
                                                params.setPayment({
                                                    date_paid: payment.time_paid.split(" ")[0],
                                                    time_paid: payment.time_paid.split(" ")[1],
                                                    loan_id: payment.loan_id,
                                                    payment_id: payment.payment_id,
                                                    account_id: payment.account_id,
                                                    reference_no: payment.reference_no,
                                                    principal_paid: payment.principal_paid,
                                                    interest_paid: payment.interest_paid,
                                                    user_id: payment.user_id,
                                                    prepared_by: payment.cashier_id,
                                                })
                                            }/>

                                        <Button
                                            negative size='tiny' icon='trash' disabled={!params.hasRoles}
                                            onClick={() => delete_payment(payment)}/>

                                    </Table.Cell>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{Utils.localise_date(payment.time_paid)}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.principal_paid)}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.interest_paid)}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {Utils.comma_number(payment.interest_paid + payment.principal_paid)}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
