import {MyInput, MySelect, SelectData, TablePagination} from "../../../utils/Components";
import Utils from "../../../utils/Utils";
import React from "react";
import {ToastsStore} from "react-toasts";
import {Page, Req} from "../../../utils/Req";
import {ShowLoan} from "../container/ShowLoan";
import {MultiSelect} from "../../../utils/components/MultiSelect";
import {Button} from "semantic-ui-react";
import ListingTable, {ListingLoan} from "./ListingTable";
import {Account} from "../../../utils/Models";

export interface PortFolioLoan {
    type_id: number
    type_name: string
    loan_count: number
}

export default function LoansPortfolio(params: {
    branches: { select: SelectData[], selected: number[] }, type_id?: number, user_id?: number, accounts: Account[]
}) {
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = React.useState({
        name: '', branches: params.branches.selected, min: Utils.today(),
        max: Utils.today(), status: 'active'
    })
    const handle_search_change = (name: string, value: string | number | Array<number | string>) => {
        setSearch({...search, [name]: value})
    }

    const [loans, setLoans] = React.useState<Array<ListingLoan>>([])
    const [showLoan, setShowLoanInfo] = React.useState({show: false, loan_id: 0})
    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})

    const get_loans = (props: { page: number, pages: number }) => {
        if (search.branches.length === 0) {
            ToastsStore.error("No branches selected, reload page if branches select is empty")
            return
        }
        setLoading(true)
        Req.get_loans_listing_portfolio({
            branches: JSON.stringify(search.branches), status: search.status as 'active' | 'complete' | 'all',
            name: search.name, loan_type: params.type_id, user_id: params.user_id, page: props.page, pages: props.pages
        })
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("loans")) {
                    setLoans(response.data.loans)
                    setPages({pages: response.data.pages, page: props.page, limit: response.data.limit})
                } else {
                    ToastsStore.error("Failed to get loan portfolios")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Failed to get loan portfolios")
            })
    }

    React.useEffect(() => {
        setLoans([])
    }, [params.type_id, params.user_id, search.status])

    return (
        <>
            {
                showLoan.show &&
                <ShowLoan
                    close={() => setShowLoanInfo({loan_id: 0, show: false})}
                    loan_id={showLoan.loan_id} accounts={params.accounts}
                />}

            {
                !showLoan.show &&
                <>
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MySelect
                                    value={search.status}
                                    change={(value) => handle_search_change('status', value as string)}
                                    options={[
                                        {text: "Active Loans", value: 'active'},
                                        {text: "Complete", value: 'complete'},
                                        {text: "All Loans", value: 'all'}]
                                    }/>
                            </div>
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select offices" items={params.branches.select} selected={search.branches}
                                    change={(value) => handle_search_change('branches', value)}/>
                            </div>
                            <div>
                                <MyInput
                                    placeholder="Customer name" title="" name="name" value={search.name}
                                    change={handle_search_change} enter={() => get_loans({pages: 0, page: 1})}/>
                            </div>
                        </div>
                        <div className="button_bar">
                            <Button floated='right' size='mini' icon='search' disabled={loading} loading={loading}
                                    content="Search" primary labelPosition="left" onClick={() => get_loans({pages: 0, page: 1})}/>
                        </div>
                    </div>

                    <div className="table_container">
                        <ListingTable loans={loans} click={(loan_id) => setShowLoanInfo({loan_id: loan_id, show: true})}/>
                    </div>

                    <div className="table_footer">
                        <TablePagination total={pages.pages} change={(page: number) => get_loans({page: page, pages: pages.pages})}/>
                    </div>
                </>
            }
        </>
    )
}
