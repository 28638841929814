import React from "react";
import {Button, Table} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import Utils from "../../../utils/Utils";
import {MyDateInput, MyInput, SelectData} from "../../../utils/Components";
import {Req} from "../../../utils/Req";
import {MultiSelect} from "../../../utils/components/MultiSelect";
import {ShowLoan} from "../container/ShowLoan";
import {Account} from "../../../utils/Models";

interface Expected {
    loan_id: number
    client_name: string
    client_contact: string
    date_expected: string
    loan_principal: number
    loan_balance: number
    payment_principal: number
    payment_interest: number
    actual_principal: number
    actual_interest: number
}

export type arrears_periods = -1 | -7 | 0 | 7 | 14 | 28 | 30

export default function PaymentArrears(params: {
    branches: { select: SelectData[], selected: number[] }, period: arrears_periods, accounts: Account[]
}) {
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = React.useState({name: '', branches: params.branches.selected, min: Utils.today(), max: Utils.today(), loan_id: ''})
    const handle_search_change = (name: string, value: string | number | Array<number | string>) => {
        setSearch({...search, [name]: value})
    }

    const [payments, setPayments] = React.useState<Array<Expected>>([])
    const [showLoan, setShowLoanInfo] = React.useState({show: false, loan_id: 0})

    const search_payments = () => {
        if (search.branches.length === 0) {
            ToastsStore.error("No branches selected, reload page if branches select is empty")
            return
        }
        setLoading(true)
        Req.get_expected_payments({
            branches: JSON.stringify(search.branches), name: search.name.trim(), max_date: search.max,
            min_date: search.min, period: params.period, loan_id: search.loan_id.trim()
        })
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("arrears")) {
                    setPayments(response.data.arrears)
                } else {
                    ToastsStore.error("Failed to get payments")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Failed to get payments")
            })
    }

    React.useEffect(() => {
        setPayments([])
    }, [params.period])

    return (
        <>
            {
                showLoan.show &&
                <ShowLoan close={() => setShowLoanInfo({loan_id: 0, show: false})}
                          loan_id={showLoan.loan_id} accounts={params.accounts}/>
            }

            {
                !showLoan.show &&
                <>
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select offices" items={params.branches.select} selected={search.branches}
                                    change={(value) => handle_search_change('branches', value)}/>
                            </div>
                            {
                                params.period === -1 &&
                                <>
                                    <div>
                                        <MyDateInput
                                            title="" value={search.min} name="min" placeholder="Minimum Date"
                                            change={handle_search_change} maxDate={search.max}/>
                                    </div>
                                    <div>
                                        <MyDateInput
                                            title="" value={search.max} name="max" placeholder="Maximum Date"
                                            change={handle_search_change} minDate={search.min}/>
                                    </div>
                                </>
                            }
                            <div>
                                <MyInput
                                    placeholder="Search by client name" title="" name="name" value={search.name}
                                    change={handle_search_change} enter={search_payments}/>
                            </div>
                            <div>
                                <MyInput
                                    placeholder="Search by loan no" title="" name="loan_id" value={search.loan_id}
                                    change={handle_search_change} enter={() => search_payments()}/>
                            </div>
                        </div>
                        <div className="button_bar">
                            <Button floated='right' size='mini' icon='search' disabled={loading} loading={loading}
                                    onClick={search_payments} content="Search" primary labelPosition="left"/>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' inverted color='grey' selectable className='has_total'>
                            <Table.Header fullWidth>
                                <Table.Row key={0}>
                                    <Table.HeaderCell style={{width: '40px'}} rowSpan='2'>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}} rowSpan='2'>Date expected</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}} rowSpan='2'>Loan No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}} rowSpan='2'>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}} rowSpan='2'>Client Contact</Table.HeaderCell>

                                    <Table.HeaderCell colSpan='3' textAlign="center" style={{width: '360px'}}>Principal Amount</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='3' textAlign="center" style={{width: '360px'}}>Interest Amount</Table.HeaderCell>

                                    <Table.HeaderCell style={{width: '120px'}} rowSpan='2'>Total</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row key={1}>
                                    <Table.HeaderCell style={{width: '120px'}}>Expected</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Balance</Table.HeaderCell>

                                    <Table.HeaderCell style={{width: '120px'}}>Expected</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Balance</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    payments
                                        .map((payment, index) =>
                                            <Table.Row
                                                key={index}
                                                onClick={() => {
                                                    if (payment.loan_id > 0) {
                                                        setShowLoanInfo({loan_id: payment.loan_id, show: true})
                                                    }
                                                }}>
                                                {
                                                    payment.loan_id === 0 ?
                                                        <>
                                                            <Table.Cell style={{width: '590px'}} colSpan={5}>
                                                                Total Expected Balances
                                                            </Table.Cell>
                                                        </> :
                                                        <>
                                                            <Table.Cell style={{width: '40px'}} textAlign='center'>{Utils.row_number(index)}</Table.Cell>
                                                            <Table.Cell style={{width: '140px'}}>{Utils.localise_date(payment.date_expected)}</Table.Cell>
                                                            <Table.Cell style={{width: '70px'}}>{payment.loan_id}</Table.Cell>
                                                            <Table.Cell style={{width: '200px'}}>{payment.client_name}</Table.Cell>
                                                            <Table.Cell style={{width: '140px'}}>{payment.client_contact}</Table.Cell>
                                                        </>
                                                }

                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.payment_principal)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {Utils.comma_number(payment.payment_principal - payment.actual_principal)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.actual_principal)}</Table.Cell>

                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.payment_interest)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {Utils.comma_number(payment.payment_interest - payment.actual_interest)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.actual_interest)}</Table.Cell>

                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.loan_balance)}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </>
            }
        </>
    )
}
