import React from 'react'
import {Req} from "../../../utils/Req";

export default function DailyReportSummary(params: { url: string }) {

    return (
        <div className="table_container container_no_footer">
            {
                params.url !== "" &&
                <div className="h-100">
                    <iframe src={Req.BASE_URL + "" + params.url} style={{width: '100%', height: '100%'}} frameBorder="0" title="PDF Reader"/>
                </div>
            }
        </div>
    )
}
