import {MultiSelect, multiselect_type} from "../../../../utils/components/MultiSelect";
import {Loading, MyDateInput, SelectData, TablePagination} from "../../../../utils/Components";
import {Button, Table} from "semantic-ui-react";
import Utils, {InputFile} from "../../../../utils/Utils";
import React from "react";
import {Page, Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {AccountTransfer} from "../CashTransfers";
import {Account} from "../../../../utils/Models";

export default function TransferList(params: {
    branches: { select: SelectData[], selected: number[] }, transfers: AccountTransfer[], accounts: Account[],
    setTransfers: (transfers: AccountTransfer[]) => void, setTransfer: (transfer: AccountTransfer) => void
}) {
    const [loading, setLoading] = React.useState({message: "", show: false})

    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})
    const [search, setSearch] = React.useState({
        min_date: Utils.weekly_date().sunday, max_date: Utils.today(), branches: params.branches.selected
    })
    const handle_search = (name: string, value: string | number | multiselect_type) => {
        setSearch({...search, [name]: value})
    }

    const load_deposits = (aParams: { page: number, pages: number }) => {
        setLoading({message: "Loading cash transfers, please wait", show: true})
        Req.get_cash_transfers({
            max_date: search.max_date, min_date: search.min_date, branches: JSON.stringify(search.branches),
            page: aParams.page, pages: aParams.pages,
        })
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("transfers")) {
                    let aTransfers: Array<any> = response.data['transfers'];
                    aTransfers.forEach((new_deposit, index) => {
                        new_deposit['transfer_receipt'] = new_deposit['transfer_receipt'] === "" ? null :
                            new InputFile(`${Req.BASE_URL}${new_deposit['transfer_receipt']}`)
                        new_deposit[index] = new_deposit
                    })
                    params.setTransfers(aTransfers)
                    setPages({...pages, pages: response.data.pages, page: aParams.page})
                } else {
                    ToastsStore.error("Could not load bank deposits")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not load bank deposits")
                setLoading({message: "", show: false})
            })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MultiSelect
                            multiple={true} placeholder="Select branches" items={params.branches.select}
                            selected={search.branches} change={(value) => handle_search('branches', value)}/>
                    </div>
                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" maxDate={search.max_date}
                            placeholder="Select min date" change={handle_search}/>
                    </div>
                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" minDate={search.min_date}
                            placeholder="Select max date" change={handle_search}/>
                    </div>
                </div>

                <div className="button_bar">
                    <Button
                        size='mini' content="Search" primary labelPosition="left" icon="search"
                        onClick={() => load_deposits({page: 1, pages: 0})}/>
                </div>
            </div>

            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '170px'}}>Date Transferred</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Branch Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Account From</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Account To</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            params.transfers.map((transfer, index) =>
                                <Table.Row key={transfer.transfer_id} onClick={() => params.setTransfer(transfer)}>
                                    <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index, pages)}</Table.Cell>
                                    <Table.Cell style={{width: '170px'}}>
                                        {Utils.localise_date_time(`${transfer.date_transferred} ${transfer.time_transferred}`)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>
                                        {params.branches.select.filter((branch)=> branch.value === transfer.office_id)[0]?.text}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {params.accounts.filter((account)=> account.account_id === transfer.account_from)[0]?.account_name}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {params.accounts.filter((account)=> account.account_id === transfer.account_to)[0]?.account_name}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {Utils.comma_number(transfer.amount_transferred as number)}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>

            <div className="table_footer">
                <TablePagination
                    total={pages.pages}
                    change={(page: number) => load_deposits({page: page, pages: pages.pages})}/>
            </div>
        </>
    )
}
