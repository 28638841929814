import React from "react";
import Utils, {ConfirmAction, initial_confirm,} from "../../utils/Utils";
import {Loading, loading_params, LoadingParams, MyDateInput, MyInput, MySelect, select_type} from "../../utils/Components";
import {Button, Confirm, Table} from "semantic-ui-react";
import {Req} from "../../utils/Req"
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import {BranchUser} from "../../nav/SideBar";
import {Account} from "../../utils/Models";

interface Allocation {
    allocation_id: number
    allocated_by: number
    office_id: number
    accountant_id: number
    allocation_date: string
    time_allocated: string
    amount_allocated: number | string
    account_id: number
    source_id: number
    till_status: 'active' | 'pending'
    allocation_type: 'loan' | 'petty' | 'expenses' | ''
}

export default function TillAllocations(params: { branchUser: BranchUser, accounts: Account[] }) {
    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)
    const user = useAppSelector(getStaff)

    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const initial_allocation: Allocation = {
        allocation_date: Utils.today(), allocation_id: 0, amount_allocated: "", account_id: 0, source_id: 1,
        time_allocated: "", office_id: 0, accountant_id: 0, allocated_by: user.staff.user_id, till_status: 'pending', allocation_type: ''
    }

    const [allocations, setAllocations] = React.useState <Array<Allocation>>([])
    const [allocation, setAllocation] = React.useState <Allocation>(initial_allocation)
    const handle_change = (name: string, value: string | number) => {
        setAllocation({...allocation, [name]: value})
    }

    const [search, setSearch] = React.useState({
        min_date: Utils.weekly_date().sunday, max_date: Utils.today(), office_id: user.staff.office_id, user_id: 0
    })
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const load_allocations = () => {
        if (search.office_id === 0) {
            ToastsStore.info("Select an allocation branch")
            return
        }

        setLoading({show: true, text: 'Loading allocations, please wait'})
        Req.get_allocations({
            max_date: search.max_date, min_date: search.min_date, office_id: search.office_id, user_id: search.user_id
        })
            .then((response) => {
                setLoading(loading_params)
                if (response.data.hasOwnProperty("allocations")) {
                    setAllocations(response.data.allocations)
                } else {
                    ToastsStore.error("Could not load allocations, please retry")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not load allocations, please retry")
            })
    }

    const get_username = (user_id: number) => {
        return params.branchUser.users.filter((aUser) => aUser.user_id === user_id)[0]?.user_name
    }

    const save_allocations = () => {
        const amount_allocated = Utils.strip_commas(allocation.amount_allocated)

        if (allocation.office_id === 0) {
            ToastsStore.error("Select an allocation office")
        } else if (allocation.accountant_id === 0) {
            ToastsStore.error("Select a user to allocate money to")
        } else if (allocation.accountant_id === user.staff.user_id && user.staff.user_email !== 'pandewilliam100@gmail.com') {
            ToastsStore.error("You cannot allocate funds to your own account")
        } else if (allocation.source_id === 0) {
            ToastsStore.error("Select an account where money is going from")
        } else if (allocation.account_id === 0) {
            ToastsStore.error("Select an account the allocation is going to")
        } else if (!Utils.is_valid_number(amount_allocated)) {
            ToastsStore.error("Enter a valid amount allocated")
        } else if (allocation.allocation_type === "") {
            ToastsStore.error("Select type of allocation for the money")
        } else {
            setConfirm({
                ...confirm, open: true,
                content: `Are you sure you want to continue allocating ${Utils.comma_number(parseFloat(amount_allocated))} to ${get_username(allocation.accountant_id)}`,
                onCancel: () => setConfirm({...confirm, open: false}),
                onConfirm: () => {
                    setConfirm({...confirm, open: false})
                    setLoading({show: true, text: "Saving till allocation, please wait"})

                    Req.save_allocation({
                        allocation_date: allocation.allocation_date,
                        allocation_id: allocation.allocation_id,
                        user_id: allocation.accountant_id,
                        amount_allocated: parseFloat(amount_allocated),
                        office_id: allocation.office_id,
                        source_id: allocation.source_id,
                        account_id: allocation.account_id,
                        allocation_type: allocation.allocation_type as 'loan' | 'petty' | 'expenses'
                    })
                        .then((response) => {
                            setLoading(loading_params)
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data['code'] === 1) {
                                    const new_allocation = {
                                        ...allocation,
                                        allocation_id: response.data.allocation_id,
                                        time_allocated: response.data.time_allocated,
                                        amount_allocated: parseFloat(amount_allocated)
                                    }
                                    if (allocation.allocation_id === 0) {
                                        setAllocations(allocations => [new_allocation, ...allocations])
                                    } else {
                                        setAllocations(allocations.map((aA) =>
                                            aA.allocation_id === allocation.allocation_id ? new_allocation : aA)
                                        )
                                    }
                                    setAllocation(new_allocation)
                                    ToastsStore.success("Successfully saved allocation")
                                } else if (response.data['code'] === 2) {
                                    ToastsStore.error("Till was already closed, you cannot make another allocation")
                                } else if (response.data['code'] === 3) {
                                    ToastsStore.error(response.data.msg)
                                }
                            } else {
                                ToastsStore.error("Could not allocate, please retry")
                            }
                        })
                        .catch(() => {
                            setLoading(loading_params)
                            ToastsStore.error("Could not allocate, please retry")
                        })
                }
            })
        }
    }

    const approve_allocation = (allocation_id: number) => {
        setConfirm({
            ...confirm, open: true,
            content: `Do you wish to continue approving this Till allocation. Please do so only after verifying receipt of money`,
            onCancel: () => setConfirm({...confirm, open: false}),
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                setLoading({show: true, text: "Approving till allocation, please wait"})
                Req.approve_allocation({allocation_id: allocation_id})
                    .then((response) => {
                        setLoading({show: false, text: ""})
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            ToastsStore.success("Till allocation approved successfully")
                            setAllocations(allocations.map((a) => a.allocation_id === allocation_id ?
                                {...a, till_status: 'active'} : a))
                        } else {
                            ToastsStore.error("Could not approve allocation")
                        }
                    })
                    .catch(() => {
                        setLoading({show: false, text: ""})
                        ToastsStore.error("Could not approve allocation")
                    })
            }
        })
    }

    React.useEffect(() => {
        load_allocations()
    }, [])

    return (
        <>
            <Confirm
                open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MySelect
                                    change={(value: select_type) => handle_search('office_id', (value as number))}
                                    value={search.office_id}
                                    options={[{text: 'Select a branch', value: 0},
                                        ...params.branchUser.branches.map((branch) =>
                                            ({text: branch.office_name, value: branch.office_id}))]
                                    }/>
                            </div>
                            <div>
                                <MySelect
                                    change={(value: select_type) => handle_search('user_id', (value as number))} value={search.user_id}
                                    options={[
                                        {text: 'All users', value: 0},
                                        ...params.branchUser.users
                                            .filter((user) => user.office_id === search.office_id)
                                            .map((user) => ({text: user.user_name, value: user.user_id}))
                                    ]}/>
                            </div>
                            <div>
                                <MyDateInput
                                    value={search.min_date} name="min_date" maxDate={search.max_date}
                                    placeholder="Minimum payment date" change={handle_search}/>
                            </div>
                            <div>
                                <MyDateInput
                                    value={search.max_date} name="max_date" maxDate={Utils.today()}
                                    minDate={search.min_date} placeholder="Maximum payment date" change={handle_search}/>
                            </div>
                        </div>

                        <div className="button_bar">
                            <Button primary size='mini' onClick={load_allocations} icon='search' labelPosition='left' content='Search'/>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '75px'}}/>
                                    <Table.HeaderCell style={{width: '120px'}}>Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Allocated To</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Account</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Allocation</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Allocated By</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    allocations.map((allocation) =>
                                        <Table.Row key={allocation.allocation_id}>
                                            <Table.Cell style={{width: '75px'}}>
                                                <Button
                                                    positive size='tiny' icon='info circle' onClick={() => setAllocation(allocation)}/>
                                                <Button
                                                    positive={allocation.till_status === "active"}
                                                    negative={allocation.till_status === "pending"}
                                                    size='tiny' icon='shield'
                                                    disabled={user.staff.user_id !== allocation.accountant_id || allocation.till_status !== 'pending'}
                                                    onClick={() => approve_allocation(allocation.allocation_id)}/>
                                            </Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.localise_date(allocation.allocation_date)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{get_username(allocation.accountant_id)}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>
                                                {
                                                    params.accounts.filter((aAccount) =>
                                                        aAccount.account_id === allocation.account_id)[0]?.account_name
                                                }
                                            </Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.comma_number(allocation.amount_allocated as number)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{get_username(allocation.allocated_by)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="form">
                        <div className="form_header">
                            {
                                allocation.allocation_id === 0 ? "Create new allocation"
                                    : `Allocation details for ${Utils.localise_date(allocation.allocation_date)}`
                            }
                        </div>

                        <div className="form_content">
                            <MySelect
                                change={(value) => setAllocation({...allocation, office_id: value as number, accountant_id: 0})}
                                title="Branch of Allocation" value={allocation.office_id}
                                options={[
                                    {text: 'Select a branch', value: 0},
                                    ...params.branchUser.branches.map((branch) =>
                                        ({text: branch.office_name, value: branch.office_id}))
                                ]}/>

                            <MySelect
                                change={(value) => setAllocation({...allocation, accountant_id: value as number})}
                                title="Select user you are allocating funds to" value={allocation.accountant_id}
                                options={[
                                    {text: 'Select a user', value: 0},
                                    ...params.branchUser.users
                                        .filter((user) =>
                                            (user.user_id === allocation.accountant_id && allocation.allocation_id > 0) ||
                                            (user.office_id === allocation.office_id && user.user_status === 'active'))
                                        .map((user) => ({text: user.user_name, value: user.user_id}))
                                ]}/>

                            <div style={{marginBottom: '15px'}}>
                                <MyDateInput
                                    title="Select Allocation Date" value={allocation.allocation_date} name="allocation_date"
                                    placeholder="Set date of allocation" maxDate={Utils.today()}/>
                            </div>

                            <MySelect
                                change={(value) => setAllocation({...allocation, source_id: value as number})}
                                title="Select source of money" value={allocation.source_id}
                                options={[
                                    {text: 'Select an account', value: 0},
                                    ...params.accounts.map((account) =>
                                        ({text: account.account_name, value: account.account_id}))
                                ]}/>

                            <MySelect
                                change={(value) => setAllocation({...allocation, account_id: value as number})}
                                title="Select account you are allocating funds to" value={allocation.account_id}
                                options={[
                                    {text: 'Select an account', value: 0},
                                    ...params.accounts.map((account) =>
                                        ({text: account.account_name, value: account.account_id}))
                                ]}/>

                            <MySelect
                                change={(value) => {
                                    setAllocation({...allocation, allocation_type: value as 'loan' | 'petty' | 'expenses'})
                                }}
                                title="Reason for allocating funds" value={allocation.allocation_type}
                                options={[
                                    {text: 'Select an reason for allocation', value: ''},
                                    {text: 'For loan disbursement', value: 'loan'},
                                    {text: 'For office expenses', value: 'expenses'},
                                ]}/>

                            <MyInput
                                placeholder="Enter amount allocated" title="Amount Allocated" name="amount_allocated"
                                change={handle_change} value={Utils.comma_input(allocation.amount_allocated)}/>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={() => setAllocation(initial_allocation)} labelPosition={"right"}
                                            content="Clear Data" icon="trash" size='mini' fluid/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button
                                        positive onClick={save_allocations}
                                        disabled={allocation.allocation_id > 0 || !user.roles.core_roles?.includes("till_allocation")}
                                        content="Save Allocation" icon="save" size='mini' fluid labelPosition='left'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
