import {Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import React from "react";
import {Account} from "../../../../utils/Models";

export interface RcCreditorPayment {
    account_id: number
    creditor_name: string
    interest_paid: number
    principal_paid: number
    time_paid: string
    payment_id: number
    loan_id: number
}

export default function RcCreditorPayments(params: { payments: RcCreditorPayment[], accounts: Account[] }) {
    return (
        <>
            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}} textAlign="center">Loan No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '220px'}}>Creditor Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Payment Account</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Principal Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Interest Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Total Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '200px'}}>Date Acquired</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.payments.map((payment, index) =>
                                <Table.Row key={payment.payment_id}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '70px'}} textAlign="center">{payment.loan_id}</Table.Cell>
                                    <Table.Cell style={{width: '220px'}}>{payment.creditor_name}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {Utils.get_journal_account(payment.account_id, params.accounts)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '160px'}}>{Utils.comma_number(payment.principal_paid)}</Table.Cell>
                                    <Table.Cell style={{width: '160px'}}>{Utils.comma_number(payment.interest_paid)}</Table.Cell>
                                    <Table.Cell style={{width: '160px'}}>
                                        {Utils.comma_number(payment.interest_paid + payment.principal_paid)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '200px'}}>{Utils.localise_date_time(payment.time_paid)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
