import React from 'react';
import {Button, Image, Input, Table} from "semantic-ui-react";
import {Req} from "../../../utils/Req";
import Utils, {InputFile} from "../../../utils/Utils";
import {MyInput, MyLabel, MySelect, select_type, SelectData} from "../../../utils/Components"
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import placeholder from '../../../../assets/images/image.png'
import AccessRights, {AccessRight, initial_right, Rights} from "../settings/AccessRights";
import Offices, {Office} from "./Offices";
import {useAppSelector} from "../../../store/hooks";
import {getStaff, UserRights} from "../../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import PhoneInput from "react-phone-input-2";

export interface SystemUser {
    user_id: number
    user_name: string
}

export interface MultiUser {
    office_id: number
    text: string
    value: number
}

export interface User {
    user_id: number
    office_id: number
    office_name: string
    first_name: string
    last_name: string
    user_name: string
    user_gender: 'Male' | 'Female' | 'Other'
    main_contact: string
    other_contact: string
    user_email: string
    user_avatar: InputFile | null
    access_rights: Array<AccessRight>
    user_status: 'active' | 'inactive'
}

export const initial_user: User = {
    first_name: "", user_gender: 'Other', last_name: "", main_contact: '+256', office_id: 0, other_contact: '+256', user_name: "",
    user_avatar: null, user_email: "", user_id: 0, office_name: '', access_rights: [], user_status: 'active',
}

export default function Users() {
    const staff = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState(false)
    const [saving, setSaving] = React.useState(false)

    const [offices, setOffices] = React.useState<Array<SelectData>>([])
    const [branches, setBranches] = React.useState<Array<Office>>([])

    const [showRights, setShowRights] = React.useState(false)
    const [showOffices, setShowOffices] = React.useState(false)

    const [user, setUser] = React.useState<User>(initial_user)
    const handle_change = (name: string, value: string | number) => {
        setUser({...user, [name]: value})
    }
    const initialise_user_data = (user: User) => {
        let new_user: User = Utils.invalid_contact(user.main_contact.toString())
            ? {...user, main_contact: ''} : {...user, main_contact: `+${user.main_contact}`}

        new_user = Utils.invalid_contact(new_user.other_contact.toString())
            ? {...new_user, other_contact: ''} : {...new_user, other_contact: `+${new_user.other_contact}`}
        setUser(new_user)
    }

    const format_user_roles = (roles: UserRights): Array<AccessRight> => {
        const access_rights: Array<AccessRight> = []
        initial_right.forEach((right) => {
            let access_right: AccessRight = {branches: [], right_id: right.right_id, right_name: right.right_name}
            if (roles.hasOwnProperty(right.right_id)) {
                access_right = {...access_right, branches: roles[right.right_id]}
            }
            access_rights.push(access_right)
        })
        return access_rights
    }

    const [users, setUsers] = React.useState<Array<User>>([])
    const [search, setSearch] = React.useState({name: "", branch: 0, status: 'active'})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const get_users = () => {
        setLoading(true)
        Req.get_users({name: search.name, branch: search.branch, status: search.status})
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("users")) {
                    let users_: Array<User> = [];
                    (response.data["users"] as Array<any>).forEach((value) => {
                        users_ = [...users_, {...value, access_rights: format_user_roles(value.access_rights)}]
                    })
                    setUsers(users_)

                    const _branches: Array<Office> = response.data.offices
                    setOffices(Utils.combo_office(_branches))
                    setBranches(_branches)
                } else {
                    ToastsStore.error("Could not load user data, please retry")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Could not load user data, please retry")
            })
    }

    const save_user = () => {
        if (user.office_id === 0) {
            ToastsStore.error("Select office attached to")
        } else if (Utils.invalid_name(user.first_name.trim())) {
            ToastsStore.error("Enter valid first name")
        } else if (Utils.invalid_name(user.last_name)) {
            ToastsStore.error("Enter valid last name")
        } else if (Utils.invalid_name(user.user_name)) {
            ToastsStore.error("Enter valid user name")
        } else if (Utils.invalid_contact(user.main_contact)) {
            ToastsStore.error("Enter a valid main contact")
        } else if (user.other_contact.length > 4 && Utils.invalid_contact(user.other_contact)) {
            ToastsStore.error("Enter a valid secondary contact")
        } else if (Utils.invalid_email(user.user_email)) {
            ToastsStore.error("Enter a valid email address")
        } else {
            setSaving(true)
            Req.save_user({
                first_name: user.first_name, last_name: user.last_name, main_contact: user.main_contact, office_id: user.office_id,
                other_contact: user.other_contact, user_avatar: user.user_avatar, user_email: user.user_email, user_gender: user.user_gender,
                user_id: user.user_id, user_name: user.user_name, user_status: user.user_status
            })
                .then(result => {
                    if (result.data.code === 1) {
                        const new_user =
                            {
                                ...user, user_id: result.data.user_id,
                                user_avatar: user.user_avatar !== null ? new InputFile(`${Req.BASE_URL}${result.data.user_avatar}`) : null
                            }
                        setUser(new_user)
                        if (user.user_id === 0) {
                            setUsers([...users, new_user])
                        } else {
                            setUsers(users.map((_user) => _user.user_id === user.user_id ? new_user : _user))
                        }
                        ToastsStore.success("User data has been saved successfully")
                    } else {
                        ToastsStore.error(result.data.msg)
                    }
                    setSaving(false)
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Could not save user data, please retry")
                })
        }
    }

    const clear_user = () => {
        setUser(initial_user)
    }

    React.useEffect(() => {
        const load_users = () => {
            if (user.office_id === 0) {
                setUser({...user, office_name: ''})
            } else {
                for (let index = 0; index < branches.length; index++) {
                    if (branches[index].office_id === user.office_id) {
                        setUser({...user, office_name: branches[index].office_name})
                        break
                    }
                }
            }
        }
        load_users();
    }, [user.office_id])

    React.useEffect(() => {
        get_users()
    }, [])

    return (
        <>
            <AccessRights
                show={showRights} user={user} offices={offices}
                close={(rights) => {
                    if (rights !== undefined) {
                        setUsers(users.map((_user) => _user.user_id === user.user_id ?
                            {..._user, access_rights: format_user_roles(rights)} : {..._user}))
                    }
                    setShowRights(false)
                }}/>

            <Offices show={showOffices} users={users} close={() => setShowOffices(false)} offices={branches}
                     save={(office) => {
                         if (branches.filter((_office) => _office.office_id === office.office_id).length === 1) {
                             setBranches(branches.map((_branch) => _branch.office_id === office.office_id ? office : _branch))
                             setOffices(offices.map((_office) => (_office.value as number) === office.office_id ?
                                 {text: office.office_name, value: office.office_id} : _office
                             ))
                         } else {
                             setBranches([...branches, office])
                             setOffices([...offices, {text: office.office_name, value: office.office_id}])
                         }
                     }}/>

            <div className="container-fluid p-0 h-100">
                <div className="row m-0 h-100">
                    <div className='col-12 col-md-8 p-1 h-100'>
                        <div className="content_bar">
                            <div className="search_bar">
                                <div>
                                    <MySelect
                                        change={(value: select_type) => handle_search('branch', parseInt(value as string))}
                                        title="" name="branch" value={search.branch}
                                        options={[{text: "Display all branches", value: 0}, ...offices]}/>
                                </div>

                                <div>
                                    <MySelect
                                        change={(value: select_type) => handle_search('status', (value as string))}
                                        title="" name="status" value={search.status}
                                        options={[{text: 'Active User', value: 'active'}, {text: 'User De-activated', value: 'inactive'}]}/>
                                </div>

                                <div>
                                    <Input placeholder='Search by name' size='mini' className="w-100" value={search.name}
                                           onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                               handle_search('name', data.value)
                                           }}/>
                                </div>
                            </div>

                            <div className="search_button">
                                <Button size='mini' primary icon='code branch' labelPosition="left" content="Branches"
                                        onClick={() => setShowOffices(true)}/>

                                <Button primary size='mini' loading={loading} content='Search' labelPosition='left'
                                        disabled={loading || saving} onClick={get_users} icon='search'/>
                            </div>
                        </div>

                        <div className="table_container container_no_footer">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}}/>
                                        <Table.HeaderCell style={{width: '85px'}}/>
                                        <Table.HeaderCell style={{width: '170px'}}>Full Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '150px'}}>Branch</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '120px'}}>Contact</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '100px'}}>User Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}}>Status</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        users.map((user, index) =>
                                            <Table.Row key={user.user_id}>
                                                <Table.Cell style={{width: '50px'}} textAlign='center'>
                                                    {Utils.row_number(index)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '85px'}}>
                                                    <Button
                                                        positive size='tiny' icon='edit'
                                                        onClick={() => initialise_user_data(user)}/>
                                                    <Button
                                                        positive size='tiny' icon='cogs'
                                                        disabled={!Rights.has_right(staff.roles.user_roles, Rights.manage_user_rights)}
                                                        onClick={() => {
                                                            initialise_user_data(user)
                                                            setShowRights(true);
                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '170px'}}>{`${user.last_name} ${user.first_name}`}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{user.office_name}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{user.main_contact}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{user.user_name}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}}>{user.user_status.toUpperCase()}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                    </div>

                    <div className='col-12 col-md-4 p-1 h-100'>
                        <div className='form'>
                            <div className="form_header">
                                {user.user_id === 0 ? "Create new system user" : `${user.last_name} ${user.first_name} ${user.user_name}`}
                            </div>

                            <div className="form_content">
                                <div className="mb-2">
                                    <div style={{height: '120px'}}>
                                        <Image centered src={user.user_avatar === null ? placeholder : user.user_avatar.file_src}/>
                                    </div>
                                    <div className="mt-2">
                                        <input type="file" className="input_file" id="user_avatar" accept="image/*"
                                               onChange={(event) => {
                                                   const files = event.target.files;
                                                   if (files !== null && files.length > 0) {
                                                       const file: File | null = files.item(0);
                                                       if (file !== null) {
                                                           Utils.format_file(file as File)
                                                               .then((result) => {
                                                                   setUser({...user, 'user_avatar': result})
                                                               })
                                                       }
                                                   }
                                               }}/>
                                        <label htmlFor="user_avatar" className="ui tiny grey button fluid">
                                            <i className="ui upload icon"/>
                                            Select User Passport File
                                        </label>
                                    </div>
                                </div>

                                <div className='row m-0'>
                                    <div className='col-12 p-0'>
                                        <MySelect
                                            change={(value: select_type) => handle_change('office_id', parseInt(value as string))}
                                            title="Office of Attachment" name="office_id" value={user.office_id}
                                            options={[{text: "Select a branch", value: 0}, ...offices]}/>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <MySelect
                                            change={(value: select_type) => handle_change('user_status', (value as string))}
                                            title="User Status" name="user_status" value={user.user_status}
                                            options={[
                                                {text: 'Active User', value: 'active'},
                                                {text: 'User De-activated', value: 'inactive'}]
                                            }/>
                                    </div>

                                    <div className='col-12 p-0'>
                                        <MyInput placeholder="Enter first name" title="First Name" name="first_name"
                                                 change={handle_change} value={user.first_name}/>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <MyInput placeholder="Enter last name" title="Last Name" name="last_name"
                                                 change={handle_change} value={user.last_name}/>
                                    </div>

                                    <div className='col-12 p-0'>
                                        <MySelect
                                            change={(value: select_type) => handle_change('user_gender', (value as string))}
                                            title="User Gender" name="gender" value={user.user_gender} options={Utils.genders}/>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <MyInput placeholder="Enter user name" title="User Name" name="user_name"
                                                 change={handle_change} value={user.user_name}/>
                                    </div>

                                    <div className='col-12 p-0'>
                                        <MyLabel title="Main Contact"/>
                                        <PhoneInput
                                            countryCodeEditable={false} containerStyle={{marginBottom: '15px'}}
                                            country={'ug'} value={user.main_contact} onlyCountries={['ug']}
                                            onChange={((value) => handle_change('main_contact', value))}/>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <MyLabel title="Other Contact"/>
                                        <PhoneInput
                                            countryCodeEditable={false} containerStyle={{marginBottom: '15px'}}
                                            country={'ug'} value={user.other_contact} onlyCountries={['ug']}
                                            onChange={((value) => handle_change('other_contact', value))}/>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <MyInput placeholder="Enter email address" title="Email address" name="user_email"
                                                 change={handle_change} value={user.user_email}/>
                                    </div>
                                </div>
                            </div>

                            <div className="form_footer">
                                <div className='row m-0'>
                                    <div className='col-6 pl-0 pr-1'>
                                        <Button negative onClick={clear_user} disabled={saving || loading} content='Clear Data'
                                                fluid size='tiny' icon='trash' labelPosition='right'/>
                                    </div>
                                    <div className='col-6 pl-1 pr-0'>
                                        <Button positive onClick={save_user} disabled={saving || loading} content='Save User'
                                                loading={saving} fluid size='tiny' icon='save' labelPosition='left'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
