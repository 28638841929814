import {LoanDetail, TopUp} from "../LoansDetails";
import {Button, Confirm, Table} from "semantic-ui-react";
import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import {Account} from "../../../../../utils/Models";
import {Req} from "../../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Loading} from "../../../../../utils/Components";

export default function TopUpHistory(params: {
    loanDetail: LoanDetail, selected: (top_up: TopUp) => void, hasRoles: boolean,
    accounts: Account[], update: (loanDetail: LoanDetail) => void
}) {

    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loading, setLoading] = React.useState({message: "", show: false})

    const delete_top_up = (top_up: TopUp) => {
        setConfirm({
            ...confirm, open: true, content: 'Are you sure you want to delete this loan top-up?',
            onConfirm: () => {
                setConfirm({...confirm, 'open': false})
                setLoading({message: "Deleting loan top up, please wait", show: true})

                Req.delete_creditor_top_up({loan_id: params.loanDetail.loan.loan_id, top_up_id: top_up.top_up_id})
                    .then(response => {
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            params.update(response.data.loan)
                            ToastsStore.success("Top-up has been deleted successfully")
                        } else {
                            ToastsStore.error("Error while deleting loan top up, please retry")
                        }
                        setLoading({message: "", show: false})
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        ToastsStore.error("Error while deleting loan top up, please retry")
                    })
            },
            onCancel: () => setConfirm({...confirm, 'open': false})
        })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content}/>

            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '80px'}} textAlign="center"/>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Amount Topped Up</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Top up Account</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Date Acquired</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Start Date</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            params.loanDetail.top_ups.map((top_up, index) =>
                                <Table.Row key={top_up.top_up_id}>
                                    <Table.Cell style={{width: '80px'}} textAlign="center">
                                        <Button positive size='tiny' icon='info circle' onClick={() => params.selected(top_up)}/>
                                        <Button
                                            negative size='tiny' icon='trash' disabled={!params.hasRoles}
                                            onClick={() => delete_top_up(top_up)}/>
                                    </Table.Cell>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{Utils.comma_number(top_up.top_up_amount)}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {(params.accounts.filter((a) => a.account_id === top_up.account_id))[0]?.account_name}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '150px'}}>
                                        {Utils.localise_date_time(`${top_up.top_up_date} ${top_up.top_up_time}`)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{Utils.localise_date(top_up.start_date)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
