import React from "react";
import {Req} from "../../../utils/Req";
import {Loading, MyInput, MyLabel, MySelect, select_type} from "../../../utils/Components";
import {Button, Form, Table, TextArea} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import Utils from "../../../utils/Utils";
import LoanCharges from "./LoanCharges";

export type rate_types = 'simple' | 'compound' | 'simple_interest' | ''
export type intervals = 1 | 7 | 14 | 30

export interface LoanType {
    type_id: number
    type_name: string
    type_desc: string
    loan_rate: string
    start_days: intervals
    payment_interval: intervals
    security_type: 'none' | 'guarantor' | 'collateral' | 'either' | 'both'
    loan_status: 'active' | 'de-active'
    charges: LoanTypeCharge[]
    interest_mode: 'daily' | 'monthly'
}

export const initial_type: LoanType = {
    payment_interval: 30, loan_rate: "", start_days: 30, type_desc: "", type_id: 0, type_name: "",
    security_type: 'either', loan_status: 'active', charges: [], interest_mode: 'monthly'
}

/*charges*/
export interface LoanTypeCharge {
    charge_id: number
    fee_description: string
    fee_name: string
    type_id: number
    tiers: LoanChargeTier[]
}

interface LoanChargeTier {
    charge_id: number
    charge_type: "Flat" | "Percentage"
    charge_value: number | string
    min_value: number | string
}

export const initial_charge: LoanTypeCharge = {
    charge_id: 0, fee_description: "", fee_name: "", tiers: [], type_id: 0
}


export default function LoanTypes() {
    const [loading, setLoading] = React.useState({show: false, title: "Loading loan types, please wait..."})
    const [showCharges, setShowCharges] = React.useState<{ show: boolean, charge: LoanTypeCharge }>({
        show: false, charge: initial_charge
    })
    const [loanType, setLoanType] = React.useState<LoanType>(initial_type)
    const [loanTypes, setLoanTypes] = React.useState<Array<LoanType>>([])

    const handle_change = (name: string, value: string | number) => {
        setLoanType({...loanType, [name]: value})
    }

    const save_loan_type = () => {
        if (Utils.invalid_full_name(loanType.type_name)) {
            ToastsStore.error("Please enter valid name of the loan type")
        } else if (loanType.type_desc.length < 20) {
            ToastsStore.error("Please describe this loan with at least 20 characters")
        } else if (!Utils.is_valid_number(loanType.loan_rate)) {
            ToastsStore.error("Please provide a valid interest rate, not less than 0%")
        } else {
            setLoading({show: true, title: "Saving loan type, please wait"})
            Req.save_loan_type({
                loan_rate: loanType.loan_rate,
                loan_status: loanType.loan_status,
                payment_interval: loanType.payment_interval,
                security_type: loanType.security_type,
                start_days: loanType.start_days,
                type_desc: loanType.type_desc,
                type_id: loanType.type_id,
                type_name: loanType.type_name,
                interest_mode: loanType.interest_mode
            })
                .then((response) => {
                    setLoading({show: false, title: ""})
                    if (response.data['code'] === 1) {
                        const new_loan_type = {...loanType, 'type_id': response.data.type_id}
                        setLoanType(new_loan_type)
                        if (new_loan_type.type_id === 0) {
                            setLoanTypes([new_loan_type, ...loanTypes])
                        } else {
                            setLoanTypes(loanTypes.map((_loanType) => _loanType.type_id === loanType.type_id ? loanType : _loanType))
                        }
                        ToastsStore.error("Loan type saved successfully")
                    } else {
                        ToastsStore.error(response.data.msg)
                    }
                })
                .catch(error => {
                    setLoading({show: false, title: ""})
                    ToastsStore.error(error.message)
                })
        }
    }

    const fetch_loan_types = () => {
        setLoading({show: true, title: "Loading loans, please wait"})
        Req.get_loan_types()
            .then(response => {
                setLoading({show: false, title: ""})
                if (response.data['code'] === 1) {
                    setLoanTypes(response.data.loan_types as Array<LoanType>)
                } else {
                    ToastsStore.error("Could not load loan data, please retry")
                }
            })
            .catch(error => {
                setLoading({show: false, title: ""})
                ToastsStore.error(error.message)
            })
    }

    React.useEffect(() => {
        fetch_loan_types()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.title} hide={() => setLoading({...loading, show: false})}/>

            <LoanCharges
                show={showCharges.show} loanTypeCharge={showCharges.charge}
                close={(_charges) => {
                    if (_charges !== undefined) {
                        setLoanType({...loanType, charges: _charges})
                        setLoanTypes(loanTypes.map((_loanType) => loanType.type_id === _loanType.type_id ?
                            {..._loanType, charges: _charges} : _loanType))
                        ToastsStore.success("Loan charges saved successfully")
                    }
                    setShowCharges({show: false, charge: initial_charge})
                }}/>

            <div className="row h-100 m-0">
                <div className="col-12 col-md-8 p-0 h-100">
                    <div className="content_bar">
                        <div className="search_bar"/>
                        <div className="search_button">
                            <Button primary floated='right' size='mini' onClick={fetch_loan_types} icon='search'
                                    labelPosition='left' content='Load Data'/>

                            <Button floated='right' size='mini' icon='add square' primary content='Add New'
                                    labelPosition='left' onClick={() => setLoanType(initial_type)}/>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' inverted color='grey' selectable className="">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign='center'>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Loan Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '80px'}} textAlign='center'>Loan Rate</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '80px'}} textAlign='center'>Start Days</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '80px'}}>Status</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    loanTypes
                                        .map((loan_type, index) =>
                                            <Table.Row key={loan_type.type_id} onClick={() => setLoanType(loan_type)}>
                                                <Table.Cell style={{width: '40px'}} textAlign='center'>{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '250px'}}>{loan_type.type_name}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign='center'>{loan_type.loan_rate}%</Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign='center'>{loan_type.start_days}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}}>{loan_type.loan_status.toUpperCase()}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-0 h-100">
                    <div className="form">
                        <div className="form_header">{loanType.type_id === 0 ? "New loan type" : loanType.type_name}</div>

                        <div className="form_content">
                            <MyInput placeholder="Enter loan type name" title="Loan type Name" name="type_name"
                                     change={handle_change} value={loanType.type_name}/>

                            <Form>
                                <MyLabel title="Loan type description"/>
                                <TextArea placeholder='Enter loan type description' rows={2} value={loanType.type_desc}
                                          onChange={(event, data) => {
                                              handle_change('type_desc', data.value as string)
                                          }}/>
                                <div style={{marginBottom: '15px'}}/>
                            </Form>

                            {/*Loan rate*/}
                            <MyInput placeholder="Enter loan type rate" title="Loan Interest Rate" name="loan_rate"
                                     change={handle_change} value={loanType.loan_rate}/>

                            {/*Loan security type*/}
                            <MySelect
                                change={(value: select_type) => handle_change('security_type', value as string)}
                                title="Loan security type" name="security_type" value={loanType.security_type}
                                options={
                                    [
                                        {text: "No loan security required", value: "none"},
                                        {text: "Only guarantor required", value: "guarantor"},
                                        {text: "Only collateral required", value: "collateral"},
                                        {text: "Either collateral or guarantor", value: "either"},
                                        {text: "Both collateral and guarantor", value: "both"}
                                    ]
                                }/>

                            {/*Days to start payment*/}
                            <MySelect
                                change={(value: select_type) => handle_change('start_days', parseInt(value as string))}
                                title="Days to start payment" name="start_days" value={loanType.start_days}
                                options={
                                    [
                                        {text: "One Day", value: 1}, {text: "One week (7 days)", value: 7},
                                        {text: "Two weeks (14 days)", value: 14}, {text: "One Month (30 days)", value: 30}
                                    ]
                                }/>

                            {/*Interest Mode*/}
                            <MySelect
                                change={(value: select_type) => handle_change('interest_mode', value as string)}
                                disabled={loanType.type_id > 0}
                                title="Mode of charging interest" name="interest_mode" value={loanType.interest_mode}
                                options={
                                    [{text: "Charged per day", value: "daily"}, {text: "Charged per month", value: "monthly"}]
                                }/>

                            {/*Payments Interval*/}
                            <MySelect
                                change={(value: select_type) => handle_change('payment_interval', parseInt(value as string))}
                                title="Loan payment Interval" name="payment_interval" value={loanType.payment_interval}
                                options={
                                    [
                                        {text: "Daily Payment", value: 1}, {text: "One week (7 days)", value: 7},
                                        {text: "Two weeks (14 days)", value: 14}, {text: "One Month (30 days)", value: 30}
                                    ]
                                }/>

                            {/*set active status*/}
                            <MySelect
                                change={(value: select_type) => handle_change('loan_status', value as string)}
                                title="Loan type active status" name="loan_status" value={loanType.loan_status}
                                options={[{text: "Active loan type", value: 'active'}, {text: "Not active", value: 'de-active'}]}/>

                            <div className="table_container" style={{height: 'auto'}}>
                                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}}/>
                                            <Table.HeaderCell style={{width: '200px'}}>Charge Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            loanType.charges.map((charge, index) =>
                                                <Table.Row key={index}>
                                                    <Table.Cell style={{width: '50px'}} textAlign="center">
                                                        <Button
                                                            primary size='mini' icon='edit' compact
                                                            onClick={() => setShowCharges({show: true, charge: charge})}/>
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '200px'}}>{charge.fee_name}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className='row m-0'>
                                <div className='col-5 px-0'>
                                    <Button negative onClick={() => setLoanType(initial_type)} content='Clear Data'
                                            fluid size='tiny' icon='trash' labelPosition='left'/>
                                </div>
                                <div className='col-5 px-1'>
                                    <Button positive onClick={save_loan_type} content='Save Data'
                                            fluid size='tiny' icon='save' labelPosition='left'/>
                                </div>
                                <div className='col-2 px-0'>
                                    <Button
                                        positive fluid size='tiny' icon='money' disabled={loanType.type_id === 0}
                                        onClick={() => setShowCharges({show: true, charge: {...initial_charge, type_id: loanType.type_id}})}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
