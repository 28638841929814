import {LoanDetail, TopUp} from "../LoansDetails";
import {Modal} from "semantic-ui-react";
import React from "react";
import TopUpDetail from "../topUp/TopUpDetail";
import {Account} from "../../../../../utils/Models";
import {SelectData} from "../../../../../utils/Components";
import TopUpHistory from "../topUp/TopUpHistory";

export default function LoanTopUp(params: {
    loanDetail: LoanDetail, show: boolean, update: (loan: LoanDetail) => void,
    close: () => void, accounts: Account[], users: Array<SelectData>, hasRoles: boolean
}) {
    const initial_top: TopUp = {
        account_id: 0, date_created: "", loan_id: params.loanDetail.loan.loan_id, start_date: "", top_up_amount: "",
        top_up_date: "", top_up_id: 0, top_up_reason: "", top_up_status: 'active', top_up_time: "", user_id: 0, top_up_period: 1
    }
    const [topUp, setTopUp] = React.useState(initial_top)

    return (
        <Modal open={params.show} basic size='large' closeOnDocumentClick={true} centered={false} onClose={params.close}>
            <div className="modal_div creditor_payments">
                <div className="row m-0 h-100">
                    <div className="col-12 col-md-8 p-1 h-100">
                        <TopUpHistory
                            loanDetail={params.loanDetail} selected={setTopUp} hasRoles={params.hasRoles}
                            accounts={params.accounts} update={params.update}/>
                    </div>

                    <div className="col-12 col-md-4 p-1 h-100">
                        <TopUpDetail
                            close={params.close} update={params.update} initial={initial_top} hasRoles={params.hasRoles}
                            topUp={topUp} accounts={params.accounts} users={params.users} loanInfo={params.loanDetail}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
