import {Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import React from "react";
import {Account} from "../../../../utils/Models";

export interface RcTillAllocation {
    to: number
    id: number
    amount: number
    date: string
    office: string
    from: number
    type: "expenses" | "loan"
    user: string
}

export default function RcTillAllocations(params: { allocations: RcTillAllocation[], accounts: Account[] }) {
    return (
        <>
            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '130px'}}>Branch Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Allocated To</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Allocated For</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Source Account</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Final Account</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Amount Allocated</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Date Allocated</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.allocations.map((allocation, index) =>
                                <Table.Row key={allocation.id}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '130px'}}>{allocation.office}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{allocation.user}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>
                                        {allocation.type === 'loan' ? 'Loanable Cash' : 'Office Expenses'}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>
                                        {Utils.get_journal_account(allocation.from, params.accounts)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>
                                        {Utils.get_journal_account(allocation.to, params.accounts)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '160px'}}>{Utils.comma_number(allocation.amount)}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{Utils.localise_date_time(allocation.date)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
