import React from "react";
import {Checkbox} from "semantic-ui-react";

export type multiselect_type = string | number | (string | number)[]

export function MultiSelect(
    params: {
        multiple: boolean, placeholder: string, items: Array<{ text: string, value: string | number }>,
        selected: multiselect_type, change: (value: multiselect_type) => void,
    }
) {
    const [selected, setSelected] = React.useState<multiselect_type>(params.selected)
    const [search, setSearch] = React.useState("")
    const [placeholder, setPlaceholder] = React.useState("")

    React.useEffect(() => {
        setPlaceholder(params.placeholder)
    }, [params.placeholder])

    React.useEffect(() => {
        setSelected(params.selected)
    }, [params.selected])

    React.useEffect(() => {
        if (search.trim() === "") {

        }
    }, [search])

    const propagate_parent = (event: MouseEvent) => {
        const parent = (event.target as Element).parentElement
        if (parent !== null) {
            parent.click()
        }
    }

    document.onclick = (event) => {
        const target = event.target as Element
        if (target !== null) {
            //console.log(target.getBoundingClientRect())
        }
    }

    React.useEffect(() => {
        const select_contents = document.getElementsByClassName("select_content")
        const multiselect = document.getElementsByClassName("multiselect");

        document.addEventListener('click', function (event) {
            const target = event.target as HTMLElement
            if (target !== null) {
                for (let index = 0; index < multiselect.length; index++) {
                    if (!multiselect[index].contains(target) && select_contents[index].classList.contains("show")) {
                        select_contents[index].classList.remove("show")
                        break
                    }
                }
            }
        });
    }, [])

    return (
        <div className="multiselect">
            <div className="trigger"
                 onClick={(event) => {
                     let target = event.target as HTMLDivElement;
                     if (target !== null) {
                         const sibling = target.nextElementSibling as HTMLDivElement
                         if (sibling !== null) {
                             sibling.classList.add("show")
                         }
                     }
                 }}>
                <div className="selection"
                     onClick={(event) => propagate_parent(event as unknown as MouseEvent)}>
                    {
                        params.multiple &&
                        <div onClick={(event) => propagate_parent(event as unknown as MouseEvent)}>
                            {(selected as Array<number | string>).length}
                        </div>
                    }

                </div>

                <div className="placeholder"
                     onClick={(event) => propagate_parent(event as unknown as MouseEvent)}>
                    <input type="text" placeholder={placeholder} value={search}
                           onClick={(event) => propagate_parent(event as unknown as MouseEvent)}
                           onChange={(event) => {
                               setSearch(event.target.value)
                               let parent = (event.target as Element).parentElement
                               let proceed = true
                               do {
                                   if (parent === null || parent === undefined) {
                                       proceed = false
                                   } else {
                                       console.log(parent.classList)
                                       if (parent.classList.contains("trigger")) {
                                           let sibling = parent.nextElementSibling
                                           if (sibling !== null) {
                                               if (sibling.classList.contains("select_content") &&
                                                   sibling.childElementCount === params.items.length) {
                                                   const children = sibling.children
                                                   const check = event.target.value.trim().toLowerCase()

                                                   for (let child_index = 0; child_index < children.length; child_index++) {
                                                       const child = children[child_index] as HTMLElement
                                                       const child_value = params.items[child_index].text.trim().toLocaleLowerCase()
                                                       child.style.display = child_value.indexOf(check) > -1 ? "" : "none";
                                                   }
                                               }
                                           }
                                           proceed = false
                                       } else {
                                           parent = parent.parentElement
                                       }
                                   }
                               } while (proceed)
                           }}/>
                </div>

                <div className="caret"
                     onClick={(event) => propagate_parent(event as unknown as MouseEvent)}/>
            </div>
            <div className="select_content">
                {
                    params.multiple && params.items.length > 1 &&
                    <Checkbox
                        label="Select All" checked={(selected as Array<number | string>).length === params.items.length}
                        onChange={(event, data) => {
                            let _selected: Array<number | string> = []
                            if (data.checked) {
                                params.items.forEach((_item) => {
                                    _selected = [..._selected, _item.value]
                                })
                            }
                            params.change(_selected)
                            setSelected(_selected)
                        }}
                    />
                }
                {
                    params.items.map((item, index) =>
                        <Checkbox
                            key={item.value} label={item.text}
                            checked={
                                params.multiple
                                    ? ((selected as Array<number | string>).includes(item.value))
                                    : (selected as number | string) === item.value
                            }
                            onChange={(event, data) => {
                                if (data.checked) {
                                    const new_data: multiselect_type = params.multiple ?
                                        [...(selected as Array<number | string>), item.value] : item.value
                                    params.change(new_data)
                                    setSelected(new_data)
                                } else {
                                    if (params.multiple) {
                                        const new_data: multiselect_type = (selected as Array<number | string>)
                                            .filter((value) => value !== item.value)
                                        params.change(new_data)
                                        setSelected(new_data)
                                    }
                                }
                            }}
                        />
                    )
                }
            </div>
        </div>
    )
}
