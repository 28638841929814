import React from "react";
import {SelectData} from "../../utils/Components";
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";
import Utils from "../../utils/Utils";
import {Req} from "../../utils/Req";
import {Rights} from "../admin/settings/AccessRights";
import Calendar from "./utils/Calendar";
import Company from "./utils/Company";
import {Office} from "../admin/users/Offices";
import Branch from "./utils/Branch";

export default function Home() {
    const user = useAppSelector(getStaff)
    const [offices, setOffices] = React.useState<Array<SelectData>>([])
    const [branches, setBranches] = React.useState<Array<Office>>([])

    React.useEffect(() => {
        setOffices(Utils.get_accessible_office(branches, user.roles.user_roles, Rights.view_loans).select)
    }, [branches])

    React.useEffect(() => {
        Req.get_offices()
            .then((response) => {
                if (response.data.hasOwnProperty("offices")) {
                    setBranches(response.data.offices)
                }
            })
    }, [])

    return (
        <>
            <div className="row m-0">
                <div className="col-12 col-md-6 p-2">
                    <Calendar offices={offices}/>
                </div>
                <div className="col-12 col-md-6 p-2">
                    <Branch/>
                </div>
            </div>

            {Rights.has_right(user.roles.user_roles, Rights.admin_panel) && <Company offices={branches}/>}
        </>
    )
}
