import React, {ChangeEvent} from "react";
import {Button, Confirm, Form, Image, Table, TextArea} from "semantic-ui-react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../utils/Utils"
import {ToastsStore} from "react-toasts";
import imageCompression from "browser-image-compression";
import {Loading, MyInput, MyLabel} from "../../../../utils/Components";
import {Req} from "../../../../utils/Req";
import {LoanSecurity} from "../ShowLoan";

export interface Security {
    collateral_id: number
    collateral_name: string
    collateral_value: string
    collateral_description: string
    images: Array<collateral_image>
    index: number
}

export type collateral_image = { path: string, file?: File }

export const initial_security: Security = {
    index: -1, collateral_description: "", collateral_id: 0, collateral_name: "", collateral_value: "", images: []
}

export default function LoanCollateral(props: {
    loan_id: number, securities: Array<Security>,
    setSecurities: (securities: Security[]) => void, update: (securities: LoanSecurity[]) => void,
}) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)

    const selectImage = React.useRef<HTMLInputElement | null>(null)
    const [security, setSecurity] = React.useState<Security>(initial_security)

    const handle_change = (name: string, value: string | number) => {
        setSecurity({...security, [name]: value})
    }

    const handle_image = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            const file: File | null = files.item(0);
            if (file !== null) {
                const options = {maxSizeMB: 0.05, maxWidthOrHeight: 800, useWebWorker: true}
                try {
                    imageCompression(file, options)
                        .then((compressed) => {
                            Utils.format_file(compressed)
                                .then((result) => {
                                    setSecurity({...security, images: [{path: result.file_src, file: compressed}, ...security.images]})
                                })
                        })
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    const save_collateral = () => {
        const collateral: Security = {...security, collateral_value: Utils.strip_commas(security.collateral_value)}
        if (collateral.collateral_name.length < 1) {
            ToastsStore.error("Enter a security name")
        } else if (!Utils.is_valid_number(collateral.collateral_value)) {
            ToastsStore.error("Enter valid security value")
        } else if (collateral.images.length === 0) {
            ToastsStore.error("Add at least 1(one) image for the security")
        } else {
            if (props.loan_id === 0) {
                if (collateral.index === -1) {
                    props.setSecurities([...props.securities, {...collateral, index: props.securities.length}])
                } else {
                    props.setSecurities(props.securities.map((aSecurity, index) =>
                        (index === collateral.index) ? collateral : aSecurity))
                }

            } else {
                const form_data = new FormData()
                form_data.append("loan_id", props.loan_id.toString())
                const loan_securities: Array<any> = [
                    {
                        name: collateral.collateral_name, desc: collateral.collateral_description,
                        value: collateral.collateral_value, id: collateral.collateral_id
                    }
                ]
                form_data.append("securities", JSON.stringify(loan_securities))
                collateral.images.forEach((image) => {
                    if (image.file !== undefined) {
                        form_data.append(`securities${1}[]`, image.file)
                    }
                })

                setConfirm({...confirm, open: false})
                setLoading({message: "Saving loan security, please wait", show: true})
                Req.save_security(form_data)
                    .then(response => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                props.update(response.data.securities as LoanSecurity[])
                                setSecurity(initial_security)
                                ToastsStore.success("Saved loan security successfully")
                            } else if (response.data.code === 2) {
                                ToastsStore.error("Could not save security, contact the admin")
                            }
                        }
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        ToastsStore.error("Error saving security, retry again")
                    })
            }
        }
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>
            <Confirm
                open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} size='mini'
                cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>
            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '350px'}}>Collateral Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Collateral Value</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}} textAlign="center">Images</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    props.securities.map((security, index) =>
                                        <Table.Row key={index} onClick={() => setSecurity({...security, index: index})}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">
                                                {Utils.row_number(index)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '350px'}}>{security.collateral_name}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>
                                                {Utils.comma_number(parseFloat(security.collateral_value))}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '100px'}} textAlign="center">{security.images.length}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="form">
                        <div className="form_content no_header">
                            <div className="px-1 mb-1">
                                <MyInput placeholder="Enter collateral name" title="Collateral Name" name="collateral_name"
                                         change={handle_change} value={security.collateral_name}/>
                            </div>
                            <div className="px-1 mb-1">
                                <MyInput placeholder="Enter collateral value" title="Collateral Value" name="collateral_value"
                                         change={handle_change} value={Utils.comma_input(security.collateral_value)}/>
                            </div>
                            <div className="px-1 mb-1">
                                <MyLabel title="Enter collateral description"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter collateral description' rows={6} value={security.collateral_description}
                                        onChange={((event, data) => {
                                            handle_change('collateral_description', data.value as string)
                                        })}/>
                                </Form>
                            </div>

                            {
                                security.images
                                    .map((image, index) =>
                                        <div className="collateral_images" key={index}>
                                            <Image src={image.path}/>
                                            {
                                                image.file !== undefined &&
                                                <div className="delete_image"
                                                     onClick={() => {
                                                         setSecurity({
                                                             ...security, images: security.images.filter((_, __) => __ !== index)
                                                         })
                                                     }}>
                                                    <i className="ui trash icon"/>
                                                </div>
                                            }
                                        </div>
                                    )
                            }

                            <input
                                type="file" className="input_file" accept="image/*" ref={selectImage}
                                onChange={(event) => handle_image(event)}/>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-5 px-0">
                                    <Button negative fluid content="Clear Data" size='mini' icon="trash" labelPosition="left"
                                            onClick={() => setSecurity(initial_security)}/>
                                </div>
                                <div className="col-5 px-1">
                                    <Button
                                        positive fluid content="Save Collateral" size='mini' icon="save" labelPosition="left"
                                        disabled={security.index >= 0 && props.loan_id > 0} onClick={save_collateral}/>
                                </div>

                                <div className="col-2 px-1">
                                    <Button
                                        positive fluid size='mini' icon="upload"
                                        onClick={() => (selectImage.current as HTMLInputElement).click()}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
