import React from "react";
import {Page, Req} from "../../../utils/Req";
import {Button, Table} from "semantic-ui-react";
import {Loading, loading_params, LoadingParams, MyInput, SelectData, TablePagination} from "../../../utils/Components";
import {ToastsStore} from "react-toasts";
import Utils from "../../../utils/Utils";
import {ShowLoan} from "../container/ShowLoan";
import {MultiSelect} from "../../../utils/components/MultiSelect";
import {Account} from "../../../utils/Models";

interface Loan {
    client_name: string
    interest_paid: number
    last_date: string
    loan_id: number
    loan_period: number
    loan_principal: number
    loan_rate: number
    loan_span: number
    other_fees: number
    payments: number
    penalty_paid: number
    principal_paid: number
    projected_interest: number
    start_date: string
    office_id: number
    office_name: string
}

export default function CompleteLoans(params: { branches: { select: SelectData[], selected: number[] }, accounts: Account[] }) {
    const [search, setSearch] = React.useState<{ name: string, loan_id: string, branches: Array<number> }>(
        {name: "", loan_id: "", branches: params.branches.selected})
    const handle_search = (name: string, value: string | number | Array<number | string>) => {
        setSearch({...search, [name]: value})
    }

    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)
    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})
    const [loans, setLoans] = React.useState<Array<Loan>>([])

    const search_loans = (params: { page: number, pages: number }) => {
        if (search.branches.length === 0) {
            ToastsStore.info("Select at least 1 branch")
            return
        }

        setLoading({show: true, text: 'Loading complete loans, please wait'})
        Req.get_loans_listing_complete({
            pages: params.pages, page: params.page, name: search.name, loan_id: search.loan_id, branches: JSON.stringify(search.branches)
        })
            .then(response => {
                if (response.data.hasOwnProperty("loans")) {
                    let _loans: Array<Loan> = response.data.loans
                    if (_loans.length === 0) {
                        ToastsStore.error("No loans are available available")
                    }
                    setLoans(_loans)
                    setPages({...pages, pages: response.data.pages, page: params.page, limit: response.data.limit})
                }
                setLoading(loading_params)
            })
            .catch(() => {
                setLoading(loading_params)
            })
    }

    const [loanModal, setLoanModal] = React.useState({show: false, loan_id: 0})

    return (
        <>
            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            {
                loanModal.show &&
                <ShowLoan close={() => setLoanModal({...loanModal, show: false})}
                          loan_id={loanModal.loan_id} accounts={params.accounts}/>}

            {
                !loanModal.show &&
                <>
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select offices" items={params.branches.select} selected={search.branches}
                                    change={(value) => handle_search('branches', value)}/>
                            </div>
                            <div>
                                <MyInput
                                    placeholder="Search by client name" title="" name="name" value={search.name}
                                    change={handle_search} enter={() => search_loans({page: 1, pages: 0})}/>
                            </div>
                            <div>
                                <MyInput
                                    placeholder="Search by loan no" title="" name="loan_id" value={search.loan_id}
                                    change={handle_search} enter={() => search_loans({page: 1, pages: 0})}/>
                            </div>
                        </div>

                        <div className="button_bar">
                            <Button
                                primary floated='right' size='mini' icon='search' labelPosition="left" content="Search"
                                onClick={() => search_loans({page: 1, pages: 0})}/>
                        </div>
                    </div>

                    <div className="table_container">
                        <Table celled striped compact size='small' selectable inverted color='grey' unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">Loan</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Branch Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}}>Period</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}}>Span</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Loan Principal</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Projection</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Principal</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Interest</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Penalties</Table.HeaderCell>
                                    <Table.HeaderCell className='amount_row'>Other Fees</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '130px'}}>Total Paid</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    loans.map((loan, index) =>
                                        <Table.Row key={loan.loan_id} onClick={() => setLoanModal({loan_id: loan.loan_id, show: true})}>
                                            <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index, pages)}</Table.Cell>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{loan.loan_id}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{loan.office_name}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{loan.client_name}</Table.Cell>
                                            <Table.Cell style={{width: '70px'}}>{loan.loan_period} days</Table.Cell>
                                            <Table.Cell style={{width: '70px'}}>{loan.loan_span} days</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.loan_principal)}</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.projected_interest)}</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.principal_paid)}</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.interest_paid)}</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.penalty_paid)}</Table.Cell>
                                            <Table.Cell className='amount_row'>{Utils.comma_number(loan.other_fees)}</Table.Cell>
                                            <Table.Cell style={{width: '130px'}}>
                                                {Utils.comma_number(loan.principal_paid + loan.interest_paid + loan.penalty_paid + loan.other_fees, `shs `)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="table_footer">
                        <TablePagination
                            total={pages.pages} change={(page: number) => search_loans({page: page, pages: pages.pages})}/>
                    </div>
                </>
            }
        </>
    )
}
