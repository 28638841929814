import {Button, Modal, Table} from "semantic-ui-react";
import React from "react";
import {MyDateInput, MyInput, MyLabel, MySelect, select_type} from "../../../utils/Components";
import Utils from "../../../utils/Utils";
import {SalarySummary, SalaryUser} from "./SalaryManagement";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";

interface Payment {
    user_id: number,
    reference_no: string,
    allowance_paid: string,
    salary_paid: string,
    date_paid: string
    salary_arrears: number,
    allowance_arrears: number,
    total_paid: number
}

interface SalaryPayment {
    allowance_paid: number
    date_paid: string
    payment_id: number
    reference_no: string
    salary_paid: number
    user_id: number
    username: string
}

const initial_payment: Payment = {
    user_id: 0, salary_arrears: 0, allowance_arrears: 0, salary_paid: "", allowance_paid: "", total_paid: 0,
    date_paid: Utils.today(), reference_no: ""
}

export default function SalaryPayments(params: {
    show: boolean, close: () => void, users: SalaryUser[], salarySummaries: SalarySummary[], setSummaries: (summaries: SalarySummary[]) => void
}) {
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = React.useState({min_date: "2021-01-01", max_date: Utils.today(), user_id: 0})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [payments, setPayments] = React.useState(Array<SalaryPayment>())
    const search_payments = () => {
        setLoading(true)
        Req.get_salary_payments({max_date: search.max_date, min_date: search.min_date, user_id: search.user_id})
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setPayments(response.data.payments)
                } else {
                    ToastsStore.error("Could not load payments, please retry")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Could not load payments, please retry")
            })
    }

    const [saving, setSaving] = React.useState(false)
    const [payment, setPayment] = React.useState<Payment>(initial_payment)
    const handle_payment = (name: string, value: string | number) => {
        setPayment({...payment, [name]: value})
    }

    const save_payment = () => {
        if (payment.date_paid === "") {
            ToastsStore.error("Select date of salary payment")
        } else if (!Utils.is_valid_number(payment.salary_paid)) {
            ToastsStore.error("Enter a valid salary paid")
        } else if (parseFloat(payment.salary_paid) < 0 || parseFloat(payment.salary_paid) > payment.salary_arrears) {
            ToastsStore.error("Salary paid is beyond the salary arrears limits")
        } else if (!Utils.is_valid_number(payment.allowance_paid)) {
            ToastsStore.error("Enter a valid allowance paid")
        } else if (parseFloat(payment.allowance_paid) < 0 || parseFloat(payment.allowance_paid) > payment.allowance_arrears) {
            ToastsStore.error("Allowance paid is beyond allowance arrears limits")
        } else {
            setSaving(true)
            Req.save_salary_payment({
                allowance_arrears: payment.allowance_arrears,
                allowance_paid: parseFloat(payment.allowance_paid),
                date_paid: payment.date_paid,
                reference_no: payment.reference_no,
                salary_arrears: payment.salary_arrears,
                salary_paid: parseFloat(payment.salary_paid),
                user_id: payment.user_id
            })
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setPayments([
                            {
                                allowance_paid: parseFloat(payment.allowance_paid),
                                date_paid: payment.date_paid,
                                payment_id: response.data.payment_id,
                                reference_no: payment.reference_no,
                                salary_paid: parseFloat(payment.salary_paid),
                                user_id: payment.user_id,
                                username: (users.filter((user) => user.user_id === payment.user_id))[0].username
                            },
                            ...payments]
                        )
                        params.setSummaries(
                            params.salarySummaries.map((summary) =>
                                summary.user_id === payment.user_id ?
                                    {
                                        ...summary,
                                        allowance_paid: summary.allowance_paid + parseFloat(payment.allowance_paid),
                                        salary_paid: summary.salary_paid + parseFloat(payment.salary_paid)
                                    } :
                                    {...summary}
                            )
                        )
                    } else {
                        ToastsStore.error("Error while saving payment")
                    }
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Error while saving payment")
                })
        }
    }

    const [users, setUsers] = React.useState(Array<SalaryUser>())

    React.useEffect(() => {
        setUsers(
            params.users.filter((user) =>
                (params.salarySummaries.map((summary) => (summary.user_id))).includes(user.user_id)
            )
        )
    }, [params.users, params.salarySummaries, params.show])

    React.useEffect(() => {
        if (Utils.is_valid_number(payment.salary_paid) && Utils.is_valid_number(payment.allowance_paid)) {
            setPayment({...payment, total_paid: parseFloat(payment.salary_paid) + parseFloat(payment.allowance_paid)})
        } else {
            setPayment({...payment, total_paid: 0})
        }
    }, [payment.salary_paid, payment.allowance_paid])

    return (
        <Modal open={params.show} basic size='large' centered={false} closeOnDimmerClick={!saving && !loading}
               closeOnDocumentClick={!saving && !loading} closeOnEscape={!saving && !loading} onClose={params.close}>

            <div className="salary_payments_modal">
                <div className='row m-0 h-100'>
                    <div className='col-12 px-1'>
                        <div className="content_bar">
                            <Button floated='right' size='mini' content="Search" primary labelPosition="left"
                                    loading={loading} disabled={loading} onClick={search_payments} icon="search"/>

                            <div className="search_bar">
                                <div>
                                    <MySelect
                                        change={(value: select_type) => handle_search('user_id', (value as number))}
                                        title="" name="user_id" value={search.user_id}
                                        options={[
                                            {text: 'Select all users', value: 0},
                                            ...users.map((user) => ({text: user.username, value: user.user_id}))
                                        ]}/>
                                </div>
                                <div>
                                    <MyDateInput
                                        title="" value={search.min_date} name="min_date" maxDate={search.max_date}
                                        placeholder="Minimum payment date" change={handle_search}/>
                                </div>
                                <div>
                                    <MyDateInput
                                        title="" value={search.max_date} name="max_date" maxDate={Utils.today()} minDate={search.min_date}
                                        placeholder="Maximum payment date" change={handle_search}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container_no_footer'>
                        <div className='row m-0 h-100'>
                            <div className='col-12 col-md-8 p-1 h-100'>
                                <div className="table_container full_height container_salary_paid">
                                    <Table celled striped compact size='small' inverted color='grey' selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{width: '40px'}}>No.</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '160px'}}>User Name</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '120px'}}>Date Paid</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '120px'}}>Salary Paid</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '120px'}}>Allowances Paid</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '120px'}}>Total Paid</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {
                                                payments.map((_payment, index) =>
                                                    <Table.Row key={_payment.payment_id}>
                                                        <Table.Cell style={{width: '40px'}}>{Utils.row_number(index)}</Table.Cell>
                                                        <Table.Cell style={{width: '160px'}}>{_payment.username}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>{Utils.localise_date(_payment.date_paid)}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>{Utils.comma_number(_payment.salary_paid)}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>{Utils.comma_number(_payment.allowance_paid)}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>
                                                            {Utils.comma_number(_payment.allowance_paid + _payment.salary_paid)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>

                            <div className='col-12 col-md-4 p-1 h-100'>
                                <div className='form p-2'>
                                    <div className="form_header">Make Payment</div>

                                    <div className="form_content">
                                        <div className='row m-0'>
                                            <div className='col-12 p-0'>
                                                <MySelect
                                                    change={(value: select_type) => {
                                                        const user_id = parseInt(value as string)
                                                        if (user_id === 0) {
                                                            setPayment(initial_payment)
                                                        } else {
                                                            const summary = params.salarySummaries
                                                                .filter((summary) => summary.user_id === user_id)
                                                            setPayment({
                                                                ...payment,
                                                                user_id: user_id,
                                                                allowance_arrears: summary[0].allowance_payable - summary[0].allowance_paid,
                                                                salary_arrears: summary[0].gross_salary - summary[0].salary_paid
                                                            })
                                                        }
                                                    }}
                                                    title="Employee to be paid" name="user_id" value={payment.user_id}
                                                    options={[
                                                        {text: 'Select a user', value: 0},
                                                        ...users.map((user) => ({text: user.username, value: user.user_id}))
                                                    ]}/>
                                            </div>

                                            <div className='col-12 col-md-6 p-0 pr-md-2'>
                                                <MyInput placeholder="Salary Arrears" title="Salary Arrears" name="salary_arrears"
                                                         value={Utils.comma_number(payment.salary_arrears)}/>
                                            </div>

                                            <div className='col-12 col-md-6 p-0'>
                                                <MyInput placeholder="Allowance Arrears" title="Allowance Arrears" name="allowance_arrears"
                                                         value={Utils.comma_number(payment.allowance_arrears)}/>
                                            </div>

                                            <div className='col-12 col-md-6 p-0 pr-md-2' style={{marginBottom: "15px"}}>
                                                <MyLabel title="Date Paid"/>
                                                <MyDateInput
                                                    title="" value={payment.date_paid} name="max_date" maxDate={Utils.today()}
                                                    placeholder="Date of payment" change={handle_payment}/>
                                            </div>

                                            <div className='col-12 col-md-6 p-0'>
                                                <MyInput placeholder="Reference Number" title="Reference Number" name="reference_no"
                                                         value={payment.reference_no} change={handle_payment}/>
                                            </div>

                                            <div className='col-12 col-md-6 p-0 pr-md-2'>
                                                <MyInput placeholder="Salary Paid" title="Salary Paid" name="salary_paid"
                                                         value={payment.salary_paid} change={handle_payment}/>
                                            </div>

                                            <div className='col-12 col-md-6 p-0'>
                                                <MyInput placeholder="Allowance Paid" title="Allowance Paid" name="allowance_paid"
                                                         value={payment.allowance_paid} change={handle_payment}/>
                                            </div>

                                            <div className='col-12 p-0'>
                                                <MyInput placeholder="Total Payment" title="Total Payment" name="total_paid"
                                                         value={Utils.comma_number(payment.total_paid)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form_footer">
                                        <div className='row m-0'>
                                            <div className='col-6 pl-0 pr-1'>
                                                <Button negative onClick={() => setPayment(initial_payment)} disabled={saving || loading}
                                                        content='Clear Data' fluid size='tiny' icon='trash' labelPosition='right'/>
                                            </div>
                                            <div className='col-6 pl-1 pr-0'>
                                                <Button positive onClick={save_payment} content='Make Payment'
                                                        disabled={
                                                            saving || loading ||
                                                            (payment.salary_arrears === 0 && payment.allowance_arrears === 0)
                                                        }
                                                        loading={saving} fluid size='tiny' icon='save' labelPosition='left'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}
