import axios from 'axios'
import {InputFile} from "./Utils";

export class Req {
    //static BASE_URL = "http://127.0.0.1:8006/"
    //static BASE_URL = "https://loans.wilsofts.com/"
    static BASE_URL = "https://api.sokotoholdings.com/"

    static CLIENT: "local" | "sokoto" | "tonish" = "sokoto"

    static post_params(data: any) {
        const form = new FormData();
        Object.entries(data).forEach(([prop, val]) => {
            if (val !== null) {
                if (val instanceof InputFile) {
                    if (val.file !== undefined) {
                        form.append(prop, val.file as File)
                    }
                } else {
                    form.append(prop, val as string)
                }
            }
        })
        return form
    }

    /*dashboards*/
    static initialise_system() {
        return axios({method: 'get', url: 'teller/system/initialise'});
    }

    static get_loan_summary(params: { min_date: string, max_date: string, branch_id: number }) {
        return axios({method: 'get', url: 'teller/loans/dashboard/summary/loans', params: params});
    }

    static get_branch_summary(params: { min_date: string, max_date: string, branch_id: number }) {
        return axios({method: 'get', url: 'teller/loans/dashboard/summary/branch', params: params});
    }

    static get_branches_summary(params: { min_date: string, max_date: string, branches: string }) {
        return axios({method: 'get', url: 'teller/loans/dashboard/summary/branches', params: params});
    }

    /*system calendar*/
    static get_calendar(params: { office_id: number, min_date: string, max_date: string }) {
        return axios({method: 'get', url: 'teller/calendar/get', params: params});
    }

    static pdf_calendar(params: { office_id: number, min_date: string, max_date: string }) {
        return axios({method: 'get', url: 'teller/calendar/pdf', params: params});
    }

    static daily_summary(params: {}) {
        return axios({method: 'get', url: 'teller/calendar/daily', params: params});
    }

    static pdf_daily_summary(params: {}) {
        return axios({method: 'get', url: 'teller/calendar/pdf/daily', params: params});
    }

    /*branch management*/
    static save_office(office: {
        office_id: number,
        office_name: string,
        office_location: string,
        office_manager: number,
        office_contact: string
    }) {
        return axios({method: 'post', url: 'teller/system/offices/save', data: this.post_params(office)});
    }

    static get_offices() {
        return axios({method: 'get', url: 'teller/system/offices/get'});
    }

    /*user management*/
    static update_fcm_token(params: { fcm_token: string }) {
        return axios({method: 'post', url: 'teller/users/auth/fcm', data: this.post_params(params)});
    }

    static login_user(data: { credential: string, login_password: string, fetch_data: 1 | 0, fcm_token: string }) {
        return axios({method: 'post', url: 'teller/users/auth/login', data: this.post_params(data)});
    }

    static logout_user() {
        return axios({method: 'post', url: 'teller/users/auth/logout'});
    }

    static change_password(data: { credential: string, new_password: string, current_password: string }, operation?: string) {
        return axios({
            method: 'post', url: `${operation === undefined ? 'teller/users/password/change' : 'teller/users/password/reset'}`,
            data: this.post_params(data)
        });
    }

    static get_office_users(params: { office_id: number }) {
        return axios({method: 'get', url: 'teller/users/get/branch', params: params});
    }

    static save_user(user: {
        user_id: number, office_id: number, first_name: string, last_name: string, user_name: string, user_email: string,
        user_gender: 'Male' | 'Female' | 'Other', main_contact: string, other_contact: string, user_avatar: InputFile | null,
        user_status: 'active' | 'inactive'
    }) {
        return axios({method: 'post', url: 'teller/users/save', data: this.post_params(user)});
    }

    static get_users(params: { name: string, branch: number, status: string }) {
        return axios({method: 'get', url: 'teller/users/get/search', params: params});
    }

    static update_user_rights(params: { access_rights: string, user_id: number }) {
        return axios({method: 'post', url: 'teller/users/rights/update', data: this.post_params(params)});
    }

    static get_report_rights_users(params: { office_id: number }) {
        return axios({method: 'get', url: 'teller/users/rights/report/users', params: params});
    }

    static update_report_rights(params: { report_rights: string, user_id: number }) {
        return axios({method: 'post', url: 'teller/users/rights/report/update', data: this.post_params(params)});
    }

    static get_core_roles() {
        return axios({method: 'get', url: 'teller/users/coreRoles/search'});
    }

    static update_core_roles(params: { role: string, users: string }) {
        return axios({method: 'post', url: 'teller/users/coreRoles/save', data: this.post_params(params)});
    }

    /*loan listings*/
    static get_loans_listing_complete(params: { pages: number, page: number, name: string, loan_id: string, branches: string }) {
        return axios({method: 'get', url: 'teller/loans/listing/complete', params: params});
    }

    static get_loans_listing_portfolio(params: {
        branches: string, name: string, pages: number, page: number,
        status: 'active' | 'complete' | 'all' | 'extended' | 'deleted' | 'modified' | 'monthly',
        loan_id?: string, loan_type?: number, user_id?: number, month?: number, year?: number
    }) {
        return axios({method: 'get', url: 'teller/loans/listing/portfolio', params: params});
    }

    static get_loans_listing_due(params: {
        name: string,
        branches: string,
        min_date?: string,
        max_date?: string,
        period: number,
        loan_id: string
    }) {
        return axios({method: 'get', url: 'teller/loans/listing/due', params: params});
    }

    /*loan management*/
    static get_loan_actions(params: { branches: string, loan_status: string }) {
        return axios({method: 'get', url: 'teller/loans/actionable', params: params});
    }

    static get_guarantor_loan_info(params: { client_id: number }) {
        return axios({method: 'get', url: 'teller/loans/info/guarantor', params: params});
    }

    static get_client_loan_info(params: { client_id: number }) {
        return axios({method: 'get', url: 'teller/loans/info/client', params: params});
    }

    static save_loan(data: FormData) {
        return axios({method: 'post', url: 'teller/loans/save/loan', data: data});
    }

    static save_security(data: FormData) {
        return axios({method: 'post', url: 'teller/loans/save/security', data: data});
    }

    static get_loan_info(params: { loan_id: number }) {
        return axios({method: 'get', url: 'teller/loans/details', params: params});
    }

    static approve_reject_verify(data: { loan_status: 'rejected' | 'accepted' | 'verified', loan_id: number, branch_id: number }) {
        return axios({method: 'post', url: 'teller/loans/approve_reject_verify', data: this.post_params(data)});
    }

    static disburse_loan(data: {
        start_date: string, due_date: string, loan_id: number,
        transactions: string, sources: string, charges_account: number
    }) {
        return axios({method: 'post', url: 'teller/loans/disburse/disburse', data: this.post_params(data)});
    }

    static disburse_loan_info(data: { loan_id: number }) {
        return axios({method: 'post', url: 'teller/loans/disburse/init', data: this.post_params(data)});
    }

    static calculate_loan_schedule(data: {
        loan_principal: number, loan_period: number, loan_rate: number, loan_interval: number,
        rate_type: 'simple' | 'compound' | 'simple_interest' | '', start_date: string,
        interest_mode: 'daily' | 'monthly',
    }) {
        return axios({method: 'post', url: 'teller/loans/schedule/calculate', data: this.post_params(data)});
    }

    static get_payment_schedule(params: { loan_id: number }) {
        return axios({method: 'get', url: 'teller/loans/schedule/print', params: params});
    }

    static get_payment_info(params: { loan_id: number }) {
        return axios({method: 'get', url: 'teller/loans/info/loan', params: params});
    }

    /*loan modification*/
    static adjust_loan(params: { loan_id: number }) {
        return axios({method: 'post', url: 'teller/loans/modify/adjust', data: this.post_params(params)});
    }

    static update_loan_charges(params: { loan_id: number, charges: string, reason: string }) {
        return axios({method: 'post', url: 'teller/loans/modify/charges', data: this.post_params(params)});
    }

    static reschedule_loan(params: { loan_id: number, date: string }) {
        return axios({method: 'post', url: 'teller/loans/modify/reschedule/add', data: this.post_params(params)});
    }

    static remove_loan_reschedule(params: { loan_id: number, schedule_date: string, reason: string }) {
        return axios({method: 'post', url: 'teller/loans/modify/reschedule/delete', data: this.post_params(params)});
    }

    static delete_loan(data: { loan_id: number, delete_reason: string }) {
        return axios({method: 'post', url: 'teller/loans/modify/delete/loan', data: this.post_params(data)});
    }

    static reverse_payment(data: { payment_id: number, reason: string, loan_id: number }) {
        return axios({method: 'post', url: 'teller/loans/modify/delete/payment', data: this.post_params(data)});
    }

    static extend_loan(
        params: {
            loan_id: number, extension_period: number, extension_reason: string, interest_mode: string,
            payment_start_date: string, extension_principal: number, extension_interest: number, extension_rate: number
        }) {
        return axios({method: 'post', url: 'teller/loans/modify/extension/extend', data: this.post_params(params)});
    }

    static remove_loan_extension(params: { loan_id: number, extension_id: number, removal_reason: string }) {
        return axios({method: 'post', url: 'teller/loans/modify/extension/remove', data: this.post_params(params)});
    }

    static add_penalty(params: { penalty_reason: string, penalty_amount: string, loan_id: number }) {
        return axios({method: 'post', url: 'teller/loans/modify/fees/add', data: this.post_params(params)});
    }

    static remove_penalty(params: { reason_removed: string, loan_id: number, penalty_id: number }) {
        return axios({method: 'post', url: 'teller/loans/modify/fees/remove', data: this.post_params(params)});
    }

    static deduct_interest(params: { amount_removed: string, reason_removed: string, loan_id: number }) {
        return axios({method: 'post', url: 'teller/loans/modify/fees/waive', data: this.post_params(params)});
    }

    /*inspection reports*/
    static get_inspection_reports(
        params: { min_date: string, max_date: string, client_name: string }
    ) {
        return axios({method: 'get', url: 'teller/loans/inspections/get', params: params});
    }

    static save_inspection_report(data: {
        inspection_id: number, inspected_by: number, user_name: string, date_inspected: string, client_name: string, client_contact: string,
        work_address: string, residential_address: string, marital_status: string, market_value: string, job_period: string,
        weekly_earning: string | number, weekly_savings: string | number, payment_capacity: number, guarantor_commitment: number,
        self_employed: number, rider_remittance: string | number, surety: string, properties: string
    }) {
        return axios({method: 'post', url: 'teller/loans/inspections/save', data: this.post_params(data)});
    }

    /*loan payments*/
    static get_payment_receipt(params: { payment_id: number }) {
        return axios({method: 'get', url: 'teller/payments/receipt', params: params});
    }

    static get_expected_payments(params: {
        name: string,
        branches: string,
        min_date?: string,
        max_date?: string,
        period: number,
        loan_id: string
    }) {
        return axios({method: 'get', url: 'teller/payments/arrears', params: params});
    }

    static get_loan_payment_data(params: { loan_id: number, office_id: number }) {
        return axios({method: 'get', url: 'teller/payments/initialise', params: params});
    }

    static make_payment(data: {
        loan_id: number, payer_name: string, payer_contact: string, total_paid: number,
        date_paid: string, time_paid: string, account_id: number
    }) {
        return axios({method: 'post', url: 'teller/payments/save', data: this.post_params(data)});
    }

    /*till allocations*/
    static get_allocation_accounts(params: { till_date: string }) {
        return axios({method: 'get', url: 'teller/allocations/accounts/get', params: params});
    }

    static open_allocation_account(data: { office_id: number, till_date: string, user_id: number, }) {
        return axios({method: 'post', url: 'teller/allocations/accounts/open', data: this.post_params(data)});
    }

    static save_allocation(
        data: {
            allocation_id: number, office_id: number, amount_allocated: number, source_id: number,
            allocation_date: string, user_id: number, account_id: number, allocation_type: 'loan' | 'petty' | 'expenses'
        }) {
        return axios({method: 'post', url: 'teller/allocations/tills/save', data: this.post_params(data)});
    }

    static approve_allocation(data: { allocation_id: number }) {
        return axios({method: 'post', url: 'teller/allocations/tills/verify', data: this.post_params(data)});
    }

    static get_allocations(params: { min_date: string, max_date: string, office_id: number, user_id: number }) {
        return axios({method: 'get', url: 'teller/allocations/tills/get', params: params});
    }

    static get_user_transactions(params: { transaction_date: string, transaction_user: number, office_id: number }) {
        return axios({method: 'get', url: 'teller/allocations/payments/user', params: params});
    }

    static close_user_transactions(params: {
        till_date: string, user_id: number, till_comments: string, till_data: string, till_status: 'closed' | 'faulty'
    }) {
        return axios({method: 'post', url: 'teller/allocations/payments/close', data: this.post_params(params)});
    }

    /*client requests*/
    static save_client(data: FormData) {
        return axios({method: 'post', url: 'teller/clients/save/account', data: data, maxBodyLength: Infinity, maxContentLength: Infinity});
    }

    static save_account_fee(client: { client_id: number, payments: string, account_id: number }) {
        return axios({method: 'post', url: 'teller/clients/save/fees', data: this.post_params(client)});
    }

    static get_client(params: { client_id: number }) {
        return axios({method: 'get', url: 'teller/clients/get/client', params: params});
    }

    static get_clients(params: { page: number, pages: number, client_name: string, branches: string, status: 'pending' | 'paid' | 'all' }) {
        return axios({method: 'get', url: 'teller/clients/get/search', params: params});
    }

    static get_client_summary(params: { client_id: number }) {
        return axios({method: 'get', url: 'teller/clients/summary', params: params});
    }

    static search_clients(params: { query: string, clients: string }) {
        return axios({method: 'get', url: 'teller/clients/get/query', params: params});
    }

    /*system management*/
    static save_loan_type(loanType: {
        type_name: string, type_desc: string, loan_rate: string, start_days: 1 | 7 | 14 | 30,
        payment_interval: 1 | 7 | 14 | 30, interest_mode: 'daily' | 'monthly',
        security_type: 'none' | 'guarantor' | 'collateral' | 'either' | 'both',
        loan_status: 'active' | 'de-active', type_id: number
    }) {
        return axios({method: 'post', url: 'teller/system/loan_types/save/info', data: this.post_params(loanType)});
    }

    static save_loan_charges(loanType: { charges: string, type_id: number, fee_name: string, fee_description: string, charge_id: number }) {
        return axios({method: 'post', url: 'teller/system/loan_types/save/charges', data: this.post_params(loanType)});
    }

    static get_loan_types() {
        return axios({method: 'get', url: 'teller/system/loan_types/get', params: {operation: 'get_loan_types'}});
    }

    /*account reconciliations*/
    static get_account_reconciliations(params: { min_date: string, max_date: string, types: string }) {
        return axios({method: 'get', url: 'teller/accounts/reconciliations/get', params: params});
    }

    static get_journal_accounts() {
        return axios({method: 'get', url: 'teller/accounts/journals/accounts'});
    }

    /*accounts management*/
    static save_expenses(
        data: {
            office_id: number, account_id: number, expense_description: string, sources: string,
            expense_amount: number, expense_receipt: InputFile | null, expense_date: string, expense_id: number
        }) {
        return axios({method: 'post', url: 'teller/accounts/expenses/save', data: this.post_params(data)});
    }

    static initialise_expenses(params: { min_date: string, max_date: string }) {
        return axios({method: 'get', url: 'teller/accounts/expenses/init', params: params});
    }

    static get_expenses(params: { pages: number, page: number, min_date: string, max_date: string, accountant_id: number }) {
        return axios({method: 'get', url: 'teller/accounts/expenses/search', params: params});
    }

    /*cash transfers*/
    static save_cash_transfer(
        data: {
            transfer_id: number, account_from: number, account_to: number, user_id: number, office_id: number,
            amount_transferred: number, date_transferred: string, transfer_receipt: InputFile | null
        }) {
        return axios({method: 'post', url: 'teller/accounts/cash_transfer/save', data: this.post_params(data)});
    }

    static get_cash_transfers(params: { pages: number, page: number, min_date: string, max_date: string, branches: string }) {
        return axios({method: 'get', url: 'teller/accounts/cash_transfer/search', params: params});
    }

    /*director drawings*/
    static get_director_drawings(params: { min_date: string, max_date: string, }) {
        return axios({method: 'get', url: 'teller/accounts/drawings/list', params: params});
    }

    static save_director_drawings(
        data: {
            drawings_id: number, drawn_by: number, account_id: number, amount_drawn: number,
            date_drawn: string, drawing_type: 'cash_in' | 'cash_out', descriptions: string
        }) {
        return axios({method: 'post', url: 'teller/accounts/drawings/save', data: this.post_params(data)});
    }

    /*salary scales*/
    static save_salary_scale(params: {
        user_id: number,
        salary: number,
        allowances: number,
        bank_name: string,
        account_number: string,
        account_name: string,
        scale_id: number
    }) {
        return axios({method: 'post', url: 'teller/accounts/salary/scales', data: this.post_params(params)});
    }

    static get_salary_scales() {
        return axios({method: 'get', url: 'teller/accounts/salary/scales'});
    }

    static initialise_salary_data() {
        return axios({method: 'get', url: 'teller/accounts/salary/init'});
    }

    static get_salary_schedules(params: { payment_year: number, payment_month: string, user_id: number, office_id: number }) {
        return axios({method: 'get', url: 'teller/accounts/salary/schedules', params: params});
    }

    static get_salary_payments(params: { min_date: string, max_date: string, user_id: number }) {
        return axios({method: 'get', url: 'teller/accounts/salary/payments', params: params});
    }

    static save_salary_payment(params: {
        user_id: number, reference_no: string, salary_arrears: number,
        allowance_arrears: number, allowance_paid: number, salary_paid: number, date_paid: string
    }) {
        return axios({method: 'post', url: 'teller/accounts/salary/payment', data: this.post_params(params)});
    }

    /*system creditors*/
    static save_system_creditor(params: { creditor_id: number, creditor_name: string, creditor_email: string, creditor_contact: string }) {
        return axios({method: 'post', url: 'teller/creditors/entity/save', data: this.post_params(params)});
    }

    static get_system_creditor(params: { loan_status: 'active' | 'deleted' | 'complete' | 'overdue', creditor_name: string }) {
        return axios({method: 'get', url: 'teller/creditors/entity/search', params: params});
    }

    static get_creditor_loan(params: { loan_id: number }) {
        return axios({method: 'get', url: 'teller/creditors/loan/info', params: params});
    }

    static revalidate_creditor_loan(params: { loan_id: number }) {
        return axios({method: 'get', url: 'teller/creditors/loan/revalidate', params: params});
    }

    static save_creditor_loan(params: {
        creditor_id: number, loan_principal: number, loan_rate: number, loan_id: number, account_id: number,
        loan_period: number, start_date: string, due_date: string, date_acquired: string
    }) {
        return axios({method: 'post', url: 'teller/creditors/loan/save', data: this.post_params(params)});
    }

    static top_up_creditor(params: {
        loan_id: number, user_id: number, account_id: number, top_up_amount: number,
        top_up_reason: string, top_up_date: string, start_date: string, top_up_period: number
    }) {
        return axios({method: 'post', url: 'teller/creditors/loan/top_up/save', data: this.post_params(params)});
    }

    static delete_creditor_top_up(params: { loan_id: number, top_up_id: number }) {
        return axios({method: 'post', url: 'teller/creditors/loan/top_up/delete', data: this.post_params(params)});
    }

    static pay_creditor_loan(params: {
        loan_id: number, time_paid: string, principal_paid: number, interest_paid: number,
        user_id: number, account_id: number, reference_no: string, payment_id: number
    }) {
        return axios({method: 'post', url: 'teller/creditors/loan/payment/save', data: this.post_params(params)});
    }

    static delete_creditor_payment(params: { loan_id: number, payment_id: number }) {
        return axios({method: 'post', url: 'teller/creditors/loan/payment/delete', data: this.post_params(params)});
    }

    static extend_creditor_loan(params: { loan_id: number, extension_period: number, payment_start_date: string, extension_rate: number }) {
        return axios({method: 'post', url: 'teller/creditors/loan/extend', data: this.post_params(params)});
    }

    static delete_creditor_loan(params: { loan_id: number }) {
        return axios({method: 'post', url: 'teller/creditors/loan/delete', data: this.post_params(params)});
    }

    /*reports*/
    static process_report(params: { criteria: string }, type: { action: string, type: 'pdf' | 'table' | 'excel' }) {
        return axios({method: 'get', url: `teller/report/generate/${type.action}/${type.type}`, params: params});
    }

    static process_daily_report(params: { date: string, offices: string }) {
        return axios({method: 'get', url: `teller/report/daily`, params: params});
    }

    static init_reports(params: { type: string }) {
        return axios({method: 'get', url: 'teller/report/initialise', params: params});
    }
}

export interface Page {
    page: number
    pages: number
    limit: number
}

export interface Fetch {
    start: number
    limit: number
    total: number
    page: number
    pages: number
    load?: boolean
}
