import {Req} from "../../utils/Req";
import React from "react";
import {ToastsStore} from "react-toasts";
import Utils from "../../utils/Utils";
import {Loading, MySelect, PDFReader, SelectData} from "../../utils/Components";
import IncomeCriteria, {IncomesCriteria} from "./criteria/IncomeCriteria";
import {Button} from "semantic-ui-react";
import IncomesReport from "./report/IncomesReport";
import DailyReport from "./report/DailyReport";
import DailyCriteria, {CriteriaDaily} from "./criteria/DailyCriteria";
import AgingCriteria, {CriteriaAging} from "./criteria/AgingCriteria";
import AgingReport from "./report/AgingReport";
import LoanCriteria, {CriteriaLoan} from "./criteria/LoanCriteria";
import LoanReport from "./report/LoanReport";
import LoanBalancesCriteria, {CriteriaBalances} from "./criteria/LoanBalancesCriteria";
import LoanBalancesReport from "./report/LoanBalancesReport";
import LoanPortfolioCriteria, {CriteriaPortfolio} from "./criteria/LoanPortfolioCriteria";
import LoanPortfolioReport from "./report/LoanPortfolioReport";
import ExpensesCriteria, {CriteriaExpenses} from "./criteria/ExpensesCriteria";
import ExpensesReport from "./report/ExpensesReport";
import IncomeStatementsCriteria, {CriteriaStatements} from "./criteria/IncomeStatementsCriteria";
import IncomesStatementReport from "./report/IncomesStatementReport";
import BankStatementCriteria, {CriteriaBank} from "./criteria/BankStatementCriteria";
import BankStatementReport from "./report/BankStatementReport";
import PaymentCriteria, {CriteriaPayment} from "./criteria/PaymentCriteria";
import PaymentsReport from "./report/PaymentsReport";
import TillAllocationCriteria, {CriteriaAllocation} from "./criteria/TillAllocationCriteria";
import TillAllocationReport from "./report/TillAllocationReport";
import BalanceSheetCriteria, {CriteriaBalanceSheet} from "./criteria/BalanceSheetCriteria";
import BalanceSheet from "./report/BalanceSheet";
import TrialBalanceCriteria, {CriteriaTrialBalance} from "./criteria/TrialBalanceCriteria";
import TrialBalance from "./report/TrialBalance";
import SalesReportCriteria, {CriteriaSalesReport} from "./criteria/SalesReportCriteria";
import SalesReport from "./report/SalesReport";
import {MultiUser} from "../admin/users/Users";
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";
import RestructureCriteria, {CriteriaRestructure} from "./criteria/RestructureCriteria";
import RestructuredReport from "./report/RestructuredReport";
import PortfolioRiskReport from "./report/PortfolioRiskReport";
import PortfolioRiskCriteria, {CriteriaPortfolioRisk} from "./criteria/PortfolioRiskCriteria";
import JournalCriteria, {CriteriaJournal} from "./criteria/JournalCriteria";
import JournalsReport from "./report/JournalsReport";
import {accessRoles, report_type} from "../admin/settings/ReportsPermissions";
import DailySummaryCriteria, {CriteriaDailySummary} from "./criteria/DailySummaryCriteria";
import DailyReportSummary from "./report/DailyReportSummary";
import {Account} from "../../utils/Models";

export default function SystemReports(params: { accounts: Account[], }) {
    const user = useAppSelector(getStaff)

    const [report, setReport] = React.useState<{ type: report_type, name: string, data: any, url?: string }>(
        {type: "", name: "", data: {}})
    const [modal, setModal] = React.useState(false)
    const [loader, setLoader] = React.useState({message: "", show: false})
    const [showReader, setShowReader] = React.useState({display: false, source: ""})

    /**/
    const [offices, setOffices] = React.useState<Array<SelectData>>([])
    const [loanTypes, setLoanTypes] = React.useState<Array<SelectData>>([])
    const [journalAccounts, setJournalAccounts] = React.useState<Array<SelectData>>([])
    const [users, setUsers] = React.useState<Array<MultiUser>>([])
    const [expenses, setExpenses] = React.useState<Array<MultiUser>>([])
    const [reports, setReports] = React.useState(Array<{ value: string, text: string }>())

    /*criteria data*/
    const [dailyCriteria, setDailyCriteria] = React.useState<CriteriaDaily>({
        branches: [], max_date: Utils.today(), min_date: Utils.today()
    })
    const [dailyCriteriaSummary, setDailyCriteriaSummary] = React.useState<CriteriaDailySummary>({
        branches: [], date: Utils.today()
    })
    const [incomesCriteria, setIncomesCriteria] = React.useState<IncomesCriteria>({
        min_date: Utils.today(), max_date: Utils.today(), branches: [],
    })
    const [agingCriteria, setAgingCriteria] = React.useState<CriteriaAging>(
        {branches: [], max_date: Utils.today(), min_date: '2000-01-01'}
    )
    const [restructureCriteria, setRestructureCriteria] = React.useState<CriteriaRestructure>(
        {branches: [], min_date: '2019-01-01', max_date: Utils.today()}
    )
    const [journalsCriteria, setJournalsCriteria] = React.useState<CriteriaJournal>(
        {min_date: '2019-01-01', max_date: Utils.today(), accounts: [], pages: 0, page: 1, limit: 1000}
    )
    const [loanCriteria, setLoanCriteria] = React.useState<CriteriaLoan>({
        min_date: '2000-01-01', max_date: Utils.today(), branches: [], min_principal: "",
        max_principal: "", loanTypes: [], loan_status: []
    })
    const [balancesCriteria, setBalancesCriteria] = React.useState<CriteriaBalances>({
        min_date: '2000-01-01', max_date: Utils.today(), branches: [],
    })
    const [portfolioCriteria, setPortfolioCriteria] = React.useState<CriteriaPortfolio>({
        min_date: '2000-01-01', max_date: Utils.today(), loanTypes: [], branches: [],
    })
    const [expensesCriteria, setExpensesCriteria] = React.useState<CriteriaExpenses>({
        min_date: Utils.today(), max_date: Utils.today(), branches: [], expenses: []
    })
    const [statementsCriteria, setStatementsCriteria] = React.useState<CriteriaStatements>({
        min_date: Utils.today(), max_date: Utils.today(), branches: []
    })
    const [bankCriteria, setBankCriteria] = React.useState<CriteriaBank>({
        min_date: Utils.month_start_date(), max_date: Utils.today()
    })
    const [paymentCriteria, setPaymentCriteria] = React.useState<CriteriaPayment>({
        min_date: Utils.today(), max_date: Utils.today(), office_id: [], users: []
    })
    const [allocationCriteria, setAllocationCriteria] = React.useState<CriteriaAllocation>({
        min_date: Utils.today(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)),
        max_date: Utils.today(), branches: [], users: []
    })
    const [balanceSheetCriteria, setBalanceSheetCriteria] = React.useState<CriteriaBalanceSheet>({
        min_date: '2000-01-01', max_date: Utils.today()
    })
    const [trialBalanceCriteria, setTrialBalanceCriteria] = React.useState<CriteriaTrialBalance>({
        min_date: '2000-01-01', max_date: Utils.today()
    })
    const [salesCriteria, setSalesCriteria] = React.useState<CriteriaSalesReport>({
        status: "all", branches: [], max_date: Utils.today(), min_date: '2000-01-01'
    })
    const [portFolioRiskCriteria, setPortFolioRiskCriteria] = React.useState<CriteriaPortfolioRisk>({
        branches: [], loanTypes: [], type: 'branch', users: []
    })

    const initialise_report = (_params: { type: 'pdf' | 'table' | 'excel' }) => {
        if (report.type === "daily_report") {
            generate_report({criteria: JSON.stringify(dailyCriteria), type: _params.type})
        } else if (report.type === "incomes_report") {
            generate_report({criteria: JSON.stringify(incomesCriteria), type: _params.type})
        } else if (report.type === "aging_report") {
            generate_report({criteria: JSON.stringify(agingCriteria), type: _params.type})
        } else if (report.type === "restructured_loans") {
            generate_report({criteria: JSON.stringify(restructureCriteria), type: _params.type})
        } else if (report.type === "loan_report") {
            generate_report({criteria: JSON.stringify(loanCriteria), type: _params.type})
        } else if (report.type === "loan_balances") {
            generate_report({criteria: JSON.stringify(balancesCriteria), type: _params.type})
        } else if (report.type === "loan_portfolio") {
            generate_report({criteria: JSON.stringify(portfolioCriteria), type: _params.type})
        } else if (report.type === "expenses_report") {
            generate_report({criteria: JSON.stringify(expensesCriteria), type: _params.type})
        } else if (report.type === "income_statement") {
            generate_report({criteria: JSON.stringify(statementsCriteria), type: _params.type})
        } else if (report.type === "bank_statement") {
            generate_report({criteria: JSON.stringify(bankCriteria), type: _params.type})
        } else if (report.type === "payment_report") {
            generate_report({criteria: JSON.stringify(paymentCriteria), type: _params.type})
        } else if (report.type === "till_allocation") {
            generate_report({criteria: JSON.stringify(allocationCriteria), type: _params.type})
        } else if (report.type === "balance_sheet") {
            generate_report({criteria: JSON.stringify(balanceSheetCriteria), type: _params.type})
        } else if (report.type === "trial_balance") {
            generate_report({criteria: JSON.stringify(trialBalanceCriteria), type: _params.type})
        } else if (report.type === "sales_report") {
            generate_report({criteria: JSON.stringify(salesCriteria), type: _params.type})
        } else if (report.type === "portfolio_risk") {
            generate_report({criteria: JSON.stringify(portFolioRiskCriteria), type: _params.type})
        } else if (report.type === "journals") {
            generate_report({criteria: JSON.stringify(journalsCriteria), type: _params.type})
        } else if (report.type === "daily_summary") {
            generate_daily_report({offices: JSON.stringify(dailyCriteriaSummary.branches), date: dailyCriteriaSummary.date})
        }
    }

    const generate_report = (_params: { type: 'pdf' | 'table' | 'excel', criteria: string }) => {
        setModal(false)
        setLoader({message: "Generating report, please wait", show: true})
        Req.process_report({criteria: _params.criteria}, {action: report.type, type: _params.type})
            .then((response) => {
                if (_params.type === 'pdf' && response.data.hasOwnProperty("file")) {
                    setShowReader({display: true, source: Req.BASE_URL + response.data.file})
                } else if (_params.type === 'table' && response.data.hasOwnProperty("report")) {
                    setReport({...report, data: {rp: response.data.report}})
                } else if (_params.type === 'excel' && response.data.hasOwnProperty("path")) {
                    Utils.download_file(response.data.path)
                } else {
                    ToastsStore.error("Could not generate your report, please retry")
                }
                setLoader({message: "", show: false})
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not generate report, please retry")
            })
    }

    const generate_daily_report = (_params: { date: string, offices: string }) => {
        setModal(false)
        setLoader({message: "Generating daily report, please wait", show: true})
        Req.process_daily_report({date: _params.date, offices: _params.offices})
            .then((response) => {
                setReport({...report, data: {}, url: response.data.file})
                setLoader({message: "", show: false})
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not generate report, please retry")
            })
    }

    React.useEffect(() => {
        if (reports.length > 0) {
            setReport({data: {}, type: reports[0].value as report_type, name: reports[0].text})
        }
    }, [reports])

    React.useEffect(() => {
        setModal(true)
    }, [report.type])

    React.useEffect(() => {
        Req.init_reports({type: JSON.stringify(["offices", "users", "expenses", "loan_types", "journals"])})
            .then((response) => {
                if (response.data.hasOwnProperty("data")) {
                    setOffices(response.data.data.offices)
                    setUsers(response.data.data.users)
                    setExpenses(response.data.data.expenses)
                    setLoanTypes(response.data.data.types)
                    setJournalAccounts(response.data.data.accounts)
                }
            })
        setReports([
            ...accessRoles
                .filter((report) => user.roles.report_rights.includes(report.report_type))
                .sort((a, b) => a.report_name.localeCompare(b.report_type))
                .map((report) => ({text: report.report_name, value: report.report_type}))
        ])
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({message: "", show: false})}/>

            <PDFReader source={showReader.source} open={showReader.display} close={() => setShowReader({...showReader, display: false})}/>

            <>
                <div className="content_bar">
                    <div className="search_bar">
                        <div style={{width: '250px'}}>
                            <MySelect
                                title="" name="report_type" value={report.type} placeholder="Select a report type"
                                change={(value) => {
                                    const report = accessRoles.filter((report) => report.report_type === value as string)
                                    setReport({
                                        data: {}, type: value as report_type,
                                        name: report.length === 0 ? "No report selected" : report[0].report_name
                                    })
                                }}
                                options={reports}/>
                        </div>
                    </div>

                    <div className="search_button">
                        <Button
                            primary floated='right' size='mini' icon='filter' labelPosition='left'
                            content='Filter' onClick={() => setModal(true)}/>

                        <Button
                            primary size='mini' icon='file excel' labelPosition='left'
                            disabled={["portfolio_risk", "daily_summary"].includes(report.type)}
                            content='Excel' onClick={() => initialise_report({type: 'excel'})}/>

                        <Button
                            primary size='mini' icon='file pdf' labelPosition='left'
                            content='PDF' onClick={() => initialise_report({type: 'pdf'})}/>
                    </div>
                </div>

                <>
                    {report.type === "incomes_report" && <IncomesReport transactions={report.data}/>}
                    {report.type === "daily_report" && <DailyReport transactions={report.data}/>}
                    {report.type === "daily_summary" && <DailyReportSummary url={report.url ?? ""}/>}
                    {report.type === "aging_report" && <AgingReport transactions={report.data} accounts={params.accounts}/>}
                    {report.type === "loan_report" && <LoanReport transactions={report.data} accounts={params.accounts}/>}
                    {report.type === "loan_balances" && <LoanBalancesReport transactions={report.data} accounts={params.accounts}/>}
                    {report.type === "loan_portfolio" && <LoanPortfolioReport transactions={report.data}/>}
                    {report.type === "expenses_report" && <ExpensesReport transactions={report.data}/>}
                    {report.type === "income_statement" && <IncomesStatementReport transactions={report.data} criteria={statementsCriteria}/>}
                    {report.type === "bank_statement" && <BankStatementReport transactions={report.data}/>}
                    {report.type === "payment_report" && <PaymentsReport transactions={report.data} accounts={params.accounts}/>}
                    {report.type === "till_allocation" && <TillAllocationReport transactions={report.data}/>}
                    {report.type === "balance_sheet" && <BalanceSheet transactions={report.data} criteria={balancesCriteria}/>}
                    {report.type === "trial_balance" && <TrialBalance transactions={report.data} criteria={trialBalanceCriteria}/>}
                    {report.type === "sales_report" && <SalesReport transactions={report.data} accounts={params.accounts}/>}
                    {report.type === "restructured_loans" && <RestructuredReport transactions={report.data} accounts={params.accounts}/>}
                    {report.type === "portfolio_risk" && <PortfolioRiskReport transactions={report.data}/>}
                    {
                        report.type === "journals" &&
                        <JournalsReport
                            criteria={journalsCriteria} setJournalsCriteria={setJournalsCriteria}
                            transactions={report.data} accounts={params.accounts}
                            search={(page) => {
                                generate_report({criteria: JSON.stringify({...journalsCriteria, page: page}), type: 'table'})
                            }}/>
                    }
                </>

                {/*criteria selection*/}
                <IncomeCriteria
                    show={report.type === "incomes_report" && modal} close={() => setModal(false)} offices={offices} users={users}
                    generate={() => initialise_report({type: "table"})} criteria={incomesCriteria} handle_change={setIncomesCriteria}/>

                <JournalCriteria
                    show={report.type === "journals" && modal} close={() => setModal(false)} accounts={journalAccounts}
                    generate={() => initialise_report({type: "table"})} criteria={journalsCriteria} handle_change={setJournalsCriteria}/>

                <DailyCriteria
                    offices={offices} show={report.type === "daily_report" && modal} close={() => setModal(false)}
                    criteria={dailyCriteria} setCriteria={setDailyCriteria} generate={() => initialise_report({type: "table"})}/>

                <DailySummaryCriteria
                    offices={offices} show={report.type === "daily_summary" && modal} close={() => setModal(false)}
                    criteria={dailyCriteriaSummary} setCriteria={setDailyCriteriaSummary}
                    generate={() => initialise_report({type: "pdf"})}/>

                <AgingCriteria
                    branches={offices} show={report.type === "aging_report" && modal} close={() => setModal(false)}
                    criteria={agingCriteria} handle_change={setAgingCriteria} generate={() => initialise_report({type: "table"})}/>

                <RestructureCriteria
                    branches={offices} show={report.type === "restructured_loans" && modal} close={() => setModal(false)}
                    criteria={restructureCriteria} handle_change={setRestructureCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <LoanCriteria
                    branches={offices} loanTypes={loanTypes} show={report.type === "loan_report" && modal}
                    close={() => setModal(false)} criteria={loanCriteria} handle_change={setLoanCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <LoanBalancesCriteria
                    branches={offices} show={report.type === "loan_balances" && modal} close={() => setModal(false)}
                    criteria={balancesCriteria} handle_change={setBalancesCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <LoanPortfolioCriteria
                    branches={offices} loanTypes={loanTypes} show={report.type === "loan_portfolio" && modal}
                    close={() => setModal(false)} criteria={portfolioCriteria} handle_change={setPortfolioCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <ExpensesCriteria
                    branches={offices} expenses={expenses} show={report.type === "expenses_report" && modal}
                    close={() => setModal(false)} criteria={expensesCriteria} handle_change={setExpensesCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <IncomeStatementsCriteria
                    branches={offices} show={report.type === "income_statement" && modal}
                    close={() => setModal(false)} criteria={statementsCriteria} handle_change={setStatementsCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <BankStatementCriteria
                    show={report.type === "bank_statement" && modal} close={() => setModal(false)}
                    criteria={bankCriteria} handle_change={setBankCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <PaymentCriteria
                    branches={offices} users={users} show={report.type === "payment_report" && modal}
                    close={() => setModal(false)} criteria={paymentCriteria} handle_change={setPaymentCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <TillAllocationCriteria
                    branches={offices} users={users} show={report.type === "till_allocation" && modal}
                    close={() => setModal(false)} criteria={allocationCriteria} handle_change={setAllocationCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <BalanceSheetCriteria
                    show={report.type === "balance_sheet" && modal} close={() => setModal(false)}
                    criteria={balanceSheetCriteria} handle_change={setBalanceSheetCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <TrialBalanceCriteria
                    show={report.type === "trial_balance" && modal} close={() => setModal(false)}
                    criteria={trialBalanceCriteria} handle_change={setTrialBalanceCriteria}
                    generate={() => initialise_report({type: "table"})}/>

                <SalesReportCriteria
                    branches={offices} show={report.type === "sales_report" && modal} criteria={salesCriteria}
                    handle_change={setSalesCriteria} close={() => setModal(false)}
                    generate={() => initialise_report({type: "table"})}/>

                <PortfolioRiskCriteria
                    branches={offices} users={users} loanTypes={loanTypes} show={report.type === "portfolio_risk" && modal}
                    close={() => setModal(false)} criteria={portFolioRiskCriteria} handle_change={setPortFolioRiskCriteria}
                    generate={() => initialise_report({type: "table"})}/>
            </>
        </>
    )
}
