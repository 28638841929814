import {Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import React from "react";
import {Account} from "../../../../utils/Models";

export interface RcLoanPayment {
    account: number
    loan_id: number
    client: string
    interest: number
    others: number
    id: number
    penalty: number
    principal: number
    date: string
}

export default function RcLoanPayments(params: { payments: RcLoanPayment[], accounts: Account[] }) {
    return (
        <>
            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}} textAlign="center">Loan No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Client Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '110px'}}>Principal</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '110px'}}>Interest</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '110px'}}>Penalty</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '110px'}}>Others</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '110px'}}>Total Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Payment Mode</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Date Paid</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.payments.map((payment, index) =>
                                <Table.Row key={payment.id}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}} textAlign="center">{payment.loan_id}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{payment.client}</Table.Cell>
                                    <Table.Cell style={{width: '110px'}}>{Utils.comma_number(payment.principal)}</Table.Cell>
                                    <Table.Cell style={{width: '110px'}}>{Utils.comma_number(payment.interest)}</Table.Cell>
                                    <Table.Cell style={{width: '110px'}}>{Utils.comma_number(payment.penalty)}</Table.Cell>
                                    <Table.Cell style={{width: '110px'}}>{Utils.comma_number(payment.others)}</Table.Cell>
                                    <Table.Cell style={{width: '110px'}}>
                                        {Utils.comma_number(payment.principal + payment.interest + payment.penalty + payment.others)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>
                                        {Utils.get_journal_account(payment.account, params.accounts)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{Utils.localise_date_time(payment.date)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
