import {LoanDetail, TopUp} from "../LoansDetails";
import React from "react";
import {Button, Form, TextArea} from "semantic-ui-react";
import {Account} from "../../../../../utils/Models";
import {MyDateInput, MyInput, MyLabel, MySelect, select_type, SelectData} from "../../../../../utils/Components";
import Utils from "../../../../../utils/Utils";
import {Req} from "../../../../../utils/Req";
import {ToastsStore} from "react-toasts";

export default function TopUpDetail(
    params: {
        close: () => void, update: (loan: LoanDetail) => void, initial: TopUp, hasRoles: boolean,
        topUp: TopUp, accounts: Account[], users: Array<SelectData>, loanInfo: LoanDetail
    }
) {
    const [saving, setSaving] = React.useState(false)

    const [topUp, setTopUp] = React.useState(params.initial)
    const handle_change = (name: string, value: string | number) => {
        setTopUp({...topUp, [name]: value})
    }

    const top_up_loan = () => {
        const amount = Utils.strip_commas(topUp.top_up_amount)
        if (topUp.user_id === 0) {
            ToastsStore.error("Select a person initiating the loan")
        } else if (!Utils.is_valid_number(amount) || parseFloat(amount) < 0) {
            ToastsStore.error("Enter a valid loan amount")
        } else if (topUp.account_id === 0) {
            ToastsStore.error("Select account receiving the top up")
        } else if (topUp.top_up_date === "") {
            ToastsStore.error("Enter date top up was received")
        } else if (topUp.top_up_time === "") {
            ToastsStore.error("Enter time top up was received")
        } else if (topUp.start_date === "") {
            ToastsStore.error("Enter a valid start date of payment")
        } else if (!Utils.is_valid_number(topUp.top_up_period.toString()) || parseInt(topUp.top_up_period.toString()) < 0) {
            ToastsStore.error("Enter a valid top up period")
        } else {
            setSaving(true)
            Req.top_up_creditor({
                account_id: topUp.account_id, loan_id: params.loanInfo.loan.loan_id, start_date: topUp.start_date,
                top_up_amount: parseFloat(amount), user_id: topUp.user_id, top_up_reason: topUp.top_up_reason.trim(),
                top_up_date: `${topUp.top_up_date} ${topUp.top_up_time}`, top_up_period: parseInt(topUp.top_up_period.toString()),
            })
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            setTopUp(params.initial)
                            params.update(response.data.loan)
                            ToastsStore.success("Top up saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error(`Loan cannot be topped up because it is ${response.data.status}`)
                        } else if (response.data.code === 3) {
                            ToastsStore.error(`Loan balance is below 0 i.e ${response.data.balance.toLocaleString}`)
                        }
                    } else {
                        ToastsStore.error("Error saving top up, please retry")
                    }
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Error saving top up, please retry")
                })
        }
    }

    React.useEffect(() => {
        setTopUp(params.topUp)
    }, [params.topUp])

    React.useEffect(() => {
        setTopUp({...topUp, start_date: ''})
    }, [topUp.top_up_date, topUp.top_up_time])

    return (
        <>
            <div className="form">
                <div className="form_content no_header">
                    <MySelect
                        change={(value: select_type) => handle_change('user_id', value as number)}
                        placeholder={"Select person initiating the loan"}
                        title="Top up initiated by?" name="user_id" value={topUp.user_id} options={params.users}/>

                    <MyInput
                        placeholder="Enter loan amount topped up" title="Top Up Amount" name="top_up_amount"
                        change={handle_change} value={Utils.comma_input(topUp.top_up_amount)}/>

                    <MySelect
                        title="Select account receiving the top up" name="account_id" value={topUp.account_id}
                        change={(value: select_type) => handle_change('account_id', (value as number))}
                        options={
                            [
                                {text: "Select an account ", value: 0},
                                ...params.accounts.map((account) => ({text: account.account_name, value: account.account_id}))
                            ]
                        }/>

                    <div className={'row mx-0'} style={{marginBottom: '15px'}}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MyDateInput
                                title="Date acquired" value={topUp.top_up_date} name="top_up_date"
                                placeholder="Select date of top up" change={handle_change}
                                minDate={params.loanInfo.loan.date_acquired} maxDate={Utils.today()}/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MyDateInput
                                title="Time acquired" value={topUp.top_up_time} name="top_up_time"
                                placeholder="Select time of top up" change={handle_change} type={"time"}/>
                        </div>
                    </div>

                    <div className={'row mx-0'} style={{marginBottom: '15px'}}>
                        <div className={'col-6 pl-0 pr-1'}>
                            <MyDateInput
                                title="Start date of payment" value={topUp.start_date} name="start_date"
                                placeholder="Select date of top up" change={handle_change} minDate={topUp.top_up_date}
                                disabled={topUp.top_up_date === "" || topUp.top_up_time === ""}/>
                        </div>
                        <div className={'col-6 pl-1 pr-0'}>
                            <MyInput
                                placeholder="Enter payment period" title="Payment Period" name="top_up_period"
                                change={handle_change} value={topUp.top_up_period.toString()} type={"number"}/>
                        </div>
                    </div>

                    <MyLabel title={"Details of top up"}/>
                    <Form>
                        <TextArea
                            placeholder='Enter details of top up' rows={3} value={topUp.top_up_reason}
                            onChange={((event, data) => {
                                handle_change('top_up_reason', data.value as string)
                            })}/>
                    </Form>
                </div>
                <div className="form_footer">
                    <div className="row mx-0">
                        <div className="col-2 pl-0 pr-1">
                            <Button negative disabled={saving} fluid size='tiny' icon="close" onClick={params.close}/>
                        </div>
                        <div className="col-5 px-1">
                            <Button
                                negative disabled={saving} fluid size='tiny' icon="trash" labelPosition="left"
                                content={"Clear Data"} onClick={() => setTopUp(params.initial)}/>
                        </div>
                        <div className="col-5 pl-1 pr-0">
                            <Button
                                positive onClick={top_up_loan} loading={saving} fluid size='tiny'
                                content={topUp.top_up_id === 0 ? 'Save Top Up' : 'Update Top Up'}
                                disabled={saving || params.loanInfo.loan.principal_balance <= 0 || !params.hasRoles}
                                icon="save" labelPosition="left"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
