import React from "react";
import Utils, {InputFile} from "../../utils/Utils";
import {Button, Form, Table, TextArea} from "semantic-ui-react";
import placeholder from '../../../assets/images/placeholder.png'
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";
import {MyInput, MyLabel} from "../../utils/Components";

interface LegalDocument {
    document_id: number
    document_name: string
    document_images: Array<InputFile>
    document_description: string
    document_paths: Array<InputFile>
    date_saved: string
    user_id: number
    user_name: string
}

export default function LegalDocuments() {
    const user = useAppSelector(getStaff)
    const [fetching, setFetching] = React.useState(false)

    const initial_document: LegalDocument = {
        document_description: "", document_id: 0, document_images: [], document_name: "", document_paths: [], date_saved: "",
        user_name: `${user.staff.last_name} ${user.staff.first_name}`, user_id: user.staff.user_id
    }

    const [document, setDocument] = React.useState<LegalDocument>(initial_document)
    const [documents, setDocuments] = React.useState<Array<LegalDocument>>([])
    const handle_document = (name: string, value: number | string) => {
        setDocument({...document, [name]: value})
    }


    const load_documents = () => {

    }

    const save_document = () => {

    }

    return (
        <>
            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar"/>
                        <div className="search_button">
                            <Button primary floated='right' size='mini' loading={fetching} content='Search' labelPosition='left'
                                    disabled={fetching} onClick={load_documents} icon='search'/>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                                    <Table.HeaderCell>Document Name</Table.HeaderCell>
                                    <Table.HeaderCell>Modified By</Table.HeaderCell>
                                    <Table.HeaderCell>Last Modified</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    documents.map((legal_document, index) =>
                                        <Table.Row key={legal_document.document_id}>
                                            <Table.Cell width={1}>{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell>{legal_document.document_name}</Table.Cell>
                                            <Table.Cell>{legal_document.user_name}</Table.Cell>
                                            <Table.Cell>{Utils.localise_date(legal_document.date_saved)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="form">
                        <div className="form_header">
                            {document.document_id === 0 ? "Create new Legal Document" : `Changing document ${document.document_name}`}
                        </div>

                        <div className="container-fluid form_content">
                            <MyInput
                                placeholder="Enter document name" title="Document Name"
                                name="document_name" change={handle_document} value={document.document_name}/>

                            <Form style={{marginBottom: '15px'}}>
                                <MyLabel title="Document Description"/>
                                <TextArea placeholder='Enter description about the document' rows={5}
                                          value={document.document_description}
                                          onChange={((event, data) => {
                                              handle_document('document_description', data.value as string)
                                          })}/>
                            </Form>

                            <MyLabel title="Select document files"/>
                            <div className="mb-2">
                                <div className="mt-2">
                                    <input type="file" className="input_file" id="document_images" accept="image/*"
                                           onChange={(event) => {
                                               const files = event.target.files;
                                               if (files !== null && files.length > 0) {
                                                   const file: File | null = files.item(0);
                                                   if (file !== null) {
                                                       Utils.format_file(file as File)
                                                           .then((result) => {

                                                           })
                                                   }
                                               }
                                           }}/>
                                    <label htmlFor="document_images" className="ui tiny grey button fluid">
                                        <i className="ui upload icon"/>
                                        Document Files (PDF, MS Word, Images e.t.c)
                                    </label>
                                </div>
                                <div style={{height: '160px'}}>
                                    <img src={placeholder} alt={placeholder} style={{width: '100%', height: '100%'}}/>
                                </div>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className='row m-0'>
                                <div className='col-6 pl-0 pr-1'>
                                    <Button negative onClick={() => setDocument(initial_document)} disabled={fetching}
                                            content='Clear Data' fluid size='tiny' icon='trash' labelPosition='right'/>
                                </div>
                                <div className='col-6 pl-1 pr-0'>
                                    <Button positive onClick={save_document} disabled={fetching} content='Save Document'
                                            loading={fetching} fluid size='tiny' icon='save' labelPosition='left'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
