import React from "react";
import Utils from "../../../utils/Utils";
import {Table} from "semantic-ui-react";
import {ShowLoan} from "../../loans/container/ShowLoan";
import {Account} from "../../../utils/Models";

interface Report {
    client: string
    date_disbursed: string
    due_date: string
    fee_balance: number
    interest_balance: number
    loan_id: number
    loan_principal: number
    main_contact: number
    penalty_balance: number
    principal_balance: number
    total_balance: number
}

export default function LoanBalancesReport(params: { transactions: any, accounts: Account[], }) {
    const [showLoan, setShowLoan] = React.useState({show: false, loan_id: 0})
    const [statements, setStatements] = React.useState<Array<Report>>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])


    return (
        <div className="table_container container_no_footer">
            {
                showLoan.show ?
                    <ShowLoan
                        close={() => setShowLoan({loan_id: 0, show: false})}
                        loan_id={showLoan.loan_id} accounts={params.accounts}/> :

                    <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '70px'}}>Loan No</Table.HeaderCell>
                                <Table.HeaderCell className='time_row'>Disbursed Date</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '110px'}}>Client Contact</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Loan Amount</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Principal Balance</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Interest Balance</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Penalty Balance</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Other Balance</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Total Balance</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                statements
                                    .map((transaction, index) => <>
                                            <Table.Row
                                                key={transaction.loan_id}
                                                onClick={() => {
                                                    if (transaction.loan_id > 0) {
                                                        setShowLoan({loan_id: transaction.loan_id, show: true});
                                                    }
                                                }}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">
                                                    {(index < statements.length - 1) && (index + 1)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '70px'}} textAlign="center">{transaction.loan_id}</Table.Cell>
                                                <Table.Cell className='time_row'>{Utils.localise_date(transaction.date_disbursed)}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{transaction.client}</Table.Cell>
                                                <Table.Cell style={{width: '110px'}}>{transaction.main_contact}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.loan_principal)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.principal_balance)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.interest_balance)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.penalty_balance)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.fee_balance)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.total_balance)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    )
                            }
                        </Table.Body>
                    </Table>
            }
        </div>
    )
}
