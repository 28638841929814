import {initial_charge, LoanTypeCharge} from "./LoanTypes";
import {Button, Form, Modal, Radio, Table, TextArea} from "semantic-ui-react";
import React from "react";
import {Loading, MyInput, MyLabel} from "../../../utils/Components";
import Utils from "../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../utils/Req";

export default function LoanCharges(params: {
    show: boolean, close: (charges?: LoanTypeCharge[]) => void, loanTypeCharge: LoanTypeCharge
}) {
    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [charge, setCharge] = React.useState<LoanTypeCharge>(initial_charge)
    const handle_charge = (name: string, value: string) => {
        setCharge({...charge, [name]: value})
    }
    const handle_charges = (name: string, value: string, index: number) => {
        setCharge({
                ...charge,
                tiers: [...charge.tiers.map((tier, _index) => _index === index ? {...tier, [name]: value} : tier)]
            }
        )
    }

    const save_charges = () => {
        let has_errors = false
        if (charge.fee_name.trim().length < 3) {
            has_errors = true
            ToastsStore.error("Enter a valid fee name at least 3 characters")
        } else if (charge.fee_description.trim().length < 10) {
            has_errors = true
            ToastsStore.error("Enter a valid description at least 10 characters")
        } else {
            for (let index = 0; index < charge.tiers.length && !has_errors; index++) {
                const tier = charge.tiers[index]
                if (!Utils.is_valid_number(Utils.strip_commas(tier.min_value)) || parseFloat(Utils.strip_commas(tier.min_value)) < 0) {
                    ToastsStore.error(`Enter valid minimum amount for tier "${index + 1}"`)
                    has_errors = true
                } else if (!Utils.is_valid_number(Utils.strip_commas(tier.charge_value)) || parseFloat(Utils.strip_commas(tier.charge_value)) < 0) {
                    ToastsStore.error(`Enter valid charge value for tier "${index + 1}"`)
                    has_errors = true
                } else if (index > 0 && Utils.strip_commas(charge.tiers[index - 1].charge_value) ===
                    Utils.strip_commas(charge.tiers[index].charge_value)) {
                    ToastsStore.error(`Charge value for row "${index + 1}" must be unique`)
                    has_errors = true
                } else if (parseFloat(Utils.strip_commas(tier.charge_value)) > parseFloat(Utils.strip_commas(tier.min_value))
                    && tier.charge_type === "Flat") {
                    ToastsStore.error(`Enter valid charge value for tier "${index + 1}"`)
                    has_errors = true
                } else if (parseFloat(Utils.strip_commas(tier.charge_value)) > 100 && tier.charge_type === "Percentage") {
                    ToastsStore.error(`Enter valid charge value for tier "${index + 1}"`)
                    has_errors = true
                }
            }
        }

        if (!has_errors) {
            setLoading({show: true, title: "Saving loan charges, please wait"})
            Req.save_loan_charges({
                charges: JSON.stringify(
                    charge.tiers.map((tier) => ({
                        min_value: Utils.strip_commas(tier.min_value),
                        charge_value: Utils.strip_commas(tier.charge_value),
                        charge_type: tier.charge_type
                    }))),
                type_id: charge.type_id,
                fee_name: charge.fee_name,
                fee_description: charge.fee_description,
                charge_id: charge.charge_id
            })
                .then((response) => {
                    setLoading({show: false, title: ""})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        params.close(response.data.charges)
                    } else {
                        ToastsStore.error("Error while saving charges, please retry")
                    }
                })
                .catch(() => {
                    setLoading({show: false, title: ""})
                    ToastsStore.error("Error while saving charges, please retry")
                })
        }
    }

    React.useEffect(() => {
        setCharge(params.loanTypeCharge)
    }, [params.loanTypeCharge])

    return (
        <>
            <Loading show={loading.show} text={loading.title} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={params.show} basic size='small' centered={false}>
                <div className='modal_div loan_types row m-0'>
                    <div className="col-12 col-md-5 p-1 h-100">
                        <div className="form">
                            <div className="form_content">
                                <MyInput placeholder="Enter charge name" title="Charge Name" name="fee_name"
                                         change={handle_charge} value={charge.fee_name}/>

                                <Form>
                                    <MyLabel title="Loan charge description"/>
                                    <TextArea placeholder='Enter loan charge description' rows={3} value={charge.fee_description}
                                              onChange={(event, data) => {
                                                  handle_charge('fee_description', data.value as string)
                                              }}/>
                                </Form>
                            </div>
                            <div className="form_footer">
                                <div className='row m-0'>
                                    <div className='col-5 px-0'>
                                        <Button negative onClick={() => params.close()} content='Discard'
                                                fluid size='tiny' icon='close' labelPosition='left'/>
                                    </div>
                                    <div className='col-5 px-1'>
                                        <Button positive onClick={save_charges} content='Save'
                                                fluid size='tiny' icon='save' labelPosition='left'/>
                                    </div>
                                    <div className='col-2 px-0'>
                                        <Button
                                            positive fluid size='tiny' icon='add'
                                            onClick={() => {
                                                setCharge({
                                                    ...charge, tiers: [...charge.tiers,
                                                        {
                                                            charge_id: charge.charge_id, charge_type: 'Flat',
                                                            charge_value: "", min_value: "0"
                                                        }
                                                    ]
                                                })
                                            }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-7 p-1 h-100">
                        <div className="table_container full_height">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '40px'}}/>
                                        <Table.HeaderCell style={{width: '100px'}}>Minimum</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '140px'}}>Charge Type</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '100px'}}>Amount</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        charge.tiers.map((tier, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell style={{width: '40px'}}>
                                                    <Button
                                                        negative size='mini' icon='trash' compact
                                                        onClick={() => {

                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>
                                                    <MyInput
                                                        placeholder="Enter amount" name="min_value" className="mb-0"
                                                        change={(name, value) => handle_charges(name, value, index)}
                                                        value={Utils.comma_input(tier.min_value)}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>
                                                    <div className="row m-0">
                                                        {
                                                            [{charge_type: "Flat", label: "Flat"}, {charge_type: "Percentage", label: "%age"}]
                                                                .map((type) =>
                                                                    <div className="col-6 p-0" key={type.charge_type}>
                                                                        <Radio
                                                                            label={type.label} checked={tier.charge_type === type.charge_type}
                                                                            name={`charge_type_${index}`} value={type.charge_type}
                                                                            onChange={(event, data) => {
                                                                                if (data.checked) {
                                                                                    handle_charges("charge_type", type.charge_type, index)
                                                                                }
                                                                            }}/>
                                                                    </div>
                                                                )
                                                        }
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>
                                                    <MyInput
                                                        placeholder="Enter value" name="charge_value" className="mb-0"
                                                        change={(name, value) => handle_charges(name, value, index)}
                                                        value={Utils.comma_input(tier.charge_value)}/>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
