import {MyLabel, MySelect, SelectData} from "../../../utils/Components";
import {MultiUser} from "../../admin/users/Users";
import {MultiSelect} from "../../../utils/components/MultiSelect";
import {Button, Modal} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import React from "react";

export interface CriteriaPortfolioRisk {
    users: []
    loanTypes: number[]
    branches: number[]
    type: 'branch' | 'inspector'
}

export default function PortfolioRiskCriteria(params: {
    branches: SelectData[], users: MultiUser[], loanTypes: SelectData[], show: boolean, close: () => void, criteria: CriteriaPortfolioRisk,
    handle_change: (criteria: CriteriaPortfolioRisk) => void, generate: () => void
}) {
    const handle_change = (name: string, value: string | number | Array<number | string>) => {
        params.handle_change({...params.criteria, [name]: value})
    }

    return (
        <Modal open={params.show} basic size='mini' centered={false} onClose={params.close}>
            <div className='modal_div modal_form'>
                <div className="form_content">
                    <div className="row m-0">
                        <div className="col-12 p-1">
                            <MyLabel title="Select portfolio type"/>
                            <MySelect
                                value={params.criteria.type} className="mb-0"
                                change={(value) => {
                                    params.handle_change({...params.criteria, type: value as 'inspector' | 'branch', users: [], branches: []})
                                }}
                                options={[{text: 'Branch Portfolio', value: 'branch'}, {text: 'Loan inspector portfolio', value: 'inspector'}]}/>
                        </div>

                        <div className="col-12 p-1">
                            <MyLabel title="Select loan types"/>
                            <MultiSelect
                                multiple={true} placeholder="Select loan types" items={params.loanTypes} selected={params.criteria.loanTypes}
                                change={(value) => handle_change('loanTypes', value)}/>
                        </div>

                        {
                            params.criteria.type === 'branch' &&
                            <div className="col-12 p-1">
                                <MyLabel title="Select branches"/>
                                <MultiSelect
                                    multiple={true} placeholder="Select branches" items={params.branches} selected={params.criteria.branches}
                                    change={(value) => handle_change('branches', value)}/>
                            </div>
                        }

                        {
                            params.criteria.type === 'inspector' &&
                            <div className="col-12 p-1">
                                <MultiSelect
                                    multiple={true} placeholder="Select loan inspector" items={params.users} selected={params.criteria.users}
                                    change={(value) => handle_change('users', value)}/>
                            </div>
                        }
                    </div>
                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative onClick={params.close} content="Close Window" size='mini'
                                    fluid icon='close' labelPosition="left"/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive content="Load Report" size='mini' fluid icon="table" labelPosition="left"
                                    onClick={() => {
                                        if (params.criteria.loanTypes.length === 0) {
                                            ToastsStore.error("Select at least 1 loan type")
                                        } else if (params.criteria.type === 'branch' && params.criteria.branches.length === 0) {
                                            ToastsStore.error("Select at least 1 branch")
                                        } else if (params.criteria.type === 'inspector' && params.criteria.loanTypes.length === 0) {
                                            ToastsStore.error("Select at least 1 loan inspector")
                                        } else {
                                            params.generate()
                                        }
                                    }}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
