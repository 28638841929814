import React from "react";
import {Button, Form, Modal, Table} from "semantic-ui-react";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Loading, MyLabel, SelectData} from "../../../utils/Components";

interface User {
    user_id: number
    user_status: 'active' | 'inactive'
    username: string
    core_roles: CorePermission[]
}

export type CorePermission = "loan_transfer" | "remove_penalty" | "remove_extension" | "remove_reschedule" | "waive_interest"
    | "till_allocation" | "till_closing" | "client_details" | "modify_interest_rate" | "modify_principal" |
    "reverse_payments" | "payment_backdating" | "user_roles" | "modify_loan_charges" | "modify_core_roles"

export const core_permissions: SelectData[] = [
    {text: "Transfer Loans", value: "loan_transfer"},
    {text: "Remove penalty from loans", value: "remove_penalty"},
    {text: "Remove extensions from loans", value: "remove_extension"},
    {text: "Remove reschedules from loans", value: "remove_reschedule"},
    {text: "Waive interest from loans", value: "waive_interest"},
    {text: "Allocate daily tills", value: "till_allocation"},
    {text: "Close allocated daily tills", value: "till_closing"},
    {text: "Change client details", value: "client_details"},
    {text: "Payments reversing", value: "reverse_payments"},
    {text: "Payments backdating", value: "payment_backdating"},
    {text: "User roles management", value: "user_roles"},
    {text: "Core roles management", value: "modify_core_roles"},
    {text: "Modify loan charges", value: "modify_loan_charges"}
].sort((a, b) => a.text.localeCompare(b.text))

export default function CorePermissions() {
    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [users, setUsers] = React.useState(Array<User>())
    const [coreRole, setCoreRole] = React.useState<{ users: number[], permission: CorePermission | undefined }>(
        {users: [], permission: undefined}
    )

    const get_core_permissions = () => {
        setLoading({show: true, title: "Loading core permissions, please wait..."})
        Req.get_core_roles()
            .then((response) => {
                setLoading({show: false, title: ''})
                if (response.data.hasOwnProperty("users")) {
                    setUsers(response.data.users)
                } else {
                    ToastsStore.error("Could not load core permissions, please retry")
                }
            })
            .catch(() => {
                setLoading({show: false, title: ''})
                ToastsStore.error("Could not load core permissions, please retry")
            })
    }

    const save_core_role = () => {
        setLoading({show: true, title: "Saving core permissions, please wait..."})
        Req.update_core_roles({users: JSON.stringify(coreRole.users), role: coreRole.permission as string})
            .then((response) => {
                setLoading({show: false, title: ''})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setCoreRole({...coreRole, permission: undefined})
                    setUsers(response.data.users)
                    ToastsStore.success("Core roles have been saved successfully")
                } else {
                    ToastsStore.error("Could not save core permissions, please retry")
                }
            })
            .catch(() => {
                setLoading({show: false, title: ''})
                ToastsStore.error("Could not save core permissions, please retry")
            })
    }

    const get_users = (aUsers: number[]) => {
        let list = ""
        if (users.length > 0) {
            aUsers.forEach((userId, index) => {
                list += users.filter((aUser) => aUser.user_id === userId)[0]?.username
                if (aUsers.length > 1) {
                    list += (aUsers.length - 2 === index) ? " and " : (index !== aUsers.length - 1 ? ", " : "")
                }
            })
        }
        return list
    }

    React.useEffect(() => {
        get_core_permissions()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.title} hide={() => setLoading({...loading, 'show': false})}/>

            <Modal open={coreRole.permission !== undefined} basic size='mini' centered={false}>
                <div className='modal_div modal_form'>
                    <div className="form_header">
                        {core_permissions.filter((aPerm) => aPerm.value === coreRole.permission)[0]?.text}
                    </div>

                    <div className="form_content">
                        {
                            ["active", "inactive"]
                                .map((status) =>
                                    <div key={status} style={{marginBottom: '15px'}} className={"core_role_users"}>
                                        <MyLabel title={status === 'active' ? "Active Users" : "In-Active Users"}/>
                                        {
                                            users
                                                .filter((user) => user.user_status === status)
                                                .map((user) =>
                                                    <Form.Checkbox
                                                        className="mb-2" checked={coreRole.users.includes(user.user_id)}
                                                        key={user.user_id} label={user.username} value={user.user_id}
                                                        onChange={((event, data) => {
                                                            if (data.checked as Boolean) {
                                                                setCoreRole({...coreRole, users: [...coreRole.users, user.user_id]})
                                                            } else {
                                                                setCoreRole({
                                                                    ...coreRole,
                                                                    users: coreRole.users.filter((aUser) => aUser !== user.user_id)
                                                                })
                                                            }
                                                        })}/>
                                                )
                                        }
                                    </div>
                                )
                        }
                    </div>

                    <div className="form_footer">
                        <div className="row m-0">
                            <div className="col-6 p-0 pr-1">
                                <Button
                                    negative onClick={() => setCoreRole({...coreRole, permission: undefined})}
                                    content="Close Window" size='mini' fluid icon="close" labelPosition={"left"}/>
                            </div>
                            <div className="col-6 p-0 pl-1">
                                <Button
                                    positive onClick={save_core_role} content="Update Roles" size='mini' fluid
                                    icon="save" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="table_container full_height">
                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '200px'}}>Role Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '750px'}}>Role Users</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            core_permissions.map((aRole) =>
                                <Table.Row
                                    key={aRole.value}
                                    onClick={() => setCoreRole({
                                        permission: aRole.value as CorePermission,
                                        users: users
                                            .filter((aUser) => aUser.core_roles.includes(aRole.value as CorePermission))
                                            .map((aUser) => (aUser.user_id))
                                    })}>
                                    <Table.Cell style={{width: '200px'}}>{aRole.text}</Table.Cell>
                                    <Table.Cell style={{width: '750px'}}>
                                        {
                                            get_users(
                                                users
                                                    .filter((aUser) => aUser.core_roles.includes(aRole.value as CorePermission))
                                                    .map((aUser) => (aUser.user_id))
                                            )
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
