import React from "react";
import {ShowLoan} from "../../loans/container/ShowLoan";
import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import {Account} from "../../../utils/Models";

interface Sale {
    broker_commission: number
    broker_contact: string | number
    broker_name: string
    client_name: string
    loan_id: number
    loan_principal: number
    main_contact: number | string
    office_name: string
    payments: any[]
    total_paid: number
    date_created: string
}

export default function SalesReport(params: { transactions: any, accounts: Account[], }) {
    const [showLoan, setShowLoan] = React.useState({show: false, loan_id: 0})
    const [sales, setSales] = React.useState<Array<Sale>>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setSales(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <div className="table_container container_no_footer">
            {
                showLoan.show ?
                    <ShowLoan
                        close={() => setShowLoan({loan_id: 0, show: false})}
                        loan_id={showLoan.loan_id} accounts={params.accounts}/>
                    :
                    <Table celled striped compact size='small' inverted color='grey' selectable className="">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '70px'}}>Loan No</Table.HeaderCell>
                                <Table.HeaderCell className='time_row'>Date Created</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '160px'}}>Branch Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '190px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '110px'}}>Client Contact</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '190px'}}>Broker Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '110px'}}>Broker Contact</Table.HeaderCell>
                                <Table.HeaderCell className='amount_row'>Loan Principal</Table.HeaderCell>
                                <Table.HeaderCell className='amount_row'>Commission</Table.HeaderCell>
                                <Table.HeaderCell className='amount_row'>Commission Paid</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {/*
    payments: any[]
                    */}
                        <Table.Body>
                            {
                                sales.map((sale, index) =>
                                    <Table.Row key={sale.loan_id}
                                               onClick={() => {
                                                   if (sale.loan_id > 0) {
                                                       setShowLoan({loan_id: sale.loan_id, show: true});
                                                   }
                                               }}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">
                                            {(index < sales.length + 1) && (index + 1)}
                                        </Table.Cell>

                                        <Table.Cell style={{width: '70px'}} textAlign="center">
                                            {(index < sales.length + 1) && sale.loan_id}
                                        </Table.Cell>
                                        <Table.Cell className='time_row'>{Utils.localise_date(sale.date_created)}</Table.Cell>
                                        <Table.Cell style={{width: '160px'}}>{sale.office_name}</Table.Cell>
                                        <Table.Cell style={{width: '190px'}}>{sale.client_name}</Table.Cell>
                                        <Table.Cell style={{width: '110px'}}>{sale.main_contact}</Table.Cell>
                                        <Table.Cell style={{width: '190px'}}>{sale.broker_name}</Table.Cell>
                                        <Table.Cell style={{width: '110px'}}>{sale.broker_contact}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(sale.loan_principal)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(sale.broker_commission)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(sale.total_paid)}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
            }
        </div>
    )
}
