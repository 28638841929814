import {Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import React from "react";

import {Account} from "../../../../utils/Models";

export interface RcCharge {
    account_id: number
    amount: number
    client: string
    date: string
    fee: string
    loan_id: number
    mode: string
    charge_id: number
}

export default function RcCharges(params: { charges: RcCharge[], accounts: Account[] }) {
    return (
        <>
            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}} textAlign="center">Loan No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Client Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Charge Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Payment Account</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Payment Mode</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Charge Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '180px'}}>Date Collected</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.charges.map((charge, index) =>
                                <Table.Row key={`${charge.charge_id}_${charge.loan_id}`}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}} textAlign="center">{charge.loan_id}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{charge.client}</Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>{charge.fee}</Table.Cell>
                                    <Table.Cell style={{width: '160px'}}>
                                        {Utils.get_journal_account(charge.account_id, params.accounts)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '160px'}}>{charge.mode}</Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>{Utils.comma_number(charge.amount)}</Table.Cell>
                                    <Table.Cell style={{width: '180px'}}>{Utils.localise_date_time(charge.date)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
