import React from "react";
import {SelectData} from "../../../utils/Components";
import {InputFile} from "../../../utils/Utils";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {BranchUser} from "../../../nav/SideBar";
import TransferList from "./transfer/TransferList";
import TransferInfo from "./transfer/TransferInfo";
import {Account} from "../../../utils/Models";

export interface AccountTransfer {
    transfer_id: number
    account_from: number
    account_to: number
    user_id: number
    created_by: number
    office_id: number
    date_modified: string
    amount_transferred: number | string
    date_transferred: string
    time_transferred: string
    transfer_receipt: InputFile | null
}

export default function CashTransfers(params: {
    branchUser: BranchUser, branches: { select: SelectData[], selected: number[] }, accounts: Account[]
}) {
    const [transfers, setTransfers] = React.useState<Array<AccountTransfer>>([])
    const user = useAppSelector(getStaff)

    const initial_transfer: AccountTransfer = {
        created_by: user.staff.user_id, account_from: 0, account_to: 0, amount_transferred: "", date_transferred: '',
        time_transferred: '', date_modified: "", transfer_id: 0, transfer_receipt: null, office_id: 0, user_id: 0,
    }
    const [transfer, setTransfer] = React.useState(initial_transfer)

    return (
        <div className='row m-0 h-100'>
            <div className='col-12 col-md-8 p-1 h-100'>
                <TransferList
                    transfers={transfers} setTransfer={setTransfer} setTransfers={setTransfers}
                    accounts={params.accounts} branches={params.branches}/>
            </div>

            <div className='col-12 col-md-4 p-1 h-100'>
                <TransferInfo
                    transfer={transfer} initial={initial_transfer} branchUser={params.branchUser}
                    accounts={params.accounts} transfers={transfers} setTransfers={setTransfers} setTransfer={setTransfer}/>
            </div>
        </div>
    )
}
