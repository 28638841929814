import {Button, Table} from "semantic-ui-react";
import Utils from "../../../../../utils/Utils";
import {MyInput, MyLabel} from "../../../../../utils/Components";
import React from "react";
import {LoanInfo, LoanProps, TypeCharge} from "../../ShowLoan";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";

export default function ChargesAvailable(params: {
    loanInfo: LoanInfo, loanProps: LoanProps, charges: TypeCharge[],
    setCharges: (charges: TypeCharge[]) => void, setLoanInfo: (loanInfo: LoanInfo) => void
}) {
    const user = useAppSelector(getStaff)
    const calculate_loan_charges = () => {
        const charges: TypeCharge[] = params.loanProps.charges
            .filter((charge) => charge.type_id === params.loanInfo.loan_type?.type_id)
            .map((charge) => ({fee_id: 0, charge_id: charge.charge_id, charge_type: 'Flat', charge_value: 0, charge_amount: "",}))

        const principal: string = Utils.is_valid_number(Utils.strip_commas(params.loanInfo.loan_principal)) ?
            Utils.strip_commas(params.loanInfo.loan_principal) : "0"

        if (params.loanInfo.loan_type === null || !Utils.is_valid_number(principal)) {
            params.setCharges(charges)
        } else {
            const loan_charges = params.loanProps.charges
                .filter((charge) => charge.type_id === params.loanInfo.loan_type?.type_id)
            params.setCharges(
                charges.map((charge) => {
                    let loan_charge = loan_charges.filter((loan_charge) => loan_charge.charge_id === charge.charge_id)

                    if (loan_charge.length === 1) {
                        let fees = loan_charge[0].fees
                            .filter((fee) => parseFloat(principal) >= fee.min_value)
                            .sort((a, b) => b.min_value - a.min_value)
                        if (fees.length > 0) {
                            return {
                                ...charge, charge_type: fees[0].charge_type, charge_value: fees[0].charge_value,
                                charge_amount: fees[0].charge_type === "Flat" ? fees[0].charge_value.toString() :
                                    ((fees[0].charge_value * parseFloat(principal)) / 100).toString()
                            }
                        }
                    }
                    return charge
                }))
        }
    }

    const update_loan_charges = () => {
        if (params.loanInfo.loan_status === "pending") {
            params.setLoanInfo({
                ...params.loanInfo,
                charges: params.charges
                    .filter((aCharge) =>
                        Utils.is_valid_number(Utils.strip_commas(aCharge.charge_amount)) &&
                        parseFloat(Utils.strip_commas(aCharge.charge_amount)) > 0)
                    .map((aCharge) => ({
                        cashier_name: "",
                        charge_amount: parseFloat(Utils.strip_commas(aCharge.charge_amount)),
                        charge_type: aCharge.charge_type,
                        charge_value: parseFloat(Utils.strip_commas(aCharge.charge_amount)),
                        date_paid: "",
                        charge_id: aCharge.charge_id,
                        fee_name: params.loanProps.charges.filter((lCharge) => lCharge.charge_id === aCharge.charge_id)[0]?.fee_name
                    }))
            })
        }
    }

    React.useEffect(() => {
        calculate_loan_charges()
    }, [params.loanInfo.loan_type, params.loanInfo.loan_principal])

    React.useEffect(() => {
        update_loan_charges()
    }, [params.charges])

    const get_charge_value = (charge_id: number): string => {
        const principal: string = Utils.is_valid_number(Utils.strip_commas(params.loanInfo.loan_principal)) ?
            Utils.strip_commas(params.loanInfo.loan_principal) : "0"
        const charges = params.charges.filter((charge: TypeCharge) => charge.charge_id === charge_id)
        if (charges.length === 1) {
            return Utils.comma_number(charges[0].charge_type === 'Flat' ?
                charges[0].charge_value : (charges[0].charge_value * parseFloat(principal)) / 100
            )
        }
        return "N/A"
    }

    const get_charge = (charge_id: number): string => {
        const charges = params.charges.filter((charge) => charge.charge_id === charge_id)
        if (charges.length === 1) {
            return charges[0].charge_amount
        }
        return "N/A"
    }

    return (
        <>
            <div style={{height: '50px', marginBottom: '1px'}}>
                <MyLabel title={"Charges available for loan"}/>
            </div>

            <div className="table_container" style={{height: 'auto'}}>
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '160px'}}>Charge Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Charge Value</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Amount Charged</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.loanProps.charges
                                .filter((charge) => charge.type_id === params.loanInfo.loan_type?.type_id)
                                .map((charge, index) =>
                                    <Table.Row key={charge.charge_id}>
                                        <Table.Cell style={{width: '40px'}} textAlign="center">
                                            <Button
                                                primary size='mini' icon='add' compact
                                                disabled={
                                                    params.loanInfo.loan_status === 'pending' ||
                                                    params.loanInfo.charges
                                                        .map((aCharge) => (aCharge.charge_id))
                                                        .includes(charge.charge_id) ||
                                                    !Utils.is_valid_number(Utils.strip_commas(get_charge(charge.charge_id))) ||
                                                    parseFloat(Utils.strip_commas(get_charge(charge.charge_id))) <= 0 ||
                                                    !user.roles.core_roles?.includes("modify_loan_charges")
                                                }
                                                onClick={() => {
                                                    params.setLoanInfo({
                                                        ...params.loanInfo,
                                                        charges: [...params.loanInfo.charges, {
                                                            cashier_name: "",
                                                            charge_amount: parseFloat(Utils.strip_commas(get_charge(charge.charge_id))),
                                                            charge_type: params.charges.filter((aCharge: TypeCharge) =>
                                                                charge.charge_id === aCharge.charge_id)[0].charge_type,
                                                            charge_value: parseFloat(Utils.strip_commas(get_charge_value(charge.charge_id))),
                                                            date_paid: "",
                                                            charge_id: charge.charge_id,
                                                            fee_name: params.loanProps.charges.filter((lCharge) =>
                                                                lCharge.charge_id === charge.charge_id)[0]?.fee_name

                                                        }]
                                                    })
                                                }}/>
                                        </Table.Cell>
                                        <Table.Cell style={{width: '160px'}}>{charge.fee_name}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{get_charge_value(charge.charge_id)}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>
                                            <MyInput
                                                placeholder="Enter charge" name="" className="mb-0"
                                                value={Utils.comma_input(get_charge(charge.charge_id)).toString()}
                                                change={(text, value) => {
                                                    params.setCharges(
                                                        params.charges.map((_charge) => {
                                                            if (_charge.charge_id === charge.charge_id) {
                                                                return {..._charge, charge_amount: Utils.strip_commas(value)}
                                                            } else {
                                                                return {..._charge}
                                                            }
                                                        })
                                                    )
                                                }}/>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
