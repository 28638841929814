import Utils, {InputFile} from "../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../utils/Req";
import {Loading, loading_params, LoadingParams, MyDateInput, MyInput, MySelect, select_type} from "../../../utils/Components";
import React from "react";
import {Button, Form, Image, TextArea} from "semantic-ui-react";
import AllocationTable from "../../../utils/components/AllocationTable";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {Account, Allocation} from "../../../utils/Models";
import placeholder from "../../../../assets/images/placeholder.png"

export interface Expense {
    expense_id: number
    account_id: number
    expense_description: string
    expense_amount: string | number
    accountant_id: number
    accountant_name: string
    office_id: number
    office_name: string
    expense_date: string
    last_modified: string
    expense_receipt: InputFile | null
}

export default function AddExpense(params: {
    allocations: Allocation[], setAllocations: (allocations: Allocation[]) => void, accounts: Account[]
    expense: Expense, saveExpense: (expense: Expense) => void, clear: () => void
}) {
    const user = useAppSelector(getStaff)
    const [expense, setExpense] = React.useState(params.expense)
    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)

    const handle_change = (name: string, value: string | number) => {
        setExpense({...expense, [name]: value})
    }

    const save_expenses = () => {
        const sources = Utils.get_transaction_sources(params.allocations)
        const expense_amount = Utils.strip_commas(expense.expense_amount as string)

        if (!sources.has_error) {
            if (!Utils.is_valid_number(expense_amount)) {
                ToastsStore.error("Enter valid expense amount")
            } else if (expense.account_id === 0) {
                ToastsStore.error("Select an expense")
            } else if (sources.total !== parseFloat(expense_amount)) {
                ToastsStore.error(`Total amount from sources must be ${parseFloat(expense_amount).toLocaleString()}`)
            } else {
                setLoading({show: true, text: "Saving expense, please wait"})
                Req.save_expenses(
                    {
                        expense_amount: parseFloat(expense_amount),
                        expense_date: expense.expense_date,
                        expense_description: expense.expense_description,
                        account_id: expense.account_id,
                        sources: JSON.stringify(sources.source),
                        expense_id: expense.expense_id,
                        expense_receipt: expense.expense_receipt,
                        office_id: expense.office_id,
                    })
                    .then((response) => {
                        setLoading(loading_params)
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                const aExpense = {
                                    ...expense, expense_id: response.data.expense_id,
                                    last_modified: response.data.last_modified
                                }
                                params.saveExpense(aExpense)
                                setExpense(aExpense)
                                params.setAllocations((response.data.allocations as Allocation[]).filter((allocation) => allocation.balance > 0))
                                ToastsStore.success("Successfully saved expense")
                            } else if (response.data.code === 2) {
                                ToastsStore.error("Could not locate source of expense")
                            } else if (response.data.code === 3) {
                                ToastsStore.error(`Only ${response.data.balance.toLocaleString()} is left for ${response.data.account}`)
                            }
                        } else {
                            ToastsStore.error("Could not find expense to be modified")
                        }
                    })
                    .catch(() => {
                        setLoading(loading_params)
                        ToastsStore.error("Could not find expense to be modified")
                    })
            }
        }
    }

    React.useEffect(() => {
        setExpense(params.expense)
    }, [params.expense])

    return (
        <>
            <Loading show={loading.show} text={loading.text} error={loading.error}/>

            <div className="form">
                <div className="form_header">
                    {
                        expense.expense_id === 0 ? "Create new expense"
                            : `Change for ${Utils.get_journal_account(expense.account_id, params.accounts)}`
                    }
                </div>

                <div className="form_content">
                    <div className="mb-2">
                        <div style={{height: '150px'}}>
                            <Image centered src={expense.expense_receipt === null ? placeholder : expense.expense_receipt.file_src}/>
                        </div>
                        <div className="mt-2">
                            <input type="file" className="input_file" id="expense_receipt" accept="image/*"
                                   onChange={(event) => {
                                       const files = event.target.files;
                                       if (files !== null && files.length > 0) {
                                           const file: File | null = files.item(0);
                                           if (file !== null) {
                                               Utils.format_file(file as File)
                                                   .then(result => {
                                                       setExpense({...expense, 'expense_receipt': result})
                                                   })
                                           }
                                       }
                                   }}/>
                            <label htmlFor="expense_receipt" className="ui tiny primary button fluid">
                                <i className="ui upload icon"/>
                                Select receipt of expense if any
                            </label>
                        </div>
                    </div>

                    <MyInput
                        placeholder="Created By" title="Created By" name="accountant_name" value={expense.accountant_name}/>

                    <AllocationTable allocations={params.allocations} setAllocations={params.setAllocations}/>

                    <div style={{marginBottom: '15px'}}>
                        <MyDateInput
                            title="Select Date of Expense" value={expense.expense_date} name="expense_date"
                            change={handle_change} placeholder="Set date of expense" maxDate={Utils.today()}/>
                    </div>

                    <MyInput
                        placeholder="Enter expense amount" title="Amount of Expense" name="expense_amount"
                        value={Utils.comma_input(expense.expense_amount).toString()} change={handle_change}/>

                    <MySelect
                        change={(value: select_type) => handle_change('account_id', (value as number))}
                        title="Select expense account name" value={expense.account_id} disabled={expense.expense_id > 0}
                        options={[
                            {text: "Select an expense account", value: 0},
                            ...params.accounts.map((aAccount) => ({text: aAccount.account_name, value: aAccount.account_id}))
                        ]}/>

                    <Form>
                        <TextArea
                            placeholder='Describe the expense' rows={3} value={expense.expense_description}
                            onChange={((event, data) => {
                                handle_change('expense_description', data.value as string)
                            })}/>
                    </Form>
                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative onClick={params.clear} content="Clear Data"
                                    icon="trash" size="mini" fluid labelPosition="left"/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button
                                positive onClick={save_expenses} content="Save Expense"
                                icon="save" size="mini" fluid labelPosition="left"
                                disabled={expense.expense_id > 0 && expense.accountant_id !== user.staff.user_id}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
