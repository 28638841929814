import {Button, Form, Modal, Table, TextArea} from "semantic-ui-react";
import Utils from "../../../../../utils/Utils";
import React from "react";
import {LoanInfo} from "../../ShowLoan";
import {Loading, MyLabel} from "../../../../../utils/Components";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";
import {Req} from "../../../../../utils/Req";
import {ToastsStore} from "react-toasts";

export default function ChargesAdded(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [modify, setModify] = React.useState({show: false, reason: ""})

    const update_loan_charges = () => {
        if (modify.reason.trim().length < 10) {
            ToastsStore.error("Enter valid reason for modifying loan changes")
            return
        }
        setModify({...modify, show: false})

        const charges: { charge_id: number, charge_type: 'Flat' | 'Percentage', charge_value: number, charge_amount: number }[] =
            params.loanInfo.charges
                .filter((aCharge) => aCharge.date_paid === "")
                .map((aCharge) => ({
                    charge_type: aCharge.charge_type,
                    charge_id: aCharge.charge_id,
                    charge_amount: aCharge.charge_amount,
                    charge_value: aCharge.charge_value
                }))

        setLoading({message: "Modifying loan charges, please wait", show: true})
        Req.update_loan_charges({loan_id: params.loanInfo.loan_id, reason: modify.reason.trim(), charges: JSON.stringify(charges)})
            .then(response => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        ToastsStore.success("Loan charges have been saved successfully")
                    } else if (response.data.code === 2) {
                        ToastsStore.error("Your account has not been opened up for transactions")
                    } else if (response.data.code === 3) {
                        ToastsStore.error("Your transactions for today have been closed")
                    }
                } else {
                    ToastsStore.error("Error while saving loan, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while saving loan, please retry")
            })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={modify.show} basic size='mini' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Modify Loan Charges</div>

                    <div className="form_content">
                        <MyLabel title="Reason for modifying loan charges"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason for deleting loan' rows={3} value={modify.reason}
                                onChange={((event, data) => setModify({...modify, reason: data.value as string}))}/>
                        </Form>
                    </div>

                    <div className="form_footer mt-3">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button
                                    negative onClick={() => setModify({...modify, show: false})}
                                    content="Close Window" size='mini' fluid icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button
                                    positive onClick={update_loan_charges} content="Modify Loan" size='mini'
                                    fluid icon="trash" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <div style={{height: '50px', marginBottom: '1px'}}>
                <div className={'row mx-0'}>
                    <div className='col-8 pl-0 pr-1'>
                        <MyLabel title={"Charges added to loan"}/>
                    </div>
                    <div className='col-4 pl-0 pr-1'>
                        <Button
                            disabled={params.loanInfo.loan_status === 'pending'}
                            positive fluid size='tiny' icon="save" content={"Update Charges"}
                            onClick={() => setModify({reason: "", show: true})}/>
                    </div>
                </div>
            </div>

            <div className="table_container" style={{height: 'auto'}}>
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '40px'}} textAlign={'center'}/>
                            <Table.HeaderCell style={{width: '120px'}}>Charge Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Amount Charged</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Received By</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.loanInfo.charges
                                .map((charge) =>
                                    <Table.Row key={charge.charge_id}>
                                        <Table.Cell style={{width: '40px'}} textAlign={'center'}>
                                            <Button
                                                primary size='mini' icon='trash' compact
                                                disabled={
                                                    params.loanInfo.loan_status === 'pending' || charge.date_paid !== "" ||
                                                    !user.roles.core_roles?.includes("modify_loan_charges")
                                                }
                                                onClick={() => {
                                                    params.setLoanInfo({
                                                        ...params.loanInfo,
                                                        charges: params.loanInfo.charges.filter((aCharge) =>
                                                            aCharge.charge_id !== charge.charge_id)
                                                    })
                                                }}/>
                                        </Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{charge.fee_name}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{Utils.comma_number(charge.charge_amount)}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{charge.cashier_name}</Table.Cell>
                                    </Table.Row>
                                )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
