import {BranchUser} from "../../../nav/SideBar";
import {Account} from "../../../utils/Models";
import React from "react";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import DrawingsList from "./drawings/DrawingsList";
import DrawingsInfo from "./drawings/DrawingsInfo";

export interface Drawing {
    drawings_id: number
    drawn_by: number
    created_by: number
    account_id: number
    amount_drawn: number | string
    date_drawn: string
    time_drawn: string
    drawing_type: 'cash_in' | 'cash_out'
    date_created: string
    descriptions: string
}

export default function DirectorDrawings(params: { branchUser: BranchUser, accounts: Account[] }) {
    const user = useAppSelector(getStaff)
    const initial_drawing: Drawing = {
        account_id: 0, amount_drawn: "", created_by: user.staff.user_id, date_created: "", date_drawn: "",
        descriptions: "", drawing_type: 'cash_out', drawings_id: 0, drawn_by: 0, time_drawn: ""
    }

    const [drawing, setDrawing] = React.useState(initial_drawing)
    const [drawings, setDrawings] = React.useState(Array<Drawing>())

    return (
        <div className='row m-0 h-100'>
            <div className='col-12 col-md-8 p-1 h-100'>
                <DrawingsList
                    drawings={drawings} setDrawings={setDrawings} branchUser={params.branchUser}
                    accounts={params.accounts} setDrawing={setDrawing}/>
            </div>

            <div className='col-12 col-md-4 p-1 h-100'>
                <DrawingsInfo
                    drawing={drawing} setDrawings={setDrawings} drawings={drawings} setDrawing={setDrawing}
                    initial={initial_drawing} accounts={params.accounts} branchUser={params.branchUser}/>
            </div>
        </div>
    )
}
