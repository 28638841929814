import React from "react";
import {Req} from "../utils/Req";
import Utils from "../utils/Utils";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {getFCM, getStaff, updateCoreRoles, updateFCM, updateReportRights, updateUserRoles} from "../store/slices/userSlice";
import axios from "axios";
import {addNotification, AppNotification, SystemNotification} from "../store/slices/systemSlice";
import {FirebaseApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";


export default function Firebase(params: { firebaseApp: FirebaseApp }) {
    const dispatch = useAppDispatch()
    const user = useAppSelector(getStaff)
    const fcm = useAppSelector(getFCM)
    const messaging = getMessaging(params.firebaseApp);

    const init = () => {
        getToken(messaging,
            {vapidKey: 'BOvt3ZgWBvuioz9HigugjosiYZWEyCeU7qKrwyLzarmyW4nU_J-y-8nT6Y0WTk6bPRLbspZdYRYnsRqBF2xshak'})
            .then((currentToken) => {
                if (currentToken) {
                    dispatch(updateFCM({token: currentToken, saved: false}))
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // catch error while creating client token
            });
    }

    onMessage(messaging, function (payload) {
        const data = payload.data;
        console.log(data)

        if (data !== undefined) {
            if (data.hasOwnProperty("notification_id")) {
                dispatch(addNotification(data as unknown as AppNotification))
            } else {
                const notification = data as unknown as SystemNotification

                if (notification.notification_type === "access_rights_update") {
                    dispatch(updateUserRoles(JSON.parse(notification.notification_data)))
                } else if (notification.notification_type === "report_rights_update") {
                    dispatch(updateReportRights(JSON.parse(notification.notification_data)))
                } else if (notification.notification_type === "core_rights_update") {
                    dispatch(updateCoreRoles(JSON.parse(notification.notification_data)))
                }
            }
            Utils.display_notification(data as unknown as { notification_title: string, notification_message: string })
        }
    })

    React.useEffect(() => {
        const send_token_to_server = () => {
            axios.defaults.headers['common']['Authorization'] = `Bearer ${user.user_token}`
            console.log("FCM Token", fcm.token)
            Req.update_fcm_token({fcm_token: fcm.token})
                .then((response) => {
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        dispatch(updateFCM({...fcm, saved: true}))
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        if (fcm.token !== "" && !fcm.saved && user.user_token !== "") {
            send_token_to_server()
        }
    }, [fcm, user.user_token])

    React.useEffect(() => {
        document.onclick = function () {
            if (Notification.permission !== 'granted') {
                Notification.requestPermission()
                    .then((permission) => {
                        if (permission === 'granted') {

                        }
                    });
            }
        };
        init()
    }, []);
    return (<></>)
}
