import React from "react"
import Tab from "semantic-ui-react/dist/commonjs/modules/Tab";
import {CalculateInterest} from "./CalculateInterest";
import {Modal} from "semantic-ui-react";
import CalculatePeriod from "./CalculatePeriod";
import {intervals, rate_types} from "../../../../admin/loans/LoanTypes";
import {Schedule} from "../schedule/PaymentScheduleIdeal";

export interface CalculatedSchedule {
    period: string
    principal: string
    expected: string
    interval: intervals
    start: string
    end: string
    payment: string
    rate_type: rate_types
}

export default function LoanCalculator(
    props: { result: (data?: CalculatedSchedule, schedules?: Array<Schedule>, interestRate?: number) => void, show: boolean }
) {
    const panes = [
        {menuItem: 'Interest Rate Calculator', pane: {key: 'interest_rate', content: <CalculateInterest result={props.result}/>}},
        {menuItem: 'Loan Period Calculator', pane: {key: 'loan_period', content: <CalculatePeriod result={props.result}/>}}
    ]

    return (
        <Modal size='small' centered={false} open={props.show} onClose={() => props.result()} closeOnDimmerClick={false}>
            <div className="calculator_container">
                <Tab panes={panes} renderActiveOnly={false}/>
            </div>
        </Modal>
    )
}
