import React from "react"
import Utils from "../../utils/Utils";
import {Loading, loading_params, LoadingParams, MyDateInput, MySelect, select_type} from "../../utils/Components";
import {Button, Table} from "semantic-ui-react";
import {Req} from "../../utils/Req"
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import moment from "moment";
import TillSummaries from "./TillSummaries";
import {BranchUser} from "../../nav/SideBar";
import TillAccounts, {initial_till_account, TillAccount} from "./TillAccounts";
import {Account} from "../../utils/Models";
;
export interface TillTransaction {
    account: string
    date: string
    list: Transaction[]
    office_name: string
    state: "+" | "-"
    total: number
    username: string
    store: string
    account_id: number
}

interface Transaction {
    type: string
    amount: number
}

interface Row {
    span?: number
    state?: "+" | "-"
    date?: string
    username?: string
    account?: string
    type: string
    amount: number
    total?: number
    store?: string
    account_id?: number
}

interface Search {
    date: string
    user_id: number
    office_id: number
    account_id: number
}

export interface TillSummary {
    loan: {
        allocated: { [key: number]: number }
        disbursed: { [key: number]: number }
        collected: { [key: number]: number }
    }
    expenses: {
        allocated: { [key: number]: number }
        disbursed: { [key: number]: number }
    }
    petty: {
        allocated: { [key: number]: number }
        disbursed: { [key: number]: number }
    }
}

const initial_summary: TillSummary = {
    loan: {allocated: {}, collected: {}, disbursed: {}},
    expenses: {allocated: {}, disbursed: {}},
    petty: {allocated: {}, disbursed: {}}
}

export default function TillBalances(params: { branchUsers: BranchUser, accounts: Account[] }) {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)

    const [search, setSearch] = React.useState<Search>({date: Utils.today(), user_id: 0, office_id: 0, account_id: 0})
    const [tillAccounts, setTillAccounts] = React.useState(Array<TillAccount>())
    const [tillSummaries, setTillSummaries] = React.useState(initial_summary)
    const [tillTransactions, setTillTransactions] = React.useState<Array<TillTransaction>>([])
    const [rows, setRows] = React.useState<Array<Row>>([])

    const [showSummary, setShowSummary] = React.useState({show: false, tillAccount: initial_till_account})
    const [showAccounts, setShowAccounts] = React.useState(false)

    const get_till_accounts = () => {
        setTillAccounts([])
        setLoading({show: true, text: "Loading opened up tills for the day"})
        Req.get_allocation_accounts({till_date: search.date})
            .then((response) => {
                setLoading({show: false, text: ""})
                if (response.data.hasOwnProperty("accounts")) {
                    setTillAccounts(response.data.accounts)
                } else {
                    ToastsStore.error("Could not load opened tills for the day")
                }
            })
            .catch(() => {
                setLoading({show: false, text: ""})
                ToastsStore.error("Could not load opened tills for the day")
            })
    }

    const get_user_transactions = () => {
        setLoading({show: true, text: "Loading user transactions, please wait"})

        Req.get_user_transactions({
            transaction_date: search.date, transaction_user: search.user_id, office_id: search.office_id
        })
            .then((response) => {
                setLoading({show: false, text: ""})
                if (response.data.hasOwnProperty("transactions")) {
                    setTillTransactions(response.data.transactions)
                    setTillSummaries(response.data.summaries)
                } else {
                    ToastsStore.error("Could not get user transactions")
                }
            })
            .catch(() => {
                setLoading({show: false, text: ""})
                ToastsStore.error("Could not get user transactions")
            })
    }

    React.useEffect(() => {
        setTillTransactions([])
        if (search.user_id > 0) {
            get_user_transactions()
        }
    }, [search.user_id])

    React.useEffect(() => {
        get_till_accounts()
    }, [search.date])

    React.useEffect(() => {
        let aRows: Row[] = [];
        tillTransactions
            .filter((aList) => search.account_id === 0 || search.account_id === aList.account_id)
            .forEach((account) => {
                account.list.forEach((transaction, index) => {
                    aRows = [
                        ...aRows,
                        index === 0 ?
                            {
                                span: account.list.length,
                                state: account.state,
                                date: account.date,
                                username: account.username + (search.office_id === 0 ? `(${account.office_name})` : ''),
                                account: account.account,
                                type: transaction.type,
                                amount: transaction.amount,
                                total: account.total,
                                account_id: account.account_id,
                                store: account.store,
                            }
                            : {
                                type: transaction.type,
                                amount: transaction.amount,
                            }
                    ]
                })
            })
        setRows(aRows)
    }, [tillTransactions, search.account_id])

    return (
        <>
            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <TillSummaries
                branchUsers={params.branchUsers} show={showSummary.show} tillSummary={tillSummaries}
                tillAccount={showSummary.tillAccount} transactions={tillTransactions} accounts={params.accounts}
                close={() => setShowSummary({show: false, tillAccount: initial_till_account})}
                setTillAccount={(aAccount) => {
                    setShowSummary({...showSummary, tillAccount: aAccount})
                    setTillAccounts(tillAccounts.map((bAccount) => bAccount.user_id === aAccount.user_id ? bAccount : aAccount))
                }}/>

            <TillAccounts
                till_date={search.date} show={showAccounts} tillAccounts={tillAccounts} branchUser={params.branchUsers}
                update={(aAccount) => setTillAccounts([...tillAccounts, aAccount])}
                close={() => setShowAccounts(false)}/>

            <>
                <div className="content_bar">
                    <div className="search_bar">
                        <div>
                            <MyDateInput
                                title="" value={search.date} name="date" placeholder="Select calendar date" maxDate={Utils.today()}
                                change={(name, value) => setSearch({...search, [name]: value})}/>
                        </div>
                        <div>
                            <MySelect
                                title="" name="office_id" value={search.office_id}
                                change={(value: select_type) => {
                                    setTillTransactions([])
                                    setSearch({...search, user_id: 0, office_id: value as number})
                                }}
                                options={[
                                    {text: "View all offices", value: 0},
                                    ...tillAccounts
                                        .map((aAccount) => (aAccount.office_id))
                                        .filter((id, index, ids) => ids.indexOf(id) === index)
                                        .map((branchID) => ({
                                            text: params.branchUsers.branches.filter((aBranch) =>
                                                aBranch.office_id === branchID)[0].office_name,
                                            value: branchID
                                        }))
                                ]}/>
                        </div>
                        <div>
                            <MySelect
                                change={(value: select_type) => setSearch({...search, user_id: value as number})}
                                title="" name="user_id" value={search.user_id}
                                options={[
                                    {text: "Select a user", value: 0},
                                    ...tillAccounts
                                        .filter((aAccount) => search.office_id === 0 || aAccount.office_id === search.office_id)
                                        .filter((aAccount) =>
                                            aAccount.user_id === user.staff.user_id ||
                                            user.roles.core_roles?.includes("till_closing"))
                                        .map((aAccount) => ({
                                            value: aAccount.user_id,
                                            text: params.branchUsers.users.filter((aUser) =>
                                                aUser.user_id === aAccount.user_id)[0].user_name
                                        }))
                                ]}/>
                        </div>
                        <div>
                            <MySelect
                                change={(value: select_type) => setSearch({...search, account_id: value as number})}
                                title="" name="account_id" value={search.account_id}
                                options={[
                                    {text: "View all accounts", value: 0},
                                    ...params.accounts.map((aAccount) =>
                                        ({value: aAccount.account_id, text: aAccount.account_name}))
                                ]}/>
                        </div>
                    </div>
                    <div className="button_bar">
                        <Button
                            floated='right' size='mini' icon='users' content="Till Accounts" primary labelPosition="left"
                            disabled={!user.roles.core_roles?.includes("till_allocation")}
                            onClick={() => setShowAccounts(true)}/>

                        <Button
                            floated='right' size='mini' content="Till Summary" disabled={search.user_id === 0}
                            onClick={() => setShowSummary({
                                show: true,
                                tillAccount: tillAccounts.filter((aAccount) => aAccount.user_id === search.user_id)[0]
                            })}
                            labelPosition="left" primary icon="info circle"/>

                        <Button
                            floated='right' size='mini' icon='search' disabled={search.user_id === 0}
                            onClick={get_user_transactions} content="Search" primary labelPosition="left"/>
                    </div>
                </div>

                <div className="table_container container_no_footer">
                    <Table celled striped compact size='small' inverted structured color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '80px'}}>Time</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Cashier Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '250px'}}>Account Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '170px'}}>Transaction Account</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '200px'}}>Transaction Reason</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Transaction</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Total Amount</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                rows.map((row, index) => {
                                    return row.span === undefined ?
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '200px'}} rowSpan={1}>{row.type}</Table.Cell>
                                            <Table.Cell style={{width: '130px'}} rowSpan={1}>{Utils.comma_number(row.amount)}</Table.Cell>
                                        </Table.Row>
                                        :
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '80px'}} rowSpan={row.span}>
                                                {moment(row.date, 'YYYY-MM-DD hh:mm:ss').format("LTS")}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}} rowSpan={row.span}>{row.username}</Table.Cell>
                                            <Table.Cell style={{width: '250px'}} rowSpan={row.span}>{row.account}</Table.Cell>
                                            <Table.Cell style={{width: '170px'}} rowSpan={row.span}>{row.store}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}} rowSpan={1}>{row.type}</Table.Cell>
                                            <Table.Cell style={{width: '130px'}} rowSpan={1}>{Utils.comma_number(row.amount)}</Table.Cell>
                                            <Table.Cell style={{width: '130px', color: row.state === "+" ? '#045A04' : '#890C0C', fontWeight: '600'}}
                                                        rowSpan={row.span}>
                                                {Utils.comma_number(row.total as number)}
                                            </Table.Cell>
                                        </Table.Row>
                                })
                            }
                        </Table.Body>
                    </Table>
                </div>
            </>
        </>
    )
}
