import {Table} from "semantic-ui-react";
import Utils from "../../../../../utils/Utils";
import React from "react";
import {LoanDetail} from "../LoansDetails";

export default function CreditorLoanSchedule(params: { loanDetail: LoanDetail }) {
    return (
        <Table celled striped compact size='small' inverted color='grey' selectable>
            <Table.Header fullWidth>
                <Table.Row key={1}>
                    <Table.HeaderCell style={{width: '50px'}} rowSpan={2} textAlign="center">No.</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Payment Date</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '120px'}} colSpan={1} textAlign="center">Expectations</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '300px'}} colSpan={3} textAlign="center">Payments Made</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Balance After</Table.HeaderCell>
                </Table.Row>
                <Table.Row key={2}>
                    <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Principal</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '100px'}}>Principal</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '100px'}}>Interest</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '100px'}}>Total</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    params.loanDetail.schedule.map((schedule, index) =>
                        <Table.Row key={index}>
                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(schedule.payment_date)}</Table.Cell>
                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(schedule.principal_payable)}</Table.Cell>
                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(schedule.principal_paid)}</Table.Cell>
                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(schedule.interest_paid)}</Table.Cell>
                            <Table.Cell style={{width: '100px'}}>
                                {Utils.comma_number(schedule.principal_paid + schedule.interest_paid)}
                            </Table.Cell>
                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(schedule.balance_after)}</Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>
        </Table>
    )
}
