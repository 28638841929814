import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";

export interface ListingLoan {
    branch: string
    client: string
    date_disbursed: string
    due_date: string
    last_amount: number
    last_date: number
    total_balance: number
    loan_id: number
    loan_principal: number
    loan_rate: number
    loan_span: number
    loan_period: number
    overdue: string
}

export default function ListingTable(params: { loans: ListingLoan[], click: (loan_id: number) => void }) {
    const get_width = (loan: ListingLoan) => {
        const spanned = loan.loan_span > loan.loan_period ? loan.loan_period : loan.loan_span
        const red_percentage = parseInt(((spanned / loan.loan_period) * 100).toFixed(0));
        return `${red_percentage}%`
    }

    return (
        <Table celled striped compact size='small' selectable inverted color='grey'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">Loan</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '140px'}}>Branch Name</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '150px'}}>Client Name</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '120px'}}>Date Disbursed</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '110px'}}>Due Date</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '100px'}}>Overdue</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '70px'}} textAlign="center">Rate</Table.HeaderCell>
                    <Table.HeaderCell className="amount_row">Loan Principal</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '130px'}}>Total Balance</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '90px'}}>Received</Table.HeaderCell>
                    <Table.HeaderCell className="amount_row">Amount Paid</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    params.loans.map((loan, index) =>
                        <Table.Row key={loan.loan_id} onClick={() => params.click(loan.loan_id)}>
                            <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                            <Table.Cell style={{width: '50px'}} textAlign="center">{loan.loan_id}</Table.Cell>
                            <Table.Cell style={{width: '140px'}}>{loan.branch}</Table.Cell>
                            <Table.Cell style={{width: '150px'}}>{loan.client}</Table.Cell>
                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(loan.date_disbursed)}</Table.Cell>
                            <Table.Cell className='spanned_color' style={{width: '110px'}}>
                                <div className='days_spanned'
                                     style={loan.total_balance === 0 ? {backgroundColor: 'var(--colorPrimaryDark)'} : {width: get_width(loan)}}/>
                                <div className='date'> {Utils.localise_date(loan.due_date)}</div>
                            </Table.Cell>
                            <Table.Cell style={{width: '100px'}}>{loan.overdue}</Table.Cell>
                            <Table.Cell style={{width: '70px'}} textAlign="center">
                                {loan.loan_rate.toFixed(2)} %
                            </Table.Cell>
                            <Table.Cell className="amount_row">{Utils.comma_number(loan.loan_principal)}</Table.Cell>
                            <Table.Cell style={{width: '130px'}}>{Utils.comma_number(loan.total_balance)}</Table.Cell>
                            <Table.Cell style={{width: '90px'}}>
                                {loan.last_amount > 0 ? `${loan.last_date} days ago` : ''}
                            </Table.Cell>
                            <Table.Cell className="amount_row">
                                {loan.last_amount > 0 ? Utils.comma_number(loan.last_amount) : ''}
                            </Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>
        </Table>
    )
}
