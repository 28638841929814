declare var JSPM: any;

interface Receipt {
    company: { name: string, location: string, box: string, email: string, contacts: string }
    payments: { name: string, amount: string }[],
    payment: {
        cashier: { name: string }
        client: { name: string, contact: string }
        total: number
        time: string
    }
}

export default class JSPrinter {
    static print_receipt(params: { response: any }) {
        const receipt: Receipt = params.response.receipt

        let commands = ""
        commands += "\x1b\x40" //Initialize printer
        commands += `\x1b\x61\x01`// Select justification: Centering
        commands += "\x1b\x45\x01" //Enable bold mode
        commands += "\x1b\x21\x10\x01" //FontSize
        commands += `${receipt.company.name}\x0a`
        commands += "\x1b\x21\x00\x00" //FontSize
        commands += receipt.company.location
        commands += `\x0a`
        commands += `${receipt.company.contacts}`
        commands += `\x0a`
        commands += receipt.company.box
        commands += `\x0a`
        commands += "\x1b\x45\x00" //Disable bold mode


        commands += `\x1b\x64\x01`
        commands += `\x1b\x61\x01`// Select justification: Centering
        commands += "\x1b\x45\x01" //Enable bold mode
        commands += "\x1b\x21\x10\x01" //FontSize
        commands += "\x1b\x2d\x02" //Enable underline
        commands += `PAYMENT RECEIPT`
        commands += "\x1b\x2d\x00" //Disable underline
        commands += "\x1b\x45\x00" //Disable bold mode
        commands += "\x1b\x21\x00\x00" //FontSize
        commands += `\x1b\x64\x01`

        commands += `\x1b\x64\x01`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)
        commands += `\x1b\x61\x00`// Select justification: Left
        receipt.payments.forEach((field) => {
            commands += field.name
            commands += `\x09`// Horizontal Tab
            commands += "\x1b\x45\x01" //Enable bold mode
            commands += `UGX ${field.amount}`
            commands += "\x1b\x45\x00" //Enable bold mode
            commands += `\x0a`
        })

        commands += `\x1b\x64\x02`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)

        commands += `\x1b\x61\x01`// Select justification: Centering
        commands += "\x1b\x45\x01" //Enable bold mode
        commands += "\x1b\x21\x20\x02" //FontSize
        commands += `UGX ${receipt.payment.total}\x0a`
        commands += "\x1b\x45\x01" //Disable bold mode
        commands += "\x1b\x21\x00\x00" //FontSize

        commands += `\x1b\x64\x05`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)
        commands += "\x1b\x69" //Execute paper full cut

        JSPrinter.print_data(commands)
    }

    static print_data(commands: string) {
        JSPM.JSPrintManager
            .start()
            .then(() => {
                if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
                    const printJob = new JSPM.ClientPrintJob();
                    printJob.clientPrinter = new JSPM.UserSelectedPrinter()
                    printJob.printerCommands = commands;

                    printJob.sendToClient()
                        .catch((error: any) => {
                            console.log(error)
                        })
                } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
                    alert("JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm")
                } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
                    alert("JSPM has blocked this website")
                } else {
                    alert("Could not get printer status")
                }
            });
    }
}
