import {Req} from "../../../../../utils/Req";
import Utils from "../../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Loading, MyLabel} from "../../../../../utils/Components";
import React from "react";
import {LoanInfo} from "../../ShowLoan";
import {Button, Form, Modal, TextArea} from "semantic-ui-react";

export default function ReversePayment(params: {
    loan_id: number, payment_id: number, close: () => void, setLoanInfo: (loanInfo: LoanInfo) => void
}) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [reverseReason, setReverseReason] = React.useState("")

    const reverse_payment = () => {
        if (reverseReason.trim().length < 15) {
            ToastsStore.error("Enter a valid reason for reversing payment at least 15 characters")
        } else {
            setLoading({message: "Reversing transaction, please wait", show: true})
            Req.reverse_payment({payment_id: params.payment_id, reason: reverseReason.trim(), loan_id: params.loan_id})
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            params.setLoanInfo(Utils.format_loan_data(response))
                            params.close()
                            ToastsStore.success("Payment has been reversed successfully")
                        } else if (response.data.code === 503) {
                            params.close()
                            ToastsStore.info(`This service is not yet available at the moment`)
                        }
                    } else {
                        ToastsStore.error("Could not reverse payment, please retry")
                    }
                })
                .catch(() => {
                    ToastsStore.error("Could not reverse payment, please retry")
                    setLoading({message: "", show: false})
                })
        }
    }

    React.useEffect(() => {
        setReverseReason("")
    }, [params.payment_id])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={params.payment_id > 0} basic size='mini' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Reverse Payment</div>

                    <div className="form_content">
                        <MyLabel title="Reason for reversing payment"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason for reversing payment' rows={3} value={reverseReason}
                                onChange={((event, data) => setReverseReason(data.value as string))}/>
                        </Form>
                    </div>

                    <div className="form_footer mt-3">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative onClick={() => params.close()} content="Close Window" size='mini' fluid
                                        icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={reverse_payment} content="Reverse Payment" size='mini' fluid
                                        icon="save" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
