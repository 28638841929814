import {Button, Modal} from "semantic-ui-react"
import React from "react"
import Utils from "../../../utils/Utils";
import {MyDateInput, MyLabel, SelectData} from "../../../utils/Components";
import {MultiSelect} from "../../../utils/components/MultiSelect";
import {MultiUser} from "../../admin/users/Users";
import {ToastsStore} from "react-toasts";

export interface IncomesCriteria {
    min_date: string
    max_date: string
    branches: []
}

export default function IncomeCriteria(params: {
    show: boolean, close: () => void, generate: () => void, offices: Array<SelectData>, users: Array<MultiUser>,
    criteria: IncomesCriteria, handle_change: (criteria: IncomesCriteria) => void
}) {
    const handle_change = (name: string, value: string | number | Array<number | string>) => {
        params.handle_change({...params.criteria, [name]: value})
    }

    const generate_report = () => {
        if (params.criteria.branches.length === 0) {
            ToastsStore.error("Select at least one branch")
        } else {
            params.generate()
        }
    }

    return (
        <Modal open={params.show} basic size='mini' centered={false} onClose={params.close}>
            <div className='modal_div modal_form'>
                <div className="form_content">
                    <div className="row m-0">
                        <div className="col-12 p-1">
                            <MyLabel title="Select branches"/>
                            <MultiSelect multiple={true} placeholder="Select offices" items={params.offices} selected={params.criteria.branches}
                                         change={(value) => handle_change('branches', value)}/>
                        </div>
                        <div className="col-12 p-1">
                            <MyDateInput
                                title="Minimum income Date" value={params.criteria.min_date} name="min_date"
                                maxDate={params.criteria.max_date} placeholder="Select minimum date" change={handle_change}/>
                        </div>
                        <div className="col-12 p-1">
                            <MyDateInput
                                title="Maximum income date" value={params.criteria.max_date} name="max_date"
                                maxDate={Utils.today()} minDate={params.criteria.min_date}
                                placeholder="Select maximum date" change={handle_change}/>
                        </div>
                    </div>
                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative onClick={params.close} content="Close Window" size='mini' fluid
                                    icon='close' labelPosition="left"/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive content="Load Report" size='mini' fluid icon="table" labelPosition="left"
                                    onClick={generate_report}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
