import React from "react";
import {Page, Req} from "../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Modal, Table} from "semantic-ui-react";
import Utils from "../../utils/Utils";
import {Loading, TablePagination} from "../../utils/Components";

interface Journal {
    client: string
    creation_type: string
    credit_amount: number
    date_created: string
    debit_amount: number
    entity_account: number
    entity_type: string
    journal_id: number
}

export default function ReportJournals(params: {
    account_id: number, min_date: string, max_date: string, close: () => void
}) {
    const [journals, setJournals] = React.useState<Journal[]>([])
    const [loader, setLoader] = React.useState({message: "", show: false})
    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 5000})

    const generate_report = (props: { page: number, pages: number }) => {
        const criteria = {
            account_id: params.account_id, min_date: params.min_date,
            max_date: params.max_date, pages: props.pages, page: props.page
        }
        setLoader({message: "Loading report details, please wait", show: true})

        Req.process_report({criteria: JSON.stringify(criteria)}, {action: 'journal_info', type: 'table'})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("report")) {
                    const _journals: Journal[] = response.data.report.journals
                    if (_journals.length === 0) {
                        ToastsStore.info("There are no journal data to display")
                    }
                    setJournals(_journals)
                    setPages(response.data.report.pages)
                } else {
                    ToastsStore.error("Could not generate journals statements, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not generate statements, please retry")
            })
    }

    React.useEffect(() => {
        if (params.account_id > 0) {
            setJournals([])
            setPages({page: 1, pages: 0, limit: 5000})
            generate_report({page: 1, pages: 0})
        }
    }, [params.account_id])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({message: "", show: false})}/>

            <Modal open={params.account_id > 0} basic size='large' centered={false} onClose={params.close} dimmer='blurring'>
                <div className='modal_div modal_form' style={{height: 'calc(100vh - 100px)'}}>
                    <div className="table_container" style={{height: 'calc(100% - 50px)', marginBottom: '10px'}}>
                        <Table celled striped inverted compact structured size='small' color='grey' className="has_total">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign={'center'}>No</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Creation Type</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Entity Type</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}}>A/C ID</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Account Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Credit Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Debit Amount</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    journals.map((report, index) =>
                                        (index === journals.length - 1 && pages.pages === pages.page) ?
                                            <Table.Row key={report.journal_id}>
                                                <Table.Cell style={{width: '720px'}} colSpan={6}></Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {report.debit_amount !== 0 && Utils.comma_number(report.debit_amount)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {report.credit_amount !== 0 && Utils.comma_number(report.credit_amount)}
                                                </Table.Cell>
                                            </Table.Row>
                                            :
                                            <Table.Row key={report.journal_id}>
                                                <Table.Cell style={{width: '50px'}} textAlign={'center'}>{index + 1}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{Utils.localise_date(report.date_created)}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{report.creation_type}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{report.entity_type}</Table.Cell>
                                                <Table.Cell style={{width: '70px'}}>
                                                    {report.entity_account > 0 ? report.entity_account : ''}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '250px'}}>{report.client}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {report.debit_amount !== 0 && Utils.comma_number(report.debit_amount)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {report.credit_amount !== 0 && Utils.comma_number(report.credit_amount)}
                                                </Table.Cell>
                                            </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="table_footer">
                        <TablePagination
                            total={pages.pages} change={(page: number) => generate_report({page: page, pages: pages.pages})}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}
