import {Modal} from "semantic-ui-react";
import React from "react";
import Utils from "../../../../utils/Utils";
import {SelectData} from "../../../../utils/Components";
import {LoanDetail} from "./LoansDetails";
import {Account} from "../../../../utils/Models";
import MakeCreditorPayment, {PayCreditor} from "./payment/MakeCreditorPayment";
import CreditorPaymentHistory from "./payment/CreditorPaymentHistory";

export interface CreditorPayment {
    payment_id: number
    loan_id: number
    paid_by: string
    user_id: number
    cashier_id: number
    interest_paid: number
    principal_paid: number
    time_paid: string
    date_created: string
    account_id: number
    reference_no: string
}

export function LoanPayments(params: {
    show: boolean, loan: LoanDetail, users: Array<SelectData>, user_id: number, hasRoles: boolean,
    update: (loanDetail: LoanDetail) => void, accounts: Account[], close: () => void
}) {
    const initial_payment: PayCreditor = {
        prepared_by: params.user_id, user_id: params.user_id, date_paid: Utils.today(), loan_id: params.loan.loan.loan_id,
        payment_id: 0, account_id: 0, reference_no: "", interest_paid: 0, principal_paid: 0, time_paid: ""
    }
    const [payment, setPayment] = React.useState(initial_payment)

    React.useEffect(() => {
        if (params.show) {
            setPayment(initial_payment)
        }
    }, [params.show])

    return (
        <Modal open={params.show} basic size='large' closeOnDocumentClick={true} centered={false} onClose={params.close}>
            <div className="modal_div creditor_payments">
                <div className="row m-0 h-100">
                    <div className="col-12 col-md-8 p-1 h-100">
                        <CreditorPaymentHistory
                            setPayment={setPayment} loanDetail={params.loan} update={params.update} hasRoles={params.hasRoles}/>
                    </div>

                    <div className="col-12 col-md-4 p-1 h-100">
                        <MakeCreditorPayment
                            loan={params.loan} accounts={params.accounts} payment={payment} close={params.close}
                            initial={initial_payment} update={params.update} users={params.users} hasRoles={params.hasRoles}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
