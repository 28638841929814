import React from "react";
import Utils from "../../../../../utils/Utils";
import {Loading, MyDateInput, MyInput, MySelect, select_type} from "../../../../../utils/Components";
import {Button, Table} from "semantic-ui-react";
import moment from "moment";
import {CalculatedSchedule} from "./LoanCalculator";
import {Schedule} from "../schedule/PaymentScheduleIdeal";
import {ToastsStore} from "react-toasts";

export default function CalculatePeriod(
    props: { result: (data?: CalculatedSchedule, schedules?: Array<Schedule>, interestRate?: number) => void }
) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [data, setData] = React.useState<CalculatedSchedule>({
        period: "0", principal: "", payment: "", expected: "0", interval: 30, start: Utils.today(), end: "", rate_type: 'compound'
    })
    const handle_change = (name: string, value: string | number) => {
        setData({...data, [name]: value})
    }
    const [rate, setRate] = React.useState("")
    const [schedules, setSchedules] = React.useState<Array<Schedule>>([])

    const use_data = () => {
        if (!Utils.is_valid_number(data.principal) || parseFloat(data.principal) <= 0) {
            ToastsStore.error("Provide a valid principal")
        } else if (!Utils.is_valid_number(data.payment) || parseFloat(data.payment) <= 0) {
            ToastsStore.error("Provide a valid amount to be paid")
        } else if (!Utils.is_valid_number(rate) || parseFloat(rate) < 0) {
            ToastsStore.error("Invalid interest rate provided")
        } else if (schedules.length === 0) {
            ToastsStore.error("No schedules have been calculated")
        } else {
            props.result(data, schedules, parseFloat(rate))
        }
    }

    React.useEffect(() => {
        function calculate() {
            const get_next_payment = (current: string) => {
                const date = moment(current + " 23:59:59", "YYYY-MM-DD hh:mm:ss")
                if (data.interval === 30) {
                    date.add(1, "month")
                } else {
                    date.add(data.interval, "days")
                }
                return date.format("YYYY-MM-DD")
            }

            setLoading({message: "Processing, please wait", show: true})
            const new_schedules: Array<Schedule> = []
            let total_expected = 0

            let installments = 1
            if (data.interval === 7) {
                installments = 4
            } else if (data.interval === 14) {
                installments = 2
            }

            if (Utils.is_valid_number(data.principal) && Utils.is_valid_number(data.payment) && Utils.is_valid_number(rate)
                && parseFloat(rate) >= 0 && parseFloat(data.principal) > 0 && parseFloat(data.payment) > 0) {
                let start_date = data.start

                for (let remaining = parseFloat(data.principal), month = 1; remaining > 0;) {
                    let installment_interest = (remaining * parseFloat(rate)) / 100
                    if (installment_interest >= parseFloat(data.payment)) {
                        break
                    }
                    let installment_principal = parseFloat(data.payment) - installment_interest
                    if (installment_principal > remaining) {
                        installment_principal = remaining
                    }
                    remaining = remaining - installment_principal

                    new_schedules.push({
                        month: month,
                        balance: remaining,
                        date: start_date,
                        interest: installment_interest,
                        principal: installment_principal,
                        total: installment_interest + installment_principal
                    })
                    month += 1
                    total_expected += (installment_interest + installment_principal)
                    start_date = get_next_payment(start_date)
                }
            }
            setData({
                ...data,
                period: (new_schedules.length / installments).toString(),
                end: new_schedules.length === 0 ? "" : new_schedules[new_schedules.length - 1].date,
                expected: total_expected.toString()
            })
            setSchedules(new_schedules)
            setLoading({message: "", show: false})
        }

        calculate()
    }, [data.principal, data.payment, data.start, data.interval, rate])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 col-md-4 p-1">
                        <MyInput
                            placeholder="Insert principal amount" title="Principal Receivable"
                            name="principal" value={data.principal} change={handle_change}/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyInput
                            placeholder="Insert payment to be made" title="Payment per interval"
                            name="payment" value={data.payment} change={handle_change}
                        />
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyDateInput
                            title="Payment start date" value={data.start} name="start"
                            placeholder="Payment start date" change={handle_change}/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MySelect
                            change={(value: select_type) => handle_change('interval', parseInt(value as string))}
                            title="Loan payment Interval" name="interval" value={data.interval}
                            options={[{text: "One Month", value: 30}]}/>
                    </div>

                    <div className="col-12 col-md-4 px-1 py-0">
                        <div className='row m-0'>
                            <div className="col-6 pr-1 pl-0 py-1">
                                <MyInput
                                    placeholder="Loan Rate" title="Loan Rate" name="rate" value={rate}
                                    change={(name, value) => setRate(value)}/>
                            </div>

                            <div className="col-6 pl-1 pr-0 py-1">
                                <MyInput placeholder="Loan Period" title="Loan Period" name="period" value={`${data.period} months`}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyDateInput
                            title="Payment due date" value={data.end} name="end" disabled={true}
                            placeholder="Payment due date" change={handle_change}/>
                    </div>

                    <div className="col-12 col-md-4 p-1">
                        <MyInput
                            placeholder="" title="Total amount expected" name="expected" value={Utils.comma_number(parseFloat(data.expected))}/>
                    </div>

                    <div className="col-12 p-0">
                        <div className="table_container interest_calculator_container">
                            <Table celled striped compact size='small' inverted color='grey' selectable>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                                        <Table.HeaderCell>Payment Date</Table.HeaderCell>
                                        <Table.HeaderCell>Principal</Table.HeaderCell>
                                        <Table.HeaderCell>Interest</Table.HeaderCell>
                                        <Table.HeaderCell>Total</Table.HeaderCell>
                                        <Table.HeaderCell>Balance</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        schedules.map((schedule, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell width={1}>
                                                    {Utils.row_number(index)}
                                                </Table.Cell>
                                                <Table.Cell>{Utils.localise_date(schedule.date)}</Table.Cell>
                                                <Table.Cell>{Utils.comma_number(schedule.principal)}</Table.Cell>
                                                <Table.Cell>{Utils.comma_number(schedule.interest)}</Table.Cell>
                                                <Table.Cell>{Utils.comma_number(schedule.total)}</Table.Cell>
                                                <Table.Cell>{Utils.comma_number(schedule.balance)}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 offset-md-6 p-1 calculated_buttons">
                        <div className='row m-0'>
                            <div className='col-6 p-1'>
                                <Button
                                    negative size='mini' content='Close' icon="close" labelPosition="right" fluid
                                    onClick={() => props.result()}/>
                            </div>
                            <div className='col-6 p-1'>
                                <Button
                                    positive size='mini' content='Use Data' icon="save" labelPosition="left" fluid
                                    onClick={use_data}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
