import React from "react";
import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import {CriteriaTrialBalance} from "../criteria/TrialBalanceCriteria";
import ReportJournals from "../ReportJournals";

interface Report {
    account_id: number
    account_name: string
    credit: number
    debit: number
}

export default function TrialBalance(params: { transactions: any, criteria: CriteriaTrialBalance }) {
    const [statements, setStatements] = React.useState<Array<Report>>([])
    const [showJournal, setShowJournal] = React.useState({account_id: 0})

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <>
            <ReportJournals
                account_id={showJournal.account_id} min_date={params.criteria.min_date}
                max_date={params.criteria.max_date} close={() => setShowJournal({account_id: 0})}/>

            <div className="table_container container_no_footer">
                <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '400px'}}>Account Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Debit Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Credit Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            statements
                                .filter((report) => report.debit > 0 || report.credit > 0)
                                .map((report, index) =>
                                    <Table.Row key={index} onClick={() => setShowJournal({account_id: report.account_id})}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                        <Table.Cell style={{width: '400px'}}>{report.account_name}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{report.debit !== 0 ? Utils.comma_number(report.debit) : ''}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{report.credit !== 0 ? Utils.comma_number(report.credit) : ''}</Table.Cell>
                                    </Table.Row>
                                )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
