import {LoanInfo} from "../../ShowLoan";
import {Table} from "semantic-ui-react";
import React from "react";

export default function JournalEntries(params: { loanInfo: LoanInfo }) {
    return (
        <div className="table_container full_height">
            <Table celled striped compact size='small' inverted structured color='grey' selectable className='has_total'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{width: '120px'}}>Entry Date</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '200px'}}>Account Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '200px'}}>Entity Type</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '120px'}}>Debit</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '120px'}}>Credit</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        params.loanInfo.others.journals.entries.map((entry) => {
                                return entry.items.map((item, index) =>
                                    index === 0 ?
                                        <Table.Row key={`${index}_${item.id}`}>
                                            <Table.Cell style={{width: '120px'}} rowSpan={entry.items.length}>{entry.date}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{item.ac}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{item.et}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{item.dr > 0 && item.dr.toLocaleString()}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{item.cr > 0 && item.cr.toLocaleString()}</Table.Cell>
                                        </Table.Row> :
                                        <Table.Row key={`${index}_${item.id}`}>
                                            <Table.Cell style={{width: '200px'}}>{item.ac}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{item.et}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{item.dr > 0 && item.dr.toLocaleString()}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{item.cr > 0 && item.cr.toLocaleString()}</Table.Cell>
                                        </Table.Row>
                                )
                            }
                        )
                    }

                    <Table.Row key={0}>
                        <Table.Cell style={{width: '520px'}} colSpan={3}></Table.Cell>
                        <Table.Cell style={{width: '120px'}}>{params.loanInfo.others.journals.debit.toLocaleString()}</Table.Cell>
                        <Table.Cell style={{width: '120px'}}>{params.loanInfo.others.journals.credit.toLocaleString()}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    )
}
