import {Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import React from "react";

export interface RcExpense {
    account: string
    amount: number
    date: string
    desc: string
    id: number
    office: string
    user: string
}

export default function RcExpenses(params: { expenses: RcExpense[] }) {
    return (
        <>
            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Branch Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '130px'}}>Created By</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Expense Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '170px'}}>Date</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '400px'}}>Description</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.expenses.map((expense, index) =>
                                <Table.Row key={expense.id}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{expense.office}</Table.Cell>
                                    <Table.Cell style={{width: '130px'}}>{expense.user}</Table.Cell>
                                    <Table.Cell style={{width: '150px'}}>{expense.account}</Table.Cell>
                                    <Table.Cell style={{width: '100px'}}>{Utils.comma_number(expense.amount)}</Table.Cell>
                                    <Table.Cell style={{width: '170px'}}>{Utils.localise_date_time(expense.date)}</Table.Cell>
                                    <Table.Cell style={{width: '400px'}}>{expense.desc}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
