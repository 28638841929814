import React from "react"
import Utils, {ConfirmAction, initial_confirm,} from "../../utils/Utils"
import {Loading, MyDateInput, MyInput, MyLabel} from "../../utils/Components"
import {Req} from "../../utils/Req"
import {Button, Confirm, Modal, Radio} from "semantic-ui-react"
import PhoneInput from "react-phone-input-2"
import {ToastsStore} from "react-toasts";
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";
import moment from "moment";
import {LoanInfo} from "./container/ShowLoan";
import {Account} from "../../utils/Models";

interface Payment {
    account_id: number
    payment_amount: string
    payer_name: string
    payer_contact: string
    loan_id: number
    client_name: string
    date_paid: string
    time_paid: string
}

export interface PaymentData {
    date: string,
    balance: {
        principal: number
        interest: number
        penalty: number
        total: number
        fee: number
    },
    client: {
        name: string
        contact: string | number
    },
    loan: {
        id: number
        disbursed: string
    }
}

const initial_payment: Payment = {
    client_name: "", loan_id: 0, payer_contact: "", payer_name: "", payment_amount: "", date_paid: "", time_paid: "", account_id: 0
}

export default function MakePayment(params: {
    loanInfo: PaymentData, show: boolean, accounts: Account[], close: (loanInfo?: LoanInfo, file?: string) => void
}) {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)

    const [payment, setPayment] = React.useState<Payment>(initial_payment)
    const handle_payment_change = (name: string, value: string | number) => {
        setPayment({...payment, [name]: value})
    }

    const make_payment = () => {
        const payment_amount = Utils.strip_commas(payment.payment_amount)
        if (Utils.invalid_full_name(payment.payer_name)) {
            ToastsStore.error("Enter a valid payer name")
        } else if (Utils.invalid_contact(payment.payer_contact)) {
            ToastsStore.error("Enter a valid payer contact")
        } else if (payment.date_paid !== "" && payment.time_paid === "") {
            ToastsStore.error("Enter time when payment was made")
        } else if (payment.time_paid !== "" && moment(payment.time_paid, "HH:mm").hours() < 8) {
            ToastsStore.error("Minimum time of payment must be 08:00 AM")
        } else if (payment.time_paid !== "" && moment(payment.time_paid, "HH:mm").hours() > 20) {
            ToastsStore.error("Maximum time of payment must be 08:00 PM")
        } else if (!Utils.is_valid_number(payment_amount)) {
            ToastsStore.error("Enter valid payment amount")
        } else if (parseFloat(payment_amount) < 0) {
            ToastsStore.error("Minimum payment cannot be less than 0")
        } else if (payment.account_id === 0) {
            ToastsStore.error("Select a mode of payment used")
        } else {
            setConfirm({
                ...confirm, open: true,
                content: `Are you sure you want to proceed with making a payment of 
                        "${Utils.comma_number(parseFloat(payment_amount))}" to loan "${payment.loan_id}"?`,
                onConfirm: () => {
                    setConfirm({...confirm, 'open': false})

                    setLoading({message: "Saving payment, please wait", show: true})
                    Req.make_payment({
                        loan_id: payment.loan_id, payer_contact: payment.payer_contact, payer_name: payment.payer_name,
                        total_paid: parseFloat(payment_amount), account_id: payment.account_id,
                        date_paid: payment.date_paid, time_paid: payment.time_paid,
                    })
                        .then((response) => {
                            setLoading({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    params.close(Utils.format_loan_data(response), `${Req.BASE_URL}${response.data.file}`)
                                } else if (response.data.code === 4) {
                                    ToastsStore.error(response.data.status === 'empty' ?
                                        "Your account hs not been opened up to make transactions" :
                                        "Your account has been closed for today's transactions"
                                    )
                                } else {
                                    ToastsStore.error(response.data.msg ?? "Could not make payment, please retry")
                                }
                            } else {
                                ToastsStore.error("Could not make payment, please retry")
                            }
                        })
                        .catch(() => {
                            ToastsStore.error("Could not make payment, please retry")
                            setLoading({message: "", show: false})
                        })

                },
                onCancel: () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    React.useEffect(() => {
        const contact = params.loanInfo.client.contact.toString()
        setPayment({
            payer_contact: Utils.invalid_contact(contact) ? "" : contact,
            client_name: params.loanInfo.client.name,
            payer_name: params.loanInfo.client.name,
            loan_id: params.loanInfo.loan.id,
            payment_amount: "",
            account_id: 0,
            date_paid: "",
            time_paid: ""
        })
    }, [params.loanInfo])

    return (
        <>
            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={params.show} basic size='mini' centered={false}>
                <div className="modal_div modal_form payment_modal">
                    <div className="form_header">
                        {payment.client_name} (Loan No: {payment.loan_id})
                    </div>

                    <div className="form_content">
                        <MyInput placeholder="Name of person making payment" title="Paid By" name="payer_name"
                                 value={payment.payer_name} change={handle_payment_change}/>

                        <MyLabel title="Contact of person making payment"/>
                        <PhoneInput
                            countryCodeEditable={false} containerStyle={{marginBottom: '0px'}}
                            country={'ug'} value={payment.payer_contact} onlyCountries={['ug']}
                            onChange={((value) => handle_payment_change('payer_contact', value))}/>

                        {
                            user.roles.core_roles?.includes("payment_backdating") &&
                            <div className="mt-3">
                                <MyLabel title={"Date and Time of payment"}/>
                                <div className='row m-0'>
                                    <div className="col-7 pl-0 pr-1">
                                        <MyDateInput
                                            title="" value={payment.date_paid} name="date_paid" type={"date"}
                                            maxDate={params.loanInfo.date} minDate={params.loanInfo.loan.disbursed}
                                            placeholder="Payment date" change={handle_payment_change}/>
                                    </div>
                                    <div className="col-5 pl-1 pr-0">
                                        <MyDateInput
                                            title="" value={payment.time_paid} name="time_paid" type={"time"}
                                            minDate={"08:00:00"} maxDate={"20:00:00"}
                                            placeholder="Payment date" change={handle_payment_change}/>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="mt-3">
                            <MyInput
                                title={`Total Amount Paid`} placeholder="Total Amount Paid" name="payment_amount"
                                value={Utils.comma_input(payment.payment_amount).toString()}
                                className={`payment_amount`} change={handle_payment_change}/>
                        </div>

                        <div className="row m-0">
                            <MyLabel title={"Select account receiving the amount paid"}/>
                            {
                                params.accounts
                                    .map((aAccount) =>
                                        <div key={aAccount.account_id} className={"col-12 px-0 py-1"}>
                                            <Radio
                                                label={aAccount.account_name}
                                                checked={payment.account_id === aAccount.account_id}
                                                onChange={(event, data) => {
                                                    if (data.checked) {
                                                        handle_payment_change('account_id', aAccount.account_id)
                                                    }
                                                }}/>
                                        </div>
                                    )
                            }
                        </div>

                    </div>

                    <div className="form_footer">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative onClick={() => params.close()} content="Close Window" size='mini' fluid
                                        icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={make_payment} content="Save Payment" size='mini' fluid
                                        icon="save" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
