import React from "react";
import {Button} from "semantic-ui-react";
import moment from "moment";

export default function SystemState(params: { locked: string, upgrade: boolean }) {
    const [countDown, setCountDown] = React.useState("0s")

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            if (params.locked !== "") {
                let difference = moment.duration(moment(params.locked).diff(moment(new Date()))).asSeconds()
                if(difference <= 0){
                    window.location.reload()
                }

                let time = `${Math.floor(difference / 3600)}hrs `

                difference %= 3600
                time += (Math.floor(difference / 60) + "min ")

                difference %= 60
                time += (`${Math.floor(difference)}sec`)

                setCountDown(time)
            } else {
                setCountDown("0s")
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [countDown, params.locked])

    const get_text = () => {
        if (params.locked !== '') {
            return `Sorry you cannot access the system now. It shall be accessible in<br/>${countDown}`
        } else if (params.upgrade) {
            return "There is a newer app version available, please press reload button to get the new version"
        }
        return ""
    }

    return (
        <>
            <div className="login_container">
                <div className="login_modal">
                    <h2 className='' dangerouslySetInnerHTML={{__html: get_text()}}/>

                    <Button primary className="ui button fluid" size='small' content="Reload Application"
                            onClick={() => window.location.reload()}/>
                </div>
            </div>
        </>
    )
}
